import { Button, Form, Input, Popconfirm } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { Area, GeofenceUpdate } from 'swanviz'

import { getTrimmedInputValue } from '../../../../utils/input'
import { usePermissions } from '../../../../utils/usePermissions'
import { DeleteButton } from '../../../DeleteButton'
import { InformationItem } from '../../../InformationItem'
import { PageTitle } from '../../../PageTitle'
import css from './style.module.css'

type Props = {
  geofence: GeofenceUpdate
  area: Area
  isAddingNewGeofence: boolean
  isSaving: boolean
  isDeleting: boolean
  onChange: (geofence: GeofenceUpdate) => void
  onSave: () => void
  onDelete: () => void
}

export const GeofenceHeader: React.FC<Props> = ({
  geofence,
  area,
  isAddingNewGeofence,
  isSaving,
  isDeleting,
  onChange,
  onSave,
  onDelete,
}) => {
  const {
    geofences: { canManage },
  } = usePermissions()
  const isNameValid = !!geofence.name
  const isNotEmpty = geofence.boundaries.length >= 1
  const isValid = isNameValid && isNotEmpty

  return (
    <div className={classNames(css.main, canManage && css.canManage)}>
      {canManage ? (
        <Form className={css.name} onFinish={onSave}>
          <Form.Item
            validateStatus={isNotEmpty && !isNameValid ? 'error' : undefined}
            className={css.input}
          >
            <Input
              value={geofence.name}
              size="large"
              placeholder="Geofence name"
              onChange={(e) =>
                onChange({
                  ...geofence,
                  name: getTrimmedInputValue(e),
                })
              }
            />
          </Form.Item>
          <Button
            type="primary"
            danger
            htmlType="submit"
            disabled={!isValid}
            loading={isSaving}
          >
            Save geofence
          </Button>
        </Form>
      ) : (
        <PageTitle className={css.title}>{geofence.name}</PageTitle>
      )}
      <InformationItem label="Area" className={css.area}>
        {area.name}
      </InformationItem>
      {!isAddingNewGeofence && canManage && (
        <Popconfirm
          title="Are you sure you want to delete this geofence?"
          placement="bottomLeft"
          okText="Yes"
          cancelText="No"
          onConfirm={onDelete}
        >
          <DeleteButton className={css.delete} loading={isDeleting}>
            Delete geofence
          </DeleteButton>
        </Popconfirm>
      )}
    </div>
  )
}
