import { Form, message } from 'antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { loginUser } from 'swanviz'

import { ERROR_MESSAGES } from '../../../utils/errorMessages'
import { emailRule, getRequiredRule } from '../../../utils/validationRules'
import { Button, Input, LoginWrapper } from '../../LoginWrapper'
import { DASHBOARD_URL } from '../../../utils/links'
import { useUserSession } from '../../../contexts/userSessionContext'
import css from './style.module.css'

type FormValues = {
  login: string
  password: string
  authCode?: string
}

export const Login: React.FC = () => {
  const history = useHistory()
  const [form] = Form.useForm<FormValues>()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [is2FARequired, setIs2FARequired] = React.useState(false)
  const { setUserIdSession } = useUserSession()

  const handleSubmit = async (values: FormValues) => {
    setIsSubmitting(true)
    try {
      const { id, valid, request2FA } = await loginUser(
        values.login,
        values.password,
        values.authCode
      )

      if (valid) {
        setUserIdSession(id)
        history.push(DASHBOARD_URL)
      } else if (request2FA && !is2FARequired) {
        setIs2FARequired(true)
      } else {
        message.error(ERROR_MESSAGES.invalidCredentials)
      }
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.login)
    }
    setIsSubmitting(false)
  }

  return (
    <LoginWrapper
      title={is2FARequired ? '2-step verification' : 'Log In'}
      subTitle={
        is2FARequired &&
        `We need to make sure that you are really ${form.getFieldValue(
          'login'
        )}.`
      }
      renderContent={() => (
        <Form form={form} onFinish={handleSubmit} noValidate={true}>
          <div className={is2FARequired ? css.isHidden : undefined}>
            <Input
              label="Username"
              type={is2FARequired ? 'hidden' : 'email'}
              name="login"
              rules={[emailRule]}
              disabled={is2FARequired}
            />
            <Input
              label="Password"
              type={is2FARequired ? 'hidden' : 'password'}
              name="password"
              rules={[getRequiredRule('Password')]}
              disabled={is2FARequired}
            />
          </div>
          {is2FARequired && (
            <div className={css.code}>
              <Input
                label="Code from the authentificator app"
                name="authCode"
                placeholder="Six-digit code"
                rules={[
                  getRequiredRule('Auth code'),
                  {
                    pattern: /^\d{6}$/,
                    message: 'Please enter 6-digit code',
                  },
                ]}
                autoFocus
                autoComplete="off"
                maxLength={6}
              />
            </div>
          )}
          <Button loading={isSubmitting}>Log In</Button>
        </Form>
      )}
      renderLink={() => (
        <Link to="/forgot-password">Forgot your password?</Link>
      )}
    />
  )
}
