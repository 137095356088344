import _ from 'lodash'
import React from 'react'
import { MissionRun, MissionRunFilter } from 'swanviz'

import { statusToFetchingTypeMap } from '../../utils/fetchingType'
import { filterSensorData } from './helpers'
import { SingleSensorDataFetcher } from './SingleSensorDataFetcher'
import { OnRunIsPausedChange, SensorDataByRun, SensorInfo } from './types'

type Props = {
  missionRunFilters: MissionRunFilter[]
  missionRunStatuses: Record<MissionRun['runId'], MissionRun['status']>
  children: (args: {
    sensorDataByRun: SensorDataByRun
    onRunIsPausedChange: OnRunIsPausedChange
  }) => React.ReactNode
}

export const SensorDataFetcher: React.FC<Props> = ({
  missionRunFilters,
  missionRunStatuses,
  children,
}) => {
  const [sensorDataByRun, setSensorDataByRun] = React.useState<SensorDataByRun>(
    {}
  )

  const setSensorInfo = ({
    runId,
    sensorId,
    info,
  }: {
    runId: number
    sensorId: number
    info: SensorInfo
  }) =>
    setSensorDataByRun((state) =>
      _.merge<SensorDataByRun, SensorDataByRun, SensorDataByRun>({}, state, {
        [runId]: {
          dataBySensor: {
            [sensorId]: info,
          },
          isPaused: state[runId]?.isPaused || false,
        },
      })
    )

  React.useEffect(() => {
    setSensorDataByRun((state) => filterSensorData(state, missionRunFilters))
  }, [missionRunFilters])

  const handleRunIsPausedChange = React.useCallback<OnRunIsPausedChange>(
    (runId, isPaused) => {
      setSensorDataByRun((state) => {
        const runData = state[runId]?.dataBySensor
        return _.merge<
          SensorDataByRun,
          SensorDataByRun,
          Partial<SensorDataByRun>
        >({}, state, {
          [runId]: runData && {
            dataBySensor: runData,
            isPaused,
          },
        })
      })
    },
    [setSensorDataByRun]
  )

  return (
    <>
      {children({
        sensorDataByRun,
        onRunIsPausedChange: handleRunIsPausedChange,
      })}
      {missionRunFilters.map(({ runId, sensorParamList }) => (
        <React.Fragment key={runId}>
          {sensorParamList.map((sensorId) => {
            const status = missionRunStatuses[runId]

            return (
              status && (
                <SingleSensorDataFetcher
                  key={sensorId}
                  runId={runId}
                  sensorId={sensorId}
                  fetchingType={statusToFetchingTypeMap[status]}
                  forcePause={sensorDataByRun[runId]?.isPaused || false}
                  onSuccess={(sensorData) => {
                    setSensorInfo({
                      runId,
                      sensorId,
                      info: {
                        data: sensorData,
                        isError: false,
                      },
                    })
                  }}
                  onError={() => {
                    setSensorInfo({
                      runId,
                      sensorId,
                      info: {
                        data: [],
                        isError: true,
                      },
                    })
                  }}
                />
              )
            )
          })}
        </React.Fragment>
      ))}
    </>
  )
}
