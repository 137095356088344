import React from 'react'
import { Button, Input, Space } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'

import { useWindowSize } from '../../utils/useWindowSize'
import css from './style.module.css'

export const TableSearch: React.FC<FilterDropdownProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const { isMobile } = useWindowSize()

  return (
    <div className={css.main}>
      <Input
        placeholder="Search"
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
        className={css.input}
        size={isMobile ? 'large' : undefined}
      />
      <Space>
        <Button
          type="primary"
          onClick={confirm}
          size="small"
          className={css.button}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" className={css.button}>
          Reset
        </Button>
      </Space>
    </div>
  )
}
