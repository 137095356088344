import { Button, Tooltip } from 'antd'
import React from 'react'
import { Area, SensorData } from 'swanviz'

import { isDefined } from '../../../../utils/typeGuards'
import { SensorDataByRun } from '../../../SensorDataFetcher/types'
import { FormValues } from '../types'

type Props = {
  area: Area
  datesRange: FormValues['datesRange']
  sensorDataByRun: SensorDataByRun
}

export const DownloadButton: React.FC<Props> = ({
  area,
  datesRange,
  sensorDataByRun,
}) => {
  const flatData: SensorData[] = React.useMemo(
    () =>
      Object.values(sensorDataByRun)
        .filter(isDefined)
        .flatMap((runData) => Object.values(runData.dataBySensor))
        .filter(isDefined)
        .flatMap((sensorInfo) => sensorInfo.data),
    [sensorDataByRun]
  )

  const isDisabled = flatData.length === 0

  const handleClick = () => {
    const linkEl = document.createElement('a')
    const csv = dataToCsv(flatData)
    linkEl.href = encodeURI(csv)
    linkEl.download = getFileName(area, datesRange)
    linkEl.click()
  }

  return (
    <Tooltip
      title="Select parameters to export"
      trigger={isDisabled ? undefined : []}
    >
      <Button type="link" disabled={isDisabled} onClick={handleClick}>
        ↓ Export to CSV
      </Button>
    </Tooltip>
  )
}

const getFileName = (
  area: Area,
  datesRange: FormValues['datesRange']
): string =>
  [area.name]
    .concat(datesRange.map((date) => date.format('DDMMYYYY')))
    .join('-') + '.csv'

const csvTitles: Record<keyof SensorData, string> = {
  time: 'Time',
  sensorParamId: 'Sensor id',
  location: 'Location',
  missionId: 'Mission id',
  missionName: 'Mission name',
  runDate: 'Run date',
  runId: 'Run id',
  sensorParamName: 'Sensor name',
  sensorParamValue: 'Sensor value',
  swanbotId: 'SwanBot id',
  swanbotName: 'SwanBot name',
}

const dataToCsv = (data: SensorData[]): string => {
  const firstDatum = data[0]
  const headerRow = Object.keys(firstDatum)
    .map((key) => `"${csvTitles[key as keyof SensorData] || key}"`)
    .join(',')

  return (
    'data:text/csv;charset=utf-8,' +
    [
      headerRow,
      ...data.map((datum) =>
        Object.values(datum)
          .map((value) => (typeof value === 'number' ? value : `"${value}"`))
          .join(',')
      ),
    ].join('\r\n')
  )
}
