import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import _ from 'lodash'
import React from 'react'
import { Area } from 'swanviz'

type AreaId = Area['id']

export const AreasSelect = <
  TMode extends SelectProps<unknown>['mode'] = undefined
>({
  areas,
  ...rest
}: {
  areas: Area[]
  mode?: TMode
} & SelectProps<TMode extends 'multiple' ? AreaId[] : AreaId>) => {
  return (
    <Select
      dropdownMatchSelectWidth={false}
      optionFilterProp="children"
      {...rest}
    >
      {_.sortBy(areas, 'name').map((area) => (
        <Select.Option key={area.id} value={area.id}>
          {area.name}
        </Select.Option>
      ))}
    </Select>
  )
}
