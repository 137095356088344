import { Typography } from 'antd'
import React from 'react'
import { MissionRunEssential, SwanBot } from 'swanviz'

import { useLatestImage } from '../../../../utils/useLatestImage'
import css from './style.module.css'

type Props = {
  missionRun: MissionRunEssential
  device: SwanBot
}

export const MissionImage: React.FC<Props> = ({ missionRun, device }) => {
  const { data } = useLatestImage(missionRun.runId)

  if (!data) {
    return null
  }

  return (
    <div className={css.main}>
      <img className={css.image} src={data.imageURL} alt={device.name || ''} />
      <Typography.Text className={css.text}>
        {device.name}&apos;s view
      </Typography.Text>
    </div>
  )
}
