import { Spin, Typography } from 'antd'
import React from 'react'
import { MissionRun, SensorData, SensorParameter } from 'swanviz'

import { useSelectedHeatmap } from '../../../../contexts/selectedHeatmapContext'
import { MapLegendItem } from '../../../MapLegendItem'
import { ChartRange } from '../LinearChart'
import { SensorChart } from '../SensorChart'

type Props = {
  missionRun: MissionRun
  sensor: SensorParameter
  sensorData: SensorData[] | undefined
  xRange: ChartRange
  color: string
  withGraph: boolean
  isError?: boolean
  disableZoom: boolean
  onChangeColor: (color: string) => void
}

export const MissionRunSensor: React.FC<Props> = ({
  missionRun,
  sensor,
  sensorData,
  xRange,
  color,
  withGraph,
  isError,
  disableZoom,
  onChangeColor,
}) => {
  if (isError) {
    const sensorText = `"${sensor.description.replace(' ', ' ')}"`

    return (
      <Typography.Text type="danger">
        Couldn&apos;t get data for sensor {sensorText}
        <br />
        <Typography.Link href="">Reload page</Typography.Link> to try again
      </Typography.Text>
    )
  }

  const content = withGraph ? (
    <SensorChart
      missionRun={missionRun}
      sensor={sensor}
      data={sensorData || []}
      color={color}
      xRange={xRange}
      disableZoom={disableZoom}
      onChangeColor={onChangeColor}
    />
  ) : (
    <MissionRunSensorLegend
      missionRun={missionRun}
      sensor={sensor}
      sensorData={sensorData}
      color={color}
      onChangeColor={onChangeColor}
    />
  )

  return <Spin spinning={!sensorData}>{content}</Spin>
}

const MissionRunSensorLegend: React.FC<
  Pick<
    Props,
    'missionRun' | 'sensor' | 'sensorData' | 'color' | 'onChangeColor'
  >
> = ({ missionRun: { runId }, sensor, sensorData, color, onChangeColor }) => {
  const { isHeatmapSelected, setSelectedHeatmap } = useSelectedHeatmap()
  const isSelected = isHeatmapSelected(runId, sensor.id)

  return (
    <MapLegendItem
      color={color}
      sensor={sensor}
      data={sensorData}
      isActive={isSelected}
      onClick={
        isSelected ? undefined : () => setSelectedHeatmap(runId, sensor.id)
      }
      onChangeColor={onChangeColor}
    />
  )
}
