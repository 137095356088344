import { Button, Form, Input, Popconfirm } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MissionType, NewMissionType } from 'swanviz'

import { useEstimatesUpdates } from '../../../../contexts/estimatesUpdatesContext'
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg'
import { getTrimmedInputValue } from '../../../../utils/input'
import { getMissionLink } from '../../../../utils/links'
import { isMissionRunning } from '../../../../utils/missionRuns'
import { usePermissions } from '../../../../utils/usePermissions'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { BackButton } from '../../../BackButton'
import { DeleteButton } from '../../../DeleteButton'
import { PageTitle } from '../../../PageTitle'
import { isExistingMission } from '../helpers'
import { MissionCopyButton } from '../MissionCopyButton'
import { validateMissionTasks } from '../validation'

import css from './style.module.css'

type Props = {
  missionInfo: React.ReactNode
  runControls: React.ReactNode | undefined
  mission: MissionType | NewMissionType
  isAnyTaskSelected: boolean
  isViewMode: boolean
  onChangeMission: (mission: Partial<MissionType>) => void
  onSave: () => void
  onDelete: () => void
  isStatusUpdating: boolean
  isMissionSaving: boolean
}

export const MissionHeader: React.FC<Props> = ({
  missionInfo,
  runControls,
  mission,
  isAnyTaskSelected,
  isViewMode,
  onChangeMission,
  onSave,
  onDelete,
  isStatusUpdating,
  isMissionSaving,
}) => {
  const { isMobile } = useWindowSize()
  const permissions = usePermissions()
  const { isEstimateUpdating } = useEstimatesUpdates()
  const location = useLocation()
  const isNewMode = !isExistingMission(mission)

  const [form] = Form.useForm<MissionType>()

  React.useLayoutEffect(() => {
    form.setFieldsValue(mission)
  }, [mission])

  const isUpdatingEstimates = mission.missionTasks.some((task) =>
    isEstimateUpdating(task.id)
  )
  const tasksValidationError = validateMissionTasks(mission.missionTasks)
  const isSaveAllowed =
    !isAnyTaskSelected && !isUpdatingEstimates && !tasksValidationError

  if (isMobile) {
    return (
      <div className={css.main}>
        <Link to="/missions-and-geofences">
          <BackButton type="link" />
        </Link>
        <PageTitle className={css.title}>{mission.name}</PageTitle>
      </div>
    )
  }

  const backLink =
    isExistingMission(mission) && !isViewMode
      ? getMissionLink({ id: mission.id, mode: 'view' })
      : '/missions-and-geofences'

  const renderEditOrSaveButton = () => {
    if (!permissions.missions.canManage) {
      return null
    }
    if (isViewMode) {
      return (
        <div className={css.buttons}>
          <Link
            to={{
              ...location,
              search: '?mode=edit',
            }}
          >
            <Button
              disabled={isStatusUpdating || isMissionRunning(mission)}
              type="ghost"
              icon={<EditIcon className={css.icon} />}
            >
              Edit
            </Button>
          </Link>
          <MissionCopyButton mission={mission} />
        </div>
      )
    }
    return (
      <Form.Item
        validateStatus={tasksValidationError && 'error'}
        help={tasksValidationError}
        className={css.saveButtonFormItem}
      >
        <Button
          type="primary"
          danger
          htmlType="submit"
          disabled={!isSaveAllowed}
          loading={isMissionSaving}
        >
          {isNewMode ? 'Create mission' : 'Save mission'}
        </Button>
      </Form.Item>
    )
  }

  return (
    <Form
      className={classNames(
        css.main,
        permissions.missions.canManage && css.canManage
      )}
      form={form}
      initialValues={mission}
      onValuesChange={(_changedValues, values) => {
        onChangeMission(values)
      }}
      onFinish={() => {
        if (!isSaveAllowed) return
        onSave()
      }}
    >
      <Link to={backLink}>
        <BackButton
          disabled={!isSaveAllowed}
          className={css.backButton}
          type="link"
        />
      </Link>

      <div>
        {isViewMode ? (
          <PageTitle className={css.title} title={mission.name}>
            {mission.name}
          </PageTitle>
        ) : (
          <Form.Item
            name="name"
            rules={[{ required: true }]}
            help={[]}
            getValueFromEvent={getTrimmedInputValue}
          >
            <Input size="large" placeholder="Mission name" />
          </Form.Item>
        )}
        {renderEditOrSaveButton()}
      </div>

      <div className={css.content}>
        {missionInfo}
        {runControls && <div className={css.controls}>{runControls}</div>}
      </div>
      <div className={css.rightBlock}>
        {!isViewMode && !isNewMode && (
          <Popconfirm
            placement="bottomLeft"
            title="Are you sure you want to delete this mission?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No"
          >
            <DeleteButton type="link">Delete mission</DeleteButton>
          </Popconfirm>
        )}
      </div>
    </Form>
  )
}
