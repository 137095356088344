import _ from 'lodash'
import { MissionTask, MissionType, NewMissionType } from 'swanviz'

import { getNextId } from '../../../utils/id'
import type { MissionTaskWithSpeed, MissionTypeWithSpeed } from './'

export const mergeSpeedIntoTasks = (
  tasks: MissionTask[]
): MissionTaskWithSpeed[] => {
  let previousSpeed = 0
  return tasks.reduce<MissionTaskWithSpeed[]>((acc, task) => {
    if (task.type === 'speed') {
      previousSpeed = task.args.speed
      return acc
    }
    acc.push(
      previousSpeed
        ? {
            ...task,
            speed: previousSpeed,
          }
        : task
    )
    previousSpeed = 0
    return acc
  }, [])
}

export const splitSpeedFromTasks = (
  tasks: MissionTaskWithSpeed[]
): MissionTask[] => {
  let nextId = getNextId(tasks.map((task) => task.id))
  return tasks.flatMap((task): MissionTask[] => {
    if (task.speed) {
      return [
        {
          id: nextId++,
          type: 'speed',
          args: {
            speed: task.speed,
          },
          estDuration: 0,
          estDistance: 0,
        },
        _.omit(task, 'speed') as MissionTask,
      ]
    }

    return [task]
  })
}

const setIdsByOrder = (tasks: MissionTaskWithSpeed[]): MissionTaskWithSpeed[] =>
  tasks.map((task, idx) => ({ ...task, id: idx + 1 }))

export const parseMissionFromBackend = (
  mission: MissionType | NewMissionType
): MissionTypeWithSpeed => ({
  ...mission,
  missionTasks: _.sortBy(mergeSpeedIntoTasks(mission.missionTasks), 'id'),
})

export const prepareMissionForBackend = (
  mission: MissionTypeWithSpeed
): MissionType | NewMissionType => ({
  ...mission,
  missionTasks: splitSpeedFromTasks(setIdsByOrder(mission.missionTasks)),
})

export const isExistingMission = (
  mission: NewMissionType | MissionType
): mission is MissionType => 'id' in mission
