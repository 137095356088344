import { stringify } from 'query-string'
import {
  createEnumParam,
  DecodedValueMap,
  encodeQueryParams,
  NumberParam,
  withDefault,
} from 'use-query-params'

import { ALERTS_URL } from '../../../utils/links'
import { NOTIFICATION_TYPES } from '../../../utils/notifications'
import {
  createEnumArrayParam,
  NotEmptyNumericArrayParam,
} from '../../../utils/queryParamConfigs'
import { DEFAULT_SORT_DIRECTION, DEFAULT_SORT_FIELD } from './AlertsTable'

const SortFieldEnumParam = createEnumParam([
  'ntf_time',
  'type',
  'area_id',
  'swanbot_id',
])

const SortDirectionEnumParam = createEnumParam(['ASC', 'DESC'])

const TypesEnumParam = createEnumArrayParam(NOTIFICATION_TYPES)

export const queryParamsConfig = {
  pageNo: withDefault(NumberParam, 1),
  sortField: withDefault(SortFieldEnumParam, DEFAULT_SORT_FIELD),
  sortDirection: withDefault(SortDirectionEnumParam, DEFAULT_SORT_DIRECTION),
  type: TypesEnumParam,
  areaId: NotEmptyNumericArrayParam,
  swanbotId: NotEmptyNumericArrayParam,
}

export const getAlertsLink = (
  params: Partial<DecodedValueMap<typeof queryParamsConfig>>
): string =>
  `${ALERTS_URL}?${stringify(encodeQueryParams(queryParamsConfig, params))}`
