import _ from 'lodash'
import React from 'react'
import { getUserSession, removeUserSession } from 'swanviz'
import { cache } from 'swr'

type ContextValue = {
  userIdSession: number | undefined
  setUserIdSession: (userId: number) => void
  unsetUserIdSession: () => void
}

const UserSessionContext = React.createContext<ContextValue>({
  userIdSession: undefined,
  setUserIdSession: _.noop,
  unsetUserIdSession: _.noop,
})

export const UserSessionProvider: React.FC = ({ children }) => {
  const [userIdSession, setUserIdSession] = React.useState(
    // lazy initial state to read items from local storage only once (on first render)
    // {@link https://reactjs.org/docs/hooks-reference.html#lazy-initial-state docs}
    () => {
      const userSession = getUserSession()
      return userSession ? userSession.id : undefined
    }
  )

  const unsetUserIdSession = () => {
    setUserIdSession(undefined)
    cache.clear()
    removeUserSession()
  }

  return (
    <UserSessionContext.Provider
      value={{ userIdSession, setUserIdSession, unsetUserIdSession }}
    >
      {children}
    </UserSessionContext.Provider>
  )
}

export const useUserSession = () => React.useContext(UserSessionContext)
