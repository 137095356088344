import classNames from 'classnames'
import * as React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { MissionTask, TaskStatus } from 'swanviz'

import { getTaskTypeName } from '../../../utils/missionTask'
import { DragHandle } from '../../DragHandle'
import type { OnTaskHover, OnTaskSelect } from '../../pages/Mission'
import { ItemContainer } from '../ItemContainer'
import { TaskEstimates } from '../TaskEstimates'
import { TextRows } from '../TextRows'

import { ReactComponent as FailedSVG } from './failed.svg'
import { getTaskParamsString } from './helpers'
import { ReactComponent as PencilSVG } from './pencil.svg'
import { ReactComponent as ProgressSVG } from './progress.svg'
import css from './style.module.css'
import { ReactComponent as SuccessSVG } from './success.svg'

type Props = {
  task: MissionTask
  totalDuration: number
  isActive: boolean
  isSorting: boolean
  isViewMode: boolean
  withStatus: boolean
  status?: TaskStatus['taskStatus']
  onHover: OnTaskHover
  onSelect: OnTaskSelect
}

export const Task = SortableElement(
  ({
    task,
    totalDuration,
    isActive,
    isSorting,
    isViewMode,
    onHover,
    onSelect,
    withStatus,
    status,
  }: Props) => (
    <ItemContainer
      className={classNames(
        css.main,
        isActive && css.isActive,
        isSorting && !isActive && css.isHoverDisabled
      )}
      task={
        <div className={css.task}>
          {!isViewMode && <DragHandle />}
          {withStatus && (
            <div className={css.iconWrapper}>{getStatusIcon(status)}</div>
          )}
          <TextRows
            firstRow={getTaskTypeName(task.type)}
            secondRow={getTaskParamsString(task)}
          />
        </div>
      }
      estimation={
        <>
          <TaskEstimates task={task} totalDuration={totalDuration} />
          {!isViewMode && <PencilSVG className={css.pencil} />}
        </>
      }
      onClick={() => onSelect(task.id)}
      onMouseEnter={() => onHover(task.id)}
      onMouseLeave={() => onHover(undefined)}
    />
  )
)

const getStatusIcon = (status: Props['status']) => {
  if (status === 'TASK COMPLETED') {
    return <SuccessSVG className={css.successIcon} />
  }

  if (status === 'TASK STARTED') {
    return <ProgressSVG className={css.progressIcon} />
  }

  if (status === 'TASK FAILED') {
    return <FailedSVG className={css.failedIcon} />
  }

  return null
}
