import { Empty, Popover, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { Coordinates, SensorParameter } from 'swanviz'

import { dateAndTimeFormat } from '../../../../utils/momentFormat'
import {
  getSensorParamDesc,
  IMAGES_SENSOR_ID,
} from '../../../../utils/sensorParameters'
import { isDefined } from '../../../../utils/typeGuards'

import css from './style.module.css'
import { useLocationData } from './useLocationData'
import { MissionRunFilterWithShowPath } from '../types'

type Props = {
  coordinates: Coordinates
  missionRunFilters: MissionRunFilterWithShowPath[]
  sensors: SensorParameter[]
}

export const MapPopover: React.FC<Props> = ({
  coordinates,
  missionRunFilters,
  sensors,
}) => {
  const { data, isLoading, isDebouncing } = useLocationData({
    coordinates,
    missionRunFilters,
  })

  if (!data || isLoading || isDebouncing) {
    return null
  }

  const content = missionRunFilters
    .map(({ runId, sensorParamList }) => {
      const runData = data.filter((datum) => datum.runId === runId)

      if (!runData.length) {
        return undefined
      }

      const { missionName, swanbotName, runDate } = runData[0]

      const image = runData.find(
        (datum) => datum.sensorParamId === IMAGES_SENSOR_ID
      )?.sensorParamValue
      const sensorData = runData.filter(
        (datum) => datum.sensorParamId !== IMAGES_SENSOR_ID
      )

      return (
        <div key={runId} className={css.run}>
          {typeof image === 'string' && (
            <img src={image} alt={missionName} className={css.image} />
          )}
          <div className={css.info}>
            <Typography.Text type="secondary" className={css.subTitle}>
              {missionName}
              {' · '}
              {swanbotName}
              {' · '}
              <span className={css.date}>
                {moment(runDate).format(dateAndTimeFormat)}
              </span>
            </Typography.Text>
            {sensorParamList.map((sensorId) => {
              const sensor = sensors.find((sensor) => sensor.id === sensorId)
              const sensorDatum = sensorData.find(
                (datum) => datum.sensorParamId === sensorId
              )

              return sensor && sensorDatum ? (
                <div key={sensorId} className={css.sensor}>
                  {getSensorParamDesc(sensor)}
                  <span className={css.sensorValue}>
                    {sensorDatum.sensorParamValue?.toLocaleString() ?? '—'}
                  </span>
                </div>
              ) : null
            })}
          </div>
        </div>
      )
    })
    .filter(isDefined)

  return (
    <Popover
      visible
      content={
        content.length ? (
          content
        ) : (
          <Empty
            description="No data for this point"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
      }
      placement="rightBottom"
      overlayClassName={css.main}
    />
  )
}
