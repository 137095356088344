import { Alert, Tabs } from 'antd'
import React from 'react'
import { getUserProfile } from 'swanviz'
import useSWR, { mutate } from 'swr'

import { useWindowSize } from '../../../utils/useWindowSize'
import { CenteredSpin } from '../../CenteredSpin'
import { PageTitle } from '../../PageTitle'
import { ReloadPageResult } from '../../ReloadPageResult'

import { ChangePasswordForm } from './ChangePasswordForm'
import { ProfileForm } from './ProfileForm'
import { TwoStepForm } from './TwoStepForm'

import css from './style.module.css'
import { useUser } from '../../../contexts/userContext'
import { useUserSession } from '../../../contexts/userSessionContext'

export const UserSettings: React.FC = () => {
  const { isMobile } = useWindowSize()
  const { userIdSession } = useUserSession()
  const { user } = useUser()
  const { data, isValidating, error } = useSWR('getUserProfile', getUserProfile)

  if (isValidating && !data) {
    return <CenteredSpin tip="Loading user settings" />
  }

  if (error) {
    return <ReloadPageResult title="Couldn't get user settings" />
  }

  const title = <PageTitle className={css.title}>Settings</PageTitle>

  const profileForm = <ProfileForm className={css.form} data={data} />

  if (user?.pwdChangePending) {
    return (
      <div className={css.singleTabView}>
        <Alert
          className={css.alert}
          type="warning"
          showIcon
          message="Password change required"
          description="Please change password to gain full access to the service"
        />
        <ChangePasswordForm
          className={css.form}
          userId={user.id}
          onSuccess={() => mutate(['getUser', userIdSession])}
        />
      </div>
    )
  }

  if (isMobile) {
    return (
      <div className={css.mobileMain}>
        {title}
        {profileForm}
      </div>
    )
  }

  return (
    <>
      {title}
      <Tabs className={css.tabs}>
        <Tabs.TabPane tab="Profile" key="profile">
          {profileForm}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Change password" key="changePassword">
          {user && <ChangePasswordForm className={css.form} userId={user.id} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="2-step verification" key="2step">
          <TwoStepForm
            className={css.form}
            is2FAEnabled={Boolean(data?.is2FAEnabled)}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}
