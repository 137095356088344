import React from 'react'

const mobileBreakpoint = getComputedStyle(
  document.documentElement
).getPropertyValue('--mobile-breakpoint')

const getIsMobile = (): boolean => window.matchMedia(mobileBreakpoint).matches
const getIsSmallHeightLandscape = (): boolean =>
  window.matchMedia('(orientation: landscape) and (max-height: 400px)').matches

export const useWindowSize = (): {
  isMobile: boolean
  isDesktop: boolean
  isSmallHeightLandscape: boolean
} => {
  const [isMobile, setIsMobile] = React.useState(getIsMobile)
  const [isSmallHeightLandscape, setIsSmallHeightLandscape] = React.useState(
    getIsSmallHeightLandscape
  )

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(getIsMobile())
      setIsSmallHeightLandscape(getIsSmallHeightLandscape())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    isMobile,
    isDesktop: !isMobile,
    isSmallHeightLandscape,
  }
}
