import { Input } from 'antd'
import { InputProps } from 'antd/es/input'
import classnames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { PREDEFINED_COLORS } from '../../utils/colors'

import { ReactComponent as ColorPickerIcon } from './colorPicker.svg'
import css from './style.module.css'

type Props = {
  // Optional to support usage inside Form.Item
  value?: string | undefined | null
  view?: 'icon' | 'input'
  disabled?: boolean
  onChange?: (color: string) => void
  className?: string
}

export const ColorPicker: React.FC<Props> = ({
  value,
  view = 'icon',
  disabled,
  onChange = () => null,
  className,
}) => {
  const delayedOnChange = React.useCallback(_.debounce(onChange, 300), [
    onChange,
  ])
  const commonInputProps: InputProps &
    React.InputHTMLAttributes<HTMLInputElement> = {
    type: 'color',
    list: 'predefinedColors',
    value: value ?? undefined,
    disabled,
    onChange: (e) => delayedOnChange(e.target.value),
  }

  return (
    <>
      {view === 'icon' ? (
        <span className={classnames(css.main, className)}>
          <input {...commonInputProps} className={css.input} />
          <ColorPickerIcon />
        </span>
      ) : (
        <Input
          {...commonInputProps}
          className={classnames(css.colorInput, className)}
        />
      )}
      <datalist id="predefinedColors">
        {PREDEFINED_COLORS.map((color) => (
          <option key={color} value={color} />
        ))}
      </datalist>
    </>
  )
}
