import React from 'react'
import { Spin } from 'antd'

import css from './style.module.css'
import classNames from 'classnames'

export const CenteredSpin: React.FC<React.ComponentProps<typeof Spin>> = (
  props
) => (
  <Spin
    size="large"
    {...props}
    className={classNames(props.className, css.main)}
  />
)
