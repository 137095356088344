import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { DASHBOARD_URL } from '../../../utils/links'
import { isSwanBotOnMission } from '../../../utils/swanbot'
import { useAreasAndSwanBots } from '../../../utils/useAreasAndSwanBots'
import { useDocumentTitle } from '../../../utils/useDocumentTitle'
import { useWindowSize } from '../../../utils/useWindowSize'
import { BackButton } from '../../BackButton'
import { CenteredSpin } from '../../CenteredSpin'
import { PageTitle } from '../../PageTitle'
import { PageWithMapLayout } from '../../PageWithMapLayout'
import { ReloadPageResult } from '../../ReloadPageResult'

import { AreaMap } from './AreaMap'
import css from './style.module.css'
import { SwanBotList } from './SwanBotList'
import { SwanBotPathsFetcher } from './SwanBotPathsFetcher'

export const AreaPage: React.FC = () => {
  const {
    areas,
    swanBots: allSwanBots,
    isLoading,
    isError,
  } = useAreasAndSwanBots()
  const { areaId: areaIdParam } = useParams<{ areaId?: string }>()
  const area = areaIdParam
    ? areas.find((a) => a.id === parseInt(areaIdParam, 10))
    : null

  useDocumentTitle(area?.name ? `${area.name} area map` : 'Area map')

  if (isLoading) {
    return <CenteredSpin tip="Loading area data" />
  }

  if (!area || isError) {
    return <ReloadPageResult title="Couldn't get area data" />
  }

  const swanBotsOnMission = allSwanBots
    .filter((sb) => sb.areaId === area.id)
    .filter(isSwanBotOnMission)

  return (
    <AreaPageLayout
      header={
        <div className={css.header}>
          <Link to={DASHBOARD_URL}>
            <BackButton type="link" />
          </Link>
          <PageTitle className={css.title}>{area.name}</PageTitle>
        </div>
      }
      list={
        <div className={css.list}>
          <SwanBotList swanBots={swanBotsOnMission} />
        </div>
      }
      map={
        <SwanBotPathsFetcher swanBots={swanBotsOnMission}>
          {(pathsBySwanBot) => (
            <AreaMap
              area={area}
              swanBots={swanBotsOnMission}
              pathsBySwanBot={pathsBySwanBot}
            />
          )}
        </SwanBotPathsFetcher>
      }
    />
  )
}

const AreaPageLayout: React.FC<{
  header: React.ReactNode
  list: React.ReactNode
  map: React.ReactNode
}> = ({ header, list, map }) => {
  const { isDesktop } = useWindowSize()

  return isDesktop ? (
    <PageWithMapLayout header={header} aside={list} map={map} />
  ) : (
    <div className={css.mobileLayout}>
      {header}
      {map}
      {list}
    </div>
  )
}
