import { stringify } from 'query-string'
import {
  DecodedValueMap,
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  encodeQueryParams,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { INTERPOLATION_DEFAULTS } from '../../../utils/defaults'
import { DATA_URL } from '../../../utils/links'
import { isNotNil } from '../../../utils/typeGuards'
import type { InterpolationPageProps } from './'

export const interpolationQueryConfig = {
  areaId: NumberParam,
  sensorParam: StringParam,
  startDate: NumberParam,
  endDate: NumberParam,
  missionName: withDefault(StringParam, INTERPOLATION_DEFAULTS.missionName),
  swanbotName: withDefault(StringParam, INTERPOLATION_DEFAULTS.swanbotName),
  minDepth: withDefault(NumberParam, INTERPOLATION_DEFAULTS.depth.min),
  maxDepth: NumberParam,
  missionRunIds: DelimitedNumericArrayParam,
  missionRunDates: DelimitedNumericArrayParam,
  missionRunNames: DelimitedArrayParam,
  backUrl: withDefault(StringParam, DATA_URL),
}

export const getInterpolationLink = (
  params: Partial<DecodedValueMap<typeof interpolationQueryConfig>>
) =>
  `/interpolation?${stringify(
    encodeQueryParams(interpolationQueryConfig, params)
  )}`

export const useInterpolationPageProps = ():
  | InterpolationPageProps
  | undefined => {
  const [
    {
      areaId,
      sensorParam,
      startDate,
      endDate,
      missionName,
      swanbotName,
      minDepth,
      maxDepth,
      missionRunIds: queryMissionRunIds,
      missionRunDates: queryMissionRunDates,
      missionRunNames: queryMissionRunNames,
      backUrl,
    },
  ] = useQueryParams(interpolationQueryConfig)

  const areIncorrectQueryParams =
    !areaId || !sensorParam || !startDate || !endDate || !isNotNil(maxDepth)

  if (areIncorrectQueryParams) {
    return undefined
  }

  const missionRunIds = queryMissionRunIds?.filter(isNotNil) ?? []
  const missionRunDates = queryMissionRunDates?.filter(isNotNil) ?? []
  const missionRunNames = queryMissionRunNames?.filter(isNotNil) ?? []

  return {
    inputs: {
      areaId,
      sensorParam,
      startDate,
      endDate,
      minDepth,
      maxDepth,
      missionRunIds,
      missionName,
      swanbotName,
    },
    missionRuns: missionRunIds.map((runId, idx) => ({
      runId,
      missionName: missionRunNames[idx],
      runDate: missionRunDates[idx],
    })),
    backUrl,
  }
}
