import useSWR, { cache, Key } from 'swr'
import { Configuration, Fetcher } from 'swr/dist/types'

export const useCachedSWR = <Data>(
  key: Key,
  fetcher: Fetcher<Data>,
  options?: Configuration
) => {
  const isCacheHit = cache.has(key)
  return useSWR<Data>(key, () => (isCacheHit ? cache.get(key) : fetcher()), {
    revalidateOnMount: Boolean(key) && !isCacheHit,
    ...options,
  })
}
