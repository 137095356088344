import { Select, Typography } from 'antd'
import { SelectProps } from 'antd/lib/select'
import classnames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { SwanBot } from 'swanviz'

import { getMissionLink } from '../../utils/links'
import { shortTimeFormat } from '../../utils/momentFormat'
import { ALL_OPTION } from '../pages/DataVis/allOption'
import { StatusIcon } from '../StatusIcon'
import { SwanBotIcon } from '../SwanBotIcon'

import css from './style.module.css'

type Props = {
  swanBots: SwanBot[]
  value?: SwanBot['id']
  onChange?: (value: SwanBot['id']) => void
  disabled?: boolean
  isSwanBotDisabled?: (swanBot: SwanBot) => boolean
  shouldDisplayMissionLink?: (swanBot: SwanBot) => boolean
  withAllOption?: boolean
  size?: SelectProps<unknown>['size']
  className?: string
}

export const SwanbotSelect: React.FC<Props> = ({
  swanBots,
  value,
  onChange,
  disabled,
  isSwanBotDisabled = _.stubFalse,
  shouldDisplayMissionLink = _.stubFalse,
  withAllOption = false,
  size,
  className,
}) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={classnames(className, css.select, disabled && css.isDisabled)}
      dropdownMatchSelectWidth={false}
      size={size}
    >
      {withAllOption && <Select.Option value={ALL_OPTION}>All</Select.Option>}
      {swanBots.map((swanBot) => (
        <Select.Option
          key={swanBot.id}
          value={swanBot.id}
          disabled={isSwanBotDisabled(swanBot)}
        >
          <div className={css.option}>
            <SwanBotIcon swanBot={swanBot} className={css.icon} />
            <span className={css.name}>{swanBot.name}</span>{' '}
            <span className={css.status}>
              <StatusIcon status={swanBot.status} />
            </span>
          </div>
          <SwanBotOptionSubtitle
            swanBot={swanBot}
            withMissionLink={shouldDisplayMissionLink(swanBot)}
          />
        </Select.Option>
      ))}
    </Select>
  )
}

const SwanBotOptionSubtitle: React.FC<{
  swanBot: SwanBot
  withMissionLink: boolean
}> = ({
  swanBot: { currMissionId, currRunId, currRunDate },
  withMissionLink,
}) => {
  if (!currMissionId || !currRunId || !currRunDate) {
    return null
  }

  if (withMissionLink) {
    return (
      <div className={css.runDate}>
        <Link
          to={getMissionLink({
            id: currMissionId,
            mode: 'view',
            runId: currRunId,
          })}
          target="_blank"
        >
          <Typography.Link>On mission #{currMissionId}</Typography.Link>
        </Link>{' '}
        since {moment(currRunDate).format(shortTimeFormat)}
      </div>
    )
  }

  return (
    <Typography.Text type="secondary" className={css.runDate}>
      Started run #{currRunId} at {moment(currRunDate).format(shortTimeFormat)}
    </Typography.Text>
  )
}
