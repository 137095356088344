import { Button, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { InterpolationInputs, MissionRun } from 'swanviz'

import { DATA_URL } from '../../../utils/links'
import { CenteredSpin } from '../../CenteredSpin'
import { PageWithMapLayout } from '../../PageWithMapLayout'
import { ReloadPageResult } from '../../ReloadPageResult'

import { InterpolationHeader } from './InterpolationHeader'
import { InterpolationMapContainer } from './InterpolationMapContainer'
import { useInterpolationPageProps } from './query'
import { useInterpolationPageData } from './useInterpolationPageData'

export type InterpolationPageProps = {
  inputs: InterpolationInputs
  missionRuns: InterpolationMissionRun[]
  backUrl: string
}

export type InterpolationMissionRun = Pick<
  MissionRun,
  'runId' | 'runDate' | 'missionName'
>

export type InterpolationRunInfo = {
  run: InterpolationMissionRun
  visible: boolean
}

export const Interpolation: React.FC = () => {
  const pageProps = useInterpolationPageProps()

  if (!pageProps) {
    return (
      <Result
        status="error"
        title="Incorrect page URL"
        subTitle="Please check query params in page URL"
        extra={
          <Link to={DATA_URL}>
            <Button danger>Go to Data page</Button>
          </Link>
        }
      />
    )
  }

  return <InterpolationContent {...pageProps} />
}

const InterpolationContent: React.FC<InterpolationPageProps> = ({
  inputs,
  missionRuns,
  backUrl,
}) => {
  const pageData = useInterpolationPageData(inputs)
  const [isOriginalData, setIsOriginalData] = React.useState(false)

  if (pageData.loading) {
    return <CenteredSpin tip="Loading interpolation info" />
  }

  if (pageData.error) {
    return <ReloadPageResult title="Couldn't get interpolation info" />
  }

  const {
    interpolationInfo,
    isInterpolationInfoLoading,
    area,
    geofence,
    sensors,
  } = pageData.data
  const sensor = sensors.find((sensor) => sensor.name === inputs.sensorParam)

  if (!sensor) {
    return <ReloadPageResult title="Couldn't get sensor info" />
  }

  const header = (
    <InterpolationHeader
      interpolationInputs={inputs}
      area={area}
      geofence={geofence}
      backUrl={backUrl}
      isOriginalData={isOriginalData}
      onChangeIsOriginalData={setIsOriginalData}
    />
  )
  const map = (
    <InterpolationMapContainer
      interpolationInfo={interpolationInfo}
      interpolationInputs={inputs}
      area={area}
      geofence={geofence}
      missionRuns={missionRuns}
      isInterpolationInfoLoading={isInterpolationInfoLoading}
      isOriginalData={isOriginalData}
      sensor={sensor}
    />
  )

  return <PageWithMapLayout header={header} aside={null} map={map} />
}
