import { formatCoordinates } from '../../../utils/geojson'
import type { MissionTaskWithSpeed } from '../../pages/Mission'

export const getTaskParamsString = (
  task: MissionTaskWithSpeed
): string | undefined => {
  switch (task.type) {
    case 'go': {
      const { location } = task.args
      const locationText =
        location === 'home' ? 'Home' : location && formatCoordinates(location)
      return joinInfoParts([locationText, getSpeedText(task.speed)])
    }
    case 'sensorDepth':
    case 'waterSampler':
      return `${task.args.sensorDepth}m`
    case 'stationKeep':
    case 'survey':
      return joinInfoParts([`${task.args.duration}s`, getSpeedText(task.speed)])
    case 'lawnmower': {
      const { leg, legs, bearing, spacing } = task.args
      return joinInfoParts([
        `${bearing}°`,
        `${legs}×${leg}m`,
        `${spacing}m`,
        getSpeedText(task.speed),
      ])
    }
    case 'sensors':
      return task.args.enable ? 'Enable' : 'Disable'
  }
}

export const joinInfoParts = (
  parts: Array<string | number | null | undefined>
): string => parts.filter(Boolean).join(' · ')

const getSpeedText = (
  speed: MissionTaskWithSpeed['speed']
): string | undefined => (speed ? `${speed}m/s` : undefined)
