import React from 'react'
import { GoTaskLocation } from 'swanviz'
import { Radio, Typography } from 'antd'

import { formatCoordinates } from '../../../utils/geojson'
import { LocationInput } from '../../LocationInput'
import css from './style.module.css'

export const GoTaskLocationInput: React.FC<{
  isViewMode: boolean
  location?: GoTaskLocation
  onChange?: (location: GoTaskLocation) => void
}> = ({ isViewMode, location = null, onChange }) => {
  const isHome = location === 'home'

  if (isViewMode && isHome) {
    return <>Home</>
  }

  return (
    <>
      {!isViewMode && (
        <Radio.Group
          options={[
            {
              value: true,
              label: 'Home',
            },
            {
              value: false,
              label: 'Coordinates',
            },
          ]}
          value={isHome}
          disabled={isViewMode}
          optionType="button"
          className={css.radio}
          onChange={(e) => onChange?.(e.target.value ? 'home' : null)}
        />
      )}
      {location !== 'home' &&
        (isViewMode ? (
          <Typography.Text>{formatCoordinates(location)}</Typography.Text>
        ) : (
          <LocationInput
            value={location}
            withValidation={false}
            onChange={(newLocation) => onChange?.(newLocation)}
          />
        ))}
    </>
  )
}
