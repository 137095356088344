import { Spin, Typography } from 'antd'
import classNames from 'classnames'
import * as React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { MissionTask, TaskStatus } from 'swanviz'

import { move } from '../../utils/array'
import { isDefined } from '../../utils/typeGuards'
import type { HoveredTaskId, OnTaskHover, OnTaskSelect } from '../pages/Mission'

import { ItemContainer } from './ItemContainer'
import css from './style.module.css'
import { Task } from './Task'

type Props = {
  missionTasks: MissionTask[]
  taskStatuses: TaskStatus[] | undefined
  isViewMode: boolean
  isLoading: boolean
  hoveredTaskId: HoveredTaskId
  onTaskHover: OnTaskHover
  onTaskSelect: OnTaskSelect
  onChange: (newTasks: MissionTask[]) => void
}

export const TasksList: React.FC<Props> = ({
  missionTasks,
  isViewMode,
  onChange,
  taskStatuses,
  ...rest
}) => {
  const [isSorting, setIsSorting] = React.useState(false)

  return (
    <SortableList
      missionTasks={missionTasks}
      taskStatuses={taskStatuses}
      useDragHandle
      lockToContainerEdges
      lockAxis="y"
      isSorting={isSorting}
      isViewMode={isViewMode}
      helperClass={css.sortableHelper}
      onSortStart={() => setIsSorting(true)}
      onSortEnd={({ oldIndex, newIndex }) => {
        onChange(move(missionTasks, oldIndex, newIndex))
        setIsSorting(false)
      }}
      {...rest}
    />
  )
}

const SortableList = SortableContainer(
  ({
    missionTasks,
    taskStatuses,
    isViewMode,
    isSorting,
    isLoading,
    hoveredTaskId,
    onTaskHover,
    onTaskSelect,
  }: {
    missionTasks: MissionTask[]
    taskStatuses: TaskStatus[] | undefined
    isViewMode: boolean
    isSorting: boolean
  } & Pick<
    Props,
    'isLoading' | 'hoveredTaskId' | 'onTaskHover' | 'onTaskSelect'
  >) => {
    const isMissionRunning = isDefined(taskStatuses)

    return (
      <div
        className={classNames(css.main, !isViewMode && css.withBottomPadding)}
      >
        <Spin spinning={isLoading}>
          <div className={css.header}>
            <ItemContainer
              task={<Typography.Text type="secondary">Task</Typography.Text>}
              estimation={
                <Typography.Text type="secondary">Estimation</Typography.Text>
              }
            />
          </div>
          {missionTasks.map((task, taskIndex) => {
            const totalDuration = missionTasks
              .filter((task, idx) => idx <= taskIndex)
              .reduce((acc, task) => acc + task.estDuration, 0)

            const status =
              taskStatuses &&
              taskStatuses.find(({ taskId }) => taskId === task.id)?.taskStatus

            return (
              <Task
                key={task.id}
                task={task}
                index={taskIndex}
                isActive={task.id === hoveredTaskId}
                isSorting={isSorting}
                isViewMode={isViewMode}
                totalDuration={totalDuration}
                disabled={isViewMode}
                onHover={onTaskHover}
                onSelect={onTaskSelect}
                withStatus={isMissionRunning}
                status={status}
              />
            )
          })}
        </Spin>
      </div>
    )
  }
)
