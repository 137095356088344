import { PickInfo } from '@deck.gl/core/lib/deck'
import { GeoJsonLayer } from '@deck.gl/layers'
import type {
  Coordinates,
  MissionTask,
  MissionType,
  NewMissionType,
} from 'swanviz'
import { TaskStatus } from 'swanviz'

import type {
  HoveredTaskId,
  OnTaskHover,
  OnTaskSelect,
  SelectedTaskId,
} from '../../'
import { ORANGE_RGB } from '../../../../../utils/colors'
import {
  createLineFeature,
  createPointFeature,
  createPolygonFeature,
} from '../../../../../utils/geojson'
import {
  getPreviousLocation,
  getTaskPoints,
  TaskPoint,
} from '../../../../../utils/missionTasks'
import { isDefined, isNotNil } from '../../../../../utils/typeGuards'

import { getFeatureStyleGetter } from './pointMapStyles'

export const getMissionLayers = ({
  mission,
  taskStatuses,
  currentLocation,
  hoveredTaskId,
  selectedTaskId,
  isViewMode,
  onTaskHover,
  onTaskSelect,
}: {
  mission: MissionType | NewMissionType
  taskStatuses: TaskStatus[] | undefined
  currentLocation: Coordinates | null
  hoveredTaskId: HoveredTaskId
  selectedTaskId: SelectedTaskId
  isViewMode: boolean
  onTaskHover: OnTaskHover
  onTaskSelect: OnTaskSelect
}) => {
  const hoveredTask = mission.missionTasks.find(
    (missionTask) => missionTask.id === hoveredTaskId
  )
  const selectedTask = mission.missionTasks.find(
    (missionTask) => missionTask.id === selectedTaskId
  )

  const taskPoints = getTaskPoints(mission.missionTasks)

  const surveyLayer = getSurveyLayer({
    hoveredTask,
    selectedTask,
    isViewMode,
  })
  const lawnmowerLayer = getLawnmowerLayer({
    tasks: mission.missionTasks,
    hoveredTask,
    selectedTask,
  })

  const lineLayer = getLineLayer({
    startingLocation: shouldShowLineFromSwanBotToFirstPoint({
      taskPoints,
      taskStatuses,
    })
      ? currentLocation
      : null,
    coordinates: taskPoints.map((point) => point.coordinates),
  })

  const pointFeatures = taskPoints.map((point) =>
    createPointFeature(point.coordinates, { taskId: point.taskId })
  )

  const featureStyleGetter = getFeatureStyleGetter({
    isViewMode,
    tasks: mission.missionTasks,
    hoveredTaskId,
    selectedTaskId,
  })

  const getTaskIdFromPickInfo = (
    pickInfo: PickInfo<typeof pointFeatures[number]>
  ) => pickInfo.object?.properties?.taskId

  const pointsLayer = new GeoJsonLayer({
    id: 'missionPoints',
    data: pointFeatures,
    lineWidthUnits: 'pixels',
    pointRadiusUnits: 'pixels',
    getRadius: featureStyleGetter('radius'),
    getLineWidth: featureStyleGetter('width'),
    getFillColor: featureStyleGetter('fill'),
    getLineColor: featureStyleGetter('line'),
    onHover: (pickInfo) => onTaskHover(getTaskIdFromPickInfo(pickInfo)),
    onClick: (pickInfo) => onTaskSelect(getTaskIdFromPickInfo(pickInfo)),
    pickable: !isDefined(selectedTaskId),
  })

  return [surveyLayer, lineLayer, lawnmowerLayer, pointsLayer]
}

export const shouldShowLineFromSwanBotToFirstPoint = ({
  taskPoints,
  taskStatuses,
}: {
  taskPoints: TaskPoint[]
  taskStatuses: TaskStatus[] | undefined
}): boolean => {
  const firstTaskPoint = taskPoints.length ? taskPoints[0] : null
  const firstTaskPointStatus =
    firstTaskPoint &&
    taskStatuses?.find(
      (taskStatus) => taskStatus.taskId === firstTaskPoint.taskId
    )?.taskStatus
  return (
    !firstTaskPointStatus ||
    firstTaskPointStatus === 'TASK STARTED' ||
    firstTaskPointStatus === null
  )
}

const getLineLayer = ({
  startingLocation,
  coordinates,
}: {
  startingLocation: Coordinates | null
  coordinates: Coordinates[]
}) => {
  const lineFeature = createLineFeature(
    [startingLocation, ...coordinates].filter(isNotNil)
  )

  return new GeoJsonLayer({
    id: 'missionLine',
    data: [lineFeature],
    getLineColor: ORANGE_RGB,
    lineWidthUnits: 'pixels',
  })
}

const getSurveyLayer = ({
  hoveredTask,
  selectedTask,
  isViewMode,
}: {
  hoveredTask?: MissionTask
  selectedTask?: MissionTask
  isViewMode: boolean
}) => {
  const taskForPolygon = isViewMode ? selectedTask || hoveredTask : hoveredTask
  const coordinates =
    taskForPolygon?.type === 'survey' ? taskForPolygon.args.aoi : undefined

  return new GeoJsonLayer({
    id: 'surveyPolygon',
    data: coordinates ? [createPolygonFeature([coordinates])] : [],
    getLineColor: ORANGE_RGB,
    getFillColor: [...ORANGE_RGB, 0.2 * 255],
    lineWidthUnits: 'pixels',
  })
}

const getLawnmowerLayer = ({
  tasks,
  hoveredTask,
  selectedTask,
}: {
  tasks: MissionTask[]
  hoveredTask?: MissionTask
  selectedTask?: MissionTask
}) => {
  const task = selectedTask || hoveredTask

  if (task?.type === 'lawnmower') {
    const waypointsLocations = task.waypoints.map(
      (waypoint) => waypoint.location
    )
    const previousLocation = getPreviousLocation({
      taskId: task.id,
      allTasks: tasks,
    })

    if (previousLocation) {
      return new GeoJsonLayer({
        id: 'lawnmowerLine',
        data: [createLineFeature([previousLocation, ...waypointsLocations])],
        getLineColor: [0, 129, 239],
        getLineWidth: 2,
        lineWidthUnits: 'pixels',
      })
    }
  }
}
