import React from 'react'
import { FormInstance } from 'antd/es/form'
import { MissionTask } from 'swanviz'
import { useEstimatesUpdates } from '../../../contexts/estimatesUpdatesContext'
import { Button, Spin } from 'antd'

import { DeleteButton } from '../../DeleteButton'
import type { Props as TaskFormProps } from '../'
import css from './style.module.css'

export const TaskFormActions: React.FC<
  Pick<TaskFormProps, 'task' | 'isNewTask' | 'onSave' | 'onDelete'> & {
    form: FormInstance<MissionTask>
  }
> = ({ task, form, isNewTask, onSave, onDelete }) => {
  const { isEstimateUpdating } = useEstimatesUpdates()
  const isUpdating = task.type === 'lawnmower' && isEstimateUpdating(task.id)

  return (
    <Spin tip="Updating points on the map" spinning={isUpdating}>
      <Button type="primary" danger htmlType="submit" disabled={isUpdating}>
        Save
      </Button>

      {isNewTask && (
        <Button
          type="link"
          className={css.addMore}
          onClick={() => {
            form.validateFields().then(() => {
              onSave({ oneMoreTaskType: task.type })
            })
          }}
        >
          Save and create one more
        </Button>
      )}

      {!isNewTask && (
        <div className={css.delete}>
          <DeleteButton onClick={onDelete}>Delete task</DeleteButton>
        </div>
      )}
    </Spin>
  )
}
