import React from 'react'
import { getMissionRun, MissionRun } from 'swanviz'
import useSWR from 'swr'

import { MISSION_RUN_POLL_INTERVAL } from '../../../../poll-config'
import { isRunStarted } from '../../../../utils/missionRuns'

type Props = {
  missionRun: MissionRun
  onChange: (missionRun: MissionRun) => void
}

export const MissionRunUpdater: React.FC<Props> = ({
  missionRun,
  onChange,
}) => {
  const { runId } = missionRun
  const shouldUpdateStatus = isRunStarted(missionRun.status)

  useSWR(
    shouldUpdateStatus ? ['getMissionRun', runId] : null,
    () => getMissionRun(runId),
    {
      revalidateOnMount: false,
      refreshInterval: MISSION_RUN_POLL_INTERVAL,
      onSuccess: (patch) => {
        if (patch.status !== missionRun.status) {
          onChange({ ...missionRun, ...patch })
        }
      },
    }
  )

  return null
}
