import { EditAction, ModifyMode } from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import * as turf from '@turf/turf'
import { GeoJSON } from 'geojson'
import {
  Coordinates,
  getLawnmowerBearing,
  getLawnmowerLeg,
  getLawnmowerSpacing,
} from 'swanviz'

import { getTransparentColor } from '../../../../../utils/colors'
import {
  getLawnmowerPolygonPoints,
  LawnmowerMissionTask,
} from '../../../../../utils/lawnmower'

type EditPointType = 'length' | 'width' | 'bearing'

export const getEditLawnmowerLayer = ({
  task,
  startLocation,
  layerParams,
  onChange,
}: {
  task: LawnmowerMissionTask
  startLocation: Coordinates
  layerParams: {}
  onChange: (task: LawnmowerMissionTask) => void
}) => {
  const data = getLawnmowerFeatures(startLocation, task)

  const onEdit = ({ updatedData }: EditAction<typeof data>) => {
    const lengthPointCoordinates = getEditPointCoordinates(
      updatedData,
      'length'
    )
    const widthPointCoordinates = getEditPointCoordinates(updatedData, 'width')
    const bearingPointCoordinates = getEditPointCoordinates(
      updatedData,
      'bearing'
    )

    onChange({
      ...task,
      args: {
        ...task.args,
        bearing: getLawnmowerBearing({
          startLocation,
          endLocation: bearingPointCoordinates,
          length: task.args.leg,
          width: task.args.spacing * (task.args.legs - 1),
        }),
        leg: getLawnmowerLeg({
          startLocation,
          endLocation: lengthPointCoordinates,
          bearing: task.args.bearing,
        }),
        spacing: getLawnmowerSpacing({
          startLocation,
          endLocation: widthPointCoordinates,
          legs: task.args.legs,
          bearing: task.args.bearing,
        }),
      },
    })
  }

  return new EditableGeoJsonLayer({
    ...layerParams,
    data,
    mode: ModifyMode,
    selectedFeatureIndexes: [1, 2, 3],
    getLineColor: getTransparentColor,
    getFillColor: () => [0, 129, 239, 0.1 * 255],
    getTentativeFillColor: getTransparentColor,
    getTentativeLineColor: getTransparentColor,
    onEdit,
    _subLayerProps: {
      geojson: {
        getPointRadius: 0,
      },
    },
  })
}

const getEditPointCoordinates = (
  data: GeoJSON.FeatureCollection,
  type: EditPointType
): Coordinates => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const point = data.features.find(
    (feature): feature is GeoJSON.Feature<GeoJSON.Point> =>
      feature.properties?.type === type
  )!

  return point.geometry.coordinates as Coordinates
}

const getLawnmowerFeatures = (
  startingLocation: Coordinates,
  task: LawnmowerMissionTask
): GeoJSON.FeatureCollection => {
  const polygonPoints = getLawnmowerPolygonPoints(startingLocation, task)
  const editPoints = getLawnmowerEditIconsCoordinates(startingLocation, task)

  return {
    type: 'FeatureCollection',
    features: [
      turf.polygon([
        [...Object.values(polygonPoints), polygonPoints.bottomLeft],
      ]),
      createEditPoint(editPoints.bearing, 'bearing'),
      createEditPoint(editPoints.length, 'length'),
      createEditPoint(editPoints.width, 'width'),
    ],
  }
}

const createEditPoint = (
  coordinates: Coordinates,
  type: EditPointType
): GeoJSON.Feature<GeoJSON.Point> => turf.point(coordinates, { type })

export const getLawnmowerEditIconsCoordinates = (
  startingLocation: Coordinates,
  task: LawnmowerMissionTask
): {
  [key in EditPointType]: Coordinates
} => {
  const polygonPoints = getLawnmowerPolygonPoints(startingLocation, task)
  const lengthPoint = turf.midpoint(
    polygonPoints.topLeft,
    polygonPoints.topRight
  )
  const widthPoint = turf.midpoint(
    polygonPoints.topRight,
    polygonPoints.bottomRight
  )

  return {
    length: lengthPoint.geometry.coordinates as Coordinates,
    width: widthPoint.geometry.coordinates as Coordinates,
    bearing: polygonPoints.topRight,
  }
}
