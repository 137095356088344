import { HeatmapLayer } from '@deck.gl/aggregation-layers'
import React from 'react'
import { MissionRun, SensorData, SensorParameter } from 'swanviz'

import { useSelectedHeatmap } from '../../../../../contexts/selectedHeatmapContext'
import { getHeatmapLayer } from '../../../../../geoLayers/heatmapLayer'
import { isDefined } from '../../../../../utils/typeGuards'
import { GetSensorColor } from '../../../../../utils/useSensorColors'
import { SensorDataByRun } from '../../../../SensorDataFetcher/types'

export const useHeatmapLayers = ({
  missionRuns,
  sensors,
  sensorData,
  getSensorColor,
}: {
  missionRuns: MissionRun[]
  sensors: SensorParameter[]
  sensorData: SensorDataByRun
  getSensorColor: GetSensorColor
}): HeatmapLayer<SensorData>[] => {
  const { isHeatmapSelected } = useSelectedHeatmap()

  return React.useMemo(() => {
    return [...missionRuns]
      .reverse()
      .map((missionRun) => {
        const selectedSensorId = missionRun.sensorIds.find((sensorId) =>
          isHeatmapSelected(missionRun.runId, sensorId)
        )

        if (!isDefined(selectedSensorId)) return undefined

        const sensor = sensors.find((sensor) => sensor.id === selectedSensorId)
        const color = getSensorColor(missionRun.runId, selectedSensorId)
        const heatmapData =
          sensorData[missionRun.runId]?.dataBySensor[selectedSensorId]?.data

        return sensor && heatmapData?.length
          ? getHeatmapLayer({
              runId: missionRun.runId,
              data: heatmapData,
              sensor,
              color,
            })
          : undefined
      })
      .filter(isDefined)
  }, [missionRuns, sensors, sensorData, getSensorColor, isHeatmapSelected])
}
