import { GeoJsonLayer } from '@deck.gl/layers'
import { GeoJSON } from 'geojson'
import { MissionRunLocInfo } from 'swanviz'

import { BLUE_RGB, hexToRgb, rgbToHex } from '../utils/colors'
import { createLineFeature } from '../utils/geojson'

export const getMissionRunPathLayer = ({
  id,
  actualPath,
  hexColor = rgbToHex(BLUE_RGB),
  visible = true,
}: {
  id: number | string
  actualPath: MissionRunLocInfo[]
  hexColor?: string
  visible?: boolean
}): GeoJsonLayer<GeoJSON.Feature<GeoJSON.LineString>> => {
  return new GeoJsonLayer({
    id: `pathLine_${id}`,
    data: [createLineFeature([...actualPath.map((item) => item.location)])],
    getLineColor: hexToRgb(hexColor),
    lineWidthUnits: 'pixels',
    getLineWidth: 2,
    lineJointRounded: true,
    visible,
  })
}
