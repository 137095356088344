import { Typography } from 'antd'
import React from 'react'
import { Area } from 'swanviz'
import { BooleanParam, useQueryParam } from 'use-query-params'

import { usePermissions } from '../../../../utils/usePermissions'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { AddButton } from '../../../AddButton'
import { ModalContainer } from '../../../ModalContainer'
import { NewSwanBotForm } from '../NewSwanBotForm'

export const AddSwanBot: React.FC<{ areas: Area[] }> = ({ areas }) => {
  const permissions = usePermissions()
  const [isModalOpened, setModalOpened] = useQueryParam('add', BooleanParam)
  const { isMobile } = useWindowSize()

  if (isMobile || !permissions.swanbots.canAdd) {
    return null
  }

  const closeModal = () => setModalOpened(undefined)

  return (
    <>
      <AddButton onClick={() => setModalOpened(true)}>New SwanBot</AddButton>
      {isModalOpened && (
        <ModalContainer onClose={closeModal}>
          <Typography.Title level={2}>New SwanBot</Typography.Title>
          <NewSwanBotForm areas={areas} onSuccess={closeModal} />
        </ModalContainer>
      )}
    </>
  )
}
