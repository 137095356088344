import { Button, Typography } from 'antd'
import React from 'react'
import type { User } from 'swanviz'

import { useWindowSize } from '../../../../../utils/useWindowSize'
import { BackButton } from '../../../../BackButton'

import css from './style.module.css'

type Props = {
  user?: User
  onPasswordReset: () => void
  isResettingPassword: boolean
  onRequestClose: () => void
}

export const ModalTop: React.FC<Props> = ({
  user,
  onPasswordReset,
  isResettingPassword,
  onRequestClose,
}) => {
  const { isDesktop, isMobile } = useWindowSize()

  return (
    <>
      <div className={css.titleWrapper}>
        {isMobile && <BackButton onClick={onRequestClose} />}
        <Typography.Text className={css.title}>
          {user ? user.username : 'New User'}
        </Typography.Text>
      </div>
      {isDesktop && user && (
        <Button
          type="text"
          className={css.resetPassword}
          onClick={onPasswordReset}
          loading={isResettingPassword}
        >
          Reset password
        </Button>
      )}
    </>
  )
}
