import _ from 'lodash'
import React from 'react'
import {
  Area,
  Geofence,
  getAllTaskStatus,
  getMissionRun,
  MissionTask,
  MissionType,
  SwanBot,
} from 'swanviz'
import useSWR from 'swr'

import type { HoveredTaskId, MissionTypeWithSpeed, SelectedTaskId } from '../'
import { MISSION_TASKS_POLL_INTERVAL } from '../../../../poll-config'
import { MISSION_DEFAULTS } from '../../../../utils/defaults'
import { isRunExecuting } from '../../../../utils/missionRuns'
import { isDefined } from '../../../../utils/typeGuards'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { AddButton } from '../../../AddButton'
import { TasksList } from '../../../TasksList'

import { DesktopLayout } from '../DesktopLayout'
import { EstimatesUpdaters } from '../EstimatesUpdaters'
import { isExistingMission } from '../helpers'
import { MissionHeader } from '../MissionHeader'
import { MissionImage } from '../MissionImage'
import { MissionInfo } from '../MissionInfo'
import { MissionLog } from '../MissionLog'
import { MissionMap } from '../MissionMap'
import { MissionRunControls } from '../MissionRunControls'
import { MobileLayout } from '../MobileLayout'
import { TaskDrawer } from '../TaskDrawer'

import css from './style.module.css'
import { useSelectedSwanBot } from './useSelectedSwanBot'

type Props = {
  area: Area
  areas: Area[]
  swanBots: SwanBot[]
  geofences: Geofence[]
  mission: MissionTypeWithSpeed
  isMissionSaving: boolean
  isViewMode: boolean
  onChange: React.Dispatch<React.SetStateAction<MissionTypeWithSpeed>>
  onSave: () => void
  onDelete: () => void
}

export const MissionPageContent: React.FC<Props> = ({
  area,
  areas,
  swanBots,
  geofences,
  mission,
  isMissionSaving,
  isViewMode,
  onChange,
  onSave,
  onDelete,
}) => {
  const { isDesktop } = useWindowSize()

  const [hoveredTaskId, setHoveredTaskId] =
    React.useState<HoveredTaskId>(undefined)
  const [selectedTaskId, setSelectedTaskId] =
    React.useState<SelectedTaskId>(undefined)
  const [isAddingNewTask, setIsAddingNewTask] = React.useState(false)
  const [isStatusUpdating, setIsStatusUpdating] = React.useState(false)

  const geofence = geofences?.find(
    (geofence) => geofence.id === mission.geofenceId
  )

  const swanBotsForArea = React.useMemo(
    () =>
      swanBots.filter((swanbot) => {
        return swanbot.areaId === mission.areaId
      }) || [],
    [swanBots, mission.areaId]
  )
  const { swanBot, setSelectedSwanBotId } = useSelectedSwanBot({
    isViewMode,
    swanBots: swanBotsForArea,
    mission,
  })
  const runId = swanBot?.currRunId

  const { data: missionRun, isValidating: isMissionRunValidating } = useSWR(
    runId ? ['getMissionRun', runId] : null,
    () => (runId ? getMissionRun(runId) : undefined)
  )
  const isNewMissionRunLoading =
    isMissionRunValidating && missionRun?.runId !== runId

  const { data: taskStatuses, isValidating: areTaskStatusesValidating } =
    useSWR(
      runId ? ['getAllTaskStatus', runId] : null,
      async () => (runId ? await getAllTaskStatus(runId) : []),
      {
        refreshInterval: isRunExecuting(missionRun?.status)
          ? MISSION_TASKS_POLL_INTERVAL
          : 0,
      }
    )
  const areTaskStatusesLoading = !taskStatuses && areTaskStatusesValidating

  const updateTasks = (missionTasks: MissionTask[]) =>
    onChange((state) => ({ ...state, missionTasks }))

  React.useEffect(() => {
    isDefined(selectedTaskId) && setHoveredTaskId(undefined)
  }, [selectedTaskId])

  const onChangeMission = (missionPatch: Partial<MissionType>) =>
    onChange({ ...mission, ...missionPatch })

  const Layout = isDesktop ? DesktopLayout : MobileLayout

  const missionInfo = (
    <MissionInfo
      areas={areas}
      geofences={geofences}
      mission={mission}
      isViewMode={isViewMode}
      onChangeMission={onChangeMission}
    />
  )

  const runControls =
    isViewMode && isExistingMission(mission) ? (
      <MissionRunControls
        mission={mission}
        missionRun={missionRun}
        swanBot={swanBot}
        swanBots={swanBotsForArea}
        isStatusUpdating={isStatusUpdating}
        isMissionRunLoading={isNewMissionRunLoading}
        onRequestStart={() => setIsStatusUpdating(true)}
        onRequestFinish={() => setIsStatusUpdating(false)}
        onSelectSwanBot={setSelectedSwanBotId}
      />
    ) : undefined

  // If the mission is being edited at the same time as any of its tasks,
  // then the default speed value can be null, string or number.
  const missionSpeed =
    (mission.nominalSpeed && parseFloat(mission.nominalSpeed.toString())) ||
    MISSION_DEFAULTS.nominalSpeed.value

  const onEstimateChange = React.useCallback(
    (tasksUpdater) =>
      onChange((state) => ({
        ...state,
        missionTasks: tasksUpdater(state.missionTasks),
      })),
    [onChange]
  )

  return (
    <Layout
      header={
        <MissionHeader
          missionInfo={missionInfo}
          runControls={runControls}
          mission={mission}
          isViewMode={isViewMode}
          isAnyTaskSelected={isDefined(selectedTaskId)}
          onChangeMission={onChangeMission}
          onSave={onSave}
          onDelete={onDelete}
          isStatusUpdating={isStatusUpdating}
          isMissionSaving={isMissionSaving}
        />
      }
      tasks={
        <>
          {!isViewMode && (
            <EstimatesUpdaters
              tasks={mission.missionTasks}
              area={area}
              missionSpeed={missionSpeed}
              onChange={onEstimateChange}
            />
          )}

          <TasksList
            missionTasks={mission.missionTasks}
            hoveredTaskId={hoveredTaskId}
            isViewMode={isViewMode}
            isLoading={areTaskStatusesLoading}
            onTaskHover={setHoveredTaskId}
            onTaskSelect={setSelectedTaskId}
            onChange={updateTasks}
            taskStatuses={taskStatuses}
          />

          {!isViewMode && (
            <div className={css.addTask}>
              <AddButton onClick={() => setIsAddingNewTask(true)}>
                New mission task
              </AddButton>
            </div>
          )}

          <TaskDrawer
            missionTasks={mission.missionTasks}
            missionSpeed={missionSpeed}
            selectedTaskId={selectedTaskId}
            isViewMode={isViewMode}
            isAddingNewTask={isAddingNewTask}
            onChangeTasks={updateTasks}
            onSelect={setSelectedTaskId}
            onRequestClose={() => {
              setSelectedTaskId(undefined)
              setIsAddingNewTask(false)
            }}
          />
        </>
      }
      info={missionInfo}
      controls={runControls}
      map={
        <MissionMap
          mission={mission}
          missionRun={missionRun}
          taskStatuses={taskStatuses}
          area={area}
          device={swanBot}
          geofence={geofence}
          hoveredTaskId={hoveredTaskId}
          selectedTaskId={selectedTaskId}
          isViewMode={isViewMode}
          onTaskHover={setHoveredTaskId}
          onTaskSelect={isDesktop ? setSelectedTaskId : _.noop}
          onChangeTasks={updateTasks}
        />
      }
      image={
        swanBot &&
        missionRun &&
        isRunExecuting(missionRun.status) && (
          <MissionImage missionRun={missionRun} device={swanBot} />
        )
      }
      log={
        missionRun &&
        isRunExecuting(missionRun?.status) &&
        swanBot && (
          <MissionLog
            runId={missionRun.runId}
            areaId={area.id}
            swanBotId={swanBot.id}
          />
        )
      }
    />
  )
}
