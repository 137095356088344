import React from 'react'
import { Coordinates, getLocationData, LocationData } from 'swanviz'
import useSWR from 'swr'

import { IMAGES_SENSOR_ID } from '../../../../utils/sensorParameters'
import { useDebounce } from '../../../../utils/useDebounce'
import { MissionRunFilterWithShowPath } from '../types'

export const useLocationData = ({
  coordinates,
  missionRunFilters,
}: {
  coordinates: Coordinates
  missionRunFilters: MissionRunFilterWithShowPath[]
}): {
  data: LocationData[] | undefined
  isLoading: boolean
  isError: boolean
  isDebouncing: boolean
} => {
  const missionRunFiltersWithImages = React.useMemo(
    () =>
      missionRunFilters.map((missionRunFilter) => ({
        ...missionRunFilter,
        sensorParamList: [
          ...missionRunFilter.sensorParamList,
          IMAGES_SENSOR_ID,
        ],
      })),
    missionRunFilters
  )

  const debouncedCoordinates = useDebounce(coordinates, 100)

  const { data, isValidating, error } = useSWR(
    debouncedCoordinates
      ? ['getLocationData', debouncedCoordinates, missionRunFiltersWithImages]
      : null,
    () =>
      debouncedCoordinates
        ? getLocationData(debouncedCoordinates, missionRunFiltersWithImages)
        : undefined
  )

  return {
    data,
    isLoading: isValidating,
    isError: !!error,
    isDebouncing: coordinates !== debouncedCoordinates,
  }
}
