import {
  decodeArray,
  decodeNumericArray,
  encodeArray,
  encodeNumericArray,
  QueryParamConfig,
} from 'use-query-params'
import { isNotNil } from './typeGuards'

export const createEnumArrayParam = <T extends string>(
  values: T[]
): QueryParamConfig<(string | null)[] | null | undefined, T[] | undefined> => ({
  encode: encodeArray,
  decode: (input) => {
    const arr = decodeArray(input)
    return arr
      ? arr.filter((item): item is T => values.includes(item as any))
      : undefined
  },
})

export const NotEmptyNumericArrayParam: QueryParamConfig<
  (number | null)[] | null | undefined,
  number[] | undefined
> = {
  encode: encodeNumericArray,
  decode: (input) => {
    const arr = decodeNumericArray(input)?.filter(isNotNil)
    return arr?.length ? arr : undefined
  },
}
