import { Form, InputNumber, Radio } from 'antd'
import { FormInstance } from 'antd/es/form'
import React from 'react'

import { MissionTask } from 'swanviz'
import { MISSION_TASK_DEFAULTS } from '../../../utils/defaults'
import {
  getNotZeroRule,
  getRequiredNumberRules,
} from '../../../utils/validationRules'
import type { MissionTaskWithSpeed } from '../../pages/Mission'
import { DurationFormItem } from '../DurationFormItem'
import { GoTaskLocationInput } from '../GoTaskLocationInput'
import { InputNumberWithPostfix } from '../InputNumberWithPostfix'
import { Label } from '../Label'
import { SpeedFormItem } from '../SpeedFormItem'
import { SurveyTaskForm } from '../SurveyTaskForm'

type Props = {
  form: FormInstance<MissionTaskWithSpeed>
  isViewMode: boolean
  missionSpeed: number
}

export const TaskFormContent: React.FC<Props> = ({
  form,
  isViewMode,
  missionSpeed,
}) => {
  const taskType: MissionTask['type'] = form.getFieldValue('type')

  switch (taskType) {
    case 'go':
      return (
        <>
          <Form.Item
            label={<Label>Location</Label>}
            name={['args', 'location']}
            rules={[
              {
                validator: (_rule, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        'Please enter coordinates or select a point on the map'
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
            valuePropName="location"
          >
            <GoTaskLocationInput isViewMode={isViewMode} />
          </Form.Item>
          <SpeedFormItem isViewMode={isViewMode} missionSpeed={missionSpeed} />
        </>
      )

    case 'lawnmower': {
      const {
        bearing: bearingDefaults,
        spacing: spacingDefaults,
        leg: legDefaults,
        legs: legsDefaults,
      } = MISSION_TASK_DEFAULTS.lawnmower

      return (
        <>
          <Form.Item
            label={<Label>Initial direction</Label>}
            name={['args', 'bearing']}
            rules={getRequiredNumberRules(
              'direction',
              bearingDefaults.min,
              bearingDefaults.max,
              'Direction should be 0-360°'
            )}
          >
            <InputNumberWithPostfix
              min={bearingDefaults.min}
              max={bearingDefaults.max}
              precision={bearingDefaults.precision}
              readOnly={isViewMode}
              postfix="°"
            />
          </Form.Item>
          <Form.Item
            label={<Label>Spacing</Label>}
            name={['args', 'spacing']}
            rules={[
              ...getRequiredNumberRules(
                'spacing',
                spacingDefaults.min,
                spacingDefaults.max
              ),
              getNotZeroRule('spacing'),
            ]}
          >
            <InputNumberWithPostfix
              min={spacingDefaults.min}
              max={spacingDefaults.max}
              step={spacingDefaults.step}
              precision={spacingDefaults.precision}
              readOnly={isViewMode}
              postfix="m"
            />
          </Form.Item>
          <Form.Item
            label={<Label>Length of each leg</Label>}
            name={['args', 'leg']}
            rules={[
              ...getRequiredNumberRules(
                'length',
                legDefaults.min,
                legDefaults.max
              ),
              getNotZeroRule('length'),
            ]}
          >
            <InputNumberWithPostfix
              min={legDefaults.min}
              max={legDefaults.max}
              step={legDefaults.step}
              precision={legDefaults.precision}
              readOnly={isViewMode}
              postfix="m"
            />
          </Form.Item>
          <Form.Item
            label={<Label>Number of legs</Label>}
            name={['args', 'legs']}
            rules={getRequiredNumberRules(
              'number of legs',
              legsDefaults.min,
              legsDefaults.max
            )}
          >
            <InputNumber
              min={legsDefaults.min}
              max={legsDefaults.max}
              precision={legsDefaults.precision}
              readOnly={isViewMode}
            />
          </Form.Item>
          <SpeedFormItem isViewMode={isViewMode} missionSpeed={missionSpeed} />
        </>
      )
    }

    case 'survey':
      return (
        <SurveyTaskForm isViewMode={isViewMode} missionSpeed={missionSpeed} />
      )

    case 'stationKeep':
      return <DurationFormItem isViewMode={isViewMode} />

    case 'sensorDepth':
    case 'waterSampler': {
      const { min, max, step, precision } = MISSION_TASK_DEFAULTS.sensorDepth
      return (
        <Form.Item
          label={<Label>Sensor Depth</Label>}
          name={['args', 'sensorDepth']}
          rules={getRequiredNumberRules('sensor depth', min, max)}
        >
          <InputNumberWithPostfix
            min={min}
            max={max}
            step={step}
            precision={precision}
            readOnly={isViewMode}
            postfix="m"
          />
        </Form.Item>
      )
    }

    case 'sensors':
      return (
        <Form.Item label={<Label>Status</Label>} name={['args', 'enable']}>
          <Radio.Group
            options={[
              { label: 'Enable', value: true },
              { label: 'Disable', value: false },
            ]}
            optionType="button"
            buttonStyle="solid"
            disabled={isViewMode}
          />
        </Form.Item>
      )

    default:
      return null
  }
}
