import { Switch, Table } from 'antd'
import type { ColumnsType, ColumnType } from 'antd/es/table'
import type { ColumnFilterItem } from 'antd/es/table/interface'
import _ from 'lodash'
import React from 'react'
import { downloadUserLog, User } from 'swanviz'

import {
  getColumnFilterProps,
  getColumnSearchProps,
  sorter,
  stickyProps,
} from '../../../../utils/table'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { DownloadActivityLogButton } from '../../../DownloadActivityLogButton'
import { roleLabels } from '../../../../utils/roles'
import { isDefined, isNotNil } from '../../../../utils/typeGuards'

import css from './style.module.css'
import { PopulatedSwanbot } from '../../../../utils/populateSwanbots'

type Props = {
  users: User[]
  swanbots: PopulatedSwanbot[]
  onSelectUser: (id: number) => void
  onToggleIsEnabled: (user: User, newValue: boolean) => void
  loading: boolean
}

export type PopulatedUser = User & {
  swanbotsNames: string[]
}

export const UsersTable: React.FC<Props> = ({
  users,
  swanbots,
  onSelectUser,
  onToggleIsEnabled,
  loading,
}) => {
  const { isDesktop } = useWindowSize()

  const swanbotFilters: ColumnFilterItem[] = swanbots
    .map((swanbot) => swanbot.name)
    .filter(isNotNil)
    .sort()
    .map((swanbotName) => ({
      text: swanbotName,
      value: swanbotName,
    }))

  const swanbotsById = _.keyBy(swanbots, _.property('id'))

  const populatedUsers: PopulatedUser[] = users.length
    ? users.map((user) => ({
        ...user,
        swanbotsNames: user.swanbots
          .map((swanbotId) => swanbotsById[swanbotId]?.name)
          .filter(isNotNil),
      }))
    : []

  return (
    <Table
      loading={loading}
      rowKey={(row) => row.id}
      dataSource={populatedUsers}
      columns={getColumns({ onToggleIsEnabled, swanbotFilters, isDesktop })}
      pagination={false}
      rowClassName={css.row}
      scroll={{ x: 1000 }}
      {...stickyProps}
      onRow={(user) => ({
        onClick: () => onSelectUser(user.id),
      })}
    />
  )
}

const getColumns = ({
  onToggleIsEnabled,
  swanbotFilters,
  isDesktop,
}: {
  onToggleIsEnabled: Props['onToggleIsEnabled']
  swanbotFilters: ColumnFilterItem[]
  isDesktop: boolean
}): ColumnsType<PopulatedUser> => {
  const columns: Array<ColumnType<PopulatedUser> | undefined> = [
    isDesktop
      ? {
          title: 'Enable',
          dataIndex: 'enabled',
          width: 87,
          sorter: sorter('enabled'),
          render: (value: PopulatedUser['enabled'], user) => (
            <Switch
              checked={value}
              onChange={(checked, event) => {
                event.stopPropagation()
                onToggleIsEnabled(user, checked)
              }}
            />
          ),
        }
      : undefined,
    {
      title: 'Username',
      dataIndex: 'username',
      sorter: sorter('username'),
      ellipsis: true,
      defaultSortOrder: 'ascend',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
      width: '20%',
      ellipsis: !isDesktop,
      sorter: sorter('fullName'),
      ...getColumnSearchProps('fullName'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      width: 145,
      sorter: sorter('department'),
      ...getColumnSearchProps('department'),
    },
    {
      title: 'User roles',
      dataIndex: 'roles',
      width: 125,
      render: (value: PopulatedUser['roles']) =>
        value
          .map((role) => roleLabels[role])
          .filter(isDefined)
          .join(', '),
      filters: _.toPairs(roleLabels).map(([value, text]) => ({ text, value })),
      ...getColumnFilterProps('roles'),
    },
    {
      title: 'Assigned SwanBots',
      dataIndex: 'swanbotsNames',
      width: '20%',
      render: (value: PopulatedUser['swanbotsNames']) => value?.join(', '),
      filters: swanbotFilters,
      ...getColumnFilterProps('swanbotsNames'),
    },
    {
      title: 'Activity log',
      render: (_text, user) => (
        <DownloadActivityLogButton
          filename={`${user.fullName}'s log`}
          downloadAction={downloadUserLog}
        />
      ),
      width: isDesktop ? 120 : 140,
    },
  ]

  return columns.filter(isDefined)
}
