import React from 'react'
import { Typography } from 'antd'
import classnames from 'classnames'

import { ReactComponent as LogoSVG } from './logo.svg'
import css from './style.module.css'

export const Logo: React.FC<{
  isBig?: boolean
  className?: string
}> = ({ isBig, className }) => (
  <span className={classnames(css.main, className, isBig && css.isBig)}>
    <LogoSVG className={css.logo} />
    <div className={css.titleWrapper}>
      <Typography.Text className={css.title}>SWAN</Typography.Text>
      <Typography.Text className={css.subTitle}>by Subnero</Typography.Text>
    </div>
  </span>
)
