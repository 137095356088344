import { TextLayer } from '@deck.gl/layers'
import { AlignmentBaseline } from '@deck.gl/layers/text-layer/text-layer'
import * as turf from '@turf/turf'
import { Coordinates, MissionTask } from 'swanviz'

import { HALF_WHITE_RGBA } from '../../../../../utils/colors'
import {
  getNextLocation,
  getPreviousLocation,
} from '../../../../../utils/missionTasks'
import { isNotNil } from '../../../../../utils/typeGuards'

type TextDatum = {
  position: Coordinates
  text: string
  angle: number
  align: AlignmentBaseline
}

const TEXT_FONT_SIZE = 14
const TEXT_LINE_OFFSET = 4
const TEXT_PADDING_X = 4

export const getGoTaskDistancesLayer = (
  task: Extract<MissionTask, { type: 'go' }>,
  tasks: MissionTask[]
): TextLayer<any> | undefined => {
  const { location } = task.args
  if (!location || location === 'home') {
    return
  }

  const previousLocation = getPreviousLocation({
    taskId: task.id,
    allTasks: tasks,
  })
  const nextLocation = getNextLocation({
    taskId: task.id,
    allTasks: tasks,
  })

  const data: TextDatum[] = [
    previousLocation && getTextDatum(previousLocation, location),
    nextLocation && getTextDatum(location, nextLocation),
  ].filter(isNotNil)

  return new TextLayer<TextDatum>({
    id: 'goTaskDistances',
    data,
    getPosition: (d) => d.position,
    getText: (d) => d.text,
    getAngle: (d) => d.angle,
    getAlignmentBaseline: (d) => d.align,
    getSize: TEXT_FONT_SIZE,
    fontFamily: "'Inter', serif",
    backgroundColor: HALF_WHITE_RGBA,
    // Emulating offset from the line
    lineHeight: 1 + TEXT_LINE_OFFSET / TEXT_FONT_SIZE,
    backgroundPadding: [
      TEXT_PADDING_X,
      0,
      TEXT_PADDING_X,
      -1 * TEXT_LINE_OFFSET,
    ],
  })
}

const getTextDatum = (
  location1: Coordinates,
  location2: Coordinates
): TextDatum => {
  const bearing = turf.bearing(location1, location2, { final: true })
  const angle = 90 - bearing

  return {
    angle: bearing > 180 ? angle - 180 : angle,
    align: 'bottom',
    position: turf.midpoint(location1, location2).geometry
      .coordinates as Coordinates,
    text: `${Math.round(
      turf.distance(location1, location2, { units: 'meters' })
    )} m`,
  }
}
