import React from 'react'
import { SensorParameter } from 'swanviz'
import { Button, Typography } from 'antd'

import css from './style.module.css'

type Props = {
  sensors: SensorParameter[]
  onClick: (sensor: SensorParameter) => void
}

export const RestSensorsList: React.FC<Props> = ({ sensors, onClick }) => {
  return (
    <>
      <Typography.Title level={3} className={css.title}>
        Add new alert for:
      </Typography.Title>
      {sensors.map((sensor) => (
        <Button
          key={sensor.id}
          onClick={() => onClick(sensor)}
          type="primary"
          className={css.button}
        >
          {sensor.description}
        </Button>
      ))}
    </>
  )
}
