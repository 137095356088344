import { Modal } from 'antd'
import classNames from 'classnames'
import React from 'react'

import { toggleScroll } from '../../utils/scroll'
import { useWindowSize } from '../../utils/useWindowSize'
import css from './style.module.css'

type Props = {
  withPadding?: boolean
  onClose: () => void
}

export const ModalContainer: React.FC<Props> = ({
  withPadding = true,
  children,
  onClose,
}) => {
  const { isDesktop, isMobile } = useWindowSize()

  React.useEffect(() => {
    if (isMobile) {
      toggleScroll(false)
      return () => toggleScroll(true)
    }
  }, [isMobile])

  return isDesktop ? (
    <Modal visible footer={null} width={540} onCancel={onClose}>
      {children}
    </Modal>
  ) : (
    <div className={classNames(css.main, withPadding && css.withPadding)}>
      {children}
    </div>
  )
}
