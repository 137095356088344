import { Typography } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { downloadSwanBotLog, SwanBot } from 'swanviz'

import type { OnSwanBotUpdate } from '..'
import { getActivityLogFilename } from '../../../utils/swanbot'
import { usePermissions } from '../../../utils/usePermissions'
import { useWindowSize } from '../../../utils/useWindowSize'
import { BackButton } from '../../BackButton'
import { ColorPicker } from '../../ColorPicker'
import { DownloadActivityLogButton } from '../../DownloadActivityLogButton'
import { SwanBotStatus } from '../../SwanBotStatus'
import { LatestImage } from '../LatestImage'

import css from './style.module.css'

type Props = {
  swanBot: SwanBot
  onChange: OnSwanBotUpdate
  onRequestClose: () => void
}

export const ModalTop: React.FC<Props> = ({
  swanBot,
  onChange,
  onRequestClose,
}) => {
  const { isDesktop } = useWindowSize()
  const permissions = usePermissions()
  const canEdit = isDesktop && permissions.swanbots.canManage

  return (
    <div className={classNames(css.main, canEdit && css.canManage)}>
      <div className={css.info}>
        <div className={css.nameWrapper}>
          {canEdit ? (
            <ColorPicker
              className={css.colorPicker}
              value={swanBot.iconColor}
              view="input"
              onChange={(newColor) => onChange({ iconColor: newColor })}
            />
          ) : (
            <BackButton className={css.back} onClick={onRequestClose} />
          )}
          <Typography.Text
            editable={
              canEdit && {
                onChange: (name) => onChange({ name }),
                tooltip: false,
              }
            }
            className={css.name}
          >
            {swanBot.name}
          </Typography.Text>
        </div>
        <div className={css.status}>
          <SwanBotStatus swanBot={swanBot} />
        </div>
        {canEdit && (
          <div className={css.download}>
            <DownloadActivityLogButton
              filename={getActivityLogFilename(swanBot)}
              downloadAction={() => downloadSwanBotLog(swanBot.id)}
            >
              ↓ Download activity log
            </DownloadActivityLogButton>
          </div>
        )}
      </div>
      <span>
        <Typography.Text type="secondary">Added</Typography.Text>
        <br />
        <span className={css.date}>
          {moment(swanBot.registrationDate).format('D MMM YYYY')}
        </span>
      </span>
      <span>
        <Typography.Text type="secondary">ID</Typography.Text>
        <br />
        {swanBot.id}
      </span>
      {swanBot.currRunId && (
        <div className={css.image}>
          <Typography.Text type="secondary">Latest image</Typography.Text>
          <br />
          <LatestImage runId={swanBot.currRunId} />
        </div>
      )}
    </div>
  )
}
