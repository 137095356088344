import React from 'react'
import { MissionRun, MissionRunFilter, SensorParameter } from 'swanviz'

import type { InterpolationMissionRun } from '../'
import {
  GetSensorColor,
  SetSensorColor,
  useSensorColors,
} from '../../../../utils/useSensorColors'
import { PathsByRun, RunPathsFetcher } from '../../../RunPathsFetcher'
import { SensorDataFetcher } from '../../../SensorDataFetcher'
import { SensorDataByRun } from '../../../SensorDataFetcher/types'

export const InterpolationMapDataFetcher: React.FC<{
  missionRuns: InterpolationMissionRun[]
  sensor: SensorParameter
  isOriginalData: boolean
  children: (props: {
    pathsByRun: PathsByRun
    sensorDataByRun: SensorDataByRun
    getSensorColor: GetSensorColor
    setSensorColor: SetSensorColor
  }) => React.ReactElement
}> = ({ missionRuns, sensor, isOriginalData, children }) => {
  const missionRunFilters = React.useMemo<MissionRunFilter[]>(
    () =>
      sensor
        ? missionRuns.map((run) => ({
            runId: run.runId,
            sensorParamList: [sensor.id],
          }))
        : [],
    [missionRuns, sensor]
  )
  const missionRunStatuses = React.useMemo(
    () =>
      Object.fromEntries(
        missionRuns.map((run): [MissionRun['runId'], MissionRun['status']] => [
          run.runId,
          'MISSION COMPLETED',
        ])
      ),
    [missionRuns]
  )
  const { getSensorColor, setSensorColor } = useSensorColors(missionRunFilters)

  return (
    <RunPathsFetcher
      missionRunFilters={missionRunFilters}
      missionRunStatuses={missionRunStatuses}
    >
      {(pathsByRun) => (
        <SensorDataFetcher
          missionRunFilters={isOriginalData ? missionRunFilters : []}
          missionRunStatuses={missionRunStatuses}
        >
          {({ sensorDataByRun }) =>
            children({
              sensorDataByRun,
              pathsByRun,
              getSensorColor,
              setSensorColor,
            })
          }
        </SensorDataFetcher>
      )}
    </RunPathsFetcher>
  )
}
