import React from 'react'
import ReactDOM from 'react-dom'
import { message } from 'antd'

import './index.css'
import { App } from './App'

message.config({
  maxCount: 5,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
