import { stringify } from 'query-string'
import type { MissionPageMode } from '../components/pages/Mission'

export const DASHBOARD_URL = '/'
export const AREA_URL = '/area'
export const ALERTS_URL = '/alerts'
export const DATA_URL = '/data'
export const LOGIN_URL = '/login'
export const SETTINGS_URL = '/settings'
export const GEOFENCE_URL = '/geofence'

export const getAreaLink = (id: number): string => `${AREA_URL}/${id}`

export const getSwanBotLink = (id: number): string => `/swan-bots?id=${id}`

export const getMissionLink = <Mode extends MissionPageMode>(
  params:
    | { id: 'new' }
    | {
        id: number
        mode?: Mode
        runId?: Mode extends 'view' ? number : never
      }
): string => {
  if (params.id === 'new') {
    return '/mission/new'
  }
  const { id, mode, runId } = params
  const queryString = stringify({ mode, runId })
  return `/mission/${id}${queryString ? '?' + queryString : ''}`
}

export const getGeofenceLink = (id: number): string => `${GEOFENCE_URL}/${id}`
export const getNewGeofenceLink = (areaId: number): string =>
  `${GEOFENCE_URL}/new?areaId=${areaId}`
