import { GeoJsonLayer } from '@deck.gl/layers'
import { mask, centerOfMass, circle } from '@turf/turf'
import { GeoJSON } from 'geojson'
import { Geofence } from 'swanviz'

import { createPolygonFeature } from '../utils/geojson'

export const getGeofenceLayer = (geofence: Geofence) => {
  if (!geofence.boundaries.length) {
    return
  }

  const [geofenceFeature, ...obstructionFeatures] = geofence.boundaries.map(
    (boundary) => createPolygonFeature([boundary.polygon])
  )

  return new GeoJsonLayer({
    id: 'geojson',
    data: [getPolygonOutside(geofenceFeature), ...obstructionFeatures],
    getFillColor: () => [240, 243, 246, 0.5 * 255],
    getLineColor: () => [33, 31, 37, 0.2 * 255],
    lineWidthUnits: 'pixels',
  })
}

const getPolygonOutside = (polygon: GeoJSON.Feature<GeoJSON.Polygon>) => {
  const polygonCenter = centerOfMass(polygon).geometry?.coordinates

  if (!polygonCenter) {
    return polygon
  }

  const circleAroundPolygon = circle(polygonCenter, 50)

  return mask(polygon, circleAroundPolygon)
}
