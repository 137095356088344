import { message } from 'antd'
import React from 'react'

import { ERROR_MESSAGES } from './errorMessages'

export const useLocalStorageState = <T>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  // lazy initial state to read items from local storage only once (on first render)
  // {@link https://reactjs.org/docs/hooks-reference.html#lazy-initial-state docs}
  const [value, setValue] = React.useState(() => {
    let storageItem: string | null = null
    try {
      storageItem = localStorage.getItem(key)
    } catch (e) {
      console.error(e)
    }
    return storageItem ? (JSON.parse(storageItem) as T) : initialValue
  })

  React.useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (err) {
      console.error(err)
      try {
        // Clearing storage in case quota is exceeded or data is corrupted
        localStorage.removeItem(key)
      } catch (storageClearingError) {
        console.error(storageClearingError)
        message.error(ERROR_MESSAGES.localStorageUpdate)
      }
    }
  }, [value])

  return [value, setValue]
}
