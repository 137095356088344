import * as React from 'react'
import { SortableHandle } from 'react-sortable-hoc'

import { ReactComponent as DragHandleSVG } from './drag-handle.svg'

import css from './style.module.css'

export const DragHandle = SortableHandle(() => (
  <DragHandleSVG className={css.dragHandle} />
))
