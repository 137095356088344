import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, DatePicker, Form, Radio, Select } from 'antd'
import React, { FunctionComponent } from 'react'
import { Area, MissionType, SwanBot } from 'swanviz'

import { dateFormat } from '../../../../utils/momentFormat'
import { getItemsForSelect } from '../../../../utils/select'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { AreasSelect } from '../../../AreasSelect'
import { InformationItem } from '../../../InformationItem'
import { SwanbotSelect } from '../../../SwanbotSelect'
import { ALL_OPTION } from '../allOption'
import { DisplayAsOptions, FormValues } from '../types'

import css from './style.module.css'

const { RangePicker } = DatePicker
const { Option } = Select

export type Props = {
  values: FormValues
  areas: Area[]
  swanBots: SwanBot[]
  missions: MissionType[]
  anyMissionRunSelected: boolean
  onValuesChange: (newValue: FormValues) => void
}

export const displayAsOptions: DisplayAsOptions = [
  {
    value: 'map',
    label: 'Map',
  },
  {
    value: 'plot',
    label: 'Plot',
  },
]

export const DataForm: FunctionComponent<Props> = ({
  values,
  areas,
  swanBots,
  missions,
  anyMissionRunSelected,
  onValuesChange,
}) => {
  const { isDesktop, isMobile } = useWindowSize()
  const [form] = Form.useForm<FormValues>()

  React.useLayoutEffect(() => {
    form.setFieldsValue(values)
  }, [values])

  return (
    <ConfigProvider
      getPopupContainer={
        isMobile
          ? (trigger) => trigger?.parentElement || document.body
          : undefined
      }
    >
      <Form
        className={css.form}
        layout="inline"
        form={form}
        onValuesChange={(_changedValues, values) => onValuesChange(values)}
        size={isMobile ? 'large' : undefined}
      >
        <InformationItem
          className={css.date}
          titleClassName={css.subTitle}
          label="Date"
        >
          <Form.Item noStyle name="datesRange">
            <RangePicker
              dropdownClassName={css.rangePicker}
              allowClear={false}
              format={
                isMobile
                  ? (value) => value.format(dateFormat) // disables manual entry
                  : undefined
              }
            />
          </Form.Item>
        </InformationItem>
        <InformationItem titleClassName={css.subTitle} label="Area">
          <Form.Item noStyle name="areaId">
            <AreasSelect className={css.areaSelect} areas={areas} />
          </Form.Item>
        </InformationItem>
        <InformationItem titleClassName={css.subTitle} label="Mission">
          <Form.Item noStyle name="missionId">
            <Select
              className={css.missionSelect}
              dropdownMatchSelectWidth={false}
              showSearch
              optionFilterProp="children"
              dropdownAlign={{ points: ['tr', 'br'] }}
            >
              <Option value={ALL_OPTION}>All</Option>
              {getItemsForSelect(missions, values.areaId).map((mission) => (
                <Option key={mission.id} value={mission.id}>
                  {mission.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </InformationItem>
        <InformationItem
          className={css.swanBot}
          titleClassName={css.subTitle}
          label="SwanBot"
        >
          <Form.Item noStyle name="swanbotId">
            <SwanbotSelect
              swanBots={getItemsForSelect(swanBots, values.areaId)}
              withAllOption={true}
            />
          </Form.Item>
        </InformationItem>
        {isDesktop && (
          <InformationItem titleClassName={css.subTitle} label="Display as">
            <Form.Item noStyle name="displayAs">
              <Radio.Group>
                {displayAsOptions.map((displayAsOption) => (
                  <Radio.Button
                    key={displayAsOption.value}
                    value={displayAsOption.value}
                  >
                    {displayAsOption.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </InformationItem>
        )}
        {isDesktop && values.displayAs === 'plot' && anyMissionRunSelected && (
          <Form.Item name="hideMap">
            <HideMapButton />
          </Form.Item>
        )}
      </Form>
    </ConfigProvider>
  )
}

const HideMapButton: React.FC<{
  value?: boolean
  onChange?: (value: boolean) => void
}> = ({ value, onChange }) => (
  <Button
    className={css.hideMap}
    icon={value ? <EyeOutlined /> : <EyeInvisibleOutlined />}
    onClick={() => onChange?.(!value)}
  >
    {value ? 'Show map' : 'Hide map'}
  </Button>
)
