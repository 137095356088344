import React from 'react'

import { SCROLL_CONTAINER_ID } from '../../utils/scroll'
import { Navigation } from '../Navigation'
import { LandscapeUnsupportedAlert } from '../LandscapeUnsupportedAlert'
import css from './style.module.css'

export const PageLayout: React.FC = ({ children }) => {
  return (
    <>
      <Navigation />
      <LandscapeUnsupportedAlert />
      <div className={css.wrapper}>
        <div id={SCROLL_CONTAINER_ID} className={css.content}>
          {children}
        </div>
      </div>
    </>
  )
}
