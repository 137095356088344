import React from 'react'
import ApexChart from 'react-apexcharts'
import type { ApexOptions } from 'apexcharts'

import { SensorInfo } from '../SensorsTab/useSensorsTabData'

type Props = {
  data: SensorInfo['chartData']
}

export const SimpleSensorChart: React.FC<Props> = ({ data }) => (
  <ApexChart
    series={[
      {
        data,
      },
    ]}
    options={getChartOptions(data)}
    height={44}
  />
)

const getChartOptions = (chartData: SensorInfo['chartData']): ApexOptions => {
  const lastItem = chartData[chartData.length - 1]

  const annotations: ApexAnnotations = {
    points: [
      {
        x: lastItem[0],
        y: lastItem[1],
        marker: {
          size: 1,
          fillColor: '#A29FA8',
          strokeColor: '#A29FA8',
          radius: 2,
        },
      },
    ],
  }

  return {
    chart: {
      sparkline: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        opacityFrom: 0,
        opacityTo: 1,
        stops: [0, 30],
      },
    },
    stroke: {
      colors: ['#211F25'],
      width: 1,
    },
    grid: {
      padding: {
        top: 4,
        right: 4,
      },
    },
    annotations,
  }
}
