import { getLatestImage, Image } from 'swanviz'
import useSWR from 'swr'

import { LATEST_IMAGE_POLL_INTERVAL } from '../poll-config'

export const useLatestImage = (
  runId: number
): {
  data: Image | undefined
  isLoading: boolean
  isError: boolean
} => {
  const { data, isValidating: isLoading, error } = useSWR(
    ['getLatestImage', runId],
    async () => await getLatestImage(runId),
    {
      refreshInterval: LATEST_IMAGE_POLL_INTERVAL,
    }
  )

  return {
    data,
    isLoading,
    isError: !!error,
  }
}
