import { Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { MissionType } from 'swanviz'

import { shortDateFormat, shortTimeFormat } from '../../utils/momentFormat'
import { usePermissions } from '../../utils/usePermissions'
import { ALL_OPTION } from '../pages/DataVis/allOption'
import { getDataLink } from '../pages/DataVis/queryUtils'

export const LastRunLink: React.FC<{
  missionId: MissionType['id']
  areaId: MissionType['areaId']
  lastMissionRun: MissionType['lastMissionRun']
}> = ({ lastMissionRun, missionId, areaId }) => {
  const {
    dataVis: { canView: canViewDataVis },
  } = usePermissions()

  if (lastMissionRun === null) {
    return <Typography.Text>Never</Typography.Text>
  }

  const lastMissionRunMoment = moment(lastMissionRun)
  const isLastMissionRunToday = lastMissionRunMoment.isSame(moment(), 'day')
  const text = lastMissionRunMoment.format(
    isLastMissionRunToday ? shortTimeFormat : shortDateFormat
  )

  return canViewDataVis ? (
    <Link
      to={getDataLink({
        datesRange: [lastMissionRunMoment, lastMissionRunMoment],
        missionId: missionId,
        areaId: areaId,
        swanbotId: ALL_OPTION,
        displayAs: 'map',
      })}
    >
      <Typography.Link>{text}</Typography.Link>
    </Link>
  ) : (
    <>{text}</>
  )
}
