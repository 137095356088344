import { Button, Empty, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { Area, SwanBot } from 'swanviz'

import { getAreaLink, getSwanBotLink } from '../../../../utils/links'
import {
  batteryLevelSorter,
  swanBotStatusSorter,
} from '../../../../utils/swanbot'
import { sorter, stickyProps } from '../../../../utils/table'
import { usePermissions } from '../../../../utils/usePermissions'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { BatteryLevel } from '../../../BatteryLevel'
import { HomeIcon } from '../../../HomeIcon'
import { SwanBotIcon } from '../../../SwanBotIcon'
import { SwanBotStatus } from '../../../SwanBotStatus'
import { AreaDeleteButton } from '../AreaDeleteButton'
import { AreaEditButton } from '../AreaEditButton'
import { MissionNotifications } from '../MissionNotifications'

import css from './style.module.css'

type Props = {
  areas: Area[]
  swanBots: SwanBot[]
}

type AreaRow = Area & {
  children: SwanBot[] | EmptyRow[]
}
type EmptyRow = { areaId: number }
type TableRow = AreaRow | SwanBot | EmptyRow

const COLUMNS_COUNT = 5
const SKIPPED_CELL = { props: { colSpan: 0 } }

export const DashboardTable: React.FC<Props> = ({ areas, swanBots }) => {
  const { isDesktop } = useWindowSize()
  const {
    dashboard: { canEditArea },
    missions: { canView: canViewMissions },
  } = usePermissions()

  const treeData: AreaRow[] = _.sortBy(areas, 'name').map((area) => {
    const swanBotsFromArea = swanBots.filter(
      (swanBot) => swanBot.areaId === area.id
    )

    return {
      ...area,
      children: swanBotsFromArea.length
        ? swanBotsFromArea
        : [{ areaId: area.id }],
    }
  })

  return (
    <Table
      className={css.main}
      dataSource={treeData}
      columns={getColumns({
        canEditArea: isDesktop && canEditArea,
        canViewAreaMap: canViewMissions,
        isDesktop,
      })}
      pagination={false}
      rowKey={(row) => {
        if (isEmptyRow(row)) return 'empty'
        if (isSwanBotRow(row)) return `swanBot_${row.id}`
        return `area_${row.name}`
      }}
      rowClassName={(row) => (isSwanBotOrEmptyRow(row) ? '' : css.areaRow)}
      defaultExpandAllRows
      indentSize={0}
      {...stickyProps}
      size={isDesktop ? undefined : 'small'}
    />
  )
}

const getColumns = ({
  canEditArea,
  canViewAreaMap,
  isDesktop,
}: {
  canEditArea: boolean
  canViewAreaMap: boolean
  isDesktop: boolean
}): ColumnsType<TableRow> => {
  const columns: ColumnsType<TableRow> = [
    {
      title: 'SwanBot',
      dataIndex: 'name',
      width: isDesktop ? 180 : '37%',
      sorter: (a, b) =>
        isSwanBotRow(a) && isSwanBotRow(b) ? sorter('name')(a, b) : 0,
      render: (_value, row) => {
        if (isEmptyRow(row)) {
          return {
            children: (
              <Empty
                description="You have no SwanBots assigned to you in this area"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
            props: {
              colSpan: COLUMNS_COUNT,
            },
          }
        }

        if (isSwanBotRow(row)) {
          return (
            <div className={css.swanBotName}>
              <SwanBotIcon swanBot={row} className={css.swanIcon} />
              <Link to={getSwanBotLink(row.id)} target="_blank">
                {row.name}
              </Link>
            </div>
          )
        }

        return {
          children: (
            <div className={css.areaTitle}>
              <span className={css.areaName}>{row.name}</span>
              {canEditArea && (
                <>
                  <AreaEditButton area={row} />
                  <AreaDeleteButton area={row} />
                </>
              )}
              {canViewAreaMap && (
                <Link
                  to={getAreaLink(row.id)}
                  title="The currently running missions in the area"
                  className={css.areaLink}
                >
                  Area map
                </Link>
              )}
            </div>
          ),
          props: {
            colSpan: COLUMNS_COUNT,
          },
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: isDesktop ? 250 : '40%',
      sorter: (a, b) =>
        isSwanBotRow(a) && isSwanBotRow(b) ? swanBotStatusSorter(a, b) : 0,
      render: (_value, row) =>
        isSwanBotRow(row) ? <SwanBotStatus swanBot={row} /> : SKIPPED_CELL,
    },
    {
      title: isDesktop ? 'Battery voltage' : 'Battery',
      dataIndex: 'batteryLevel',
      width: isDesktop ? 160 : '23%',
      sorter: isDesktop
        ? (a, b) =>
            isSwanBotRow(a) && isSwanBotRow(b) ? batteryLevelSorter(a, b) : 0
        : false,
      render: (_value, row) =>
        isSwanBotRow(row) ? (
          <div className={css.battery}>
            <BatteryLevel level={row.batteryLevel} isCompact={!isDesktop} />
          </div>
        ) : (
          SKIPPED_CELL
        ),
    },
    {
      title: 'Location',
      dataIndex: 'home',
      width: 125,
      render: (_value, row) => {
        return isSwanBotRow(row) ? (
          <Link to={getSwanBotLink(row.id)} target="_blank">
            <Button
              className={css.homeLink}
              type="text"
              icon={<HomeIcon className={css.homeIcon} />}
            >
              Show
            </Button>
          </Link>
        ) : (
          SKIPPED_CELL
        )
      },
    },
    {
      title: 'Notifications',
      render: (_value, row) =>
        isSwanBotRow(row)
          ? row.currRunId && (
              <MissionNotifications missionRunId={row.currRunId} />
            )
          : SKIPPED_CELL,
    },
  ]

  return isDesktop ? columns : _.take(columns, 3)
}

const isSwanBotOrEmptyRow = (row: TableRow): row is SwanBot | EmptyRow =>
  'areaId' in row

const isSwanBotRow = (row: TableRow): row is SwanBot =>
  isSwanBotOrEmptyRow(row) && !isEmptyRow(row)

const isEmptyRow = (row: TableRow): row is EmptyRow => {
  const keys = Object.keys(row)
  return keys.length === 1 && keys[0] === 'areaId'
}
