import { message } from 'antd'
import React from 'react'
import type { User } from 'swanviz'
import { getUser } from 'swanviz'
import useSWR from 'swr'

import { CenteredSpin } from '../components/CenteredSpin'
import { ERROR_MESSAGES } from '../utils/errorMessages'
import { useUserSession } from './userSessionContext'

type ContextValue = {
  user: User | undefined
}

const UserContext = React.createContext<ContextValue>({
  user: undefined,
})

export const UserProvider: React.FC = ({ children }) => {
  const { userIdSession, unsetUserIdSession } = useUserSession()

  const { data: user, isValidating } = useSWR(
    userIdSession ? ['getUser', userIdSession] : null,
    userIdSession ? () => getUser(userIdSession) : null
  )

  React.useEffect(() => {
    if (user && !user.enabled) {
      unsetUserIdSession()
      message.error(ERROR_MESSAGES.userIsDisabled)
    }
  }, [user])

  const shouldShowSpinner = (userIdSession && !user) || (isValidating && !user)

  return (
    <UserContext.Provider value={{ user }}>
      {shouldShowSpinner ? <CenteredSpin tip="Loading user data" /> : children}
    </UserContext.Provider>
  )
}

export const useUser = () => React.useContext(UserContext)
