import classnames from 'classnames'
import React from 'react'
import { SwanBot } from 'swanviz'

import css from './style.module.css'
import { ReactComponent as SwanBotSVG } from './swanBot.svg'

type Props = {
  swanBot: SwanBot
  className?: string
  style?: React.CSSProperties
}

export const SwanBotIcon: React.FC<Props> = ({ swanBot, className, style }) => {
  return (
    <SwanBotSVG
      className={classnames(css.main, className)}
      style={{
        color: swanBot.iconColor ?? undefined,
        ...style,
      }}
    />
  )
}
