import React from 'react'
import { Empty, Result, Spin } from 'antd'
import { SwanBot } from 'swanviz'

import { useSensorsTabData } from './useSensorsTabData'
import css from './style.module.css'
import { SimpleSensorChart } from '../SensorSimpleChart'

type Props = {
  swanBot: SwanBot
}

export const SensorsTab: React.FC<Props> = ({ swanBot }) => {
  const { sensors, isLoading, isError } = useSensorsTabData(swanBot)

  if (!swanBot.currRunId) {
    return (
      <Empty
        description="This SwanBot currently is not on the mission"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    )
  }

  return (
    <Spin spinning={!sensors.length && isLoading}>
      {isError ? (
        <Result
          status="error"
          title="Couldn't get SwanBot's sensors data"
          subTitle="Try to close and then open this modal again"
        />
      ) : sensors.length ? (
        <>
          {sensors.map((sensor) => {
            return (
              <div key={sensor.id} className={css.row}>
                <div className={css.cell}>{sensor.name}</div>
                <div className={css.cell}>
                  <SimpleSensorChart data={sensor.chartData} />
                </div>
                <div className={css.cell}>{sensor.latestData}</div>
              </div>
            )
          })}
        </>
      ) : (
        <Empty
          description="No data from SwanBot's current mission yet"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </Spin>
  )
}
