import React from 'react'
import { Typography } from 'antd'
import classNames from 'classnames'
import { Boundary } from 'swanviz'

import { removeAt, updateAt } from '../../../../utils/array'
import { DeleteButton } from '../../../DeleteButton'
import type { HoveredPolygonId } from '../'
import { AddButton } from '../../../AddButton'

import css from './style.module.css'
import { usePermissions } from '../../../../utils/usePermissions'

type Props = {
  obstructions: Boundary[]
  hoveredPolygonId: HoveredPolygonId
  isAddingNewPolygon: boolean
  onHover: (id: HoveredPolygonId) => void
  onChange: (obstructions: Boundary[]) => void
  onAdd: () => void
}

export const ObstructionsList: React.FC<Props> = ({
  obstructions,
  hoveredPolygonId,
  isAddingNewPolygon,
  onHover,
  onChange,
  onAdd,
}) => {
  const {
    geofences: { canManage },
  } = usePermissions()

  const handleDelete = (idx: number) => onChange(removeAt(obstructions, idx))
  const handleNameChange = (idx: number, name: string) =>
    onChange(
      updateAt(obstructions, idx, {
        ...obstructions[idx],
        name,
      })
    )

  return (
    <>
      <div className={css.item}>
        <Typography.Text type="secondary">Obstructions</Typography.Text>
      </div>
      {obstructions.length ? (
        obstructions.map(({ id, name }, idx) => {
          return (
            <div
              key={id}
              className={classNames(
                css.item,
                css.obstruction,
                id === hoveredPolygonId && css.isHovered
              )}
              onMouseEnter={() => onHover(id)}
              onMouseLeave={() => onHover(undefined)}
            >
              <Typography.Text
                editable={
                  canManage && {
                    onChange: (value) => handleNameChange(idx, value),
                    tooltip: false,
                  }
                }
                className={css.name}
              >
                {name}
              </Typography.Text>
              {canManage && (
                <DeleteButton
                  onClick={() => handleDelete(idx)}
                  className={css.delete}
                />
              )}
            </div>
          )
        })
      ) : (
        <div className={css.item}>No obstructions</div>
      )}

      {canManage && (
        <div className={css.add}>
          <AddButton disabled={isAddingNewPolygon} onClick={onAdd}>
            New obstruction
          </AddButton>
        </div>
      )}
    </>
  )
}
