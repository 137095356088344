import { PlusOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Coordinates } from 'swanviz'

import { useHighlightedSurveyPoint } from '../../../contexts/highlightedSurveyPointContext'
import { move, removeAt, updateAt } from '../../../utils/array'
import { formatCoordinates } from '../../../utils/geojson'
import { DeleteButton } from '../../DeleteButton'
import { DragHandle } from '../../DragHandle'
import { LocationInput } from '../../LocationInput'

import css from './style.module.css'

export const SurveyPolygonInput: React.FC<{
  isViewMode: boolean
  value?: Coordinates[]
  onChange?: (newPolygon: Coordinates[]) => void
}> = ({ value = [], isViewMode, onChange }) => {
  if (isViewMode) {
    return (
      <>
        {value.map((coords, idx) => (
          <div key={idx}>
            <Typography.Text>{formatCoordinates(coords)}</Typography.Text>
          </div>
        ))}
      </>
    )
  }

  const updatePolygon = (points: Coordinates[]) => onChange?.(points)

  return (
    <SortablePointInputs
      useDragHandle
      lockToContainerEdges
      lockAxis="y"
      helperClass={css.sortableHelper}
      points={value}
      onChange={updatePolygon}
      onSortEnd={({ oldIndex, newIndex }) =>
        updatePolygon(move(value, oldIndex, newIndex))
      }
    />
  )
}

const SortablePointInputs = SortableContainer(
  ({
    points,
    onChange,
  }: {
    points: Coordinates[]
    onChange: (newPoints: Coordinates[]) => void
  }) => {
    const [isAddingPoint, setIsAddingPoint] = React.useState(false)
    const { setHoveredSurveyPointIdx, setFocusedSurveyPointIdx } =
      useHighlightedSurveyPoint()

    const changePoint = (idx: number, newValue: Coordinates) =>
      onChange(updateAt(points, idx, newValue))
    const removePoint = (idx: number) => onChange(removeAt(points, idx))
    const addPoint = (newPoint: Coordinates) => onChange([...points, newPoint])

    const pointsIncludingNew = isAddingPoint ? [...points, null] : points
    const isNewPoint = (idx: number) => idx === points.length

    return (
      <div>
        {pointsIncludingNew.map((coords, idx) => (
          <PointInput
            key={idx}
            index={idx}
            value={coords}
            onChange={(newCoords) => {
              if (isNewPoint(idx)) {
                addPoint(newCoords)
                setIsAddingPoint(false)
              } else {
                changePoint(idx, newCoords)
              }
            }}
            onDelete={() => {
              if (isNewPoint(idx)) {
                setIsAddingPoint(false)
              } else {
                removePoint(idx)
              }
              setHoveredSurveyPointIdx(undefined)
              setFocusedSurveyPointIdx(undefined)
            }}
            onHover={(isHovered) =>
              setHoveredSurveyPointIdx(isHovered ? idx : undefined)
            }
            onFocus={() => setFocusedSurveyPointIdx(idx)}
            onBlur={() => setFocusedSurveyPointIdx(undefined)}
          />
        ))}
        <Button
          className={css.addButton}
          icon={<PlusOutlined />}
          disabled={isAddingPoint}
          onClick={() => setIsAddingPoint(true)}
        >
          Add point
        </Button>
      </div>
    )
  }
)

const PointInput = SortableElement(
  ({
    value,
    onChange,
    onDelete,
    onHover,
    onFocus,
    onBlur,
  }: {
    value: Coordinates | null
    onChange: (newValue: Coordinates) => void
    onDelete: () => void
    onHover?: (isHovered: boolean) => void
    onFocus?: () => void
    onBlur?: () => void
  }) => {
    return (
      <div
        className={css.item}
        onMouseEnter={() => onHover?.(true)}
        onMouseLeave={() => onHover?.(false)}
      >
        <div className={classNames(css.handle, !value && css.isHidden)}>
          <DragHandle />
        </div>
        <LocationInput
          value={value}
          withValidation={false}
          onChange={(newCoords) => newCoords && onChange(newCoords)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <DeleteButton onClick={onDelete} onFocus={onFocus} onBlur={onBlur} />
      </div>
    )
  }
)
