import * as React from 'react'
import { Button } from 'antd'

import { ReactComponent as ZoomInSVG } from './zoomIn.svg'
import { ReactComponent as ZoomOutSVG } from './zoomOut.svg'
import css from './style.module.css'

export const Zoom: React.FC<{
  onChange: (delta: number) => void
}> = ({ onChange }) => {
  const commonButtonProps = {
    type: 'text',
    block: true,
    className: css.button,
  } as const

  return (
    <div className={css.main}>
      <Button
        {...commonButtonProps}
        icon={<ZoomInSVG />}
        title="Zoom in"
        onClick={() => onChange(+1)}
      />
      <div className={css.divider} />
      <Button
        {...commonButtonProps}
        icon={<ZoomOutSVG />}
        title="Zoom out"
        onClick={() => onChange(-1)}
      />
    </div>
  )
}
