import { Form, Select, Typography } from 'antd'
import * as _ from 'lodash'
import React from 'react'
import { MissionTask } from 'swanviz'

import { getTaskTypeName } from '../../utils/missionTask'
import { getEmptyTask } from '../../utils/missionTasks'
import { useWindowSize } from '../../utils/useWindowSize'

import { Label } from './Label'
import css from './style.module.css'
import { TaskFormActions } from './TaskFormActions'
import { TaskFormContent } from './TaskFormContent'

export type Props = {
  task: MissionTask
  missionSpeed: number
  isViewMode: boolean
  isNewTask: boolean
  onChange: (task: MissionTask) => void
  onSave: (options?: { oneMoreTaskType: MissionTask['type'] }) => void
  onDelete: () => void
}

const SELECTABLE_TASK_TYPES: Array<MissionTask['type']> = [
  'go',
  'lawnmower',
  'survey',
  'sensorDepth',
  'waterSampler',
  'stationKeep',
  'sensors',
]

export const TaskForm: React.FC<Props> = ({
  task,
  missionSpeed,
  isViewMode,
  isNewTask,
  onSave,
  onDelete,
  onChange,
}) => {
  const { isMobile } = useWindowSize()
  const [form] = Form.useForm<MissionTask>()

  React.useLayoutEffect(() => {
    form.setFieldsValue(task)
  }, [task])

  const handleValuesChange = (
    changedValues: Partial<MissionTask>,
    values: Partial<MissionTask>
  ) => {
    if (changedValues.type) {
      onChange(getEmptyTask(changedValues.type, task.id))
      return
    }

    const updateTask = () =>
      onChange(
        _.mergeWith({}, task, values, (objValue, srcValue) =>
          Array.isArray(srcValue) ? srcValue : undefined
        )
      )

    const allowInvalidValues =
      changedValues.args &&
      // To show invalid survey polygon (with self-intersections) on the map
      (('aoi' in changedValues.args && changedValues.args.aoi) ||
        // To allow empty location for Go task
        'location' in changedValues.args)

    if (allowInvalidValues) {
      updateTask()
    } else {
      // Without timeout validation will always fail: https://github.com/ant-design/ant-design/issues/26747#issuecomment-692553855
      setTimeout(() => {
        form.validateFields().then(updateTask)
      })
    }
  }

  return (
    <Form
      form={form}
      initialValues={task}
      layout="vertical"
      hideRequiredMark
      noValidate
      size={isMobile ? 'large' : undefined}
      onValuesChange={handleValuesChange}
      onFinish={() => onSave()}
    >
      <Typography.Title level={2}>
        {isNewTask ? 'New task' : getTaskTypeName(task.type)}
      </Typography.Title>

      {isNewTask && (
        <>
          <Form.Item label={<Label>Type</Label>} name="type">
            <Select
              options={SELECTABLE_TASK_TYPES.map((type) => ({
                value: type,
                label: getTaskTypeName(type),
              }))}
            />
          </Form.Item>
          <hr className={css.line} />
        </>
      )}

      <TaskFormContent
        form={form}
        isViewMode={isViewMode}
        missionSpeed={missionSpeed}
      />

      {!isViewMode && (
        <div className={css.actions}>
          <TaskFormActions
            task={task}
            form={form}
            isNewTask={isNewTask}
            onSave={onSave}
            onDelete={onDelete}
          />
        </div>
      )}
    </Form>
  )
}
