import { Table, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ColumnFilterItem } from 'antd/es/table/interface'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import type { Area } from 'swanviz'
import { downloadSwanBotLog } from 'swanviz'

import { PopulatedSwanbot } from '../../../../utils/populateSwanbots'
import {
  getActivityLogFilename,
  statusNames,
  swanBotStatusSorter,
} from '../../../../utils/swanbot'
import {
  getColumnFilterProps,
  getColumnSearchProps,
  getFilters,
  sorter,
  stickyProps,
} from '../../../../utils/table'
import { DownloadActivityLogButton } from '../../../DownloadActivityLogButton'
import { SwanBotStatus } from '../../../SwanBotStatus'
import { TableFilterIcon } from '../../../TableFilterIcon'

import css from './style.module.css'
import { usePermissions } from '../../../../utils/usePermissions'

type Props = {
  swanBots: PopulatedSwanbot[]
  areas: Area[]
  onSelectSwanBot: (id: number) => void
}

const DELETED_FILTER_VALUE = 'DELETED'

export const SwanBotsTable: React.FC<Props> = ({
  swanBots,
  areas,
  onSelectSwanBot,
}) => {
  const {
    swanbots: { canManage },
  } = usePermissions()

  const areaFilters = getFilters({
    items: areas,
    getValue: (item) => item.name,
  })

  return (
    <Table
      rowKey={(row) => row.id}
      dataSource={swanBots}
      columns={getColumns(areaFilters, canManage)}
      pagination={false}
      rowClassName={(row) => classNames(css.row, row.delFlag && css.isDeleted)}
      scroll={{ x: 1200 }}
      {...stickyProps}
      onRow={(swanBot) => ({
        onClick: swanBot.delFlag
          ? undefined
          : () => onSelectSwanBot(swanBot.id),
      })}
    />
  )
}

const getColumns = (
  areaFilters: ColumnFilterItem[],
  canManage: boolean
): ColumnsType<PopulatedSwanbot> => {
  const columns: ColumnsType<PopulatedSwanbot> = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: sorter('id'),
      defaultSortOrder: 'ascend',
      width: '5%',
    },
    {
      title: 'SwanBot',
      dataIndex: 'name',
      width: '25%',
      ellipsis: true,
      render: (value) => (
        <Typography.Text className={css.name}>{value}</Typography.Text>
      ),
      sorter: sorter('name'),
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '15%',
      sorter: swanBotStatusSorter,
      render: (_value, swanBot) => <SwanBotStatus swanBot={swanBot} />,
      filters: _.toPairs(statusNames)
        .map(([value, text]) => ({ text, value }))
        .concat({
          text: 'Deleted',
          value: DELETED_FILTER_VALUE,
        }),
      onFilter: (value, record) => {
        if (value === DELETED_FILTER_VALUE) return record.delFlag
        return !record.delFlag && record.status === value
      },
      filterIcon: TableFilterIcon,
    },
    {
      title: 'Area',
      dataIndex: 'areaName',
      width: '10%',
      sorter: sorter('areaName'),
      filters: areaFilters,
      ...getColumnFilterProps('areaName'),
    },
    {
      title: 'Home location',
      dataIndex: 'home',
      width: '15%',
      render: (value: PopulatedSwanbot['home']) =>
        value ? value.join(', ') : '—',
    },
    {
      title: 'Added',
      dataIndex: 'registrationDate',
      width: '10%',
      sorter: sorter('registrationDate'),
      render: (value: PopulatedSwanbot['registrationDate']) =>
        moment(value).format('D MMM YYYY'),
    },
  ]

  if (canManage) {
    return columns.concat({
      title: 'Activity log',
      render: (_text, swanBot) => (
        <DownloadActivityLogButton
          filename={getActivityLogFilename(swanBot)}
          downloadAction={() => downloadSwanBotLog(swanBot.id)}
        />
      ),
      width: '10%',
    })
  }

  return columns
}
