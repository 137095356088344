import React from 'react'
import { dequal } from 'dequal/lite'

export function useDeepMemoizedValue<T>(value: T): T {
  const [memoizedValue, setMemoizedValue] = React.useState(value)

  React.useLayoutEffect(() => {
    if (!dequal(memoizedValue, value)) {
      setMemoizedValue(value)
    }
  }, [value])

  return memoizedValue
}
