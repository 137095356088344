import { Collapse, Tabs } from 'antd'
import React from 'react'

import { BackButton } from '../../../../BackButton'
import { ModalContainer } from '../../../../ModalContainer'
import { PageTitle } from '../../../../PageTitle'
import { LayoutProps } from '../../types'

import css from './style.module.css'

type Props = Pick<
  LayoutProps,
  'map' | 'summary' | 'displayAs' | 'onDisplayAsChange'
> & {
  onClose: () => void
}

export const MobileDataView: React.FC<Props> = ({
  map,
  summary,
  displayAs,
  onDisplayAsChange,
  onClose,
}) => {
  const [isMapVisible, setIsMapVisible] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => setIsMapVisible(true), 300)
  }, [])

  return (
    <ModalContainer withPadding={false} onClose={onClose}>
      <div className={css.main}>
        <div>
          <BackButton onClick={onClose} />
          <PageTitle className={css.title}>Data</PageTitle>
        </div>
        <Tabs
          className={css.tabs}
          activeKey={displayAs}
          animated={false}
          onChange={(newKey) =>
            newKey !== displayAs &&
            onDisplayAsChange(newKey as typeof displayAs)
          }
        >
          <Tabs.TabPane tab="Map" key="map">
            <div className={css.map}>{isMapVisible && map}</div>
            <Collapse ghost className={css.legend}>
              <Collapse.Panel key="legend" header="Legend" forceRender>
                {summary}
              </Collapse.Panel>
            </Collapse>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Plots" key="plot" className={css.charts}>
            {summary}
          </Tabs.TabPane>
        </Tabs>
      </div>
    </ModalContainer>
  )
}
