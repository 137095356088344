import { Button } from 'antd'
import React from 'react'
import { MissionRun, SwanBot } from 'swanviz'

import { ReactComponent as PauseIcon } from '../../../../icons/pause.svg'
import { isRunStarted, isRunExecuting } from '../../../../utils/missionRuns'
import { usePermissions } from '../../../../utils/usePermissions'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { HomeIcon } from '../../../HomeIcon'

import { ReactComponent as StartIcon } from './start.svg'
import { ReactComponent as StopIcon } from './stop.svg'
import css from './style.module.css'

export type ButtonClickHandler = (buttonIndex: number) => () => void
type Props = {
  missionRunStatus: MissionRun['status'] | undefined
  swanBotStatus: SwanBot['status'] | undefined
  loadingButtonIndex: number | null
  isMissionStatusUpdating: boolean
  isDesktop: boolean
  buttonClassName: string
  start: ButtonClickHandler
  suspend: ButtonClickHandler
  resume: ButtonClickHandler
  abort: ButtonClickHandler
  abortToHome: ButtonClickHandler
}
export const MissionRunButtons: React.FC<Props> = (props) => {
  const permissions = usePermissions()
  const { isDesktop, isMobile } = useWindowSize()

  if (!permissions.missions.canManage) {
    return null
  }

  return (
    <>
      {getButtons(props)
        .filter((button) => button.shouldRender)
        .map((button, index) => (
          <React.Fragment key={button.label}>
            <Button
              type={button.type}
              danger={button.danger}
              disabled={button.disabled}
              icon={button.icon}
              size={isDesktop ? undefined : 'large'}
              onClick={button.onClick(index)}
              loading={index === props.loadingButtonIndex}
              className={props.buttonClassName}
            >
              {button.label}
            </Button>
            {isMobile && index === 0 && <div className={css.lineBreak} />}
          </React.Fragment>
        ))}
    </>
  )
}

const abortableSwanBotStatuses: Array<SwanBot['status']> = [
  'IN_A_MISSION',
  'SUSPENDED',
  'ABORT_TO_HOME',
]

export const getButtons = ({
  missionRunStatus,
  swanBotStatus,
  isMissionStatusUpdating,
  isDesktop,
  start,
  suspend,
  resume,
  abort,
  abortToHome,
}: Props): Array<
  {
    label: string
    shouldRender: boolean
    onClick: ButtonClickHandler
  } & Pick<
    React.ComponentProps<typeof Button>,
    'type' | 'danger' | 'disabled' | 'icon'
  >
> => {
  const areAllButtonsDisabled = !swanBotStatus || isMissionStatusUpdating

  return [
    {
      shouldRender: !isRunStarted(missionRunStatus),
      type: 'primary' as const,
      danger: true,
      disabled: areAllButtonsDisabled || swanBotStatus !== 'IDLE',
      icon: <StartIcon className={css.icon} />,
      onClick: start,
      label: isDesktop ? 'Start mission' : 'Start',
    },
    {
      shouldRender: isRunExecuting(missionRunStatus),
      type: 'primary' as const,
      danger: true,
      disabled: areAllButtonsDisabled || swanBotStatus !== 'IN_A_MISSION',
      icon: <PauseIcon className={css.icon} />,
      onClick: suspend,
      label: 'Suspend',
    },
    {
      shouldRender: missionRunStatus === 'MISSION SUSPENDED',
      type: 'primary' as const,
      danger: true,
      disabled: areAllButtonsDisabled || swanBotStatus !== 'SUSPENDED',
      icon: <StartIcon className={css.icon} />,
      onClick: resume,
      label: 'Resume',
    },
    {
      shouldRender: true,
      type: 'ghost' as const,
      danger: false,
      disabled:
        areAllButtonsDisabled ||
        !abortableSwanBotStatuses.includes(swanBotStatus),
      icon: <StopIcon className={css.icon} />,
      onClick: abort,
      label: 'Abort',
    },
    {
      shouldRender: true,
      type: 'ghost' as const,
      danger: false,
      disabled:
        areAllButtonsDisabled ||
        (swanBotStatus !== 'IDLE' &&
          swanBotStatus !== 'IN_A_MISSION' &&
          swanBotStatus !== 'SUSPENDED'),
      icon: <HomeIcon className={css.icon} />,
      onClick: abortToHome,
      label: isRunStarted(missionRunStatus) ? 'Abort to home' : 'Send to home',
    },
  ]
}
