import { DrawPolygonMode, EditAction, ModifyMode } from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { GeoJSON } from 'geojson'
import { Coordinates, MissionTask } from 'swanviz'
import { commonEditableLayerGuidesProps } from '../../../../../geoLayers/editableLayer'
import { RED_RGB } from '../../../../../utils/colors'
import { createPolygonFeature } from '../../../../../utils/geojson'
import { isDefined } from '../../../../../utils/typeGuards'

const POINT_COLORS = {
  default: RED_RGB,
  inactive: [192, 150, 150],
}

export const getEditSurveyLayer = ({
  task,
  highlightedSurveyPointIdx,
  isAddingNewGeoFeature,
  layerParams,
  onChangeAddingNewGeoFeature,
  onChange,
}: {
  task: Extract<MissionTask, { type: 'survey' }>
  highlightedSurveyPointIdx: number | undefined
  isAddingNewGeoFeature: boolean
  layerParams: {}
  onChangeAddingNewGeoFeature: (newValue: boolean) => void
  onChange: (task: MissionTask) => void
}) => {
  const { aoi: polygon } = task.args
  const isValidPolygon = polygon.length >= 3
  const isModifying = isValidPolygon && !isAddingNewGeoFeature

  const data: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: isValidPolygon ? [createPolygonFeature([polygon])] : [],
  }

  const onEdit = ({ updatedData, editType }: EditAction<typeof data>) => {
    if (editType === 'addTentativePosition') {
      onChangeAddingNewGeoFeature(true)
    } else if (editType === 'addFeature') {
      onChangeAddingNewGeoFeature(false)
    }

    const newPolygonWasAdded =
      isAddingNewGeoFeature && editType === 'addFeature'
    const shouldApplyChanges = newPolygonWasAdded || isModifying

    if (shouldApplyChanges && updatedData.features.length) {
      const { geometry } = updatedData.features[0]
      if (geometry.type === 'Polygon') {
        onChange({
          ...task,
          args: {
            ...task.args,
            aoi: geometry.coordinates[0] as Coordinates[],
          },
        })
      }
    }
  }

  return new EditableGeoJsonLayer({
    ...layerParams,
    data,
    mode: isModifying ? ModifyMode : DrawPolygonMode,
    selectedFeatureIndexes: [0],
    onEdit,
    _subLayerProps: {
      guides: {
        ...commonEditableLayerGuidesProps,
        getFillColor: (feature: GeoJSON.Feature<GeoJSON.Polygon>) => {
          const [, pointIdx] = feature.properties?.positionIndexes ?? []
          if (
            isDefined(highlightedSurveyPointIdx) &&
            pointIdx !== highlightedSurveyPointIdx
          ) {
            return POINT_COLORS.inactive
          }
          return POINT_COLORS.default
        },
      },
    },
  })
}
