import { Form, Input } from 'antd'
import { InputProps } from 'antd/es/input'
import React from 'react'
import type { Coordinates } from 'swanviz'

import { areValidCoordinates, formatCoordinates } from '../../utils/geojson'
import css from './style.module.css'

type Props = {
  // Optional to support usage inside Form.Item
  value?: Coordinates | null
  precision?: number
  withValidation?: boolean
  onChange?: (value: Coordinates) => void
} & Pick<InputProps, 'onFocus' | 'onBlur' | 'disabled'>

export const LocationInput: React.FC<Props> = ({
  value: outerValue = null,
  precision = 6,
  withValidation = true,
  onChange,
  ...inputProps
}) => {
  const [inputValue, setInputValue] = React.useState(
    formatCoordinates(outerValue, precision)
  )
  const [isFocused, setIsFocused] = React.useState(false)

  const inputCoordinates = React.useMemo(
    () => stringToCoords(inputValue),
    [inputValue]
  )
  const areCoorsValid = areValidCoordinates(inputCoordinates)
  const showError = withValidation && !areCoorsValid

  // Sync inner value
  React.useEffect(() => {
    if (!isFocused) {
      setInputValue(formatCoordinates(outerValue, precision))
    }
  }, [outerValue, isFocused])

  // Pass up only valid coordinates
  React.useEffect(() => {
    if (isFocused && areCoorsValid) {
      onChange?.(inputCoordinates)
    }
  }, [inputCoordinates])

  return (
    <Form.Item
      className={css.formItem}
      validateStatus={showError ? 'error' : undefined}
      help={
        showError ? (
          <span className={css.error}>
            Please select a point on the map or enter valid coordinates
          </span>
        ) : undefined
      }
    >
      <Input
        placeholder="Enter or paste coordinates"
        {...inputProps}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={(e) => {
          inputProps.onFocus?.(e)
          setIsFocused(true)
        }}
        onBlur={(e) => {
          inputProps.onBlur?.(e)
          setInputValue(formatCoordinates(inputCoordinates, precision))
          setIsFocused(false)
        }}
      />
    </Form.Item>
  )
}

const stringToCoords = (inputString: string): Coordinates | null => {
  const coords = inputString
    .split(',')
    .map((str) => str.trim())
    .map((str) => Number.parseFloat(str))
  return coords.length === 2 ? (coords as Coordinates) : null
}
