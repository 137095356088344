import { Result } from 'antd'
import React from 'react'
import { useUser } from '../../../contexts/userContext'
import { PageLayout } from '../../PageLayout'
import { Link } from 'react-router-dom'
import { LOGIN_URL } from '../../../utils/links'

export const Page404 = () => {
  const { user } = useUser()

  const component404 = (
    <Result
      status="404"
      title="404"
      subTitle="Page Not Found"
      extra={!user && <Link to={LOGIN_URL}>Try to log in</Link>}
    />
  )

  if (user) {
    return <PageLayout>{component404}</PageLayout>
  }

  return component404
}
