import { HtmlOverlay } from '@nebula.gl/overlays'
import _ from 'lodash'
import React from 'react'
import { Area, SwanBot } from 'swanviz'

import { getMissionRunPathLayer } from '../../../../geoLayers/missionRunPathLayer'
import { isTruthy } from '../../../../utils/typeGuards'
import { Map } from '../../../Map'
import { MapIcon } from '../../../MapIcon'
import { PathsBySwanBot } from '../SwanBotPathsFetcher'

export const AreaMap: React.FC<{
  area: Area
  swanBots: SwanBot[]
  pathsBySwanBot: PathsBySwanBot
}> = ({ area, swanBots, pathsBySwanBot }) => {
  const pathLayers = React.useMemo(() => {
    return swanBots
      .map((swanBot) => {
        const swanBotPath = pathsBySwanBot[swanBot.id]
        return (
          swanBotPath &&
          getMissionRunPathLayer({
            id: swanBot.id,
            actualPath: swanBotPath,
            hexColor: swanBot.iconColor ?? undefined,
          })
        )
      })
      .filter(isTruthy)
  }, [swanBots, pathsBySwanBot])

  return (
    <Map
      longitude={area.coordinates[0]}
      latitude={area.coordinates[1]}
      layers={pathLayers}
    >
      <HtmlOverlay zIndex={0}>
        {swanBots.flatMap((swanBot) => {
          const lastPathPoint = _.last(pathsBySwanBot[swanBot.id])
          const swanBotLocation =
            lastPathPoint?.location ?? swanBot.currLocation
          const swanBotHeading = lastPathPoint?.heading ?? swanBot.currHeading

          return [
            swanBot.home && (
              <MapIcon
                key={`home_${swanBot.id}`}
                type="home"
                coordinates={swanBot.home}
                color={swanBot.iconColor}
              />
            ),
            swanBotLocation && (
              <MapIcon
                key={`swan_${swanBot.id}`}
                type="swan"
                coordinates={swanBotLocation}
                heading={swanBotHeading}
                swanBot={swanBot}
              />
            ),
          ]
        })}
      </HtmlOverlay>
    </Map>
  )
}
