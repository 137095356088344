import _ from 'lodash'
import React from 'react'
import { Coordinates } from 'swanviz'

type HoveredChartPoint = Coordinates | undefined

type ContextValue = {
  hoveredPoint: HoveredChartPoint
  setHoveredPoint: (newValue: HoveredChartPoint) => void
}

const HoveredChartPointContext = React.createContext<ContextValue>({
  hoveredPoint: undefined,
  setHoveredPoint: _.noop,
})

export const HoveredChartPointProvider: React.FC = ({ children }) => {
  const [hoveredPoint, setHoveredPoint] = React.useState<HoveredChartPoint>()

  return (
    <HoveredChartPointContext.Provider
      value={{ hoveredPoint, setHoveredPoint }}
    >
      {children}
    </HoveredChartPointContext.Provider>
  )
}

export const useHoveredChartPoint = () =>
  React.useContext(HoveredChartPointContext)
