export const move = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): T[] => {
  const itemRemovedArray = array
    .slice(0, fromIndex)
    .concat(array.slice(fromIndex + 1, array.length))
  return [
    ...itemRemovedArray.slice(0, toIndex),
    array[fromIndex],
    ...itemRemovedArray.slice(toIndex, itemRemovedArray.length),
  ]
}

export const updateAt = <T>(array: T[], index: number, newItem: T): T[] => {
  return index >= array.length
    ? array
    : [
        ...array.slice(0, index),
        newItem,
        ...array.slice(index + 1, array.length),
      ]
}

export const insertAt = <T>(array: T[], index: number, newItem: T): T[] => {
  return [
    ...array.slice(0, index),
    newItem,
    ...array.slice(index, array.length),
  ]
}

export const removeAt = <T>(array: T[], index: number): T[] => {
  return [...array.slice(0, index), ...array.slice(index + 1, array.length)]
}
