import _ from 'lodash'
import React from 'react'
import { MissionRun, SensorParameter } from 'swanviz'
import { isDefined } from '../utils/typeGuards'

type RunId = MissionRun['runId']
type SensorId = SensorParameter['id']

type ContextValue = {
  isHeatmapSelected: (runId: RunId, sensorId: SensorId) => boolean
  setSelectedHeatmap: (runId: RunId, sensorId: SensorId | undefined) => void
}

const SelectedHeatmapContext = React.createContext<ContextValue>({
  isHeatmapSelected: () => false,
  setSelectedHeatmap: _.noop,
})

export const SelectedHeatmapProvider: React.FC = ({ children }) => {
  const [selectedHeatmaps, setSelectedHeatmaps] = React.useState<
    Record<RunId, SensorId>
  >({})

  const contextValue: ContextValue = {
    isHeatmapSelected: (runId, sensorId) =>
      selectedHeatmaps[runId] === sensorId,
    setSelectedHeatmap: React.useCallback(
      (runId, sensorId) =>
        setSelectedHeatmaps((state) =>
          isDefined(sensorId)
            ? { ...state, [runId]: sensorId }
            : _.omit(state, runId)
        ),
      [setSelectedHeatmaps]
    ),
  }

  return (
    <SelectedHeatmapContext.Provider value={contextValue}>
      {children}
    </SelectedHeatmapContext.Provider>
  )
}

export const useSelectedHeatmap = () => React.useContext(SelectedHeatmapContext)
