import { Button, Empty, Form, message, Spin } from 'antd'
import _ from 'lodash'
import React, { useState } from 'react'
import {
  AlertSetting,
  LatestSensorData,
  SensorParameter,
  SwanBot,
  updateAlertSetting,
} from 'swanviz'
import { mutate } from 'swr'

import { ERROR_MESSAGES } from '../../../utils/errorMessages'
import { useCheckUnsavedChanges } from '../../../utils/useCheckUnsavedChanges'
import { useWindowSize } from '../../../utils/useWindowSize'
import { SENSORS_FIELD_NAME } from '../AlertsTab/constants'
import type {
  AlertSettingsFormValues,
  SensorAlertField,
} from '../AlertsTab/types'
import { RestSensorsList } from '../RestSensors'
import { SensorAlert } from '../SensorAlert'
import css from './style.module.css'

type Props = {
  swanBot: SwanBot
  alertSettings: AlertSetting[]
  latestSensorData: LatestSensorData[] | undefined
  sensors: SensorParameter[]
  isLoading: boolean
}

export const AlertsTabContent: React.FC<Props> = ({
  swanBot,
  alertSettings,
  latestSensorData,
  sensors,
  isLoading,
}) => {
  const { isDesktop, isMobile } = useWindowSize()
  const [isUpdating, setIsUpdating] = useState(false)
  const [form] = Form.useForm<AlertSettingsFormValues>()

  const initialValues: AlertSettingsFormValues = {
    [SENSORS_FIELD_NAME]: alertSettings.map((alertSetting) => ({
      id: alertSetting.id,
      value: [alertSetting.min, alertSetting.max] as [number, number],
    })),
  }

  const [values, setValues] = React.useState(alertSettings)
  const { setBackupValue, hasChanges } = useCheckUnsavedChanges({
    value: values,
  })

  React.useEffect(() => {
    setBackupValue(alertSettings)
  }, [alertSettings])

  const onValuesChange = (values: AlertSettingsFormValues) => {
    setValues(toAlertSettings(values[SENSORS_FIELD_NAME]))
  }

  const onSave = async (formValues: AlertSettingsFormValues) => {
    setIsUpdating(true)
    try {
      await updateAlertSetting(swanBot.id, toAlertSettings(formValues.sensors))
      await mutate(['getAlertSettings', swanBot.id])
      message.success('Alert settings have been updated')
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.alertSettingsUpdate)
    }
    setIsUpdating(false)
  }

  return (
    <Spin tip="Fetching alert settings" spinning={isLoading}>
      <Form
        initialValues={initialValues}
        form={form}
        className={css.main}
        onFinish={onSave}
        onValuesChange={(_changedValues, values) => onValuesChange(values)}
      >
        <Form.List name={SENSORS_FIELD_NAME}>
          {(fields, { add, remove }) => {
            const sensorAlerts = form.getFieldValue(
              SENSORS_FIELD_NAME
            ) as SensorAlertField[]

            const restSensors = _.differenceBy(
              sensors,
              sensorAlerts,
              (sensor) => sensor.id
            )

            if (isMobile && !fields.length) {
              return (
                <Empty
                  description="No alerts configured for this SwanBot"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )
            }

            return (
              <>
                {fields.map((field) => {
                  const id = form.getFieldValue([
                    SENSORS_FIELD_NAME,
                    field.name,
                    'id',
                  ])
                  const sensor = sensors.find((sensor) => sensor.id === id)

                  if (!sensor) {
                    return null
                  }

                  const latestData = latestSensorData?.find(
                    (sensorData) => sensorData.sensorParamId === id
                  )?.sensorParamValue

                  return (
                    <div key={field.key} className={css.sensorAlert}>
                      <SensorAlert
                        field={field}
                        sensor={sensor}
                        remove={remove}
                        latestData={latestData}
                        onValuesChange={onValuesChange}
                      />
                    </div>
                  )
                })}
                {isDesktop && Boolean(restSensors.length) && (
                  <RestSensorsList
                    sensors={restSensors}
                    onClick={(sensor) =>
                      add({ id: sensor.id, value: [sensor.min, sensor.max] })
                    }
                  />
                )}
              </>
            )
          }}
        </Form.List>
        {isDesktop && (
          <Button
            className={css.save}
            loading={isUpdating}
            htmlType="submit"
            type="primary"
            disabled={!hasChanges}
            danger
          >
            Save
          </Button>
        )}
      </Form>
    </Spin>
  )
}

const toAlertSettings = (sensors: SensorAlertField[]): AlertSetting[] => {
  return sensors
    .filter((sensorAlert) => Boolean(sensorAlert.id))
    .map((sensorAlert) => {
      const [min, max] = sensorAlert.value
      return {
        id: sensorAlert.id,
        min,
        max,
      }
    })
}
