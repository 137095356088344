import React from 'react'
import { Button } from 'antd'

import { ReactComponent as TrashcanSVG } from './trashcan.svg'
import css from './style.module.css'
import classNames from 'classnames'

export const DeleteButton: React.FC<React.ComponentProps<typeof Button>> = (
  props
) => {
  return (
    <Button
      {...props}
      className={classNames(
        props.className,
        css.main,
        props.children && css.withText
      )}
      type="text"
      icon={<TrashcanSVG className={css.icon} />}
    />
  )
}
