import { Drawer, message, Popconfirm } from 'antd'
import _ from 'lodash'
import React from 'react'
import { MissionTask } from 'swanviz'

import type { SelectedTaskId } from '../'
import { getNextId } from '../../../../utils/id'
import { getTaskTypeName } from '../../../../utils/missionTask'
import {
  getEmptyTask,
  removeTaskById,
  updateTaskById,
} from '../../../../utils/missionTasks'
import { CloseButton } from '../../../CloseButton'
import { TaskForm } from '../../../TaskForm'

import css from './style.module.css'

type Props = {
  missionTasks: MissionTask[]
  missionSpeed: number
  selectedTaskId: SelectedTaskId
  isViewMode: boolean
  isAddingNewTask: boolean
  onSelect: (taskId: SelectedTaskId) => void
  onChangeTasks: (missionTasks: MissionTask[]) => void
  onRequestClose: () => void
}

export const TaskDrawer: React.FC<Props> = ({
  missionTasks,
  missionSpeed,
  selectedTaskId,
  isViewMode,
  isAddingNewTask,
  onSelect,
  onChangeTasks,
  onRequestClose,
}) => {
  const [isConfirmVisible, setIsConfirmVisible] = React.useState(false)
  /**
   * 1. When user opens task editing form, we create a backup of tasks state
   * 2. While user is editing task, we apply changes immediately
   * 3. If user clicks "Save", we don't do anything as task is already updated
   * 4. If user closes editing form or closes browser page while editing, we restore a backup from point 1
   */
  const tasksBackupRef = React.useRef<MissionTask[]>()
  const selectedTask = missionTasks.find((task) => task.id === selectedTaskId)
  const isVisible = !!selectedTask || isAddingNewTask

  const restoreTasksBackup = () =>
    tasksBackupRef.current && onChangeTasks(tasksBackupRef.current)

  React.useEffect(() => {
    tasksBackupRef.current = isVisible ? _.cloneDeep(missionTasks) : undefined
  }, [isVisible])

  const addNewTask = (type: MissionTask['type'] = 'go') => {
    tasksBackupRef.current = _.cloneDeep(missionTasks)

    const newTaskId = getNextId(missionTasks.map((t) => t.id))
    onChangeTasks([...missionTasks, getEmptyTask(type, newTaskId)])
    onSelect(newTaskId)
  }

  React.useEffect(() => {
    if (isAddingNewTask) {
      addNewTask()
    }
  }, [isAddingNewTask])

  React.useEffect(() => {
    return restoreTasksBackup
  }, [])

  const handleClose = () => {
    tasksBackupRef.current && onChangeTasks(tasksBackupRef.current)
    onRequestClose()
  }

  const handleTaskDelete = (taskId: MissionTask['id']) => {
    onChangeTasks(removeTaskById(missionTasks, taskId))
    onRequestClose()
  }

  const handleTaskUpdate = (newValue: MissionTask) =>
    onChangeTasks(updateTaskById(missionTasks, newValue))

  const handleConfirmVisibleChange = (newConfirmVisible: boolean) => {
    const hasNothingChanged = !hasTasksListChanged({
      prev: tasksBackupRef.current,
      next: missionTasks,
    })

    if (newConfirmVisible && hasNothingChanged) {
      handleClose()
      return
    }

    setIsConfirmVisible(newConfirmVisible)
  }

  return (
    <Drawer
      className={css.main}
      visible={isVisible}
      mask={false}
      placement="left"
      width="100%"
      closable={false}
      getContainer={false}
      keyboard={false}
    >
      <Popconfirm
        visible={isConfirmVisible}
        onVisibleChange={handleConfirmVisibleChange}
        placement="bottom"
        title="You have unsaved changes. Close anyway?"
        onConfirm={handleClose}
        okText="Yes"
        cancelText="No"
      >
        <CloseButton className={css.close} />
      </Popconfirm>

      {selectedTask && (
        <TaskForm
          task={selectedTask}
          missionSpeed={missionSpeed}
          isViewMode={isViewMode}
          isNewTask={isAddingNewTask}
          onSave={(options) => {
            if (options?.oneMoreTaskType) {
              message.success(
                `"${getTaskTypeName(
                  options.oneMoreTaskType
                )}" task has been created`
              )
              addNewTask(options.oneMoreTaskType)
            } else {
              onRequestClose()
            }
          }}
          onDelete={() => handleTaskDelete(selectedTask.id)}
          onChange={handleTaskUpdate}
        />
      )}
    </Drawer>
  )
}

export const hasTasksListChanged = ({
  prev = [],
  next = [],
}: {
  prev?: MissionTask[]
  next?: MissionTask[]
}): boolean => {
  if (next.length - prev.length === 1) {
    // New task has been added
    const newTask = _.last(next)
    if (newTask) {
      const emptyTask = getEmptyTask(newTask.type, newTask.id)
      return !_.isEqual(emptyTask, newTask)
    }
  }

  return !_.isEqual(prev, next)
}
