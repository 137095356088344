import { Button, Empty, Image, Modal, Result, Spin, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import LazyLoad from 'react-lazyload'

import { useRunImages } from './useRunImages'

import css from './style.module.css'

type Props = {
  runId: number
  title: string
}

const IMAGES_CONTAINER_ID = 'imagesContainer'

export const ImagesModal: React.FC<Props> = ({ title, runId }) => {
  const [isModalVisible, setModalVisible] = React.useState(false)

  const { images, isLoading, isError } = useRunImages(
    isModalVisible ? runId : null
  )

  return (
    <>
      <Button
        size="small"
        type="link"
        className={css.button}
        onClick={() => setModalVisible(true)}
      >
        Images from mission
      </Button>
      <Modal
        title={title}
        visible={isModalVisible}
        footer={null}
        bodyStyle={{ padding: 0 }}
        destroyOnClose
        onCancel={() => setModalVisible(false)}
        width={620}
      >
        <Spin spinning={isLoading}>
          {isError ? (
            <Result
              status="error"
              title="Couldn't get images for this mission"
              subTitle="Try to close and then open this modal again"
            />
          ) : (
            <div className={css.main} id={IMAGES_CONTAINER_ID}>
              {images.length ? (
                <Image.PreviewGroup>
                  {images.map((image) => {
                    const imageTimeFormatted = moment(image.time).format(
                      'D MMMM, HH:mm:ss'
                    )
                    return (
                      <LazyLoad
                        height={155}
                        once={true}
                        key={image.imageURL}
                        scrollContainer={`#${IMAGES_CONTAINER_ID}`}
                      >
                        <div className={css.slide}>
                          <Image
                            src={image.imageURL}
                            wrapperClassName={css.image}
                            alt="Taken by SwanBot"
                          />
                          <Typography.Text className={css.time}>
                            {imageTimeFormatted}
                          </Typography.Text>
                        </div>
                      </LazyLoad>
                    )
                  })}
                </Image.PreviewGroup>
              ) : (
                <Empty
                  className={css.empty}
                  description="No images from this run at the moment"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
          )}
        </Spin>
      </Modal>
    </>
  )
}
