import { Table, Typography } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { ColumnFilterItem } from 'antd/es/table/interface'
import React from 'react'
import { Link } from 'react-router-dom'
import { Area, Geofence } from 'swanviz'

import { getGeofenceLink } from '../../../../utils/links'
import {
  getColumnFilterProps,
  getColumnSearchProps,
  getFilters,
  sorter,
  stickyProps,
} from '../../../../utils/table'
import { usePermissions } from '../../../../utils/usePermissions'
import { TableHeader } from '../../../TableHeader'
import { GeofencesButtons } from '../GeofencesButtons'

export type PopulatedGeofence = Geofence & {
  areaName: string
}

type Props = {
  geofences: PopulatedGeofence[]
  areas: Area[]
  pagination: TablePaginationConfig
  isLoading: boolean
}

export const GeofencesTable: React.FC<Props> = ({
  geofences,
  areas,
  pagination,
  isLoading,
}) => {
  const permissions = usePermissions()

  const areaFilters = getFilters({
    items: areas,
    getValue: (item) => item.name,
  })

  return (
    <>
      <TableHeader
        title="Geofences"
        buttons={
          permissions.geofences.canManage ? (
            <GeofencesButtons areas={areas} />
          ) : null
        }
      />
      <Table
        rowKey={(row) => row.id}
        dataSource={geofences}
        loading={isLoading}
        columns={getGeofenceColumns(areaFilters)}
        pagination={pagination}
        scroll={{ x: 500 }}
        {...stickyProps}
      />
    </>
  )
}

const getGeofenceColumns = (
  areaFilters: ColumnFilterItem[]
): ColumnsType<PopulatedGeofence> => [
  {
    title: 'Geofence',
    dataIndex: 'name',
    render: (text, geofence) => (
      <Link to={getGeofenceLink(geofence.id)}>
        <Typography.Link>{text}</Typography.Link>
      </Link>
    ),
    width: 350,
    sorter: sorter('name'),
    ...getColumnSearchProps('name'),
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Area',
    dataIndex: 'areaName',
    width: 200,
    sorter: sorter('areaName'),
    filters: areaFilters,
    ...getColumnFilterProps('areaName'),
  },
  {
    title: 'Author',
    dataIndex: 'createdUser',
    width: 300,
    sorter: sorter('createdUser'),
    ...getColumnSearchProps('createdUser'),
  },
  {},
]
