import { Popover, Radio } from 'antd'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Area, Geofence, InterpolationInputs } from 'swanviz'

import { shortDateFormat } from '../../../../utils/momentFormat'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { BackButton } from '../../../BackButton'
import { InformationItem } from '../../../InformationItem'
import { PageTitle } from '../../../PageTitle'

import css from './style.module.css'

type Props = {
  interpolationInputs: InterpolationInputs
  area: Area
  geofence: Geofence
  backUrl: string
  isOriginalData: boolean
  onChangeIsOriginalData: (newValue: boolean) => void
}

export const InterpolationHeader: React.FC<Props> = ({
  interpolationInputs,
  area,
  geofence,
  backUrl,
  isOriginalData,
  onChangeIsOriginalData,
}) => {
  const { isDesktop } = useWindowSize()

  const viewRadio = (
    <Radio.Group
      className={css.radio}
      options={[
        { label: 'Original', value: true },
        { label: 'Interpolated', value: false },
      ]}
      optionType="button"
      buttonStyle="solid"
      size={isDesktop ? 'middle' : 'small'}
      value={isOriginalData}
      onChange={(e) => onChangeIsOriginalData(e.target.value)}
    />
  )

  const inputsInfoList = (
    <>
      <InformationItem label="Date">
        {formatDate(interpolationInputs.startDate)}{' '}
        <span className={css.dateSeparator}>→</span>{' '}
        {formatDate(interpolationInputs.endDate)}
      </InformationItem>
      <InformationItem label="Area">{area.name}</InformationItem>
      <InformationItem label="Missions">
        {interpolationInputs.missionName}
      </InformationItem>
      <InformationItem label="SwanBot">
        {interpolationInputs.swanbotName}
      </InformationItem>
      <InformationItem label="Area geofence">{geofence.name}</InformationItem>
    </>
  )

  return (
    <div className={css.main}>
      <Link to={backUrl}>
        <BackButton type="link" className={css.back} />
      </Link>
      <PageTitle className={css.title}>
        {isDesktop ? 'Interpolated data' : 'Data'}
      </PageTitle>
      {isDesktop ? (
        <div className={css.info}>
          <InformationItem label="Data">{viewRadio}</InformationItem>
          {inputsInfoList}
        </div>
      ) : (
        <>
          {viewRadio}
          <Popover
            title="Interpolated data"
            content={<div className={css.info}>{inputsInfoList}</div>}
            trigger="click"
            placement="bottomRight"
            arrowPointAtCenter
          >
            <span className={css.infoIcon}>i</span>
          </Popover>
        </>
      )}
    </div>
  )
}

const formatDate = (date: number): string =>
  moment(date).format(`${shortDateFormat} YYYY`)
