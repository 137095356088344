import _ from 'lodash'
import React from 'react'

type HighlightedPointIdx = number | undefined

type ContextValue = {
  highlightedSurveyPointIdx: HighlightedPointIdx
  setHoveredSurveyPointIdx: (newValue: HighlightedPointIdx) => void
  setFocusedSurveyPointIdx: (newValue: HighlightedPointIdx) => void
}

const HighlightedSurveyPointContext = React.createContext<ContextValue>({
  highlightedSurveyPointIdx: undefined,
  setHoveredSurveyPointIdx: _.noop,
  setFocusedSurveyPointIdx: _.noop,
})

export const HighlightedSurveyPointProvider: React.FC = ({ children }) => {
  const [hoveredPointIdx, setHoveredPointIdx] =
    React.useState<HighlightedPointIdx>()
  const [focusedPointIdx, setFocusedPointIdx] =
    React.useState<HighlightedPointIdx>()

  const value = React.useMemo<ContextValue>(
    () => ({
      highlightedSurveyPointIdx: hoveredPointIdx ?? focusedPointIdx,
      setHoveredSurveyPointIdx: setHoveredPointIdx,
      setFocusedSurveyPointIdx: setFocusedPointIdx,
    }),
    [hoveredPointIdx, focusedPointIdx, setHoveredPointIdx, setFocusedPointIdx]
  )

  return (
    <HighlightedSurveyPointContext.Provider value={value}>
      {children}
    </HighlightedSurveyPointContext.Provider>
  )
}

export const useHighlightedSurveyPoint = () =>
  React.useContext(HighlightedSurveyPointContext)
