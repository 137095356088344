import { Form, Input, message } from 'antd'
import React from 'react'
import { addSwanBot, Area, SwanBotAdd } from 'swanviz'

import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import { getTrimmedInputValue } from '../../../../utils/input'
import { useCheckUnsavedChanges } from '../../../../utils/useCheckUnsavedChanges'
import { getRequiredRule } from '../../../../utils/validationRules'
import { AreasSelect } from '../../../AreasSelect'
import { SaveButton } from '../../../SaveButton'

import css from './style.module.css'

export const NewSwanBotForm: React.FC<{
  areas: Area[]
  onSuccess?: (newSwanBotId: number) => void
}> = ({ areas, onSuccess }) => {
  const [form] = Form.useForm<SwanBotAdd>()
  const [values, setValues] = React.useState<SwanBotAdd>({
    areaId: null,
    name: '',
    swanbotKey: '',
  })
  const [isLoading, setLoading] = React.useState(false)

  const { setBackupValue } = useCheckUnsavedChanges({
    value: values,
  })

  React.useEffect(() => {
    setBackupValue(values)
  }, [])

  const handleSubmit = async (formValues: SwanBotAdd) => {
    setLoading(true)
    try {
      const newSwanBotId = await addSwanBot(formValues)
      message.success(
        `SwanBot #${newSwanBotId} has been added, you can assign it to a user now`,
        5
      )
      setBackupValue(undefined)
      onSuccess?.(newSwanBotId)
    } catch (err: any) {
      console.error(err)
      message.error(err.message || ERROR_MESSAGES.swanBotAdd)
    }
    setLoading(false)
  }

  return (
    <Form
      form={form}
      initialValues={values}
      colon={false}
      hideRequiredMark
      className={css.main}
      onFinish={handleSubmit}
      onValuesChange={(_changedValues, values) => {
        setValues(values)
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[getRequiredRule('Name')]}
        getValueFromEvent={getTrimmedInputValue}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Area"
        name="areaId"
        rules={[
          {
            required: true,
            message: 'Please select area',
          },
        ]}
      >
        <AreasSelect areas={areas} />
      </Form.Item>
      <Form.Item
        label="Public key"
        name="swanbotKey"
        rules={[getRequiredRule('Public key')]}
        getValueFromEvent={getTrimmedInputValue}
      >
        <Input />
      </Form.Item>
      <SaveButton loading={isLoading} />
    </Form>
  )
}
