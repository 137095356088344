import { message, Spin, Tabs } from 'antd'
import React from 'react'
import { Area, SwanBot, SwanBotUpdate, updateSwanBot } from 'swanviz'
import { mutate } from 'swr'

import { updateAt } from '../../utils/array'
import { ERROR_MESSAGES } from '../../utils/errorMessages'
import { usePermissions } from '../../utils/usePermissions'
import { useWindowSize } from '../../utils/useWindowSize'
import {
  ALERTS_TAB_KEY,
  LOCATION_TAB_KEY,
  SENSORS_TAB_KEY,
  TabKey,
} from '../pages/SwanBots/constants'

import { AlertsTab } from './AlertsTab'
import { DeleteSwanBot } from './DeleteSwanBot'
import { LocationTab } from './LocationTab'
import { ModalTop } from './ModalTop'
import { SensorsTab } from './SensorsTab'
import css from './style.module.css'

type Props = {
  swanBot: SwanBot
  areas: Area[]
  onRequestClose: () => void
  activeTab: TabKey
  setActiveTab: (activeTab: TabKey) => void
}

export type OnSwanBotUpdate = (swanBotUpdate: SwanBotUpdate) => void

export const SwanBotModalContent: React.FC<Props> = ({
  swanBot,
  areas,
  onRequestClose,
  activeTab,
  setActiveTab,
}) => {
  const { isDesktop } = useWindowSize()
  const {
    swanbots: { canManage, canSetAlerts, canViewSensors },
  } = usePermissions()
  const [isDeleting, setDeleting] = React.useState(false)
  const [isUpdating, setUpdating] = React.useState(false)

  const handleSwanBotUpdate = async (swanBotUpdate: SwanBotUpdate) => {
    setUpdating(true)
    try {
      await updateSwanBot(swanBot.id, swanBotUpdate)
      await mutate(
        'getSwanBotsForUser',
        (swanBots: SwanBot[]) => {
          const swanBotIdx = swanBots.findIndex(
            (item) => item.id === swanBot.id
          )
          return updateAt(swanBots, swanBotIdx, {
            ...swanBot,
            ...swanBotUpdate,
          })
        },
        false
      )
      message.success('SwanBot had been updated')
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.swanBotUpdate)
    }
    setUpdating(false)
  }

  return (
    <Spin
      spinning={isDeleting || isUpdating}
      tip={
        (isDeleting && 'Deleting SwanBot') ||
        (isUpdating && 'Updating SwanBot') ||
        undefined
      }
    >
      <ModalTop
        swanBot={swanBot}
        onChange={handleSwanBotUpdate}
        onRequestClose={onRequestClose}
      />

      <div className={css.tabs}>
        {isDesktop && canManage && (
          <div className={css.delete}>
            <DeleteSwanBot
              id={swanBot.id}
              isDeleting={isDeleting}
              disabled={['IN_A_MISSION', 'SUSPENDED', 'ABORT_TO_HOME'].includes(
                swanBot.status
              )}
              onChangeDeleting={setDeleting}
              onDelete={onRequestClose}
            />
          </div>
        )}
        <Tabs
          activeKey={activeTab}
          destroyInactiveTabPane={true}
          onTabClick={(activeKey) => setActiveTab(activeKey as TabKey)}
          className={css.tabs}
        >
          <Tabs.TabPane tab="Location" key={LOCATION_TAB_KEY}>
            <LocationTab
              areas={areas}
              swanBot={swanBot}
              onChange={handleSwanBotUpdate}
            />
          </Tabs.TabPane>
          {canViewSensors && (
            <Tabs.TabPane tab="Sensors" key={SENSORS_TAB_KEY}>
              <SensorsTab swanBot={swanBot} />
            </Tabs.TabPane>
          )}
          {canSetAlerts && (
            <Tabs.TabPane tab="Alerts" key={ALERTS_TAB_KEY}>
              <AlertsTab swanBot={swanBot} />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    </Spin>
  )
}
