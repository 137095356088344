import { Collapse } from 'antd'
import React from 'react'

import css from './style.module.css'

export const SingleCollapse: React.FC<{ header: React.ReactNode }> = ({
  header,
  children,
}) => {
  return (
    <Collapse ghost className={css.main}>
      <Collapse.Panel key="panel" header={header}>
        {children}
      </Collapse.Panel>
    </Collapse>
  )
}
