import React from 'react'

import { HoveredChartPointProvider } from '../../../contexts/hoveredChartPoint'
import { CenteredSpin } from '../../CenteredSpin'
import { ReloadPageResult } from '../../ReloadPageResult'
import { DataVisPageContent } from './DataVisPageContent'
import { useDataVisDictionaries } from './useDataVisDictionaries'

export const DataVis: React.FC = () => {
  const dictionaries = useDataVisDictionaries()

  if (dictionaries.loading) {
    return <CenteredSpin tip="Loading data" />
  }

  if (dictionaries.error) {
    return <ReloadPageResult title="Couldn't receive data information" />
  }

  return (
    <HoveredChartPointProvider>
      <DataVisPageContent {...dictionaries.data} />
    </HoveredChartPointProvider>
  )
}
