import { pick, omit } from 'lodash'
import moment from 'moment'
// https://github.com/Sage/jsurl/pull/17
import JSURL from '@yaska-eu/jsurl2'
import { Area, MissionRun, MissionType, SwanBot } from 'swanviz'

import { DATA_URL } from '../../../utils/links'
import { dateFormat } from '../../../utils/momentFormat'
import { isNotNil } from '../../../utils/typeGuards'
import { ALL_OPTION } from './allOption'
import {
  DataVisQueryObject,
  DisplayAsOptions,
  FilterQueryObject,
  FormValues,
  MissionRunFilterWithShowPath,
} from './types'

export type CompactMissionRun = [number, number[], boolean]

export const toFormValues = (
  queryObject: FilterQueryObject,
  {
    areas,
    missions,
    swanBots,
    displayAsOptions,
  }: {
    areas: Area[]
    missions: MissionType[]
    swanBots: SwanBot[]
    displayAsOptions: DisplayAsOptions
  }
): FormValues => {
  const initialValues: FormValues = {
    datesRange: getDefaultDates(),
    areaId: areas[0]?.id,
    missionId: ALL_OPTION,
    swanbotId: ALL_OPTION,
    displayAs: displayAsOptions[0].value,
  }

  const momentFromDate = moment(queryObject.fromDate, dateFormat, true)
  const fromDate = momentFromDate.isValid()
    ? momentFromDate
    : initialValues.datesRange[0]

  const momentToDate = moment(queryObject.toDate, dateFormat, true)
  const toDate = momentToDate.isValid()
    ? momentToDate
    : initialValues.datesRange[1]

  return {
    datesRange: [fromDate, toDate],
    areaId:
      areas.find((area) => area.id === Number(queryObject.areaId))?.id ||
      initialValues.areaId,
    missionId:
      queryObject.missionId === ALL_OPTION
        ? ALL_OPTION
        : missions.find(
            (mission) => mission.id === Number(queryObject.missionId)
          )?.id || initialValues.missionId,
    swanbotId:
      queryObject.swanbotId === ALL_OPTION
        ? ALL_OPTION
        : swanBots.find(
            (swanBot) => swanBot.id === Number(queryObject.swanbotId)
          )?.id || initialValues.swanbotId,
    displayAs:
      displayAsOptions.find(
        (displayAs) => displayAs.value === queryObject.displayAs
      )?.value || initialValues.displayAs,
    hideMap: queryObject.hideMap === 'true',
  }
}

export const getDefaultDates = (): FormValues['datesRange'] => [
  moment().subtract(1, 'day'),
  moment(),
]

export const toQueryObject = (formValues: FormValues): FilterQueryObject => {
  return {
    fromDate: formValues.datesRange[0].format(dateFormat),
    toDate: formValues.datesRange[1].format(dateFormat),
    areaId: String(formValues.areaId),
    missionId: String(formValues.missionId),
    swanbotId: String(formValues.swanbotId),
    displayAs: formValues.displayAs,
    hideMap: Boolean(formValues.hideMap).toString(),
  }
}

export const pickFilterRelatedFields = (
  query: DataVisQueryObject
): FilterQueryObject => {
  return pick(query, [
    'fromDate',
    'toDate',
    'areaId',
    'missionId',
    'swanbotId',
    'displayAs',
    'hideMap',
  ])
}

export const normalizeMissionRuns = (
  missionRunsFromQuery: string,
  missionRuns: MissionRun[]
): MissionRunFilterWithShowPath[] => {
  try {
    const missionRunFilters = parseMissionRuns(missionRunsFromQuery)
    return missionRunFilters
      .map((missionRunFilter) => {
        const missionRun = missionRuns.find(
          (item) => item.runId === missionRunFilter.runId
        )

        if (!missionRun) {
          return null
        }

        return {
          runId: missionRun.runId,
          sensorParamList: missionRunFilter.sensorParamList
            .map((filterSensorParamId) => {
              return missionRun.sensorIds.find(
                (sensorId) => sensorId === filterSensorParamId
              )
            })
            .filter(isNotNil),
          showPath: Boolean(missionRunFilter.showPath),
        }
      })
      .filter(isNotNil)
  } catch {
    return []
  }
}

const parseMissionRuns = (
  stringifiedMissionRuns: string
): MissionRunFilterWithShowPath[] => {
  const compactMissionRuns = JSURL.parse(
    stringifiedMissionRuns
  ) as CompactMissionRun[]

  return compactMissionRuns.map<MissionRunFilterWithShowPath>(
    (compactMissionRun) => ({
      runId: compactMissionRun[0],
      sensorParamList: compactMissionRun[1],
      showPath: compactMissionRun[2],
    })
  )
}

export const stringifyMissionRuns = (
  missionRuns: MissionRunFilterWithShowPath[]
) => {
  const compactMissionRuns: CompactMissionRun[] = missionRuns.map(
    (missionRun) => [
      missionRun.runId,
      missionRun.sensorParamList,
      missionRun.showPath,
    ]
  )

  return JSURL.stringify(compactMissionRuns)
}

export const getDataUrl = (
  query: FilterQueryObject | DataVisQueryObject
): string => {
  let params = new URLSearchParams(omit(query, 'missionRuns')).toString()
  if ('missionRuns' in query) {
    params += `&missionRuns=${query.missionRuns}`
  }
  return `${DATA_URL}?${params}`
}

export const getDataLink = (
  filterValues: FormValues,
  missionRuns?: MissionRunFilterWithShowPath[]
): string => {
  const query: FilterQueryObject | DataVisQueryObject = {
    ...toQueryObject(filterValues),
    ...(missionRuns ? { missionRuns: stringifyMissionRuns(missionRuns) } : {}),
  }

  return getDataUrl(query)
}
