import { message } from 'antd'
import React from 'react'
import { addArea, AreaAdd } from 'swanviz'
import { mutate } from 'swr'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'

import { PREDEFINED_COLORS } from '../../../../utils/colors'
import {
  DEFAULT_COORDINATES,
  INTERPOLATION_DEFAULTS,
} from '../../../../utils/defaults'
import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import { AddButton } from '../../../AddButton'
import { AreaModal, AreaModalForm } from '../AreaModal'

const EMPTY_AREA: AreaAdd = {
  name: '',
  colorCode: PREDEFINED_COLORS[0],
  depth: INTERPOLATION_DEFAULTS.depth.value,
  coordinates: DEFAULT_COORDINATES,
  geofenceId: null,
}

export const AreaAddButton: React.FC = () => {
  const [isAdding, setIsAdding] = React.useState(false)

  const [isModalOpened, setIsModalOpened] = useQueryParam(
    'addArea',
    withDefault(BooleanParam, false)
  )
  const open = () => setIsModalOpened(true)
  const close = () => setIsModalOpened(undefined)

  const handleSubmit = async (newArea: AreaAdd) => {
    setIsAdding(true)
    try {
      await addArea(newArea)
      await mutate('getAreaList')
      message.success(`Area "${newArea.name}" has been added`)
      close()
    } catch (e) {
      console.error(e)
      message.error(ERROR_MESSAGES.areaAdd)
    }
    setIsAdding(false)
  }

  return (
    <>
      <AddButton onClick={open}>New area</AddButton>
      <AreaModal
        visible={isModalOpened}
        closable={!isAdding}
        onCancel={close}
        title="New area"
      >
        <AreaModalForm
          initialValues={EMPTY_AREA}
          disabled={isAdding}
          onSubmit={handleSubmit}
        />
      </AreaModal>
    </>
  )
}
