import { Form, Input, message, Switch, Typography } from 'antd'
import { RuleObject } from 'antd/es/form'
import { FormItemLabelProps } from 'antd/es/form/FormItemLabel'
import React from 'react'
import { updateUserSetting, UserProfile } from 'swanviz'
import { mutate } from 'swr'

import { useUserSession } from '../../../../contexts/userSessionContext'
import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import { getTrimmedInputValue } from '../../../../utils/input'
import { isDefined } from '../../../../utils/typeGuards'
import { useCheckUnsavedChanges } from '../../../../utils/useCheckUnsavedChanges'
import { useWindowSize } from '../../../../utils/useWindowSize'
import {
  getRequiredRule,
  validateNameRule,
} from '../../../../utils/validationRules'
import { SaveButton } from '../../../SaveButton'

const PHONE_REG_EXP = /^\+?[0-9\s]+$/

export const ProfileForm: React.FC<{
  className: string
  data?: UserProfile
}> = ({ className, data }) => {
  const { isDesktop, isMobile } = useWindowSize()
  const [values, setValues] = React.useState<UserProfile | undefined>(data)
  const [isSaving, setIsSaving] = React.useState(false)
  const { setBackupValue, hasChanges } = useCheckUnsavedChanges({
    value: values,
  })
  const { userIdSession } = useUserSession()
  const [form] = Form.useForm()
  const emptyLabel: FormItemLabelProps['label'] = isDesktop ? (
    // to preserve empty space in left column
    <></>
  ) : undefined

  const getPhoneValidationRules = () => {
    return [
      form.getFieldValue('enablePhoneNtf')
        ? getRequiredRule('Phone')
        : undefined,
      {
        validator: (rule: RuleObject, value: any) => {
          return !value || PHONE_REG_EXP.test(value)
            ? Promise.resolve()
            : Promise.reject('Phone number should have only numbers and "+"')
        },
      },
    ].filter(isDefined)
  }

  const handleSave = async (profile: UserProfile) => {
    try {
      setIsSaving(true)
      await updateUserSetting(profile)
      message.success('User settings have been uploaded successfully')
      mutate('getUserProfile')
      mutate(['getUser', userIdSession])
    } catch (e) {
      console.error(e)
      message.error(ERROR_MESSAGES.userSettingsUpdate)
    } finally {
      setIsSaving(false)
    }
  }

  React.useEffect(() => {
    setValues(data)
    setBackupValue(data)
  }, [data])

  return (
    <Form
      form={form}
      initialValues={values}
      layout={isDesktop ? 'horizontal' : 'vertical'}
      className={className}
      onValuesChange={(_changedValues, formValues) => {
        setValues({
          ...values,
          ...formValues,
        })
      }}
      onFinish={handleSave}
      colon={false}
    >
      <Form.Item
        label="Name"
        name="fullName"
        rules={[getRequiredRule('Name'), validateNameRule]}
        getValueFromEvent={getTrimmedInputValue}
      >
        <Input disabled={isMobile} />
      </Form.Item>
      <Form.Item label="Department" name="department">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Organisation" name="organization">
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phoneNo"
        dependencies={['enablePhoneNtf']}
        rules={getPhoneValidationRules()}
      >
        <Input disabled={isMobile} />
      </Form.Item>
      <Form.Item label={emptyLabel} dependencies={['enablePhoneNtf']}>
        {() => (
          <>
            <Form.Item noStyle name="enablePhoneNtf" valuePropName="checked">
              <Switch disabled={isMobile} />
            </Form.Item>{' '}
            <Typography.Text>Send alerts by sms</Typography.Text>
          </>
        )}
      </Form.Item>
      <Form.Item label="Email" name="username">
        <Input disabled />
      </Form.Item>
      <Form.Item label={emptyLabel} dependencies={['enableEmailNtf']}>
        {() => (
          <>
            <Form.Item noStyle name="enableEmailNtf" valuePropName="checked">
              <Switch disabled={isMobile} />
            </Form.Item>{' '}
            <Typography.Text>Send alert by email</Typography.Text>
          </>
        )}
      </Form.Item>
      {isDesktop && <SaveButton loading={isSaving} disabled={!hasChanges} />}
    </Form>
  )
}
