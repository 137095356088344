import { CopyOutlined } from '@ant-design/icons'
import { Button, Input, message, Modal } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { addMission, NewMissionType } from 'swanviz'

import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import { getTrimmedInputValue } from '../../../../utils/input'
import { getMissionLink } from '../../../../utils/links'

import css from './style.module.css'

export const MissionCopyButton: React.FC<{
  mission: NewMissionType
}> = ({ mission }) => {
  const [isModalVisible, setModalVisible] = React.useState(false)

  return (
    <>
      <Button icon={<CopyOutlined />} onClick={() => setModalVisible(true)}>
        Make a copy
      </Button>
      <MissionCopyModal
        mission={mission}
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  )
}

const MissionCopyModal: React.FC<{
  mission: NewMissionType
  visible: boolean
  onClose: () => void
}> = ({ mission, visible, onClose }) => {
  const [newMissionName, setNewMissionName] = React.useState<string>()
  const [isCopying, setCopying] = React.useState(false)
  const history = useHistory()

  const handleSubmit = async () => {
    if (!newMissionName) {
      return
    }

    setCopying(true)

    try {
      const newMissionId = await addMission({
        ...mission,
        name: newMissionName,
      })
      history.push(getMissionLink({ id: newMissionId, mode: 'edit' }))
    } catch (err: any) {
      console.error(err)
      message.error(err.message || ERROR_MESSAGES.missionDelete)

      setCopying(false)
    }
  }

  return (
    <Modal
      title={`Make a copy of mission "${mission.name}"`}
      footer={null}
      visible={visible}
      closable={!isCopying}
      maskClosable={!isCopying}
      onCancel={onClose}
    >
      <form className={css.form} onSubmit={handleSubmit}>
        New name
        <Input
          value={newMissionName}
          disabled={isCopying}
          onChange={(e) => setNewMissionName(getTrimmedInputValue(e))}
        />
        <Button
          className={css.submit}
          type="primary"
          htmlType="submit"
          danger
          disabled={!newMissionName}
          loading={isCopying}
        >
          Make a copy
        </Button>
      </form>
    </Modal>
  )
}
