import { DrawPointMode, EditAction, ModifyMode } from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { GeoJSON } from 'geojson'
import { Coordinates, MissionTask } from 'swanviz'

import { commonEditableLayerGuidesProps } from '../../../../../geoLayers/editableLayer'
import { RED_RGB } from '../../../../../utils/colors'
import { createPointFeature } from '../../../../../utils/geojson'
import { getPreviousLocation } from '../../../../../utils/missionTasks'
import { getEditLawnmowerLayer } from './editLawnmowerLayer'
import { getEditSurveyLayer } from './editSurveyLayer'

export const getTaskEditLayer = ({
  task,
  tasks,
  highlightedSurveyPointIdx,
  isAddingNewGeoFeature,
  onChangeAddingNewGeoFeature,
  onChange,
}: {
  task: MissionTask
  tasks: MissionTask[]
  highlightedSurveyPointIdx: number | undefined
  isAddingNewGeoFeature: boolean
  onChangeAddingNewGeoFeature: (newValue: boolean) => void
  onChange: (task: MissionTask) => void
}) => {
  const commonLayerParams: ConstructorParameters<
    typeof EditableGeoJsonLayer
  >[0] = {
    id: 'editableTask',
    pickable: true,
  }

  switch (task.type) {
    case 'go': {
      const { location: taskLocation } = task.args
      if (taskLocation === 'home') {
        return undefined
      }
      const data: GeoJSON.FeatureCollection = {
        type: 'FeatureCollection',
        features: taskLocation ? [createPointFeature(taskLocation)] : [],
      }
      const onEdit = ({ updatedData }: EditAction<typeof data>) => {
        if (updatedData.features.length) {
          const { geometry } = updatedData.features[0]
          if (geometry.type === 'Point') {
            onChange({
              ...task,
              args: {
                ...task.args,
                location: geometry.coordinates as Coordinates,
              },
            })
          }
        }
      }

      return new EditableGeoJsonLayer({
        ...commonLayerParams,
        data,
        mode: taskLocation ? ModifyMode : DrawPointMode,
        selectedFeatureIndexes: [0],
        onEdit,
        _subLayerProps: {
          geojson: {
            visible: false,
          },
          guides: {
            ...commonEditableLayerGuidesProps,
            getFillColor: () => RED_RGB,
          },
        },
      })
    }

    case 'survey': {
      return getEditSurveyLayer({
        task,
        highlightedSurveyPointIdx,
        isAddingNewGeoFeature,
        layerParams: commonLayerParams,
        onChange,
        onChangeAddingNewGeoFeature,
      })
    }

    case 'lawnmower': {
      const previousLocation = getPreviousLocation({
        taskId: task.id,
        allTasks: tasks,
      })

      return previousLocation
        ? getEditLawnmowerLayer({
            task,
            startLocation: previousLocation,
            layerParams: commonLayerParams,
            onChange,
          })
        : undefined
    }
  }
}
