export const MAP_STYLES = [
  {
    label: 'MapBox Light',
    value: 'mapbox://styles/mapbox/light-v10',
  },
  {
    label: 'MapBox Dark',
    value: 'mapbox://styles/mapbox/dark-v10',
  },
  {
    label: 'MapBox Streets',
    value: 'mapbox://styles/mapbox/streets-v11',
  },
  {
    label: 'MapBox Satellite',
    value: 'mapbox://styles/mapbox/satellite-v9',
  },
  {
    label: 'OpenStreetMap',
    value: {
      version: 8,
      sources: {
        osm: {
          type: 'raster',
          tiles: [
            'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
            'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
            'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png',
          ],
          tileSize: 256,
          attribution:
            '© <a href="https://www.openstreetmap.org">OpenStreetMap</a> contributors',
        },
      },
      layers: [
        {
          id: 'tiles',
          type: 'raster',
          source: 'osm',
          minzoom: 0,
          maxzoom: 22,
        },
      ],
    },
  },
  {
    label: 'OneMap',
    value: {
      version: 8,
      sources: {
        onemap: {
          type: 'raster',
          tiles: [
            'https://maps-a.onemap.sg/v3/Original/{z}/{x}/{y}.png',
            'https://maps-b.onemap.sg/v3/Original/{z}/{x}/{y}.png',
            'https://maps-c.onemap.sg/v3/Original/{z}/{x}/{y}.png',
          ],
          tileSize: 256,
          attribution:
            '<img src="https://docs.onemap.sg/maps/images/oneMap64-01.png" style="height:20px;width:20px;"/> New OneMap | Map data &copy; contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>',
        },
      },
      layers: [
        {
          id: 'onemap-tiles',
          type: 'raster',
          source: 'onemap',
          maxZoom: 18,
          minZoom: 11,
        },
      ],
    },
  },
] as const
