import { Form, message, Typography } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { verifyForgotPassword } from 'swanviz'
import { StringParam, useQueryParam } from 'use-query-params'

import { ERROR_MESSAGES } from '../../../utils/errorMessages'
import {
  emailRule,
  getRequiredRule,
  MIN_PASSWORD_LENGTH,
  passwordLengthRule,
} from '../../../utils/validationRules'
import { Button, Input, LoginWrapper } from '../../LoginWrapper'
import { LOGIN_URL } from '../../../utils/links'

type FormValues = {
  login: string
  password: string
  confirmPassword: string
}

export const ResetPassword: React.FC = () => {
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [form] = Form.useForm<FormValues>()
  const [securityToken] = useQueryParam('token', StringParam)

  const handleSubmit = async (values: FormValues) => {
    if (!securityToken) return

    setIsSubmitting(true)
    try {
      await verifyForgotPassword({
        username: values.login,
        newPassword: values.password,
        securityToken,
      })
      message.success('Password has been changed successfully')
      history.push(LOGIN_URL)
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.resetPassword)
    }
    setIsSubmitting(false)
  }

  return (
    <LoginWrapper
      title="New password"
      subTitle={
        <>
          Please set a new password.
          <br />
          Password need to contain {MIN_PASSWORD_LENGTH} symbols.
        </>
      }
      renderContent={() =>
        securityToken ? (
          <Form form={form} onFinish={handleSubmit} noValidate={true}>
            <Input
              label="Username"
              type="email"
              name="login"
              rules={[emailRule]}
            />
            <Input
              label="Password"
              type="password"
              name="password"
              rules={[getRequiredRule('Password'), passwordLengthRule]}
            />
            <Input
              label="Confirm password"
              type="password"
              name="confirmPassword"
              dependencies={['password', 'confirmPassword']}
              rules={[
                {
                  validator: (_rule, value) =>
                    form.getFieldValue('password') === value
                      ? Promise.resolve()
                      : Promise.reject("Passwords don't match"),
                },
              ]}
            />
            <Button loading={isSubmitting}>Set new password</Button>
          </Form>
        ) : (
          <Typography.Paragraph type="danger">
            Link is incorrect, please check email for password reset link
          </Typography.Paragraph>
        )
      }
    />
  )
}
