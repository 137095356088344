import { Button, Result } from 'antd'
import React from 'react'

type Props = React.ComponentProps<typeof Result>

export const ReloadPageResult: React.FC<Props> = (props) => {
  return (
    <Result
      status="error"
      title="Something went wrong"
      subTitle="Please reload page to try again"
      extra={
        <Button danger onClick={() => window.location.reload()}>
          Reload page
        </Button>
      }
      {...props}
    />
  )
}
