import { Button, message } from 'antd'
import React from 'react'
import { Area, updateArea } from 'swanviz'
import { mutate } from 'swr'
import { NumberParam, useQueryParam } from 'use-query-params'

import { ReactComponent as EditIcon } from '../../../../icons/edit.svg'
import { updateAt } from '../../../../utils/array'
import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import { AreaModal, AreaModalForm } from '../AreaModal'
import css from './style.module.css'

export const AreaEditButton: React.FC<{
  area: Area
}> = ({ area }) => {
  const [isUpdating, setUpdating] = React.useState(false)

  const [queryAreaId, setQueryAreaId] = useQueryParam('editArea', NumberParam)
  const isVisible = area.id === queryAreaId
  const open = () => setQueryAreaId(area.id)
  const close = () => setQueryAreaId(undefined)

  const handleSubmit = async (editedArea: Area) => {
    setUpdating(true)
    try {
      await updateArea(editedArea)
      await mutate(
        'getAreaList',
        (areas: Area[]) => {
          const areaIndex = areas.findIndex((item) => item.id === editedArea.id)
          return updateAt(areas, areaIndex, editedArea)
        },
        false
      )
      message.success(`Area "${editedArea.name}" has been updated`)
      close()
    } catch (e) {
      console.error(e)
      message.error(ERROR_MESSAGES.areaEdit)
    }
    setUpdating(false)
  }

  return (
    <>
      <Button
        icon={<EditIcon />}
        title="Edit area"
        type="text"
        size="small"
        className={css.button}
        onClick={open}
      />
      <AreaModal
        visible={isVisible}
        closable={!isUpdating}
        onCancel={close}
        title="Edit area"
      >
        <AreaModalForm
          initialValues={area}
          disabled={isUpdating}
          onSubmit={handleSubmit}
        />
      </AreaModal>
    </>
  )
}
