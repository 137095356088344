import { HtmlOverlay, HtmlOverlayItem } from '@nebula.gl/overlays'
import React from 'react'
import {
  Area,
  Coordinates,
  MissionRun,
  SensorParameter,
  SwanBot,
} from 'swanviz'

import { useHoveredChartPoint } from '../../../../contexts/hoveredChartPoint'
import { GetSensorColor } from '../../../../utils/useSensorColors'
import { Map } from '../../../Map'
import { PathsByRun } from '../../../RunPathsFetcher'
import { SensorDataByRun } from '../../../SensorDataFetcher/types'
import { MapPopover } from '../MapPopover'
import { MissionRunFilterWithShowPath } from '../types'

import { useHeatmapLayers } from './layers/heatmapLayers'
import {
  getHoveredPointLayer,
  getMissionRunLayers,
} from './layers/missionLayers'

type Props = {
  area: Area
  missionRuns: MissionRun[]
  missionRunFilters: MissionRunFilterWithShowPath[]
  sensorData: SensorDataByRun
  sensors: SensorParameter[]
  getSensorColor: GetSensorColor
  pathsByRun: PathsByRun
  swanBots: SwanBot[]
}

export type HoveredMapPoint = Coordinates | null

export const DataVisMap: React.FC<Props> = ({
  area,
  missionRuns,
  missionRunFilters,
  sensorData,
  sensors,
  getSensorColor,
  pathsByRun,
  swanBots,
}) => {
  const [areaLong, areaLat] = area.coordinates

  const { hoveredPoint: hoveredChartPoint } = useHoveredChartPoint()
  const [hoveredMapPoint, setHoveredMapPoint] =
    React.useState<HoveredMapPoint>(null)

  const heatmapLayers = useHeatmapLayers({
    missionRuns,
    sensorData,
    sensors,
    getSensorColor,
  })

  const missionRunLayers = React.useMemo(
    () =>
      getMissionRunLayers({
        pathsByRun,
        swanBots,
        onHover: setHoveredMapPoint,
      }),
    [pathsByRun, setHoveredMapPoint]
  )

  const hoveredPointLayer = React.useMemo(
    () => getHoveredPointLayer(hoveredChartPoint || hoveredMapPoint),
    [hoveredChartPoint, hoveredMapPoint]
  )

  const layers = [...heatmapLayers, ...missionRunLayers, hoveredPointLayer]

  return (
    <Map
      longitude={areaLong}
      latitude={areaLat}
      layers={layers}
      pickingRadius={10}
      getCursor={(interactiveState) => {
        if (interactiveState.isDragging) return 'grabbing'
        if (hoveredMapPoint) return 'pointer'
        return 'grab'
      }}
    >
      <HtmlOverlay zIndex={0}>
        {hoveredMapPoint && (
          <HtmlOverlayItem coordinates={hoveredMapPoint}>
            <MapPopover
              coordinates={hoveredMapPoint}
              missionRunFilters={missionRunFilters}
              sensors={sensors}
            />
          </HtmlOverlayItem>
        )}
      </HtmlOverlay>
    </Map>
  )
}
