import { Form, Input, Select } from 'antd'
import _ from 'lodash'
import React from 'react'
import { User, UserAdd, UserUpdate } from 'swanviz'

import { getTrimmedInputValue } from '../../../../../utils/input'
import { roleLabels, roles } from '../../../../../utils/roles'
import { useWindowSize } from '../../../../../utils/useWindowSize'
import {
  emailRule,
  getRequiredRule,
  validateNameRule,
} from '../../../../../utils/validationRules'
import { SaveButton } from '../../../../SaveButton'
import css from './style.module.css'

type onUserSave =
  | ((userData: UserAdd) => void)
  | ((userData: UserUpdate) => void)

type Props = {
  user?: User
  onSave: onUserSave
  isSaving: boolean
}

export const UserFormTab: React.FC<Props> = ({ user, onSave, isSaving }) => {
  const [form] = Form.useForm<User>()
  const { isDesktop, isMobile } = useWindowSize()

  const initialValues: UserAdd = {
    username: user?.username || '',
    fullName: user?.fullName || '',
    department: user?.department || '',
    organization: user?.organization || '',
    roles: user?.roles || [],
  }

  const [values, setValues] = React.useState(initialValues)

  return (
    <Form
      initialValues={initialValues}
      form={form}
      onValuesChange={(_changedValues, formValues) => {
        setValues({
          ...values,
          ...formValues,
        })
      }}
      onFinish={onSave}
      className={css.form}
      colon={false}
      hideRequiredMark={true}
      noValidate={true}
      size={isMobile ? 'large' : undefined}
    >
      {!user && (
        <Form.Item label="Username (email)" name="username" rules={[emailRule]}>
          <Input type="email" />
        </Form.Item>
      )}

      <Form.Item
        label="Full name"
        name="fullName"
        rules={[getRequiredRule('Full name'), validateNameRule]}
        getValueFromEvent={getTrimmedInputValue}
      >
        <Input readOnly={isMobile} />
      </Form.Item>

      <Form.Item
        label="Department"
        name="department"
        rules={[getRequiredRule('Department')]}
        getValueFromEvent={getTrimmedInputValue}
      >
        <Input readOnly={isMobile} />
      </Form.Item>

      <Form.Item
        label="Organisation"
        name="organization"
        rules={[getRequiredRule('Organisation')]}
        getValueFromEvent={getTrimmedInputValue}
      >
        <Input readOnly={isMobile} />
      </Form.Item>

      <Form.Item label="Roles" name="roles" rules={[getRequiredRule('Roles')]}>
        <Select
          mode="multiple"
          placeholder="Select roles"
          style={{ width: '100%' }}
          disabled={isMobile}
        >
          {roles.map((role) => (
            <Select.Option key={role} value={role}>
              {roleLabels[role]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {isDesktop && (
        <SaveButton
          loading={isSaving}
          disabled={isUserFormDataEqual(initialValues, values)}
        />
      )}
    </Form>
  )
}

const isUserFormDataEqual = (a: UserAdd, b: UserAdd) => {
  return (
    a.username === b.username &&
    a.fullName === b.fullName &&
    a.department === b.department &&
    a.organization === b.organization &&
    _.isEqual(a.roles.slice().sort(), b.roles.slice().sort())
  )
}
