import { Coordinates, getLawnmowerPolygon, MissionTask } from 'swanviz'

export type LawnmowerMissionTask = Extract<MissionTask, { type: 'lawnmower' }>

export const getLawnmowerPolygonPoints = (
  startingLocation: Coordinates,
  task: LawnmowerMissionTask
): Record<
  'bottomLeft' | 'topLeft' | 'topRight' | 'bottomRight',
  Coordinates
> => {
  const polygon = getLawnmowerPolygon(startingLocation, task)

  return {
    bottomLeft: polygon[0],
    topLeft: polygon[1],
    topRight: polygon[2],
    bottomRight: polygon[3],
  }
}

export const getSimplifiedLawnmowerPath = (
  startingLocation: Coordinates | null,
  task: LawnmowerMissionTask
): Coordinates[] => {
  if (!startingLocation) {
    return []
  }

  const lawnmowerPolygonPoints = getLawnmowerPolygonPoints(
    startingLocation,
    task
  )

  return task.args.legs % 2 === 0
    ? [
        lawnmowerPolygonPoints.topLeft,
        lawnmowerPolygonPoints.topRight,
        lawnmowerPolygonPoints.bottomRight,
      ]
    : [
        lawnmowerPolygonPoints.topLeft,
        lawnmowerPolygonPoints.bottomRight,
        lawnmowerPolygonPoints.topRight,
      ]
}
