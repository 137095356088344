import { InputNumber, Slider } from 'antd'
import React from 'react'

import { INTERPOLATION_DEFAULTS } from '../../../../utils/defaults'

import css from './style.module.css'

export type DepthRange = [number, number]

type Props = {
  value: DepthRange
  max: number
  disabled: boolean
  onChange: (newValue: DepthRange) => void
}

export const DepthControl: React.FC<Props> = ({
  value,
  onChange,
  max,
  disabled,
}) => {
  const [from, to] = value
  const { min, step, precision } = INTERPOLATION_DEFAULTS.depth

  return (
    <div>
      <div className={css.inputs}>
        <span className={css.label}>Depth, m</span>
        <InputNumber
          value={from}
          min={min}
          max={Math.min(to, max)}
          step={step}
          precision={precision}
          disabled={disabled}
          className={css.input}
          onChange={(newFrom) =>
            typeof newFrom === 'number' && onChange([newFrom, to])
          }
        />
        <span className={css.divider}>—</span>
        <InputNumber
          value={to}
          min={Math.max(from, min)}
          max={max}
          step={step}
          precision={precision}
          disabled={disabled}
          className={css.input}
          onChange={(newTo) =>
            typeof newTo === 'number' && onChange([from, newTo])
          }
        />
      </div>
      <Slider
        range
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        marks={{
          [min]: <span className={css.mark}>{min}</span>,
          [max]: <span className={css.mark}>{max}</span>,
        }}
      />
    </div>
  )
}
