import { Form } from 'antd'
import React from 'react'

import { MISSION_TASK_DEFAULTS } from '../../../utils/defaults'
import { getRequiredNumberRules } from '../../../utils/validationRules'
import { InputNumberWithPostfix } from '../InputNumberWithPostfix'
import { Label } from '../Label'

export const DurationFormItem: React.FC<{ isViewMode: boolean }> = ({
  isViewMode,
}) => (
  <Form.Item
    label={<Label>Duration</Label>}
    name={['args', 'duration']}
    rules={getRequiredNumberRules(
      'duration',
      MISSION_TASK_DEFAULTS.duration.min
    )}
  >
    <InputNumberWithPostfix
      min={MISSION_TASK_DEFAULTS.duration.min}
      precision={MISSION_TASK_DEFAULTS.duration.precision}
      readOnly={isViewMode}
      postfix="s"
    />
  </Form.Item>
)
