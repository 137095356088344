import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQueryParam, NumberParam } from 'use-query-params'
import useSWR from 'swr'
import {
  getGeofence,
  addGeofence,
  updateGeofence,
  Boundary,
  getAreaList,
  deleteGeofence,
  GeofenceUpdate,
} from 'swanviz'
import { message } from 'antd'
import _ from 'lodash'

import { ERROR_MESSAGES } from '../../../utils/errorMessages'
import { isDefined } from '../../../utils/typeGuards'
import { useWindowSize } from '../../../utils/useWindowSize'
import { CenteredSpin } from '../../CenteredSpin'
import { ReloadPageResult } from '../../ReloadPageResult'
import { GeofencePageContent } from './GeofencePageContent'
import { useCheckUnsavedChanges } from '../../../utils/useCheckUnsavedChanges'

export type HoveredPolygonId = Boundary['id'] | undefined

export const GeofencePage: React.FC = () => {
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const { geofenceId } = useParams<{ geofenceId?: string }>()
  const isAddingNewGeofence = !isDefined(geofenceId)
  const [queryAreaId] = useQueryParam('areaId', NumberParam)
  const [geofence, setGeofence] = React.useState<GeofenceUpdate>()
  const [isSaving, setIsSaving] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const { setBackupValue } = useCheckUnsavedChanges({
    value: geofence,
  })

  const { data: fetchedGeofence, isValidating: isGeofenceLoading } = useSWR(
    isAddingNewGeofence ? null : 'getGeofence',
    async () => await getGeofence(Number(geofenceId))
  )
  const { data: areas, isValidating: isAreasLoading } = useSWR(
    'getAreaList',
    getAreaList
  )

  const isLoading = isGeofenceLoading || isAreasLoading
  const area = areas?.find((a) => a.id === geofence?.areaId)

  React.useEffect(() => {
    if (isMobile) {
      history.replace('/')
    }
  }, [isMobile])

  React.useEffect(() => {
    if (isAddingNewGeofence && queryAreaId) {
      const initialGeofence: GeofenceUpdate = {
        name: '',
        boundaries: [],
        id: Infinity,
        areaId: queryAreaId,
      }

      setGeofence(initialGeofence)
      setBackupValue(_.cloneDeep(initialGeofence))
    }
  }, [])

  React.useEffect(() => {
    if (!isAddingNewGeofence && fetchedGeofence) {
      setGeofence(fetchedGeofence)
      setBackupValue(_.cloneDeep(fetchedGeofence))
    }
  }, [fetchedGeofence])

  if (isLoading) {
    return <CenteredSpin tip="Loading geofence" />
  }

  if (!area || !geofence) {
    return <ReloadPageResult title="Couldn't receive geofence information" />
  }

  const handleSuccess = () => {
    setBackupValue(undefined)
    history.push('/missions-and-geofences')
  }

  const handleSave = async () => {
    if (isSaving) return
    setIsSaving(() => true)
    try {
      const saveMethod = isAddingNewGeofence ? addGeofence : updateGeofence

      // TODO remove after swanviz issue is resolved: https://github.com/subnero1/swanviz/issues/24
      const hasBoundaryWithId0 = geofence.boundaries.some((b) => b.id === 0)
      const newGeofence = hasBoundaryWithId0
        ? {
            ...geofence,
            boundaries: geofence.boundaries.map((b) => ({
              ...b,
              id: b.id + 1,
            })),
          }
        : geofence

      await saveMethod(newGeofence)
      handleSuccess()
    } catch (err: any) {
      console.error(err)
      message.error(
        err.message === 'Invalid inputs for adding geofence'
          ? ERROR_MESSAGES.geofenceUniqueName
          : ERROR_MESSAGES.geofenceSave,
        3
      )
    }
    setIsSaving(false)
  }

  const handleDelete = async () => {
    if (isDeleting) return
    setIsDeleting(true)
    try {
      await deleteGeofence(geofence.id)
      handleSuccess()
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.geofenceDelete)
    }
    setIsDeleting(false)
  }

  return (
    <GeofencePageContent
      geofence={geofence}
      area={area}
      isAddingNewGeofence={isAddingNewGeofence}
      isSaving={isSaving}
      isDeleting={isDeleting}
      onChange={setGeofence}
      onSave={handleSave}
      onDelete={handleDelete}
    />
  )
}
