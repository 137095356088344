import { TablePaginationConfig } from 'antd/es/table'
import React from 'react'

import { useWindowSize } from '../../../utils/useWindowSize'
import { CenteredSpin } from '../../CenteredSpin'
import { ReloadPageResult } from '../../ReloadPageResult'

import { GeofencesTable } from './GeofencesTable'
import { MissionsTable } from './MissionsTable'
import css from './style.module.css'
import { useMissionsAndGeofences } from './useMissionsAndGeofences'

export const MissionsAndGeofences: React.FC = () => {
  const { isDesktop } = useWindowSize()
  const {
    areas,
    missions,
    geofences,
    areAreasLoading,
    areMissionsLoading,
    areGeofencesLoading,
    isError,
  } = useMissionsAndGeofences()

  if (areAreasLoading) {
    return <CenteredSpin tip="Loading missions and geofences" />
  }

  if (isError) {
    return <ReloadPageResult title="Couldn't get missions and geofences data" />
  }

  return (
    <>
      <MissionsTable
        missions={missions}
        areas={areas}
        geofences={geofences}
        pagination={PAGINATION_PROPS}
        isLoading={areMissionsLoading}
      />

      {isDesktop && (
        <div className={css.geofences}>
          <GeofencesTable
            geofences={geofences}
            areas={areas}
            pagination={PAGINATION_PROPS}
            isLoading={areGeofencesLoading}
          />
        </div>
      )}
    </>
  )
}

const PAGINATION_PROPS: TablePaginationConfig = {
  className: css.pagination,
  defaultPageSize: 10,
  hideOnSinglePage: true,
  responsive: true,
}
