import React from 'react'
import { Typography } from 'antd'

import css from './style.module.css'

type Props = {
  title: string
  buttons: React.ReactNode
}

export const TableHeader: React.FC<Props> = ({ title, buttons }) => (
  <div className={css.main}>
    <Typography.Text className={css.title}>{title}</Typography.Text>
    {buttons}
  </div>
)
