import { Checkbox, Typography } from 'antd'
import classNames from 'classnames'
import * as React from 'react'
import { MissionRun, SensorParameter } from 'swanviz'

import { ReactComponent as PauseIcon } from '../../../icons/pause.svg'
import { isRunExecuting } from '../../../utils/missionRuns'
import { ItemContainer } from '../ItemContainer'
import { ReactComponent as OngoingRunTriangleSVG } from './ongoingRunTriangle.svg'
import css from './style.module.css'

type Props = {
  isSelected: boolean
  missionRun: MissionRun
  date?: string
  time: string
  selectedSensorIds: number[]
  allSensorParameters: SensorParameter[]
  onMissionRunToggle: (runId: number) => void
  onSensorToggle: (runId: number, sensorId: number) => void
}

export const Run = ({
  isSelected,
  missionRun,
  date,
  time,
  selectedSensorIds,
  allSensorParameters,
  onMissionRunToggle,
  onSensorToggle,
}: Props) => {
  const renderSensorsChecklist = () => {
    return missionRun.sensorIds.map((sensorId) => {
      const sensor = allSensorParameters.find(
        (sensor) => sensor.id === sensorId
      )

      if (!sensor) return null

      return (
        <Checkbox
          key={sensorId}
          className={css.checkbox}
          checked={selectedSensorIds.includes(sensorId)}
          onChange={() => onSensorToggle(missionRun.runId, sensorId)}
        >
          {sensor.description}
        </Checkbox>
      )
    })
  }
  return (
    <ItemContainer
      className={classNames(css.main, isSelected && css.isSelected)}
      checkbox={
        <Checkbox
          checked={isSelected}
          onChange={() => onMissionRunToggle(missionRun.runId)}
        />
      }
      date={<Typography.Text>{date}</Typography.Text>}
      time={<Typography.Text className={css.time}>{time}</Typography.Text>}
      missionRun={
        <>
          {isRunExecuting(missionRun.status) && (
            <OngoingRunTriangleSVG className={css.icon} />
          )}
          {missionRun.status === 'MISSION SUSPENDED' && (
            <PauseIcon className={css.icon} />
          )}
          <Typography.Text>{missionRun.missionName}</Typography.Text>
          {isSelected && renderSensorsChecklist()}
        </>
      }
    />
  )
}
