import _ from 'lodash'
import { MissionRunFilter } from 'swanviz'

import { SensorDataByRun } from './types'

export const filterSensorData = (
  sensorData: SensorDataByRun,
  missionRunFilters: MissionRunFilter[]
): SensorDataByRun => {
  return Object.keys(sensorData).reduce<SensorDataByRun>((acc, key) => {
    const runId = Number(key)
    const missionRunFilter = missionRunFilters.find(
      (runFilter) =>
        runFilter.runId === runId && runFilter.sensorParamList.length > 0
    )
    if (missionRunFilter) {
      const runInfo = sensorData[runId]
      acc[runId] = runInfo && {
        ...runInfo,
        dataBySensor: _.pick(
          runInfo.dataBySensor,
          missionRunFilter.sensorParamList
        ),
      }
    }
    return acc
  }, {})
}
