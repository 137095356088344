import { Form, Switch } from 'antd'
import React from 'react'

import { TwoStepModal } from '../TwoStepModal'

import css from './style.module.css'

export const TwoStepForm: React.FC<{
  className: string
  is2FAEnabled: boolean
}> = ({ className, is2FAEnabled }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  return (
    <div className={className}>
      <Form.Item colon={false} label="2-step verification">
        <Switch
          checked={is2FAEnabled}
          onClick={() => setIsModalVisible(true)}
        />
        <span className={css.status}>
          {is2FAEnabled ? 'Enabled' : 'Disabled'}
        </span>
      </Form.Item>
      {isModalVisible && (
        <TwoStepModal
          is2FAEnabled={is2FAEnabled}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </div>
  )
}
