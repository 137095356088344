import classnames from 'classnames'
import React from 'react'
import {
  Area,
  Geofence,
  InterpolationInfo,
  InterpolationInputs,
  SensorParameter,
} from 'swanviz'

import type { InterpolationMissionRun, InterpolationRunInfo } from '../'
import { rgbToHex } from '../../../../utils/colors'
import {
  GetSensorColor,
  SetSensorColor,
} from '../../../../utils/useSensorColors'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { OverMapItem } from '../../../OverMapItem'
import { PathsByRun } from '../../../RunPathsFetcher'
import { SensorDataByRun } from '../../../SensorDataFetcher/types'
import { InterpolationDataControls } from '../InterpolationDataControls'
import { InterpolationLegend, LegendRunInfo } from '../InterpolationLegend'
import { heatmapColorToPathColor, InterpolationMap } from '../InterpolationMap'
import { InterpolationMapDataFetcher } from '../interpolationMapDataFetcher'

import css from './style.module.css'
import { useInterpolatedData } from './useInterpolatedData'

type Props = {
  missionRuns: InterpolationMissionRun[]
  area: Area
  geofence: Geofence
  interpolationInfo: InterpolationInfo | null
  interpolationInputs: InterpolationInputs
  isInterpolationInfoLoading: boolean
  isOriginalData: boolean
  sensor: SensorParameter
}

export const InterpolationMapContainer: React.FC<Props> = ({
  missionRuns,
  area,
  geofence,
  interpolationInfo,
  interpolationInputs,
  isInterpolationInfoLoading,
  isOriginalData,
  sensor,
}) => {
  const { isMobile } = useWindowSize()
  const interpolatedData = useInterpolatedData({
    active: !isOriginalData && interpolationInfo?.status === 'COMPLETED',
    inputs: interpolationInputs,
  })

  const [interpolationRunInfos, setInterpolationRunInfos] = React.useState<
    InterpolationRunInfo[]
  >(() =>
    missionRuns.map((run) => ({
      run,
      visible: true,
    }))
  )

  return (
    <InterpolationMapDataFetcher
      missionRuns={missionRuns}
      sensor={sensor}
      isOriginalData={isOriginalData}
    >
      {({ pathsByRun, sensorDataByRun, getSensorColor, setSensorColor }) => (
        <>
          <InterpolationMap
            area={area}
            geofence={geofence}
            sensor={sensor}
            runInfos={interpolationRunInfos}
            pathsByRun={pathsByRun}
            sensorDataByRun={sensorDataByRun}
            interpolatedData={interpolatedData.data}
            interpolationColor={interpolatedData.color}
            getSensorColor={getSensorColor}
          />
          <div className={css.overMap}>
            <OverMapItem
              title="Data"
              className={css.item}
              {...(isMobile
                ? {
                    collapsable: true,
                    initialIsCollapsed:
                      interpolationInfo?.status === 'COMPLETED',
                  }
                : {
                    collapsable: false,
                  })}
            >
              <InterpolationDataControls
                interpolationInfo={interpolationInfo}
                interpolationInputs={interpolationInputs}
                isInterpolationInfoLoading={isInterpolationInfoLoading}
                depthMaximum={area.depth}
              />
            </OverMapItem>
            <OverMapItem
              title="Legend"
              className={classnames(css.item, css.isScrollable)}
              collapsable={isMobile}
            >
              <InterpolationLegend
                sensor={sensor}
                legendRunInfos={getLegendRunInfos({
                  runInfos: interpolationRunInfos,
                  sensor,
                  setSensorColor,
                  onRunInfosChange: setInterpolationRunInfos,
                  pathsByRun,
                  getSensorColor,
                  sensorDataByRun,
                })}
                isOriginalData={isOriginalData}
                interpolatedData={interpolatedData}
              />
            </OverMapItem>
          </div>
        </>
      )}
    </InterpolationMapDataFetcher>
  )
}

const getLegendRunInfos = ({
  runInfos,
  sensor,
  sensorDataByRun,
  pathsByRun,
  getSensorColor,
  setSensorColor,
  onRunInfosChange,
}: {
  runInfos: InterpolationRunInfo[]
  sensor: SensorParameter
  sensorDataByRun: SensorDataByRun
  pathsByRun: PathsByRun
  getSensorColor: GetSensorColor
  setSensorColor: SetSensorColor
  onRunInfosChange: (newRunInfos: InterpolationRunInfo[]) => void
}): LegendRunInfo[] => {
  const getRunVisibilitySetter = (runId: number) => (visible: boolean) =>
    onRunInfosChange(
      runInfos.map((runInfo) =>
        runInfo.run.runId === runId ? { ...runInfo, visible } : runInfo
      )
    )

  return runInfos.map((runInfo) => {
    const { runId } = runInfo.run
    const heatmapColor = getSensorColor(runId, sensor.id)
    const pathColor = heatmapColorToPathColor(heatmapColor)
    const runSensorData = sensorDataByRun[runId]?.dataBySensor[sensor.id]?.data
    return {
      ...runInfo,
      heatmapColor: rgbToHex(heatmapColor),
      pathColor: rgbToHex(pathColor),
      sensorData: runSensorData,
      isDataLoading: !runSensorData,
      isPathLoading: !pathsByRun[runId],
      onColorChange: setSensorColor(runId, sensor.id),
      onVisibilityChange: getRunVisibilitySetter(runId),
    }
  })
}
