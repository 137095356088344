import { Form, message, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { reqForgotPassword } from 'swanviz'

import { ERROR_MESSAGES } from '../../../utils/errorMessages'
import { emailRule } from '../../../utils/validationRules'
import { Button, Input, LoginWrapper } from '../../LoginWrapper'
import { LOGIN_URL } from '../../../utils/links'

type FormValues = { login: string }

export const ForgotPassword: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [form] = Form.useForm<FormValues>()

  const handleSubmit = async (values: FormValues) => {
    setIsSubmitting(true)
    try {
      const link = await reqForgotPassword(values.login)
      // TODO it is better to remove logging after reqForgotPassword is implemented
      console.info(link)
      setIsSuccess(true)
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.reqForgotPassword)
    }
    setIsSubmitting(false)
  }

  return (
    <LoginWrapper
      title="Reset password"
      renderContent={() =>
        isSuccess ? (
          <Typography.Paragraph type="success">
            Check your email for a reset password link
          </Typography.Paragraph>
        ) : (
          <Form form={form} onFinish={handleSubmit} noValidate={true}>
            <Input
              label="Username"
              type="email"
              name="login"
              rules={[emailRule]}
            />
            <Button loading={isSubmitting}>Send Email</Button>
          </Form>
        )
      }
      renderLink={() => (
        <Link to={LOGIN_URL}>Remember your password? Log In.</Link>
      )}
    />
  )
}
