import { isEqual } from 'lodash'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Area, MissionRun, MissionType, SwanBot } from 'swanviz'

import { useDeepMemoizedValue } from '../../../../utils/useDeepMemoizedValue'
import { useQuery } from '../../../../utils/useQuery'
import { ALL_OPTION } from '../allOption'
import { displayAsOptions } from '../Form'
import {
  stringifyMissionRuns,
  normalizeMissionRuns,
  pickFilterRelatedFields,
  toFormValues,
  toQueryObject,
  getDataUrl,
} from '../queryUtils'
import {
  DataVisQueryObject,
  FilterQueryObject,
  FormValues,
  MissionRunFilterWithShowPath,
} from '../types'

/**
 * 1. Get an incoming request with or without query params;
 * 2. Convert query params to typed filters object;
 * 3. Validate incoming filters object and merge it
 *    with the default filters object;
 * 4. If the resulting filters object is not equal
 *    to the incoming filters object then
 *    replace query params using browser history API;
 * 5. On filters or mission runs selection change
 *    serialize current state and update the page url.
 *
 * Information about selected mission runs and their sensors selection
 * is stored as JSON inside query.
 *
 * Mission runs selection is reset on filters change.
 */

export const useDataVisFilter = ({
  areas,
  missions,
  swanBots,
}: {
  areas: Area[]
  missions: MissionType[]
  swanBots: SwanBot[]
}): {
  dataVisFilter: FormValues
  setDataVisFilter: (newValue: FormValues) => void
} => {
  const history = useHistory()
  const query = useQuery() as DataVisQueryObject

  const dataVisFilter = toFormValues(query, {
    areas,
    missions,
    swanBots,
    displayAsOptions,
  })

  React.useEffect(() => {
    const normalizedFilterQuery = toQueryObject(dataVisFilter)
    const currentFilterQuery = pickFilterRelatedFields(query)
    if (!isEqual(normalizedFilterQuery, currentFilterQuery)) {
      updateQuery(history, normalizedFilterQuery)
    }
  }, [query, history])

  const setDataVisFilter = (newFilters: FormValues) => {
    const shouldPreserveMissionRuns =
      newFilters.displayAs !== dataVisFilter.displayAs ||
      newFilters.hideMap !== dataVisFilter.hideMap
    const areaHasChanged = newFilters.areaId !== dataVisFilter.areaId
    const newQuery: FilterQueryObject = {
      ...toQueryObject(newFilters),
      ...(shouldPreserveMissionRuns
        ? {
            missionRuns: query.missionRuns,
          }
        : {}),
      ...(areaHasChanged
        ? {
            missionId: ALL_OPTION,
            swanbotId: ALL_OPTION,
          }
        : {}),
    }

    updateQuery(history, newQuery)
  }

  return {
    dataVisFilter,
    setDataVisFilter,
  }
}

export const useMissionRunFilters = ({
  missionRuns,
}: {
  missionRuns: MissionRun[] | undefined
}): {
  missionRunFilters: MissionRunFilterWithShowPath[]
  setMissionRunFilters: (newValue: MissionRunFilterWithShowPath[]) => void
} => {
  const history = useHistory()
  const query = useQuery() as DataVisQueryObject

  const updateMissionRunsQuery = (missionRunsQueryValue: string) => {
    updateQuery(history, {
      ...query,
      missionRuns: missionRunsQueryValue,
    })
  }

  const missionRunFilters: MissionRunFilterWithShowPath[] = normalizeMissionRuns(
    query.missionRuns,
    missionRuns || []
  )

  React.useEffect(() => {
    const normalizedMissionRunsQuery = stringifyMissionRuns(missionRunFilters)
    if (
      missionRuns &&
      !isEqual(normalizedMissionRunsQuery, query.missionRuns)
    ) {
      updateMissionRunsQuery(normalizedMissionRunsQuery)
    }
  }, [query, history, missionRuns])

  const setMissionRunFilters = (
    newMissionRunsFilters: MissionRunFilterWithShowPath[]
  ) => updateMissionRunsQuery(stringifyMissionRuns(newMissionRunsFilters))

  return {
    missionRunFilters: useDeepMemoizedValue(missionRunFilters),
    setMissionRunFilters,
  }
}

const updateQuery = (
  history: ReturnType<typeof useHistory>,
  newQuery: FilterQueryObject | DataVisQueryObject
) => {
  history.replace(getDataUrl(newQuery))
}
