import classNames from 'classnames'

import css from './style.module.css'

export const PageWithMapLayout: React.FC<{
  className?: string
  header: React.ReactNode
  aside: React.ReactNode
  map: React.ReactNode
  children?: never
}> = ({ className, header, aside, map }) => (
  <div className={classNames(css.main, className)}>
    <div className={css.header}>{header}</div>
    <div className={css.aside}>{aside}</div>
    <div className={css.map}>{map}</div>
  </div>
)
