import { Button, message, Spin, Typography } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  abortMission,
  abortToHomeMission,
  MissionRunEssential,
  MissionType,
  resumeMission,
  runMission,
  suspendMission,
  SwanBot,
} from 'swanviz'
import { mutate } from 'swr'

import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import {
  isSwanBotRunningAnotherMission,
  isSwanBotSelectable,
} from '../../../../utils/swanbot'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { SwanbotSelect } from '../../../SwanbotSelect'
import { getDataLink } from '../../DataVis/queryUtils'
import { ButtonClickHandler, MissionRunButtons } from '../MissionRunButtons'
import css from './style.module.css'

type Props = {
  mission: MissionType
  missionRun: MissionRunEssential | undefined
  swanBot: SwanBot | undefined
  swanBots: SwanBot[]
  isStatusUpdating: boolean
  isMissionRunLoading: boolean
  onRequestStart: () => void
  onRequestFinish: () => void
  onSelectSwanBot: (swanBotId: SwanBot['id']) => void
}

export const MissionRunControls: React.FC<Props> = ({
  mission,
  missionRun,
  swanBot,
  swanBots,
  isStatusUpdating,
  isMissionRunLoading,
  onRequestStart,
  onRequestFinish,
  onSelectSwanBot,
}) => {
  const { isDesktop } = useWindowSize()
  const [loadingButtonIndex, setLoadingButtonIndex] = useState<number | null>(
    null
  )
  const swanBotsOnTheMission = mission.currentRunId?.length ?? 0

  const updateMissionStatus = <T extends any>(
    apiRequest: () => Promise<T>
  ): ButtonClickHandler => {
    return (buttonIndex) => async () => {
      setLoadingButtonIndex(buttonIndex)

      onRequestStart()

      try {
        await apiRequest()
      } catch (e) {
        console.error(e)
        message.error(ERROR_MESSAGES.missionStatus)
      } finally {
        await Promise.all([
          mutate('getActiveSwanBotsForUser'),
          mutate(['getMission', mission.id]),
          mutate(missionRun ? ['getMissionRun', missionRun.runId] : null),
        ])
        setLoadingButtonIndex(null)
        onRequestFinish()
      }
    }
  }

  const start = updateMissionStatus(() =>
    swanBot
      ? runMission({
          missionId: mission.id,
          swanbotId: swanBot.id,
          geofenceId: mission.geofenceId,
          areaId: mission.areaId,
        })
      : Promise.reject(new Error('SwanBot is not selected'))
  )

  const suspend = updateMissionStatus(() =>
    missionRun
      ? suspendMission(missionRun.runId)
      : Promise.reject(new Error('Mission is not running'))
  )

  const resume = updateMissionStatus(() =>
    missionRun
      ? resumeMission(missionRun.runId)
      : Promise.reject(new Error('Mission is not running'))
  )

  const abort = updateMissionStatus(() =>
    swanBot
      ? abortMission(missionRun?.runId || null, swanBot.id)
      : Promise.reject(new Error("Can't abort"))
  )

  const abortToHome = updateMissionStatus(() =>
    swanBot
      ? abortToHomeMission(
          // @see https://github.com/subnero1/swanviz/issues/54
          missionRun?.runId || null,
          swanBot.id
        )
      : Promise.reject(new Error("Can't abort"))
  )

  return (
    <Spin spinning={isMissionRunLoading}>
      <div className={css.main}>
        <SwanbotSelect
          swanBots={swanBots}
          value={swanBot?.id}
          onChange={onSelectSwanBot}
          disabled={isStatusUpdating}
          isSwanBotDisabled={(swanBot) =>
            !isSwanBotSelectable(swanBot, mission)
          }
          shouldDisplayMissionLink={(swanBot) =>
            isSwanBotRunningAnotherMission(swanBot, mission)
          }
          size={isDesktop ? undefined : 'large'}
          className={classNames(css.item, css.select)}
        />
        {isDesktop && swanBotsOnTheMission > 0 && (
          <Typography.Text type="secondary" className={css.swanBotsCount}>
            {swanBotsOnTheMission}{' '}
            {swanBotsOnTheMission === 1 ? 'SwanBot is' : 'SwanBots are'} running
            this mission
          </Typography.Text>
        )}
        <MissionRunButtons
          missionRunStatus={missionRun?.status}
          swanBotStatus={swanBot?.status}
          loadingButtonIndex={loadingButtonIndex}
          isMissionStatusUpdating={isStatusUpdating}
          isDesktop={isDesktop}
          buttonClassName={css.item}
          start={start}
          suspend={suspend}
          resume={resume}
          abort={abort}
          abortToHome={abortToHome}
        />
        {isDesktop && swanBot?.currRunId && swanBot.currRunDate && (
          <Link
            target="_blank"
            to={getDataLink(
              {
                datesRange: [
                  moment(swanBot.currRunDate),
                  moment(swanBot.currRunDate),
                ],
                missionId: mission.id,
                areaId: mission.areaId,
                swanbotId: swanBot.id,
                displayAs: 'map',
              },
              [
                {
                  runId: swanBot.currRunId,
                  sensorParamList: [],
                  showPath: true,
                },
              ]
            )}
          >
            <Button type="text">Realtime data ↗</Button>
          </Link>
        )}
      </div>
    </Spin>
  )
}
