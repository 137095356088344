import { message } from 'antd'
import React, { useState } from 'react'
import { updateUser, User } from 'swanviz'
import { mutate } from 'swr'
import { useQueryParam } from 'use-query-params'

import { ERROR_MESSAGES } from '../../../utils/errorMessages'
import { useWindowSize } from '../../../utils/useWindowSize'
import { AddButton } from '../../AddButton'
import { CenteredSpin } from '../../CenteredSpin'
import { ModalContainer } from '../../ModalContainer'
import { ReloadPageResult } from '../../ReloadPageResult'
import { TableHeader } from '../../TableHeader'

import { NEW, NewOrNumberParam } from './newOrNumberParam'
import css from './style.module.css'
import { UserModalContent } from './UserModalContent'
import { UsersTable } from './UsersTable'
import { useUsersPageData } from './useUsersPageData'

export const Users: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useQueryParam(
    'id',
    NewOrNumberParam
  )
  const { users, swanbots, isLoading, isError } = useUsersPageData()
  const [isChangingEnabled, setIsChangingEnabled] = useState(false)
  const { isDesktop } = useWindowSize()

  const closeModal = () => setSelectedUserId(undefined)

  if (isLoading && users.length === 0) {
    return <CenteredSpin tip="Loading users" />
  }

  if (isError) {
    return <ReloadPageResult title="Couldn't get users list" />
  }

  const selectedUser =
    selectedUserId === NEW
      ? undefined
      : users.find((user) => user.id === selectedUserId)

  const addNewUser = () => setSelectedUserId(NEW)

  const handleToggleIsEnabled = async (user: User, newValue: boolean) => {
    setIsChangingEnabled(true)
    try {
      await updateUser(user.id, {
        ...user,
        enabled: newValue,
      })
      await mutate('getAllUsers')
      message.success(
        newValue ? 'User has been enabled' : 'User has been disabled'
      )
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.userStatus)
    }
    setIsChangingEnabled(false)
  }

  return (
    <div className={css.main}>
      <TableHeader
        title="User management"
        buttons={
          isDesktop ? (
            <AddButton onClick={addNewUser}>New User</AddButton>
          ) : null
        }
      />

      <UsersTable
        users={users}
        swanbots={swanbots}
        onSelectUser={setSelectedUserId}
        onToggleIsEnabled={handleToggleIsEnabled}
        loading={isChangingEnabled || isLoading}
      />

      {(selectedUser || selectedUserId === NEW) && (
        <ModalContainer onClose={closeModal}>
          <UserModalContent
            user={selectedUser}
            swanbots={swanbots}
            onRequestClose={closeModal}
            onUserCreated={(userId) => setSelectedUserId(userId)}
          />
        </ModalContainer>
      )}
    </div>
  )
}
