import { Button } from 'antd'
import { ButtonProps } from 'antd/es/button'
import classNames from 'classnames'
import React from 'react'

import css from './style.module.css'

export const BackButton: React.FC<ButtonProps> = ({ className, ...props }) => {
  return (
    <Button
      className={classNames(css.main, className)}
      type="text"
      danger
      {...props}
    >
      ←
    </Button>
  )
}
