import React from 'react'

import { PageTitle } from '../../../PageTitle'
import { PageWithMapLayout } from '../../../PageWithMapLayout'
import { LayoutProps } from '../types'
import css from './style.module.css'

export const DesktopLayout: React.FC<LayoutProps> = ({
  filters,
  runs,
  map,
  summary,
  interpolatedDataLink,
  downloadButton,
}) => {
  return (
    <PageWithMapLayout
      header={
        <div className={css.header}>
          <PageTitle>Data</PageTitle>
          {filters}
          <div className={css.actions}>
            {interpolatedDataLink}
            {downloadButton}
          </div>
        </div>
      }
      aside={<div className={css.runs}>{runs}</div>}
      map={
        <>
          {map}
          {summary}
        </>
      }
    />
  )
}
