import type { ViewStateProps as ViewState } from '@deck.gl/core/lib/deck'
import React from 'react'
import { Area, SwanBot } from 'swanviz'

import { isNotNil } from '../../../utils/typeGuards'
import { useMapViewStateFittedToBounds } from '../../../utils/useMapViewStateFittedToBounds'
import { DEFAULT_ZOOM } from '../../Map'

export const useLocationMapViewState = ({
  home,
  currLocation,
  areaCoordinates,
  ref,
}: {
  home: SwanBot['home']
  currLocation: SwanBot['currLocation']
  areaCoordinates: Area['coordinates']
  ref: React.RefObject<HTMLElement>
}): ViewState => {
  const [viewState, setViewState] = React.useState<ViewState>(() => {
    const centerPoint = home || currLocation || areaCoordinates
    return {
      longitude: centerPoint[0],
      latitude: centerPoint[1],
      zoom: DEFAULT_ZOOM,
    }
  })

  // Fit home and current location of a SwanBot on the map
  const boundsViewState = useMapViewStateFittedToBounds({
    coordinates: [home, currLocation].filter(isNotNil),
    mapContainerRef: ref,
  })

  React.useEffect(() => {
    boundsViewState && setViewState(boundsViewState)
  }, [boundsViewState])

  // Recenter map when area is changed
  React.useEffect(() => {
    setViewState({
      longitude: areaCoordinates[0],
      latitude: areaCoordinates[1],
      zoom: DEFAULT_ZOOM,
    })
  }, [areaCoordinates])

  return viewState
}
