import { HtmlOverlayItem } from '@nebula.gl/overlays'
import React from 'react'
import type { Coordinates, SwanBot } from 'swanviz'

import { HomeIcon } from '../HomeIcon'
import { SwanBotIcon } from '../SwanBotIcon'
import css from './style.module.css'

type Props = {
  coordinates: Coordinates
  // Props passed from HtmlOverlay
  x?: number
  y?: number
} & (
  | {
      type: 'home'
      color: string | null
      children?: never
    }
  | {
      type: 'swan'
      swanBot: SwanBot
      heading: number | null
      children?: never
    }
  | {
      children: React.ReactElement
    }
)

export const MapIcon: React.FC<Props> = (props) => {
  return (
    <HtmlOverlayItem coordinates={props.coordinates} x={props.x} y={props.y}>
      <div className={css.main}>
        <Icon {...props} />
      </div>
    </HtmlOverlayItem>
  )
}

const Icon: React.FC<Props> = (props) => {
  if (!('type' in props)) {
    return props.children
  }

  switch (props.type) {
    case 'home':
      return <HomeIcon style={{ color: props.color ?? undefined }} />
    case 'swan':
      return (
        <SwanBotIcon
          swanBot={props.swanBot}
          style={{
            transform: props.heading
              ? `rotate(${props.heading}deg)`
              : undefined,
          }}
        />
      )
  }
}
