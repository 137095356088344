import { Role, User } from 'swanviz'
import _ from 'lodash'

import { ANALYSER, CONTROLLER, OPERATOR, SYSTEM_ADMINISTRATOR } from './roles'
import { useUser } from '../contexts/userContext'

export type Permissions<T> = {
  users: {
    canManage: T
  }
  alerts: {
    canView: T
  }
  dataVis: {
    canView: T
  }
  missions: {
    canView: T
    canManage: T
  }
  geofences: {
    canView: T
    canManage: T
  }
  swanbots: {
    canAdd: T
    canManage: T
    canViewSensors: T
    canSetAlerts: T
  }
  dashboard: {
    canViewLatestMissions: T
    canEditArea: T
  }
}

export type UserPermissions = Permissions<boolean>

const rolesCanManageMissions: Role[] = [CONTROLLER, OPERATOR]

const permissions: Permissions<Role[]> = {
  users: {
    canManage: [SYSTEM_ADMINISTRATOR],
  },
  alerts: {
    canView: [CONTROLLER, OPERATOR, ANALYSER],
  },
  dataVis: {
    canView: [CONTROLLER, OPERATOR, ANALYSER],
  },
  missions: {
    canView: [CONTROLLER, OPERATOR, ANALYSER],
    canManage: rolesCanManageMissions,
  },
  geofences: {
    canView: [CONTROLLER, OPERATOR, ANALYSER],
    canManage: [CONTROLLER, OPERATOR],
  },
  swanbots: {
    canAdd: [SYSTEM_ADMINISTRATOR],
    canManage: [CONTROLLER, OPERATOR],
    canViewSensors: [CONTROLLER, OPERATOR, ANALYSER],
    canSetAlerts: [CONTROLLER, OPERATOR, ANALYSER],
  },
  dashboard: {
    // Latest missions includes only missions which were executed by current user
    canViewLatestMissions: rolesCanManageMissions,
    canEditArea: [SYSTEM_ADMINISTRATOR],
  },
}

export const getUserPermissions = (user: User | undefined) => {
  return _.cloneDeepWith(permissions, (value) => {
    if (Array.isArray(value)) {
      return user ? _.intersection(user.roles, value).length > 0 : false
    }
  }) as UserPermissions
}

export const usePermissions = () => {
  const { user } = useUser()

  return getUserPermissions(user)
}
