import { message, Popconfirm } from 'antd'
import React from 'react'
import { Area, deleteArea } from 'swanviz'
import { mutate } from 'swr'

import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import { DeleteButton } from '../../../DeleteButton'

export const AreaDeleteButton: React.FC<{ area: Area }> = ({ area }) => {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const handleDelete = async () => {
    setIsDeleting(true)
    try {
      await deleteArea(area.id)
      await mutate(
        'getAreaList',
        (areas: Area[]) => areas.filter((a) => a.id !== area.id),
        false
      )
      message.success('Area has been deleted')
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.areaDelete)
    }
    setIsDeleting(false)
  }

  return (
    <Popconfirm
      title="Are you sure you want to delete this area?"
      okText="Yes"
      cancelText="No"
      placement="topLeft"
      arrowPointAtCenter
      onConfirm={handleDelete}
    >
      <DeleteButton size="small" loading={isDeleting} />
    </Popconfirm>
  )
}
