import { message } from 'antd'
import 'mapbox-gl/dist/mapbox-gl.css'
import React from 'react'
import {
  BrowserRouter,
  Route as ReactRouterRoute,
  Switch,
  useHistory,
} from 'react-router-dom'
import { AuthenticationError, AuthorizationError } from 'swanviz'
import { SWRConfig } from 'swr'
import { QueryParamProvider } from 'use-query-params'

import { PageLayout } from './components/PageLayout'
import { ProtectedRoute } from './components/ProtectedRoute'
import { UserProvider } from './contexts/userContext'
import {
  UserSessionProvider,
  useUserSession,
} from './contexts/userSessionContext'
import { RouteInfo, routes } from './routes'
import { ERROR_MESSAGES } from './utils/errorMessages'
import { LOGIN_URL } from './utils/links'
import { useDocumentTitle } from './utils/useDocumentTitle'

const AppContent = () => {
  const history = useHistory()
  const { unsetUserIdSession } = useUserSession()

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        onError: (err) => {
          if (err instanceof AuthenticationError) {
            message.error(ERROR_MESSAGES.authenticationError)
            unsetUserIdSession()
            history.push(LOGIN_URL)
          } else if (err instanceof AuthorizationError) {
            message.error(ERROR_MESSAGES.authorizationError)
          }
          console.error(err)
        },
      }}
    >
      <UserProvider>
        <Switch>
          {routes.map((route) => {
            const RouteComponent = route.requiresAuthentication
              ? ProtectedRoute
              : ReactRouterRoute

            return (
              <RouteComponent
                key={route.key || route.path}
                path={route.path}
                exact
                checkHasAccess={route.checkHasAccess}
                render={() => <RouteContent route={route} />}
              />
            )
          })}
        </Switch>
      </UserProvider>
    </SWRConfig>
  )
}

const RouteContent: React.FC<{ route: RouteInfo }> = ({ route }) => {
  useDocumentTitle(route.title)

  const Component = route.component

  return route.noLayout ? (
    <Component />
  ) : (
    <PageLayout>
      <Component />
    </PageLayout>
  )
}

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={ReactRouterRoute}>
        <UserSessionProvider>
          <AppContent />
        </UserSessionProvider>
      </QueryParamProvider>
    </BrowserRouter>
  )
}
