import { Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { DATA_URL } from '../../../../utils/links'
import { PageTitle } from '../../../PageTitle'
import { SingleCollapse } from '../../../SingleCollapse'
import { LayoutProps } from '../types'

import { MobileDataView } from './MobileDataView'
import css from './style.module.css'

export const MobileLayout: React.FC<LayoutProps> = ({
  filters,
  runs,
  map,
  summary,
  interpolatedDataLink,
  missionRunFilters,
  displayAs,
  onDisplayAsChange,
}) => {
  const history = useHistory()
  const [isDataView, setIsDataView] = React.useState(false)

  React.useEffect(() => {
    const stopListening = history.listen((location, action) => {
      if (
        action === 'PUSH' &&
        location.pathname === DATA_URL &&
        !location.search
      ) {
        setIsDataView(false)
      }
    })
    return stopListening
  }, [])

  return (
    <div className={css.main}>
      <div className={css.header}>
        <PageTitle>Data</PageTitle>
        <SingleCollapse header="Filter by date, area, mission or SwanBot">
          {filters}
        </SingleCollapse>
      </div>
      {runs}

      {Boolean(missionRunFilters.length) && (
        <div className={css.actions}>
          <span className={css.actionsTitle}>Show data</span>
          <Button
            type="primary"
            size="large"
            block
            onClick={() => setIsDataView(true)}
          >
            Regular
          </Button>
          {interpolatedDataLink}
        </div>
      )}

      {isDataView && (
        <MobileDataView
          map={map}
          summary={summary}
          displayAs={displayAs}
          onDisplayAsChange={onDisplayAsChange}
          onClose={() => setIsDataView(false)}
        />
      )}
    </div>
  )
}
