import classnames from 'classnames'
import React from 'react'
import { MissionRun, SensorParameter } from 'swanviz'

import { move } from '../../../../utils/array'
import { isTruthy } from '../../../../utils/typeGuards'
import {
  GetSensorColor,
  SetSensorColor,
} from '../../../../utils/useSensorColors'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { OverMapItem } from '../../../OverMapItem'
import {
  OnRunIsPausedChange,
  SensorDataByRun,
} from '../../../SensorDataFetcher/types'
import { MissionRunSummary } from '../MissionRunSummary'
import { MissionRunFilterWithShowPath } from '../types'

import css from './style.module.css'

type Props = {
  withGraph: boolean
  missionRuns: MissionRun[]
  sensors: SensorParameter[]
  sensorData: SensorDataByRun
  missionRunFilters: MissionRunFilterWithShowPath[]
  hideMap: boolean
  onIsPathShownChange: (runId: MissionRun['runId'], isChecked: boolean) => void
  onChangeColor: SetSensorColor
  getSensorColor: GetSensorColor
  onRunIsPausedChange: OnRunIsPausedChange
  onFiltersChange: (missionRunFilter: MissionRunFilterWithShowPath[]) => void
}

export const MissionsSummary: React.FC<Props> = ({
  missionRuns,
  sensors,
  sensorData,
  withGraph,
  missionRunFilters,
  hideMap,
  onIsPathShownChange,
  onChangeColor,
  getSensorColor,
  onRunIsPausedChange,
  onFiltersChange,
}) => {
  const { isDesktop } = useWindowSize()
  const ref = React.useRef<HTMLDivElement>(null)
  const [scrollIsVisible, setScrollIsVisible] = React.useState(false)

  React.useEffect(() => {
    if (!isDesktop) {
      setScrollIsVisible(false)
      return
    }

    const handler = () => {
      if (ref && ref.current) {
        setScrollIsVisible(ref.current.scrollHeight > ref.current.offsetHeight)
      }
    }

    handler()

    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  }, [isDesktop, missionRuns, withGraph, ref])

  const isFullWidth = isDesktop && withGraph && hideMap && missionRuns.length

  const mainClassName = classnames(
    isDesktop ? css.desktopMain : css.mobileMain,
    scrollIsVisible && css.hasScroll,
    withGraph && css.withGraph,
    isFullWidth && css.fullWidth
  )

  return (
    <div ref={ref} className={mainClassName}>
      <div className={css.wrapper}>
        {missionRuns.map((missionRun) => {
          const filterIdx = missionRunFilters.findIndex(
            (filter) => filter.runId === missionRun.runId
          )
          const isPathShown =
            filterIdx > -1 && missionRunFilters[filterIdx].showPath

          return (
            <OverMapItem key={missionRun.runId} className={css.item}>
              <MissionRunSummary
                missionRun={missionRun}
                runInfo={
                  sensorData[missionRun.runId] || {
                    dataBySensor: {},
                    isPaused: false,
                  }
                }
                sensors={sensors}
                withGraph={withGraph}
                isPathShown={isPathShown}
                onIsPathShownChange={onIsPathShownChange}
                onChangeColor={onChangeColor}
                getSensorColor={getSensorColor}
                allowedMoveDirections={(
                  [
                    filterIdx > 0 && 'up',
                    filterIdx < missionRunFilters.length - 1 && 'down',
                  ] as const
                ).filter(isTruthy)}
                onIsPausedChange={(isPaused) =>
                  onRunIsPausedChange(missionRun.runId, isPaused)
                }
                onMove={(direction) => {
                  onFiltersChange(
                    move(
                      missionRunFilters,
                      filterIdx,
                      direction === 'up' ? filterIdx - 1 : filterIdx + 1
                    )
                  )
                }}
              />
            </OverMapItem>
          )
        })}
      </div>
    </div>
  )
}
