import { Coordinates } from 'swanviz'

export const MISSION_DEFAULTS = {
  nominalSpeed: {
    value: 2,
    min: 0.01,
    max: 5,
    step: 0.01,
    precision: 2,
  },
  pointRadius: {
    value: 1,
    min: 1,
    max: 5,
    precision: 0,
  },
}

export const MISSION_TASK_DEFAULTS = {
  duration: {
    value: 1,
    min: 1,
    precision: 0,
  },
  sensorDepth: {
    value: 0.1,
    min: 0.1,
    max: 5,
    step: 0.1,
    precision: 1,
  },
  speed: {
    min: 0.01,
    max: 5,
    step: 0.01,
    precision: 2,
  },
  lawnmower: {
    bearing: {
      value: 0,
      min: 0,
      max: 360,
      precision: 0,
    },
    leg: {
      value: 1,
      min: -1000,
      max: 1000,
      step: 1,
      precision: 0,
    },
    legs: {
      value: 2,
      min: 2,
      max: 500,
      precision: 0,
    },
    spacing: {
      value: 1,
      min: -1000,
      max: 1000,
      step: 1,
      precision: 0,
    },
  },
}

export const INTERPOLATION_DEFAULTS = {
  missionName: 'All',
  swanbotName: 'All',
  depth: {
    value: 5,
    min: 0,
    max: 50,
    step: 1,
    precision: 1,
  },
}

export const DEFAULT_COORDINATES: Coordinates = [103.846963, 1.292703]
