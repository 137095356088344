import { message } from 'antd'
import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import {
  createEnumParam,
  NumberParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { getSwanBotLink } from '../../../utils/links'
import { useAreasAndSwanBots } from '../../../utils/useAreasAndSwanBots'
import { usePermissions } from '../../../utils/usePermissions'
import { CenteredSpin } from '../../CenteredSpin'
import { ModalContainer } from '../../ModalContainer'
import { ReloadPageResult } from '../../ReloadPageResult'
import { SwanBotModalContent } from '../../SwanBotModalContent'
import { TableHeader } from '../../TableHeader'

import { AddSwanBot } from './AddSwanBot'
import {
  ALERTS_TAB_KEY,
  LOCATION_TAB_KEY,
  SENSORS_TAB_KEY,
  TabKey,
} from './constants'
import { SwanBotsTable } from './SwanBotsTable'

const TabEnumParam = createEnumParam<TabKey>([
  LOCATION_TAB_KEY,
  SENSORS_TAB_KEY,
  ALERTS_TAB_KEY,
])

export const SwanBots: React.FC = () => {
  const permissions = usePermissions()
  const [queryParams] = useQueryParams({
    id: NumberParam,
    tab: withDefault(TabEnumParam, LOCATION_TAB_KEY),
  })

  const shouldBeRedirectedFromAlertsTab =
    !permissions.swanbots.canSetAlerts && queryParams.tab === ALERTS_TAB_KEY
  const shouldBeRedirectedFromSensorsTab =
    !permissions.swanbots.canViewSensors && queryParams.tab === SENSORS_TAB_KEY

  useEffect(() => {
    if (shouldBeRedirectedFromAlertsTab) {
      message.warning(
        "You don't have permission to set SwanBot's alerts. You've been redirected to the SwanBot's location tab."
      )
    } else if (shouldBeRedirectedFromSensorsTab) {
      message.warning(
        "You don't have permission to view SwanBot's sensors. You've been redirected to the SwanBot's location tab."
      )
    }
  }, [shouldBeRedirectedFromAlertsTab, shouldBeRedirectedFromSensorsTab])

  if (shouldBeRedirectedFromAlertsTab || shouldBeRedirectedFromSensorsTab) {
    return <Redirect to={getSwanBotLink(Number(queryParams.id))} />
  } else {
    return <SwanBotsInner />
  }
}

const SwanBotsInner: React.FC = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    id: NumberParam,
    tab: withDefault(TabEnumParam, LOCATION_TAB_KEY),
  })

  const { swanBots, areas, isLoading, isError } = useAreasAndSwanBots()

  const closeModal = () => setQueryParams({ id: undefined, tab: undefined })

  if (isLoading) {
    return <CenteredSpin tip="Loading SwanBots" />
  }

  if (isError) {
    return <ReloadPageResult title="Couldn't get SwanBots list" />
  }

  const selectedSwanBot = swanBots.find(
    (swanBot) => swanBot.id === queryParams.id
  )

  return (
    <div>
      <TableHeader title="SwanBots" buttons={<AddSwanBot areas={areas} />} />
      <SwanBotsTable
        swanBots={swanBots}
        areas={areas}
        onSelectSwanBot={(id) => setQueryParams({ id })}
      />

      {selectedSwanBot && queryParams.tab && (
        <ModalContainer onClose={closeModal}>
          <SwanBotModalContent
            swanBot={selectedSwanBot}
            areas={areas}
            onRequestClose={closeModal}
            activeTab={queryParams.tab}
            setActiveTab={(tab) => setQueryParams({ ...queryParams, tab })}
          />
        </ModalContainer>
      )}
    </div>
  )
}
