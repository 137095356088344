import {
  getInterpolatedData,
  InterpolatedData,
  InterpolationInputs,
} from 'swanviz'

import { useCachedSWR } from '../../../../utils/useCachedSWR'
import { useLocalStorageState } from '../../../../utils/useLocalStorageState'

export type UseInterpolatedData = {
  data: InterpolatedData[] | undefined
  isLoading: boolean
  isError: boolean
  color: string
  setColor: (color: string) => void
}

export const useInterpolatedData = ({
  inputs,
  active,
}: {
  inputs: InterpolationInputs
  active: boolean
}): UseInterpolatedData => {
  const [color, setColor] = useLocalStorageState(
    'interpolation_color',
    '#01a540'
  )

  const { data, isValidating, error } = useCachedSWR(
    active ? ['getInterpolatedData', JSON.stringify(inputs)] : null,
    () => getInterpolatedData(inputs)
  )

  return {
    data,
    isLoading: isValidating,
    isError: Boolean(error),
    color,
    setColor,
  }
}
