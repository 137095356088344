import type { ViewStateProps as ViewState } from '@deck.gl/core/lib/deck'
import { DeckGL } from '@deck.gl/react'
import type { DeckGLProps } from '@deck.gl/react/deckgl'
import classNames from 'classnames'
import * as React from 'react'
import { StaticMap } from 'react-map-gl'

import { useWindowSize } from '../../utils/useWindowSize'
import { defaultMapStyle, MapStylesSwitcher } from './MapStylesSwitcher'
import { ScaleRuler } from './ScaleRuler'
import css from './style.module.css'
import { Zoom } from './Zoom'

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoic3VibmVybyIsImEiOiJjazZ2eHo2emcwNWV6M21xNTQybXFtZGU5In0.GQP3VCRAHoPsvZOiQcHx1g'

export const DEFAULT_ZOOM = 14

type Props = {
  longitude?: number
  latitude?: number
  zoom?: number
  message?: string
  withZoomControls?: boolean
  withStyleSwitcher?: boolean
  withAttribution?: boolean
} & DeckGLProps

export const Map: React.FC<Props> = ({
  latitude,
  longitude,
  zoom = DEFAULT_ZOOM,
  message,
  withZoomControls = true,
  withStyleSwitcher = true,
  withAttribution = true,
  children,
  ...restProps
}) => {
  const { isDesktop, isMobile } = useWindowSize()
  const [viewState, setViewState] = React.useState<ViewState>({
    longitude,
    latitude,
    zoom,
    bearing: 0,
    pitch: 0,
  })
  const [mapStyle, setMapStyle] = React.useState(defaultMapStyle)
  const [mapSize, setMapSize] = React.useState<{
    height: number
    width: number
  }>()

  React.useEffect(() => {
    setViewState({ ...viewState, latitude, longitude, zoom })
  }, [longitude, latitude, zoom])

  const handleChangeZoom = (delta: number) =>
    setViewState((state) => ({
      ...state,
      zoom: (state.zoom ?? DEFAULT_ZOOM) + delta,
      transitionDuration: 300,
    }))

  const showAttribution = withAttribution && isDesktop

  return (
    <div
      className={classNames(css.main, showAttribution && css.withAttribution)}
    >
      <DeckGL
        width="100%"
        height="100%"
        viewState={viewState}
        onViewStateChange={({ viewState }) => setViewState(viewState)}
        onResize={setMapSize}
        controller={{
          doubleClickZoom: isMobile,
        }}
        {...restProps}
      >
        {children}
        <StaticMap
          width="100%"
          height="100%"
          mapStyle={mapStyle}
          attributionControl={showAttribution}
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        >
          {withZoomControls && mapSize && (
            <div className={css.scale}>
              <ScaleRuler mapSize={mapSize} viewState={viewState} />
            </div>
          )}
        </StaticMap>
      </DeckGL>

      {withStyleSwitcher && (
        <div className={css.mapStyleSwitcher}>
          <MapStylesSwitcher value={mapStyle} onChange={setMapStyle} />
        </div>
      )}

      {withZoomControls && (
        <div className={css.zoom}>
          <Zoom onChange={handleChangeZoom} />
        </div>
      )}

      {message && <div className={css.message}>{message}</div>}
    </div>
  )
}
