import { message, Typography } from 'antd'
import React, { FunctionComponent } from 'react'
import { NavLink, NavLinkProps, useHistory } from 'react-router-dom'
import { logoutUser } from 'swanviz'

import { useUser } from '../../contexts/userContext'
import { ERROR_MESSAGES } from '../../utils/errorMessages'
import {
  ALERTS_URL,
  DASHBOARD_URL,
  DATA_URL,
  LOGIN_URL,
  SETTINGS_URL,
} from '../../utils/links'
import { useWindowSize } from '../../utils/useWindowSize'
import { Logo } from '../Logo'

import { DesktopLayout } from './DesktopLayout'
import { MobileLayout } from './MobileLayout'
import css from './style.module.css'

import { usePermissions, UserPermissions } from '../../utils/usePermissions'
import { isDefined } from '../../utils/typeGuards'
import type { CheckHasAccess } from '../ProtectedRoute'
import { useUserSession } from '../../contexts/userSessionContext'

type Route = Pick<NavLinkProps, 'isActive' | 'exact'> & {
  title: string
  path: string
  checkHasAccess?: CheckHasAccess
}

type RenderLinksFn = (
  props: Pick<NavLinkProps, 'className' | 'activeClassName' | 'onClick'>
) => React.ReactNode[]

export type LayoutProps = {
  logo: React.ReactNode
  renderNavLinks: RenderLinksFn
  renderUserLinks: RenderLinksFn
  userFullName: string | undefined
}

const routes: Route[] = [
  {
    title: 'Dashboard',
    path: DASHBOARD_URL,
    exact: true,
  },
  {
    title: 'Data',
    path: DATA_URL,
    checkHasAccess: (p: UserPermissions) => p.dataVis.canView,
  },
  {
    title: 'Missions and Geofences',
    path: '/missions-and-geofences',
    isActive: (match, location) => {
      return (
        Boolean(match) ||
        location.pathname.includes('mission') ||
        location.pathname.includes('geofence')
      )
    },
    checkHasAccess: (p: UserPermissions) => p.missions.canView,
  },
  {
    title: 'SwanBots',
    path: '/swan-bots',
  },
  {
    title: 'Alerts',
    path: ALERTS_URL,
    checkHasAccess: (p: UserPermissions) => p.alerts.canView,
  },
  {
    title: 'Users',
    path: '/users',
    checkHasAccess: (p: UserPermissions) => p.users.canManage,
  },
]

export const Navigation: FunctionComponent = () => {
  const history = useHistory()
  const { unsetUserIdSession } = useUserSession()
  const { user } = useUser()
  const permissions = usePermissions()
  const { isDesktop } = useWindowSize()

  const Layout = isDesktop ? DesktopLayout : MobileLayout

  const handleLogOut = async () => {
    try {
      await logoutUser()
      unsetUserIdSession()
      history.push(LOGIN_URL)
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.logout)
    }
  }

  return (
    <Layout
      logo={<Logo className={css.logo} />}
      renderNavLinks={({ className, activeClassName, onClick }) =>
        user?.pwdChangePending
          ? []
          : routes
              .map((route) => {
                const hasAccess = route.checkHasAccess
                  ? route.checkHasAccess(permissions)
                  : true

                if (!hasAccess) {
                  return undefined
                }

                return (
                  <NavLink
                    className={className}
                    activeClassName={activeClassName}
                    key={route.path}
                    to={route.path}
                    exact={route.exact}
                    isActive={route.isActive}
                    onClick={onClick}
                  >
                    {route.title}
                  </NavLink>
                )
              })
              .filter(isDefined)
      }
      renderUserLinks={({ className, activeClassName, onClick }) => [
        <NavLink
          key="settings"
          className={className}
          activeClassName={activeClassName}
          to={SETTINGS_URL}
          onClick={onClick}
        >
          Settings
        </NavLink>,
        <Typography.Link
          key="logout"
          className={className}
          onClick={(e) => {
            onClick?.(e)
            handleLogOut()
          }}
        >
          Log out
        </Typography.Link>,
      ]}
      userFullName={user?.fullName}
    />
  )
}
