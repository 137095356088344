import { WebMercatorViewport } from '@deck.gl/core'
import type { ViewStateProps as ViewState } from '@deck.gl/core/lib/deck'
import React from 'react'
import type { Coordinates } from 'swanviz'

import { DEFAULT_ZOOM } from '../components/Map'
import { getBounds } from './geojson'

export const useMapViewStateFittedToBounds = ({
  coordinates,
  mapContainerRef,
}: {
  coordinates: Coordinates[]
  mapContainerRef: React.RefObject<HTMLElement>
}): ViewState | undefined => {
  const [viewState, setViewState] = React.useState<ViewState>()

  React.useEffect(() => {
    if (coordinates.length && !viewState) {
      if (coordinates.length === 1) {
        setViewState({
          longitude: coordinates[0][0],
          latitude: coordinates[0][1],
          zoom: DEFAULT_ZOOM,
        })
      } else {
        const bounds = getBounds(coordinates)
        const viewport = new WebMercatorViewport({
          width: mapContainerRef.current?.offsetWidth,
          height: mapContainerRef.current?.offsetHeight,
        }).fitBounds(bounds, { padding: 40 }) as WebMercatorViewport & ViewState

        setViewState({
          longitude: viewport.longitude,
          latitude: viewport.latitude,
          zoom: Math.min(viewport.zoom, DEFAULT_ZOOM),
        })
      }
    }
  }, [coordinates])

  return viewState
}
