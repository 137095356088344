import classNames from 'classnames'
import React from 'react'
import { SwanBot } from 'swanviz'

import { ReactComponent as FullIcon } from './full.svg'
import { ReactComponent as LowIcon } from './low.svg'
import { ReactComponent as MediumIcon } from './medium.svg'
import css from './style.module.css'

type Props = {
  level: SwanBot['batteryLevel']
  isCompact?: boolean
}

const LEVELS: Record<
  NonNullable<SwanBot['batteryLevel']>,
  {
    Icon: typeof FullIcon
    className: string
  }
> = {
  Full: {
    Icon: FullIcon,
    className: css.full,
  },
  Medium: {
    Icon: MediumIcon,
    className: css.medium,
  },
  Low: {
    Icon: LowIcon,
    className: css.low,
  },
}

export const BatteryLevel: React.FC<Props> = ({ level, isCompact }) => {
  if (!level) {
    return null
  }

  const { Icon, className } = LEVELS[level]

  return (
    <div className={classNames(css.main, className)}>
      <Icon className={css.icon} />
      {!isCompact && <span className={css.text}>{level}</span>}
    </div>
  )
}
