import { Role } from 'swanviz'

export const SYSTEM_ADMINISTRATOR = 'SYSTEM ADMINISTRATOR'
export const CONTROLLER = 'CONTROLLER'
export const ANALYSER = 'ANALYSER'
export const OPERATOR = 'OPERATOR'

export const roleLabels: Record<Role, string> = {
  [SYSTEM_ADMINISTRATOR]: 'System administrator',
  [CONTROLLER]: 'Controller',
  [ANALYSER]: 'Analyzer',
  [OPERATOR]: 'Operator',
}

export const roles = Object.keys(roleLabels) as Role[]
