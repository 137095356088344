import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Form, Menu } from 'antd'
import _ from 'lodash'
import React from 'react'
import { SwanBot, User, UserUpdate } from 'swanviz'
import classNames from 'classnames'

import { PopulatedSwanbot } from '../../../../../utils/populateSwanbots'
import { useCheckUnsavedChanges } from '../../../../../utils/useCheckUnsavedChanges'
import { useWindowSize } from '../../../../../utils/useWindowSize'
import { SaveButton } from '../../../../SaveButton'
import { SwanBotIcon } from '../../../../SwanBotIcon'
import css from './style.module.css'

type Props = {
  user: User
  swanbots: PopulatedSwanbot[]
  onSave: (userData: UserUpdate) => void
  isSaving: boolean
}
const SWANBOTS_FIELD_NAME = 'swanbots'

export type SwanbotsFormValues = {
  [SWANBOTS_FIELD_NAME]: SwanBot['id'][]
}

export const AssignedSwanbotsTab: React.FC<Props> = ({
  user,
  swanbots,
  onSave,
  isSaving,
}) => {
  const [form] = Form.useForm<User>()
  const { isDesktop } = useWindowSize()

  const initialValues: SwanbotsFormValues = {
    [SWANBOTS_FIELD_NAME]: user.swanbots,
  }

  const [values, setValues] = React.useState(initialValues)
  const { setBackupValue, hasChanges } = useCheckUnsavedChanges({
    value: values,
  })

  React.useEffect(() => {
    setBackupValue(initialValues)
  }, [initialValues])

  return (
    <Form
      initialValues={initialValues}
      form={form}
      onValuesChange={(_changedValues, formValues) => {
        setValues({
          ...values,
          ...formValues,
        })
      }}
      onFinish={onSave}
      colon={false}
    >
      <Form.List name={SWANBOTS_FIELD_NAME}>
        {(fields, { add, remove }) => {
          const swanbotIds = form.getFieldValue(
            SWANBOTS_FIELD_NAME
          ) as SwanBot['id'][]

          const activeSwanbots = swanbots.filter((swanBot) => !swanBot.delFlag)
          const restSwanbots = _.differenceWith(
            activeSwanbots,
            swanbotIds,
            (swanbot, swanbotId) => swanbot.id === swanbotId
          )

          const menu = (
            <Menu onClick={(menuInfo) => add(Number(menuInfo.key))}>
              {restSwanbots.map((swanbot) => (
                <Menu.Item key={swanbot.id}>{swanbot.name}</Menu.Item>
              ))}
            </Menu>
          )

          return (
            <>
              <table className={css.table}>
                {fields.map((field) => {
                  const id = form.getFieldValue([
                    SWANBOTS_FIELD_NAME,
                    field.name,
                  ]) as SwanBot['id']

                  const swanbot = swanbots.find((swanbot) => swanbot.id === id)

                  if (!swanbot) {
                    return null
                  }

                  return (
                    <tr key={field.key} className={css.row}>
                      <td className={css.iconContainer}>
                        <SwanBotIcon swanBot={swanbot} />
                      </td>
                      <td
                        className={classNames(css.dataCell, css.withEllipsis)}
                        title={swanbot.name || undefined}
                      >
                        {swanbot.name}
                      </td>
                      <td className={css.dataCell}>{swanbot.areaName}</td>
                      {isDesktop && (
                        <td>
                          <Button
                            type="text"
                            onClick={() => remove(field.name)}
                            className={css.unassignSwanbotButton}
                          >
                            Unassign
                          </Button>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </table>
              {isDesktop && Boolean(restSwanbots.length) && (
                <Dropdown
                  overlay={menu}
                  className={css.addDevice}
                  trigger={['click']}
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Add SwanBot <DownOutlined />
                  </a>
                </Dropdown>
              )}
            </>
          )
        }}
      </Form.List>

      {isDesktop && (
        <SaveButton
          loading={isSaving}
          className={css.saveButton}
          disabled={!hasChanges}
        />
      )}
    </Form>
  )
}
