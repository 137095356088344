import React from 'react'
import { Button as AntButton, Form, Typography } from 'antd'

import css from './style.module.css'

export const SaveButton: React.FC<{
  loading?: boolean
  disabled?: boolean
  error?: string
  className?: string
}> = ({ loading, disabled, error, className }) => (
  <Form.Item colon={false} label="&nbsp;" className={className}>
    <AntButton
      size="large"
      type="primary"
      danger
      htmlType="submit"
      disabled={disabled}
      loading={loading}
      className={css.button}
    >
      Save
    </AntButton>
    {error && (
      <Typography.Paragraph className={css.error}>{error}</Typography.Paragraph>
    )}
  </Form.Item>
)
