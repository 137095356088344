import { ColumnsType, TableProps } from 'antd/es/table'
import { ColumnFilterItem } from 'antd/es/table/interface'
import _, { get, lowerCase } from 'lodash'
import React from 'react'
import { Area, Geofence, SwanBot } from 'swanviz'

import { TableFilterIcon } from '../components/TableFilterIcon'
import { TableSearch } from '../components/TableSearch'

export const sorter = <T,>(field: keyof T | [keyof T, ...string[]]) => (
  a: T,
  b: T
) => {
  let firstItem = get(a, field)
  let secondItem = get(b, field)

  if (_.isString(firstItem) && _.isString(secondItem)) {
    firstItem = lowerCase(firstItem)
    secondItem = lowerCase(secondItem)
  }

  if (firstItem > secondItem) {
    return 1
  }

  if (secondItem > firstItem) {
    return -1
  }

  return 0
}

export const getColumnFilterProps = <T,>(
  field: keyof T | [keyof T, ...string[]]
): Pick<ColumnsType<T>[0], 'filterIcon' | 'onFilter'> => ({
  onFilter: (value, record) => {
    const recordValue = get(record, field)
    if (Array.isArray(recordValue)) {
      return recordValue.includes(value.toString())
    }
    return recordValue === value.toString()
  },
  filterIcon: TableFilterIcon,
})

export const getColumnSearchProps = <T,>(
  field: keyof T | [keyof T, ...string[]]
): Pick<ColumnsType<T>[0], 'filterDropdown' | 'filterIcon' | 'onFilter'> => ({
  filterDropdown: (props) => {
    return <TableSearch {...props} />
  },
  filterIcon: TableFilterIcon,
  onFilter: (value, record) =>
    get(record, field)
      ? get(record, field)
          .toString()
          .toLowerCase()
          .includes(value.toString().trim().toLowerCase())
      : '',
})

export const getFilters = <T extends Area | SwanBot | Geofence>({
  items,
  getText = (item) => item.name || String(item.id),
  getValue = (item) => item.id,
}: {
  items: T[]
  getText?: (item: T) => string
  getValue?: (item: T) => string | number
}): ColumnFilterItem[] =>
  _.sortBy(
    items.map((item) => ({
      text: getText(item),
      value: getValue(item),
    })),
    'text'
  )

export const stickyProps: TableProps<any> = {
  sticky: true,
}
