import { Form, InputNumber, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { Area, Geofence, MissionType, NewMissionType } from 'swanviz'

import { MISSION_DEFAULTS } from '../../../../utils/defaults'
import { getGeofenceLink } from '../../../../utils/links'
import { formatTotalTime } from '../../../../utils/time'
import { getRequiredNumberRules } from '../../../../utils/validationRules'
import { AreasSelect } from '../../../AreasSelect'
import { GeofenceSelect } from '../../../GeofenceSelect'
import { InformationItem } from '../../../InformationItem'
import { isExistingMission } from '../helpers'

import css from './style.module.css'

type Props = {
  areas: Area[]
  geofences: Geofence[]
  mission: MissionType | NewMissionType
  isViewMode: boolean
  onChangeMission: (mission: Partial<MissionType>) => void
}

export const MissionInfo: React.FC<Props> = ({
  areas,
  geofences,
  mission,
  isViewMode,
  onChangeMission,
}) => {
  const totalTasksDistance = mission.missionTasks.reduce(
    (acc, task) => acc + task.estDistance,
    0
  )
  const totalTasksDuration = mission.missionTasks.reduce(
    (acc, task) => acc + task.estDuration,
    0
  )
  const geofenceName = geofences.find(
    (geofence) => geofence.id === mission.geofenceId
  )?.name

  return (
    <div className={css.main}>
      <InformationItem label="Est. time">
        <Typography.Text>{formatTotalTime(totalTasksDuration)}</Typography.Text>
      </InformationItem>
      <InformationItem label="Est. distance">
        <Typography.Text>
          {totalTasksDistance
            ? `${Math.round(totalTasksDistance).toLocaleString()} m`
            : '—'}
        </Typography.Text>
      </InformationItem>
      <InformationItem label="Area">
        {isViewMode ? (
          <Typography.Text>
            {areas.find((area) => area.id === mission.areaId)?.name}
          </Typography.Text>
        ) : (
          <Form.Item name="areaId" className={css.selectFormItem}>
            <AreasSelect
              areas={areas}
              size="small"
              disabled={isExistingMission(mission)}
              onChange={(newAreaId) => {
                const newGeofence = geofences.find(
                  (geofence) => geofence.areaId === newAreaId
                )
                const missionPatch: Partial<MissionType> = {
                  areaId: newAreaId,
                  geofenceId: newGeofence?.id,
                }
                onChangeMission(missionPatch)
              }}
            />
          </Form.Item>
        )}
      </InformationItem>
      <InformationItem label="Geofence" className={css.geofence}>
        {isViewMode ? (
          <Typography.Text title={geofenceName} className={css.geofenceName}>
            {geofenceName}
          </Typography.Text>
        ) : (
          <div className={css.geofenceFormItems}>
            <Form.Item
              name="geofenceId"
              className={css.selectFormItem}
              getValueProps={(value) => {
                // Treat geofenceId = 0 as empty select
                return { value: value > 0 ? value : undefined }
              }}
              rules={[
                { required: true, message: 'Select or add new geofence' },
              ]}
            >
              <GeofenceSelect
                geofences={geofences}
                areaId={mission.areaId}
                size="small"
                withNewGeofenceLink
              />
            </Form.Item>
            <Form.Item noStyle dependencies={['geofenceId']}>
              {(renderFormValues) => {
                const formGeofenceId =
                  renderFormValues.getFieldValue('geofenceId')
                return formGeofenceId ? (
                  <Link
                    to={getGeofenceLink(formGeofenceId)}
                    className={css.geofenceEdit}
                    component={Typography.Link}
                    target="_blank"
                  >
                    Edit
                  </Link>
                ) : null
              }}
            </Form.Item>
          </div>
        )}
      </InformationItem>
      <InformationItem label="Default speed">
        {isViewMode ? (
          <Typography.Text>{mission.nominalSpeed}m/s</Typography.Text>
        ) : (
          <>
            <Form.Item
              name="nominalSpeed"
              className={css.inputWrapper}
              rules={getRequiredNumberRules(
                'nominal speed',
                MISSION_DEFAULTS.nominalSpeed.min,
                MISSION_DEFAULTS.nominalSpeed.max
              )}
              help={[]}
            >
              <InputNumber
                min={MISSION_DEFAULTS.nominalSpeed.min}
                max={MISSION_DEFAULTS.nominalSpeed.max}
                step={MISSION_DEFAULTS.nominalSpeed.step}
                precision={MISSION_DEFAULTS.nominalSpeed.precision}
                className={css.input}
                size="small"
              />
            </Form.Item>{' '}
            m/s
          </>
        )}
      </InformationItem>
      <InformationItem label="Waypoint radius">
        {isViewMode ? (
          <Typography.Text>{mission.pointRadius}m</Typography.Text>
        ) : (
          <>
            <Form.Item
              name="pointRadius"
              className={css.inputWrapper}
              rules={getRequiredNumberRules(
                'point radius',
                MISSION_DEFAULTS.pointRadius.min,
                MISSION_DEFAULTS.pointRadius.max
              )}
              help={[]}
            >
              <InputNumber
                min={MISSION_DEFAULTS.pointRadius.min}
                max={MISSION_DEFAULTS.pointRadius.max}
                precision={MISSION_DEFAULTS.pointRadius.precision}
                className={css.input}
                size="small"
              />
            </Form.Item>{' '}
            m
          </>
        )}
      </InformationItem>
    </div>
  )
}
