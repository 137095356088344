import { Tabs } from 'antd'
import React from 'react'

import { LayoutProps } from '../types'
import css from './style.module.css'

export const MobileLayout: React.FC<LayoutProps> = ({
  header,
  info,
  tasks,
  map,
  image,
  log,
  controls,
}) => {
  return (
    <div className={css.main}>
      {header}
      <Tabs className={css.tabs}>
        <Tabs.TabPane tab="Tasks" key="tasks" className={css.tab}>
          <div className={css.info}>{info}</div>
          <div className={css.tasks}>{tasks}</div>
          <div className={css.controls}>{controls}</div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Map" key="map" className={css.tab}>
          <div className={css.map}>
            {map}
            {image}
          </div>
          <div className={css.controls}>{controls}</div>
        </Tabs.TabPane>

        {log && (
          <Tabs.TabPane tab="Activity log" key="log" className={css.tab}>
            {log}
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  )
}
