import _ from 'lodash'
import React from 'react'
import { MissionRun, MissionRunFilter } from 'swanviz'

import { statusToFetchingTypeMap } from '../../utils/fetchingType'
import { useDeepMemoizedValue } from '../../utils/useDeepMemoizedValue'
import { ActualPathFetcher } from '../ActualPathFetcher'
import { RunPath } from '../pages/DataVis/types'

type Props = {
  missionRunFilters: MissionRunFilter[]
  missionRunStatuses: Record<MissionRun['runId'], MissionRun['status']>
  children: (pathsByRun: PathsByRun) => React.ReactNode
}

export type PathsByRun = Record<MissionRun['runId'], RunPath>

export const RunPathsFetcher: React.FC<Props> = ({
  missionRunFilters,
  missionRunStatuses,
  children,
}) => {
  const [pathsByRun, setPathsByRun] = React.useState<PathsByRun>({})

  const displayedRunIds: Array<MissionRun['runId']> = useDeepMemoizedValue(
    missionRunFilters.map((filter) => filter.runId)
  )

  React.useEffect(() => {
    setPathsByRun((state) => _.pick(state, displayedRunIds))
  }, [displayedRunIds])

  return (
    <>
      {children(pathsByRun)}
      {displayedRunIds.map((runId) => {
        const status = missionRunStatuses[runId]

        return (
          status && (
            <ActualPathFetcher
              key={runId}
              fetchingType={statusToFetchingTypeMap[status]}
              runId={runId}
              onChange={(runPath) => {
                setPathsByRun((state) => ({
                  ...state,
                  [runId]: runPath,
                }))
              }}
            />
          )
        )
      })}
    </>
  )
}
