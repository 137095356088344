import { Rule } from 'antd/es/form'
import _ from 'lodash'

import { NAME_REG_EXP } from './nameRegExp'

export const MIN_PASSWORD_LENGTH = 8

export const getRequiredRule = (fieldName: string): Rule => ({
  validator: (_rule, value) => {
    if (typeof value === 'string' && value.trim().length > 0) {
      return Promise.resolve()
    }
    if (Array.isArray(value) && value.length > 0) {
      return Promise.resolve()
    }
    return Promise.reject(`${fieldName} is required`)
  },
})

export const validateNameRule: Rule = {
  validator: (_rule, value) =>
    value && NAME_REG_EXP.test(value)
      ? Promise.resolve()
      : Promise.reject('Name should have only letters, "." and "-"'),
}

export const passwordLengthRule: Rule = {
  type: 'string',
  min: MIN_PASSWORD_LENGTH,
  message: `Password must be at least ${MIN_PASSWORD_LENGTH} characters`,
}

export const emailRule: Rule = {
  pattern: /^.+@.+$/,
  required: true,
  message: 'Enter valid email',
}

export const getNumberRule = (
  name: string,
  min: number,
  max?: number,
  message?: string
): Rule => ({
  message:
    message ||
    (max
      ? `${_.upperFirst(name)} must be between ${min} and ${max}`
      : `${_.upperFirst(name)} cannot be less than ${min}`),
  type: 'number',
  min,
  max,
})

export const getRequiredNumberRules = (
  name: string,
  min: number,
  max?: number,
  message?: string
): Rule[] => [
  {
    required: true,
    message: message || `Please input ${name}`,
  },
  getNumberRule(name, min, max, message),
]

export const getNotZeroRule = (name: string): Rule => ({
  validator: (rule, value) =>
    _.isNumber(value) && value !== 0
      ? Promise.resolve()
      : Promise.reject(`${_.upperFirst(name)} should not be 0`),
})
