import { MissionRun } from 'swanviz'
import { updateAt } from '../../../../utils/array'
import { isDefined } from '../../../../utils/typeGuards'
import { MissionRunFilterWithShowPath } from '../types'

export const toggleIsPathShown = (
  missionRunFilters: MissionRunFilterWithShowPath[],
  runId: MissionRun['runId'],
  isPathShown: boolean
) => {
  const runIndex = missionRunFilters.findIndex((item) => item.runId === runId)

  return updateAt(missionRunFilters, runIndex, {
    runId,
    sensorParamList: missionRunFilters[runIndex].sensorParamList,
    showPath: isPathShown,
  })
}

export const getSelectedMissionRunsAndSensors = (
  missionRuns: MissionRun[],
  missionRunFilters: MissionRunFilterWithShowPath[]
): MissionRun[] => {
  return missionRunFilters
    .map((runFilter) => {
      const missionRun = missionRuns.find(
        (run) => run.runId === runFilter.runId
      )

      return missionRun
        ? {
            ...missionRun,
            sensorIds: missionRun.sensorIds.filter((sensorId) =>
              runFilter.sensorParamList.includes(sensorId)
            ),
          }
        : undefined
    })
    .filter(isDefined)
}
