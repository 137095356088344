import { Button, message, Popconfirm } from 'antd'
import React from 'react'
import { deleteMission, MissionType } from 'swanviz'
import { mutate } from 'swr'

import { ERROR_MESSAGES } from '../../../../utils/errorMessages'

type Props = {
  missionIds: Array<MissionType['id']>
  onDeletingChange: (isDeleting: boolean) => void
}

export const DeleteMissionsButton: React.FC<Props> = ({
  missionIds,
  onDeletingChange,
}) => {
  const [isDeleting, setDeleting] = React.useState(false)
  const handleDelete = async () => {
    setDeleting(true)
    try {
      await deleteMission(missionIds)
      await mutate('getAllMissions')
    } catch (err: any) {
      console.error(err)
      message.error(err.message || ERROR_MESSAGES.missionDelete)
    }
    setDeleting(false)
  }

  React.useEffect(() => {
    onDeletingChange(isDeleting)
  }, [isDeleting])

  return (
    <Popconfirm
      title={`Are you sure you want to delete ${
        missionIds.length
      } ${getPluralMissions(missionIds.length)}?`}
      okText="Yes"
      cancelText="No"
      onConfirm={handleDelete}
    >
      <Button danger disabled={!missionIds.length} loading={isDeleting}>
        Delete
      </Button>
    </Popconfirm>
  )
}

const getPluralMissions = (count: number): 'mission' | 'missions' =>
  count === 1 ? 'mission' : 'missions'
