import { Form, InputNumber, Slider, Typography } from 'antd'
import type { FormListFieldData } from 'antd/es/form/FormList'
import classNames from 'classnames'
import type { FormInstance } from 'rc-field-form'
import React from 'react'
import { SensorParameter } from 'swanviz'

import { isNotNil } from '../../../utils/typeGuards'
import { useWindowSize } from '../../../utils/useWindowSize'
import { DeleteButton } from '../../DeleteButton'
import { InformationItem } from '../../InformationItem'
import { SENSORS_FIELD_NAME } from '../AlertsTab/constants'
import { AlertSettingsFormValues } from '../AlertsTab/types'
import css from './style.module.css'

type Props = {
  field: FormListFieldData
  sensor: SensorParameter
  remove: (index: number) => void
  latestData: number | null | undefined
  onValuesChange: (values: AlertSettingsFormValues) => void
}

export const SensorAlert: React.FC<Props> = ({
  field,
  sensor,
  remove,
  latestData,
  onValuesChange,
}) => {
  const { isDesktop } = useWindowSize()
  const sensorValuePath = [SENSORS_FIELD_NAME, field.name, 'value']
  const minValuePath = [...sensorValuePath, 0]
  const maxValuePath = [...sensorValuePath, 1]

  const onNumberChange =
    (form: FormInstance<AlertSettingsFormValues>, index: number) =>
    (newValue: string | number | undefined) => {
      const values = form.getFieldsValue() as AlertSettingsFormValues
      values[SENSORS_FIELD_NAME][field.name].value[index] = Number(newValue)
      form.setFieldsValue(values)
      // setFieldsValue doesn't trigger onValuesChange,
      // so we do it manually
      // https://github.com/react-component/field-form#8-setfields-not-trigger-onfieldschange-and-setfieldsvalue-not-trigger-onvalueschange
      onValuesChange(values)
    }

  const withData = isNotNil(latestData)

  return (
    <div className={css.main}>
      <div className={classNames(css.summary, withData && css.withData)}>
        <Typography.Title level={2} className={css.title}>
          {sensor.description}
        </Typography.Title>

        <div className={css.inputs}>
          <InformationItem label="From">
            <Form.Item className={css.input} dependencies={[sensorValuePath]}>
              {(form) => {
                const value = form.getFieldValue(minValuePath)
                return isDesktop ? (
                  <InputNumber
                    value={value}
                    onChange={onNumberChange(form, 0)}
                    min={sensor.min}
                    max={Math.min(sensor.max, form.getFieldValue(maxValuePath))}
                  />
                ) : (
                  value
                )
              }}
            </Form.Item>
          </InformationItem>

          <div className={css.dash}>–</div>

          <InformationItem label="To">
            <Form.Item className={css.input} dependencies={[sensorValuePath]}>
              {(form) => {
                const value = form.getFieldValue(maxValuePath)
                return isDesktop ? (
                  <InputNumber
                    value={value}
                    onChange={onNumberChange(form, 1)}
                    min={Math.max(sensor.min, form.getFieldValue(minValuePath))}
                    max={sensor.max}
                  />
                ) : (
                  value
                )
              }}
            </Form.Item>
          </InformationItem>

          <div className={css.units}>{sensor.units}</div>
        </div>

        {withData && (
          <InformationItem label="Last data" className={css.latestData}>
            {latestData} {sensor.units}
          </InformationItem>
        )}
      </div>

      {isDesktop && (
        <>
          <Form.Item className={css.slider} name={[field.name, 'value']}>
            <Slider
              range
              marks={{
                [sensor.min]: {
                  style: { transform: 'translateX(-10%)' },
                  label: sensor.min,
                },
                [sensor.max]: {
                  style: { transform: 'translateX(-90%)' },
                  label: sensor.max,
                },
              }}
              min={sensor.min}
              max={sensor.max}
            />
          </Form.Item>
          <DeleteButton
            onClick={() => remove(field.name)}
            className={css.delete}
          >
            Delete Alert Setting
          </DeleteButton>
        </>
      )}
    </div>
  )
}
