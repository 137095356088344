import { message, Popconfirm } from 'antd'
import React from 'react'
import { deleteSwanBot } from 'swanviz'
import { mutate } from 'swr'

import { ERROR_MESSAGES } from '../../../utils/errorMessages'
import { DeleteButton } from '../../DeleteButton'

type Props = {
  id: number
  isDeleting: boolean
  disabled: boolean
  onChangeDeleting: (status: boolean) => void
  onDelete: () => void
}

export const DeleteSwanBot: React.FC<Props> = ({
  id,
  isDeleting,
  disabled,
  onChangeDeleting,
  onDelete,
}) => {
  const handleDelete = async () => {
    onChangeDeleting(true)
    try {
      await deleteSwanBot(id)
      await mutate('getSwanBotsForUser')
      message.success('SwanBot has been deleted')
      onDelete()
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.swanBotDelete)
    }
    onChangeDeleting(false)
  }

  return (
    <Popconfirm
      title="Are you sure you want to delete this SwanBot?"
      okText="Yes"
      cancelText="No"
      onConfirm={handleDelete}
    >
      <DeleteButton loading={isDeleting} disabled={disabled}>
        Delete SwanBot
      </DeleteButton>
    </Popconfirm>
  )
}
