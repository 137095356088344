import { Divider, Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import { Link } from 'react-router-dom'
import { Geofence } from 'swanviz'

import { getNewGeofenceLink } from '../../utils/links'
import { getItemsForSelect } from '../../utils/select'
import { AddButton } from '../AddButton'

import css from './style.module.css'

type Props = {
  value?: number
  geofences: Geofence[]
  areaId: number
  withNewGeofenceLink?: boolean
  onChange?: (geofenceId: number) => void
} & Pick<SelectProps<unknown>, 'size' | 'disabled' | 'loading'>

export const GeofenceSelect: React.FC<Props> = ({
  value,
  geofences,
  areaId,
  size,
  disabled,
  withNewGeofenceLink,
  onChange,
  ...selectProps
}) => {
  return (
    <Select
      size={size}
      dropdownMatchSelectWidth={false}
      showSearch
      disabled={disabled}
      optionFilterProp="name"
      style={{ minWidth: 120 }}
      value={value}
      onChange={onChange}
      dropdownRender={
        withNewGeofenceLink
          ? (menu) => (
              <div>
                {menu}
                <Divider className={css.divider} />
                <Link
                  to={getNewGeofenceLink(areaId)}
                  target="_blank"
                  component={(props) => (
                    <AddButton
                      className={css.geofenceButton}
                      type="text"
                      block
                      {...props}
                    >
                      New geofence
                    </AddButton>
                  )}
                />
              </div>
            )
          : undefined
      }
      placeholder="No geofence selected"
      {...selectProps}
    >
      {getItemsForSelect(geofences, areaId).map((geofence) => (
        <Select.Option
          key={geofence.id}
          value={geofence.id}
          name={geofence.name}
        >
          <div className={css.option}>{geofence.name}</div>
        </Select.Option>
      ))}
    </Select>
  )
}
