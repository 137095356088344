import React from 'react'
import classNames from 'classnames'

import css from './style.module.css'

type Props = {
  checkbox: React.ReactNode
  date: React.ReactNode
  time?: React.ReactNode
  missionRun: React.ReactNode
  className?: string
}

export const ItemContainer: React.FC<Props> = ({
  checkbox,
  date,
  time,
  missionRun,
  className,
}) => {
  return (
    <div className={classNames(css.main, className)}>
      <div className={css.checkbox}>{checkbox}</div>
      <div className={css.date}>{date}</div>
      <div className={css.time}>{time}</div>
      <div />
      <div className={css.missionRun}>{missionRun}</div>
    </div>
  )
}
