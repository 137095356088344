import { DrawPointMode, EditAction, ModifyMode } from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { HtmlOverlay } from '@nebula.gl/overlays'
import { Result } from 'antd'
import { GeoJSON } from 'geojson'
import React from 'react'
import { Area, Coordinates, SwanBot } from 'swanviz'

import { getTransparentColor } from '../../../utils/colors'
import { areValidCoordinates, createPointFeature } from '../../../utils/geojson'
import { Map } from '../../Map'
import { MapIcon } from '../../MapIcon'
import { useLocationMapViewState } from './useLocationMapViewState'

type Props = {
  area: Area
  swanBot: SwanBot
  isEditingAllowed: boolean
  onChangeHomeLocation: (coordinates: Coordinates) => void
}

export const LocationMap: React.FC<Props> = ({
  area,
  swanBot,
  isEditingAllowed,
  onChangeHomeLocation,
}) => {
  const { home, currLocation } = swanBot
  const ref = React.useRef<HTMLDivElement>(null)
  const viewState = useLocationMapViewState({
    home,
    currLocation,
    areaCoordinates: area.coordinates,
    ref,
  })

  if (
    (home && !areValidCoordinates(home)) ||
    (currLocation && !areValidCoordinates(currLocation))
  ) {
    return <Result status="error" title="Coordinates are incorrect" />
  }

  const data: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: home ? [createPointFeature(home)] : [],
  }

  const onEdit = ({ updatedData }: EditAction<typeof data>) => {
    if (updatedData.features.length) {
      const { geometry } = updatedData.features[0]
      if (geometry.type === 'Point') {
        onChangeHomeLocation(geometry.coordinates as Coordinates)
      }
    }
  }

  const editLayer = new EditableGeoJsonLayer({
    id: 'homePoint',
    data,
    selectedFeatureIndexes: [0],
    mode: home ? ModifyMode : DrawPointMode,
    onEdit,
    getTentativeLineColor: getTransparentColor,
    getTentativeFillColor: getTransparentColor,
    _subLayerProps: {
      geojson: {
        visible: false,
      },
    },
  })

  return (
    <div ref={ref} style={{ width: '100%', height: '100%' }}>
      <Map
        {...viewState}
        withStyleSwitcher={false}
        withAttribution={false}
        layers={isEditingAllowed ? [editLayer] : undefined}
        getCursor={
          isEditingAllowed ? editLayer.getCursor.bind(editLayer) : undefined
        }
        message={
          isEditingAllowed
            ? home
              ? 'Move home icon on the map'
              : 'Select home location on the map'
            : undefined
        }
      >
        <HtmlOverlay zIndex={0}>
          {home && (
            <MapIcon
              key="home"
              type="home"
              coordinates={home}
              color={swanBot.iconColor}
            />
          )}
          {currLocation && (
            <MapIcon
              key="swan"
              type="swan"
              coordinates={currLocation}
              heading={swanBot.currHeading}
              swanBot={swanBot}
            />
          )}
        </HtmlOverlay>
      </Map>
    </div>
  )
}
