import type { LatestSensorData, SensorParameter } from 'swanviz'
import {
  AlertSetting,
  getAlertSettings,
  getAllSensorParameters,
  getLatestSensorData,
  SwanBot,
} from 'swanviz'
import useSWR from 'swr'

import { SWANBOT_SENSORS_DATA_POLL_INTERVAL } from '../../../poll-config'

type ReturnType = {
  sensors: SensorParameter[] | undefined
  alertSettings: AlertSetting[] | undefined
  latestSensorData: LatestSensorData[] | undefined
  isLoading: boolean
  isError: boolean
}

export const useAlertsTabData = (swanbot: SwanBot): ReturnType => {
  const runId = swanbot.currRunId

  const {
    data: sensors,
    isValidating: areSensorsLoading,
    error: sensorsError,
  } = useSWR('getAllSensorParameters', getAllSensorParameters)

  const {
    data: alertSettings,
    isValidating: isAlertSettingLoading,
    error: alertSettingError,
  } = useSWR(['getAlertSettings', swanbot.id], () =>
    getAlertSettings(swanbot.id)
  )

  const { data: latestSensorData, error: latestSensorDataError } = useSWR(
    runId ? ['getLatestSensorData', swanbot.id] : null,
    () => getLatestSensorData(swanbot.id),
    {
      refreshInterval: runId ? SWANBOT_SENSORS_DATA_POLL_INTERVAL : 0,
    }
  )

  return {
    sensors,
    alertSettings,
    latestSensorData,
    isLoading: areSensorsLoading || isAlertSettingLoading,
    isError: Boolean(
      sensorsError || alertSettingError || latestSensorDataError
    ),
  }
}
