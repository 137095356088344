import { Area, SwanBot } from 'swanviz'
import { isDefined } from './typeGuards'

export type PopulatedSwanbot = SwanBot & {
  areaName: string | null
}
export const populateSwanbots = (
  swanbots: SwanBot[],
  areas: Area[]
): PopulatedSwanbot[] => {
  return areas.length
    ? swanbots
        .map((swanbot) => {
          const area = areas.find((area) => area.id === swanbot.areaId)
          return area
            ? {
                ...swanbot,
                areaName: area.name,
              }
            : undefined
        })
        .filter(isDefined)
    : []
}
