import { getAllUsers, getAllSwanBots, User, getAreaList } from 'swanviz'
import useSWR from 'swr'
import {
  PopulatedSwanbot,
  populateSwanbots,
} from '../../../utils/populateSwanbots'

export const useUsersPageData = (): {
  users: User[]
  swanbots: PopulatedSwanbot[]
  isLoading: boolean
  isError: boolean
} => {
  const {
    data: users = [],
    isValidating: areUsersLoading,
    error: usersError,
  } = useSWR('getAllUsers', getAllUsers)

  const {
    data: swanbots = [],
    isValidating: areSwanbotsLoading,
    error: swanbotsError,
  } = useSWR('getAllSwanBots', () => getAllSwanBots())

  const {
    data: areas = [],
    isValidating: areAreasLoading,
    error: areasError,
  } = useSWR('getAreaList', getAreaList)

  const populatedSwanbots = populateSwanbots(swanbots, areas)

  return {
    users: users,
    swanbots: populatedSwanbots,
    isLoading: areUsersLoading || areSwanbotsLoading || areAreasLoading,
    isError: Boolean(usersError || swanbotsError || areasError),
  }
}
