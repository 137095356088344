import _ from 'lodash'
import {
  Area,
  getAreaList,
  getFilteredNtfs,
  getSwanBotsForUser,
  Notification,
  NotificationFilter,
  SwanBot,
} from 'swanviz'
import useSWR from 'swr'

import { useDefinedValue } from '../../../utils/useDefinedValue'

export type PopulatedNotification = Notification & {
  area: Area
  swanBot: SwanBot
}

export const useAlertsPageData = (
  notificationsFilter: NotificationFilter
): {
  notifications: PopulatedNotification[]
  pageCount: number
  areas: Area[]
  swanBots: SwanBot[]
  isLoading: boolean
  isError: boolean
} => {
  const {
    data: areas = [],
    isValidating: areAreasLoading,
    error: areasError,
  } = useSWR('getAreaList', getAreaList)
  const {
    data: swanBots = [],
    isValidating: areSwanBotsLoading,
    error: swanBotsError,
  } = useSWR('getSwanBotsForUser', getSwanBotsForUser)
  const {
    data,
    isValidating: areNotificationsLoading,
    error: notificationsError,
  } = useSWR(['getFilteredNtfs', notificationsFilter], () =>
    getFilteredNtfs(notificationsFilter)
  )
  const preservedData = useDefinedValue(data, {
    notifications: [],
    pageCount: 0,
    ntfCount: 0,
  })

  return {
    notifications: getPopulatedNotifications({
      notifications: preservedData.notifications,
      swanBots,
      areas,
    }),
    pageCount: preservedData.pageCount,
    areas,
    swanBots,
    isLoading: areAreasLoading || areSwanBotsLoading || areNotificationsLoading,
    isError: Boolean(areasError || swanBotsError || notificationsError),
  }
}

const getPopulatedNotifications = ({
  notifications,
  areas,
  swanBots,
}: {
  notifications: Notification[]
  areas: Area[]
  swanBots: SwanBot[]
}): PopulatedNotification[] => {
  const areasById = _.keyBy(areas, 'id')
  const swanBotsById = _.keyBy(swanBots, 'id')

  return notifications.map((notification) => ({
    ...notification,
    area: areasById[notification.areaId],
    swanBot: swanBotsById[notification.swanbotId],
  }))
}
