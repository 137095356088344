import { message, Popconfirm } from 'antd'
import React from 'react'
import { mutate } from 'swr'
import { deleteUser } from 'swanviz'

import { ERROR_MESSAGES } from '../../../../../utils/errorMessages'
import { DeleteButton } from '../../../../DeleteButton'

type Props = {
  id: number
  isDeleting: boolean
  onChangeDeleting: (status: boolean) => void
  onDelete: () => void
}

export const DeleteUser: React.FC<Props> = ({
  id,
  isDeleting,
  onChangeDeleting,
  onDelete,
}) => {
  const handleDelete = async () => {
    onChangeDeleting(true)
    try {
      await deleteUser(id)
      await mutate('getAllUsers')
      message.success('User has been deleted')
      onDelete()
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.userDelete)
    }
    onChangeDeleting(false)
  }

  return (
    <Popconfirm
      title="Are you sure you want to delete this user?"
      okText="Yes"
      cancelText="No"
      onConfirm={handleDelete}
    >
      <DeleteButton loading={isDeleting}>Delete User</DeleteButton>
    </Popconfirm>
  )
}
