import React from 'react'
import { Result, Spin } from 'antd'
import { SwanBot } from 'swanviz'

import { useAlertsTabData } from './useAlertsTabData'
import css from './style.module.css'
import { AlertsTabContent } from '../AlertsTabContent'

type Props = {
  swanBot: SwanBot
}

export const AlertsTab: React.FC<Props> = ({ swanBot }) => {
  const {
    sensors,
    alertSettings,
    latestSensorData,
    isLoading,
    isError,
  } = useAlertsTabData(swanBot)

  if (isError) {
    return (
      <Result
        status="error"
        title="Couldn't get alert settings"
        subTitle="Try to close and then open this modal again"
      />
    )
  }

  if (sensors === undefined || alertSettings === undefined) {
    return (
      <div className={css.spinContainer}>
        <Spin
          className={css.spinner}
          tip="Fetching alert settings"
          spinning={true}
        />
      </div>
    )
  }

  return (
    <div className={css.main}>
      <AlertsTabContent
        swanBot={swanBot}
        alertSettings={alertSettings}
        latestSensorData={latestSensorData}
        sensors={sensors}
        isLoading={isLoading}
      />
    </div>
  )
}
