import { Area, getAllGeofences, getAllMissions, getAreaList } from 'swanviz'
import useSWR from 'swr'

import { PopulatedGeofence } from './GeofencesTable'
import { PopulatedMission } from './MissionsTable'

export const useMissionsAndGeofences = (): {
  areas: Area[]
  missions: PopulatedMission[]
  geofences: PopulatedGeofence[]
  areAreasLoading: boolean
  areMissionsLoading: boolean
  areGeofencesLoading: boolean
  isError: boolean
} => {
  const {
    data: areas = [],
    isValidating: areAreasLoading,
    error: areasError,
  } = useSWR('getAreaList', getAreaList)
  const {
    data: missions = [],
    isValidating: areMissionsLoading,
    error: missionsError,
  } = useSWR('getAllMissions', getAllMissions)
  const {
    data: geofences = [],
    isValidating: areGeofencesLoading,
    error: geofencesError,
  } = useSWR('getAllGeofences', getAllGeofences)

  const populatedMissions = missions.map<PopulatedMission>((mission) => {
    return {
      ...mission,
      areaName: areas.find((area) => area.id === mission.areaId)?.name || '-',
      geofenceName:
        geofences.find((geofence) => geofence.id === mission.geofenceId)
          ?.name || '-',
    }
  })

  const populatedGeofences = geofences.map<PopulatedGeofence>((geofence) => ({
    ...geofence,
    areaName: areas.find((area) => area.id === geofence.areaId)?.name || '-',
  }))

  return {
    areas,
    missions: populatedMissions,
    geofences: populatedGeofences,
    areAreasLoading,
    areMissionsLoading,
    areGeofencesLoading,
    isError: Boolean(areasError || missionsError || geofencesError),
  }
}
