import { message, Spin, Tabs } from 'antd'
import React from 'react'
import {
  addUser,
  reqForgotPassword,
  updateUser,
  User,
  UserAdd,
  UserUpdate,
} from 'swanviz'
import { mutate } from 'swr'

import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import { PopulatedSwanbot } from '../../../../utils/populateSwanbots'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { AssignedSwanbotsTab } from './AssignedSwanbotsTab'
import { DeleteUser } from './DeleteUser'
import { ModalTop } from './ModalTop'

import css from './style.module.css'
import { UserFormTab } from './UserFormTab'

type Props = {
  user?: User
  swanbots: PopulatedSwanbot[]
  onRequestClose: () => void
  onUserCreated: (userId: number) => void
}

const USER_TAB_KEY = 'user'
const SWANBOTS_TAB_KEY = 'swanbots'

export const UserModalContent: React.FC<Props> = ({
  user,
  swanbots,
  onRequestClose,
  onUserCreated,
}) => {
  const { isDesktop } = useWindowSize()
  const [isResettingPassword, setResettingPassword] = React.useState(false)
  const [isDeleting, setDeleting] = React.useState(false)
  const [isUpdating, setUpdating] = React.useState(false)
  const [isCreating, setCreating] = React.useState(false)
  const [activeTab, setActiveTab] = React.useState(USER_TAB_KEY)

  const onPasswordReset = async () => {
    if (!user) {
      return
    }
    setResettingPassword(true)
    try {
      const link = await reqForgotPassword(user.username)
      // TODO it is better to remove logging after reqForgotPassword is implemented
      console.info(link)
      message.success(
        'An email with a reset password link has been sent to the user.'
      )
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.reqForgotPassword)
    }
    setResettingPassword(false)
  }

  const handleUserUpdate = (user: User) => async (patch: UserUpdate) => {
    setUpdating(true)
    try {
      await updateUser(user.id, {
        ...user,
        ...patch,
      })
      await mutate('getAllUsers')
      message.success('User has been updated')
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.userUpdate)
    }
    setUpdating(false)
  }

  const handleUserAdd = async (newUser: UserAdd) => {
    setCreating(true)
    try {
      const userId = await addUser(newUser)
      await mutate('getAllUsers')
      onUserCreated(userId)
      setActiveTab(SWANBOTS_TAB_KEY)
      message.success('User has been created')
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.userAdd)
    }
    setCreating(false)
  }

  return (
    <Spin
      spinning={isDeleting || isUpdating}
      tip={
        (isResettingPassword && 'Resetting password') ||
        (isDeleting && 'Deleting user') ||
        (isUpdating && 'Updating user') ||
        (isCreating && 'Creating user') ||
        undefined
      }
    >
      <ModalTop
        user={user}
        onPasswordReset={onPasswordReset}
        isResettingPassword={isResettingPassword}
        onRequestClose={onRequestClose}
      />

      <div className={css.tabs}>
        {isDesktop && user && (
          <div className={css.delete}>
            <DeleteUser
              id={user.id}
              isDeleting={isDeleting}
              onChangeDeleting={setDeleting}
              onDelete={onRequestClose}
            />
          </div>
        )}
        <Tabs
          activeKey={activeTab}
          onTabClick={(activeKey) => setActiveTab(activeKey)}
        >
          <Tabs.TabPane tab="User" key={USER_TAB_KEY}>
            <UserFormTab
              user={user}
              onSave={user ? handleUserUpdate(user) : handleUserAdd}
              isSaving={isUpdating || isCreating}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Assigned SwanBots"
            key={SWANBOTS_TAB_KEY}
            disabled={!user}
          >
            {user && (
              <AssignedSwanbotsTab
                user={user}
                swanbots={swanbots}
                onSave={handleUserUpdate(user)}
                isSaving={isUpdating}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Spin>
  )
}
