import { Button, Input, message, Modal, Spin, Typography } from 'antd'
import classnames from 'classnames'
import type { QRCode as QRCodeComponentType } from 'qrcode.react'
import React from 'react'
import { disable2FA, enable2FA, getQRCode } from 'swanviz'
import useSWR, { mutate } from 'swr'

import { ERROR_MESSAGES } from '../../../../utils/errorMessages'

import css from './style.module.css'

const LazyQRCode = React.lazy<QRCodeComponentType>(() =>
  import('qrcode.react').catch(() => ({
    default: (() => (
      <>Failed to load QR code library. Please reload page to try again.</>
    )) as any,
  }))
)

const QR_CODE_SIZE = 150

type Props = {
  is2FAEnabled: boolean
  onClose: () => void
}

export const TwoStepModal: React.FC<Props> = ({ is2FAEnabled, onClose }) => {
  const [authCode, setAuthCode] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const { data: qrCode, isValidating: isLoading } = useSWR(
    'getQRCode',
    getQRCode
  )

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault()
    if (!qrCode) return
    setIsSubmitting(true)
    try {
      is2FAEnabled
        ? await disable2FA(authCode)
        : await enable2FA(authCode, qrCode)
      await mutate('getUserProfile')
      onClose()
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.twoFactorAuthChange)
    }
    setIsSubmitting(false)
  }

  return (
    <Modal
      visible
      footer={null}
      bodyStyle={{ padding: 27 }}
      onCancel={onClose}
      closable={!isSubmitting}
      maskClosable={!isSubmitting}
      width={540}
    >
      <div className={css.main}>
        <Spin spinning={isLoading}>
          {!is2FAEnabled && (
            <>
              <Typography.Text className={css.title}>
                Scan this QR code with your app
              </Typography.Text>
              <Typography.Text className={classnames(css.text, css.isBold)}>
                If you can’t use a QR code, enter this text code instead:{' '}
                {qrCode}
              </Typography.Text>
              <div
                className={css.qrCode}
                style={{ width: QR_CODE_SIZE, height: QR_CODE_SIZE }}
              >
                <React.Suspense fallback={<Spin />}>
                  {qrCode && <LazyQRCode value={qrCode} size={QR_CODE_SIZE} />}
                </React.Suspense>
              </div>
            </>
          )}
          <Typography.Text className={css.text}>
            {is2FAEnabled
              ? 'Enter the six-digit code from the 2FA application'
              : 'Then enter the six-digit code from the application'}
          </Typography.Text>
          <form onSubmit={handleSubmit}>
            <div className={css.inputWrapper}>
              <Input
                className={css.input}
                autoFocus
                size="large"
                required
                minLength={6}
                maxLength={6}
                placeholder="Six-digit code"
                autoComplete="off"
                disabled={isSubmitting}
                value={authCode}
                onChange={(e) =>
                  setAuthCode(e.target.value.replace(/[^\d]/, ''))
                }
              />
            </div>
            <div>
              <Button
                size="large"
                type="primary"
                danger
                htmlType="submit"
                loading={isSubmitting}
                className={css.button}
              >
                {is2FAEnabled ? 'Disable' : 'Enable'}
              </Button>
            </div>
          </form>
        </Spin>
      </div>
    </Modal>
  )
}
