import { centerOfMass } from '@turf/turf'
import _ from 'lodash'
import { Coordinates, MissionTask } from 'swanviz'

import type { MissionTaskWithSpeed } from '../components/pages/Mission'
import { removeAt, updateAt } from './array'
import { MISSION_TASK_DEFAULTS } from './defaults'
import { createPolygonFeature } from './geojson'
import { getSimplifiedLawnmowerPath } from './lawnmower'
import { isDefined } from './typeGuards'

export const getTaskIdx = (
  tasks: MissionTask[],
  taskId: MissionTask['id']
): number => tasks.findIndex((task) => task.id === taskId)

export const removeTaskById = (
  tasks: MissionTask[],
  taskId: MissionTask['id']
): MissionTask[] => removeAt(tasks, getTaskIdx(tasks, taskId))

export const updateTaskById = (
  tasks: MissionTask[],
  newValue: MissionTask
): MissionTask[] => updateAt(tasks, getTaskIdx(tasks, newValue.id), newValue)

export const getEmptyTask = (
  type: MissionTask['type'],
  id: MissionTask['id']
): MissionTaskWithSpeed => {
  const common = {
    id,
    estDistance: 0,
    estDuration: 0,
  }

  switch (type) {
    case 'go':
      return {
        ...common,
        type: 'go',
        args: {
          location: null,
        },
        speed: undefined,
      }
    case 'stationKeep':
      return {
        ...common,
        type: 'stationKeep',
        args: {
          duration: MISSION_TASK_DEFAULTS.duration.value,
        },
      }
    case 'sensorDepth':
      return {
        ...common,
        type: 'sensorDepth',
        args: {
          sensorDepth: MISSION_TASK_DEFAULTS.sensorDepth.value,
        },
      }
    case 'waterSampler':
      return {
        ...common,
        type: 'waterSampler',
        args: {
          sensorDepth: MISSION_TASK_DEFAULTS.sensorDepth.value,
        },
      }
    case 'survey':
      return {
        ...common,
        type: 'survey',
        args: {
          duration: MISSION_TASK_DEFAULTS.duration.value,
          aoi: [],
          type: null,
          quantity: [],
        },
        speed: undefined,
      }
    case 'lawnmower':
      return {
        ...common,
        type: 'lawnmower',
        args: {
          bearing: MISSION_TASK_DEFAULTS.lawnmower.bearing.value,
          leg: MISSION_TASK_DEFAULTS.lawnmower.leg.value,
          legs: MISSION_TASK_DEFAULTS.lawnmower.legs.value,
          spacing: MISSION_TASK_DEFAULTS.lawnmower.spacing.value,
        },
        waypoints: [],
        speed: undefined,
      }
    case 'sensors':
      return {
        ...common,
        type: 'sensors',
        args: {
          enable: true,
        },
      }
    // Is not displayed in UI
    case 'speed':
      return {
        ...common,
        type: 'speed',
        args: {
          speed: 0.01,
        },
      }
  }
}

export type TaskPoint = {
  taskId: MissionTask['id']
  coordinates: Coordinates
}

export const getTaskPoints = (tasks: MissionTask[]): Array<TaskPoint> => {
  return tasks
    .flatMap((task) => {
      switch (task.type) {
        case 'go': {
          const { location } = task.args
          return location && location !== 'home'
            ? {
                taskId: task.id,
                coordinates: location,
              }
            : undefined
        }
        case 'lawnmower': {
          if (task.waypoints.length > 0) {
            return task.waypoints.map((waypoint) => ({
              taskId: task.id,
              coordinates: waypoint.location,
            }))
          }

          const previousLocation = getPreviousLocation({
            taskId: task.id,
            allTasks: tasks,
          })
          return getSimplifiedLawnmowerPath(previousLocation, task).map(
            (point) => ({
              taskId: task.id,
              coordinates: point,
            })
          )
        }
        case 'survey': {
          const { aoi: polygonCoordinates } = task.args
          if (polygonCoordinates.length < 3) {
            return undefined
          }
          const polygonFeature = createPolygonFeature([polygonCoordinates])
          const polygonCenter = centerOfMass(polygonFeature)
          return polygonCenter.geometry
            ? {
                taskId: task.id,
                coordinates: polygonCenter.geometry.coordinates as Coordinates,
              }
            : undefined
        }
        default:
          return undefined
      }
    })
    .filter(isDefined)
}

export const getPreviousLocation = ({
  taskId,
  allTasks,
}: {
  taskId: MissionTask['id']
  allTasks: MissionTask[]
}): Coordinates | null => {
  const currentTaskIdx = getTaskIdx(allTasks, taskId)
  const previousTasks = allTasks.slice(0, currentTaskIdx)
  const previousTaskPoints = getTaskPoints(previousTasks)

  return _.last(previousTaskPoints)?.coordinates ?? null
}

export const getNextLocation = ({
  taskId,
  allTasks,
}: {
  taskId: MissionTask['id']
  allTasks: MissionTask[]
}): Coordinates | null => {
  const currentTaskIdx = getTaskIdx(allTasks, taskId)
  const nextTasks = allTasks.slice(currentTaskIdx + 1)
  const nextTaskPoints = getTaskPoints(nextTasks)

  return _.first(nextTaskPoints)?.coordinates ?? null
}
