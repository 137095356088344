import { Spin } from 'antd'
import _ from 'lodash'
import * as React from 'react'
import { MissionTask } from 'swanviz'

import { useEstimatesUpdates } from '../../../contexts/estimatesUpdatesContext'
import { formatTotalTime } from '../../../utils/time'
import { joinInfoParts } from '../Task/helpers'
import { TextRows } from '../TextRows'

type Props = {
  task: MissionTask
  totalDuration: number
}

export const TaskEstimates: React.FC<Props> = ({ task, totalDuration }) => {
  const { isEstimateUpdating } = useEstimatesUpdates()

  return (
    <Spin size="small" spinning={isEstimateUpdating(task.id)} delay={100}>
      <TextRows
        firstRow={formatTotalTime(task.estDuration, 1)}
        secondRow={joinInfoParts([
          formatTotalTime(totalDuration, 1),
          task.estDistance
            ? `${_.round(task.estDistance).toLocaleString()} m`
            : undefined,
        ])}
      />
    </Spin>
  )
}
