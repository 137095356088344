import React, { useEffect } from 'react'
import type { RouteProps } from 'react-router'
import { Redirect, Route } from 'react-router-dom'
import { message } from 'antd'

import { usePermissions, UserPermissions } from '../../utils/usePermissions'
import { DASHBOARD_URL, LOGIN_URL, SETTINGS_URL } from '../../utils/links'
import { useUser } from '../../contexts/userContext'
import { useUserSession } from '../../contexts/userSessionContext'

export type CheckHasAccess = (permissions: UserPermissions) => boolean

type Props = RouteProps & { checkHasAccess?: CheckHasAccess }

export const ProtectedRoute: React.FC<Props> = ({
  checkHasAccess,
  ...routeProps
}) => {
  const permissions = usePermissions()
  const { userIdSession } = useUserSession()
  const { user } = useUser()

  const shouldBeRedirectedToDashboard =
    user && checkHasAccess && !checkHasAccess(permissions)
  const shouldBeRedirectedToSettings =
    user?.pwdChangePending && routeProps.path !== SETTINGS_URL

  useEffect(() => {
    if (shouldBeRedirectedToDashboard) {
      message.warning(
        "You don't have permission to access this page. You've been redirected to the Dashboard."
      )
    }
  }, [shouldBeRedirectedToDashboard])

  switch (true) {
    case !userIdSession:
      return <Redirect to={LOGIN_URL} />
    case shouldBeRedirectedToDashboard:
      return <Redirect to={DASHBOARD_URL} />
    case shouldBeRedirectedToSettings:
      return <Redirect to={SETTINGS_URL} />
    default:
      return <Route {...routeProps} />
  }
}
