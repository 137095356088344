import { Pagination } from 'antd'
import React from 'react'
import { useQueryParams } from 'use-query-params'
import { getScrollContainer } from '../../../utils/scroll'
import { useWindowSize } from '../../../utils/useWindowSize'
import { PageTitle } from '../../PageTitle'
import { ReloadPageResult } from '../../ReloadPageResult'

import { AlertsTable } from './AlertsTable'
import { MobileAlertsFilters } from './MobileAlertsFilters'
import { queryParamsConfig } from './query'
import css from './style.module.css'
import { useAlertsPageData } from './useAlertsPageData'

export const Alerts: React.FC = () => {
  const [filter, setFilter] = useQueryParams(queryParamsConfig)
  const { isMobile } = useWindowSize()

  const {
    notifications,
    pageCount,
    areas,
    swanBots,
    isLoading,
    isError,
  } = useAlertsPageData(filter)

  React.useEffect(() => {
    getScrollContainer()?.scrollTo({ top: 0, behavior: 'smooth' })
  }, [filter])

  if (isError) {
    return (
      <ReloadPageResult title="Couldn't get alerts and notifications list" />
    )
  }

  const resetFilters = () =>
    setFilter({
      areaId: undefined,
      swanbotId: undefined,
      type: undefined,
      sortField: undefined,
      sortDirection: undefined,
      pageNo: 1,
    })

  return (
    <>
      <PageTitle className={css.title}>Alerts and notifications</PageTitle>
      {isMobile && (
        <div className={css.filters}>
          <MobileAlertsFilters
            areas={areas}
            swanBots={swanBots}
            value={filter}
            onChange={setFilter}
            onReset={resetFilters}
          />
        </div>
      )}
      <AlertsTable
        isLoading={isLoading}
        notifications={notifications}
        areas={areas}
        swanBots={swanBots}
        filter={filter}
        onChangeFilter={setFilter}
        onResetFilters={resetFilters}
      />
      {pageCount > 1 && (
        <div className={css.pagination}>
          <Pagination
            current={filter.pageNo}
            pageSize={1}
            total={pageCount}
            showSizeChanger={false}
            responsive
            onChange={(page) => {
              setFilter({ pageNo: page })
            }}
          />
        </div>
      )}
    </>
  )
}
