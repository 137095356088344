import { HtmlOverlay } from '@nebula.gl/overlays'
import _ from 'lodash'
import React from 'react'
import {
  Area,
  Geofence,
  MissionRunEssential,
  MissionRunLocInfo,
  MissionTask,
  MissionType,
  NewMissionType,
  SwanBot,
  TaskStatus,
} from 'swanviz'

import type {
  HoveredTaskId,
  OnTaskHover,
  OnTaskSelect,
  SelectedTaskId,
} from '../'
import { useHighlightedSurveyPoint } from '../../../../contexts/highlightedSurveyPointContext'
import { getGeofenceLayer } from '../../../../geoLayers/geofenceLayer'
import { getMissionRunPathLayer } from '../../../../geoLayers/missionRunPathLayer'
import { statusToFetchingTypeMap } from '../../../../utils/fetchingType'
import { isRunStarted } from '../../../../utils/missionRuns'
import { updateTaskById } from '../../../../utils/missionTasks'
import { isDefined, isTruthy } from '../../../../utils/typeGuards'
import { ActualPathFetcher } from '../../../ActualPathFetcher'
import { Map } from '../../../Map'
import { MapIcon } from '../../../MapIcon'

import { getLawnmowerIcons } from './lawnmowerIcons'
import { getTaskEditLayer } from './layers/editLayer'
import { getGoTaskDistancesLayer } from './layers/goTaskDistancesLayer'
import { getMissionLayers } from './layers/missionLayers'

type Props = {
  mission: MissionType | NewMissionType
  missionRun: MissionRunEssential | undefined
  taskStatuses: TaskStatus[] | undefined
  area: Area
  device: SwanBot | undefined
  geofence: Geofence | undefined
  hoveredTaskId: HoveredTaskId
  selectedTaskId: SelectedTaskId
  isViewMode: boolean
  onTaskHover: OnTaskHover
  onTaskSelect: OnTaskSelect
  onChangeTasks: (missionTasks: MissionTask[]) => void
}

export const MissionMap: React.FC<Props> = ({
  mission,
  missionRun,
  taskStatuses,
  area,
  geofence,
  device,
  hoveredTaskId,
  selectedTaskId,
  isViewMode,
  onTaskHover,
  onTaskSelect,
  onChangeTasks,
}) => {
  const { highlightedSurveyPointIdx } = useHighlightedSurveyPoint()

  const [actualPath, setActualPath] = React.useState<MissionRunLocInfo[]>([])
  const shouldShowPath = isRunStarted(missionRun?.status)

  React.useEffect(() => {
    if (!shouldShowPath) {
      setActualPath([])
    }
  }, [shouldShowPath])

  const selectedTask = mission.missionTasks.find(
    (task) => task.id === selectedTaskId
  )
  const [isAddingNewGeoFeature, setAddingNewGeoFeature] = React.useState(false)

  React.useEffect(() => {
    !selectedTask && setAddingNewGeoFeature(false)
  }, [selectedTask])

  const [areaLong, areaLat] = area.coordinates
  const lastPathPoint = _.last(actualPath)
  const currentLocation = lastPathPoint?.location ?? device?.currLocation
  const currentHeading = lastPathPoint?.heading ?? device?.currHeading

  const missionRunLayer =
    shouldShowPath &&
    getMissionRunPathLayer({
      id: 'mission',
      actualPath,
      hexColor: device?.iconColor ?? undefined,
    })
  const geoFenceLayer = geofence && getGeofenceLayer(geofence)
  const missionLayers = getMissionLayers({
    mission,
    taskStatuses,
    currentLocation: currentLocation || null,
    hoveredTaskId,
    selectedTaskId,
    isViewMode,
    onTaskHover,
    onTaskSelect,
  })
  const editingLayer =
    !isViewMode &&
    selectedTask &&
    getTaskEditLayer({
      task: selectedTask,
      tasks: mission.missionTasks,
      highlightedSurveyPointIdx,
      isAddingNewGeoFeature,
      onChangeAddingNewGeoFeature: setAddingNewGeoFeature,
      onChange: (task) =>
        onChangeTasks(updateTaskById(mission.missionTasks, task)),
    })
  const goTaskDistancesLayer =
    !isViewMode &&
    selectedTask?.type === 'go' &&
    getGoTaskDistancesLayer(selectedTask, mission.missionTasks)

  const layers = [
    geoFenceLayer,
    ...missionLayers,
    missionRunLayer,
    editingLayer,
    goTaskDistancesLayer,
  ].filter(isTruthy)

  const message = isViewMode
    ? undefined
    : getMessageText({ selectedTask, isAddingNewGeoFeature })

  return (
    <>
      <Map
        longitude={areaLong}
        latitude={areaLat}
        message={message}
        layers={layers}
        pickingRadius={10}
        getCursor={(interactiveState) => {
          if (editingLayer) return editingLayer.getCursor(interactiveState)
          if (interactiveState.isDragging) return 'grabbing'
          if (isDefined(hoveredTaskId)) return 'pointer'
          return 'grab'
        }}
      >
        <HtmlOverlay zIndex={0}>
          {[
            device?.home && (
              <MapIcon
                key="home"
                type="home"
                coordinates={device.home}
                color={device.iconColor}
              />
            ),
            device && currentLocation && (
              <MapIcon
                key={`swan_${device.id}`}
                type="swan"
                coordinates={currentLocation}
                heading={currentHeading ?? null}
                swanBot={device}
              />
            ),
            ...getLawnmowerIcons({
              isViewMode,
              task: selectedTask,
              allTasks: mission.missionTasks,
            }),
          ]}
        </HtmlOverlay>
      </Map>
      {shouldShowPath && missionRun?.status && (
        <ActualPathFetcher
          runId={missionRun.runId}
          fetchingType={statusToFetchingTypeMap[missionRun.status]}
          onChange={setActualPath}
        />
      )}
    </>
  )
}

const getMessageText = ({
  selectedTask,
  isAddingNewGeoFeature,
}: {
  selectedTask: MissionTask | undefined
  isAddingNewGeoFeature: boolean
}): string | undefined => {
  if (selectedTask) {
    switch (selectedTask.type) {
      case 'go': {
        const { location } = selectedTask.args
        if (location === 'home') return
        return selectedTask.args.location
          ? 'Move the point on the map'
          : 'Select point location on the map'
      }
      case 'lawnmower':
        return 'Modify form inputs to see changes on the map'
      case 'survey':
        return !selectedTask.args.aoi.length || isAddingNewGeoFeature
          ? 'Draw a polygon on the map, double-click to end drawing'
          : 'Modify polygon points on the map: drag to move, click to remove or add'
    }
  }
}
