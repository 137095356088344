import { Button, Input, message, Modal, Typography, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import React, { FormEventHandler } from 'react'
import { Link } from 'react-router-dom'
import { Area, importGeofence } from 'swanviz'
import { mutate } from 'swr'

import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import { getTrimmedInputValue } from '../../../../utils/input'
import { getNewGeofenceLink } from '../../../../utils/links'
import { AddButton } from '../../../AddButton'
import { AreasSelect } from '../../../AreasSelect'
import css from './style.module.css'

type Props = {
  areas: Area[]
}

export const GeofencesButtons: React.FC<Props> = ({ areas }) => {
  const [modalMode, setModalMode] = React.useState<'add' | 'import' | null>(
    null
  )
  const [selectedAreaId, setSelectedAreaId] = React.useState(
    areas.length && areas[0].id
  )
  const [name, setName] = React.useState<string>('')
  const [file, setFile] = React.useState<RcFile | null>(null)
  const [isUploading, setUploading] = React.useState(false)

  React.useEffect(() => {
    setSelectedAreaId(areas.length && areas[0].id)
  }, [areas])

  const closeModal = () => setModalMode(null)

  const handleUpload: FormEventHandler = async (e) => {
    e.preventDefault()

    if (file) {
      setUploading(true)
      try {
        await importGeofence(file, selectedAreaId, name)
        message.success('Geofence has been uploaded successfully')
        mutate('getAllGeofences')
        closeModal()
      } catch (err) {
        console.error(err)
        message.error(ERROR_MESSAGES.geofenceImport)
      }
      setUploading(false)
    }
  }

  return (
    <>
      <AddButton onClick={() => setModalMode('add')}>New geofence</AddButton>

      <Button type="link" onClick={() => setModalMode('import')}>
        ↑ New from geojson file
      </Button>

      <Modal
        visible={Boolean(modalMode)}
        footer={null}
        title={modalMode === 'add' ? 'New geofence' : 'Upload new geofence'}
        closable={!isUploading}
        maskClosable={!isUploading}
        onCancel={closeModal}
      >
        <form className={css.modalContent} onSubmit={handleUpload}>
          <Label>Area</Label>
          <AreasSelect
            areas={areas}
            size="middle"
            value={selectedAreaId}
            disabled={isUploading}
            onChange={setSelectedAreaId}
          />

          {modalMode === 'add' && (
            <Link
              to={getNewGeofenceLink(selectedAreaId)}
              target="_blank"
              className={css.modalButton}
              onClick={closeModal}
            >
              <Button type="primary" danger>
                Create
              </Button>
            </Link>
          )}

          {modalMode === 'import' && (
            <>
              <Label>Name</Label>
              <Input
                placeholder="Geofence name"
                value={name}
                disabled={isUploading}
                onChange={(e) => setName(getTrimmedInputValue(e))}
              />

              <Label>File</Label>
              <Upload
                accept=".kml"
                fileList={file ? [file] : []}
                disabled={isUploading}
                onRemove={() => setFile(null)}
                beforeUpload={(file) => {
                  setFile(file)
                  return false
                }}
              >
                <Button disabled={isUploading}>Select KML file</Button>
              </Upload>

              <div className={css.modalButton}>
                <Button
                  type="primary"
                  danger
                  htmlType="submit"
                  disabled={!file || !name}
                  loading={isUploading}
                >
                  Upload
                </Button>
              </div>
            </>
          )}
        </form>
      </Modal>
    </>
  )
}

const Label: React.FC = ({ children }) => (
  <Typography.Text className={css.label}>{children}</Typography.Text>
)
