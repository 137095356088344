import React from 'react'
import { Typography } from 'antd'
import classnames from 'classnames'

import css from './style.module.css'

type Props = {
  label: string
  className?: string
  titleClassName?: string
}

export const InformationItem: React.FC<Props> = ({
  label,
  className,
  titleClassName,
  children,
}) => (
  <div className={className}>
    <Typography.Text
      type="secondary"
      className={classnames(css.title, titleClassName)}
    >
      {label}
    </Typography.Text>
    {children}
  </div>
)
