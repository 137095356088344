import type { MissionRun } from 'swanviz'

export type FetchingType = 'once' | 'liveActive' | 'livePaused'

export const statusToFetchingTypeMap: Record<
  Exclude<MissionRun['status'], null>,
  FetchingType
> = {
  'MISSION ABORTED': 'once',
  'MISSION COMPLETED': 'once',
  'MISSION RESUMED': 'liveActive',
  'MISSION STARTED': 'liveActive',
  'MISSION SUSPENDED': 'livePaused',
}

export const isLiveUpdating = (status: MissionRun['status']): boolean =>
  !!status && statusToFetchingTypeMap[status] === 'liveActive'
