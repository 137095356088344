import { notification } from 'antd'
import _ from 'lodash'
import React from 'react'
import { getActualPath, getMissionRunLocInfo } from 'swanviz'
import useSWR, { cache, keyInterface } from 'swr'

import { MISSION_ACTUAL_PATH_POLL_INTERVAL } from '../../poll-config'
import { FetchingType } from '../../utils/fetchingType'
import { RunPath } from '../pages/DataVis/types'

type Props = {
  runId: number
  fetchingType: FetchingType
  onChange: (data: RunPath) => void
}

export const ActualPathFetcher: React.FC<Props> = ({
  runId,
  fetchingType,
  onChange,
}) => {
  const isLive = fetchingType !== 'once'
  // Include isLive in key so cache will be different for live and completed run data
  // We don't want to reuse cache data of live run for completed run, because it may be incomplete
  const swrKey: keyInterface = ['getActualPath', runId, isLive]
  const notificationKey = swrKey.join('_')

  const { data } = useSWR(
    swrKey,
    async () => {
      const cachedData: RunPath | undefined = cache.get(swrKey)

      if (isLive) {
        const previousPath = cachedData || []

        const latestItem = _.last(previousPath)
        const lastUpdated = latestItem ? latestItem.locTime + 1 : 0

        const newPathBatch = (await getMissionRunLocInfo(runId, lastUpdated))
          // Filtering can be removed when issue is resolved: https://github.com/subnero1/swanviz/issues/153
          .filter((locInfo) => locInfo.locTime >= lastUpdated)

        return previousPath.concat(_.sortBy(newPathBatch, 'locTime'))
      } else {
        return cachedData || getActualPath(runId)
      }
    },
    {
      refreshInterval:
        fetchingType === 'liveActive' ? MISSION_ACTUAL_PATH_POLL_INTERVAL : 0,
      onError: () =>
        notification.error({
          duration: 0,
          key: notificationKey,
          message: `Couldn't ${
            fetchingType === 'once' ? 'get' : 'update'
          } path for run #${runId}`,
        }),
    }
  )

  React.useEffect(() => {
    if (data) {
      onChange(data)
      notification.close(notificationKey)
    }
  }, [data])

  return null
}
