import { CloseCircleFilled } from '@ant-design/icons'
import { Button, Empty, Table, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { Area, getLatestMissions } from 'swanviz'
import useSWR from 'swr'

import { getMissionLink } from '../../../../utils/links'
import { LastRunLink } from '../../../LastRunLink'

import css from './style.module.css'

export const LatestMissions: React.FC<{ areas: Area[] }> = ({ areas }) => {
  const {
    data: latestMissions,
    isValidating: isLoading,
    error,
    mutate: updateLatestMissions,
  } = useSWR('getLatestMissions', () => getLatestMissions(3))

  return (
    <div className={css.main}>
      <Typography.Title level={2} className={css.title}>
        Latest missions
      </Typography.Title>
      <Table
        className={css.table}
        dataSource={latestMissions}
        pagination={false}
        loading={isLoading}
        size="small"
        locale={{
          emptyText: () =>
            renderEmptyText({
              isLoading,
              error,
              retry: () => updateLatestMissions(),
            }),
        }}
        rowKey={(row) => row.id}
        columns={[
          {
            title: 'Mission',
            width: '48%',
            dataIndex: 'name',
            render: (_value, mission) => (
              <Link to={getMissionLink({ id: mission.id })}>
                <Typography.Link>{mission.name}</Typography.Link>
              </Link>
            ),
            ellipsis: true,
          },
          {
            title: 'Area',
            width: '30%',
            dataIndex: 'areaId',
            render: (areaId) => areas.find((area) => area.id === areaId)?.name,
            ellipsis: true,
          },
          {
            title: 'Last run',
            width: '22%',
            dataIndex: 'lastMissionRun',
            render: (_value, mission) => (
              <LastRunLink
                missionId={mission.id}
                areaId={mission.areaId}
                lastMissionRun={mission.lastMissionRun}
              />
            ),
          },
        ]}
      />
    </div>
  )
}

const renderEmptyText = ({
  isLoading,
  error,
  retry,
}: {
  isLoading: boolean
  error: Error
  retry: () => void
}) => {
  return (
    <Empty
      className={css.empty}
      description={
        error ? (
          <Typography.Text type="danger">
            Failed to load latest missions
          </Typography.Text>
        ) : isLoading ? (
          'Loading'
        ) : (
          'No latest missions found'
        )
      }
      image={
        error ? (
          <CloseCircleFilled className={css.errorIcon} />
        ) : (
          Empty.PRESENTED_IMAGE_SIMPLE
        )
      }
    >
      {error && <Button onClick={() => retry()}>Retry</Button>}
    </Empty>
  )
}
