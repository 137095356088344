import React from 'react'
import { MissionTask } from 'swanviz'
import _ from 'lodash'

type ContextValue = {
  setEstimateUpdating: (taskId: MissionTask['id'], isUpdating: boolean) => void
  isEstimateUpdating: (taskId: MissionTask['id']) => boolean
}

const EstimatesUpdateContext = React.createContext<ContextValue>({
  setEstimateUpdating: _.noop,
  isEstimateUpdating: _.constant(false),
})

export const EstimatesUpdateProvider: React.FC = ({ children }) => {
  const [updatingTasksIds, setUpdatingTasksIds] = React.useState<
    Array<MissionTask['id']>
  >([])

  const setEstimateUpdating: ContextValue['setEstimateUpdating'] =
    React.useCallback(
      (taskId, isUpdating) =>
        setUpdatingTasksIds((state) =>
          isUpdating ? _.union(state, [taskId]) : _.without(state, taskId)
        ),
      [setUpdatingTasksIds]
    )

  const isEstimateUpdating: ContextValue['isEstimateUpdating'] =
    React.useCallback(
      (taskId) => updatingTasksIds.includes(taskId),
      [updatingTasksIds]
    )

  const contextValue: ContextValue = React.useMemo(
    () => ({
      setEstimateUpdating,
      isEstimateUpdating,
    }),
    [setEstimateUpdating, isEstimateUpdating]
  )

  return (
    <EstimatesUpdateContext.Provider value={contextValue}>
      {children}
    </EstimatesUpdateContext.Provider>
  )
}

export const useEstimatesUpdates = () =>
  React.useContext(EstimatesUpdateContext)
