import { ColorRange, RGBAColor } from '@deck.gl/core/utils/color'
import _ from 'lodash'

const COLOR_STEPS_COUNT = 6

export const makeColorRange = (
  startColor: RGBAColor,
  endColor: RGBAColor,
  maxStep: number
): ColorRange => {
  const stepDelta = maxStep / (COLOR_STEPS_COUNT - 1)
  const steps = _.times(COLOR_STEPS_COUNT, (idx) => idx * stepDelta)

  return steps.map((step) =>
    getColorStep(startColor, endColor, step)
  ) as ColorRange
}

export const getColorStep = (
  startColor: RGBAColor,
  endColor: RGBAColor,
  step: number
): RGBAColor => {
  const [startR, startG, startB, startA = 255] = startColor
  const [endR, endG, endB, endA = 255] = endColor
  const [deltaR, deltaG, deltaB, deltaA] = [
    endR - startR,
    endG - startG,
    endB - startB,
    endA - startA,
  ]

  return [
    startR + step * deltaR,
    startG + step * deltaG,
    startB + step * deltaB,
    startA + step * deltaA,
  ].map(Math.round) as RGBAColor
}

export const getRelativeWeight = (
  value: number,
  min: number,
  max: number
): number => _.clamp((value - min) / (max - min), 0, 1)

// For iOS where weights must be integers
// {@link https://github.com/visgl/deck.gl/blob/master/docs/api-reference/aggregation-layers/heatmap-layer.md#limitations}
export const WEIGHT_ABSOLUTE_MAX = 10
