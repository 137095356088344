import React from 'react'
import { SwanBot } from 'swanviz'
import { NumberParam, useQueryParam } from 'use-query-params'

import type { MissionTypeWithSpeed } from '../'
import { isSwanBotSelectable } from '../../../../utils/swanbot'
import { isExistingMission } from '../helpers'

export const useSelectedSwanBot = ({
  isViewMode,
  swanBots,
  mission,
}: {
  isViewMode: boolean
  swanBots: SwanBot[]
  mission: MissionTypeWithSpeed
}): {
  swanBot: SwanBot | undefined
  setSelectedSwanBotId: (id: SwanBot['id']) => void
} => {
  const [runId, setRunId] = useQueryParam('runId', NumberParam)
  const [selectedSwanBotId, setSelectedSwanBotId] =
    React.useState<SwanBot['id']>()
  const swanBot = React.useMemo(
    () => swanBots.find((swanbot) => swanbot.id === selectedSwanBotId),
    [swanBots, selectedSwanBotId]
  )

  // Choosing selected SwanBot when mission changes
  const missionId = isExistingMission(mission) ? mission.id : undefined
  React.useEffect(() => {
    if (isExistingMission(mission)) {
      const isValidRunIdFromQuery =
        runId && mission.currentRunId?.includes(runId)
      const initialRunId = isValidRunIdFromQuery
        ? runId
        : mission.currentRunId?.[0]
      const swanBotDoingMissionRun =
        initialRunId && swanBots.find((s) => s.currRunId === initialRunId)
      if (swanBotDoingMissionRun) {
        setSelectedSwanBotId(swanBotDoingMissionRun.id)
        return
      }
    }

    const firstSelectableSwanBot = swanBots.find((s) =>
      isSwanBotSelectable(s, mission)
    )
    setSelectedSwanBotId(firstSelectableSwanBot?.id)
  }, [missionId])

  // Store run id in URL
  React.useEffect(() => {
    setRunId(swanBot?.currRunId ?? undefined, 'replaceIn')
  }, [swanBot])

  return {
    swanBot: isViewMode ? swanBot : undefined,
    setSelectedSwanBotId,
  }
}
