import { InputNumber } from 'antd'
import React from 'react'

export const InputNumberWithPostfix: React.FC<
  React.ComponentProps<typeof InputNumber> & {
    postfix: string
  }
> = ({ postfix, ...rest }) => (
  <>
    <InputNumber {...rest} /> {postfix}
  </>
)
