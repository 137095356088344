import { MenuOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import classNames from 'classnames'
import React from 'react'

import type { LayoutProps } from '..'
import { toggleScroll } from '../../../utils/scroll'
import css from './style.module.css'

export const MobileLayout: React.FC<LayoutProps> = ({
  logo,
  renderNavLinks,
  renderUserLinks,
  userFullName,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  React.useEffect(() => {
    toggleScroll(!isExpanded)
  }, [isExpanded])

  const handleLinkClick = () => setIsExpanded(false)

  return (
    <div className={classNames(css.main, isExpanded && css.isExpanded)}>
      <div className={css.backdrop} onClick={() => setIsExpanded(false)} />
      <div className={css.menu}>
        {renderNavLinks({
          className: css.link,
          activeClassName: css.active,
          onClick: handleLinkClick,
        })}
        {userFullName && (
          <>
            <div className={css.name}>{userFullName}</div>
            {renderUserLinks({
              className: css.link,
              activeClassName: css.active,
              onClick: handleLinkClick,
            })}
          </>
        )}
      </div>
      <div className={css.content}>
        {logo}
        <Button
          type="text"
          className={css.expander}
          onClick={() => setIsExpanded((state) => !state)}
        >
          Menu
          <MenuOutlined />
        </Button>
      </div>
    </div>
  )
}
