import { HeatmapLayer } from '@deck.gl/aggregation-layers'
import _ from 'lodash'
import { InterpolatedData, SensorData, SensorParameter } from 'swanviz'

import { RGBColor } from '../utils/colors'
import {
  getRelativeWeight,
  makeColorRange,
  WEIGHT_ABSOLUTE_MAX,
} from './heatmapHelpers'

export const getHeatmapLayer = <T extends SensorData | InterpolatedData>({
  runId,
  data,
  sensor,
  color,
  visible = true,
}: {
  runId: number | string
  data: T[]
  sensor: SensorParameter
  color: RGBColor
  visible?: boolean
}): HeatmapLayer<T> => {
  const [sensorMin, sensorMax] = getSensorHeatmapDataRange(data, sensor)

  const getWeight = ({ sensorParamValue }: SensorData | InterpolatedData) =>
    _.isNumber(sensorParamValue)
      ? Math.round(
          WEIGHT_ABSOLUTE_MAX *
            getRelativeWeight(sensorParamValue, sensorMin, sensorMax)
        )
      : 0
  const maxWeight = Math.max(...data.map(getWeight))

  return new HeatmapLayer({
    id: `heatmap run(${runId})`,
    data,
    aggregation: 'MEAN',
    radiusPixels: 25,
    threshold: 0,
    getPosition: (datum) => datum.location,
    getWeight,
    colorRange: makeColorRange(
      [...color, 0],
      [...color, 255],
      maxWeight / WEIGHT_ABSOLUTE_MAX
    ),
    visible,
  })
}

export const getSensorHeatmapDataRange = (
  data: (SensorData | InterpolatedData)[] | undefined,
  sensor: SensorParameter
): [number, number] => {
  const sensorRange: [number, number] = [sensor.min, sensor.max]

  if (!data?.length) {
    return sensorRange
  }

  const sensorValues = data
    .map(({ sensorParamValue }) => sensorParamValue)
    .filter(_.isNumber)
  const dataMin = Math.min(...sensorValues)
  const dataMax = Math.max(...sensorValues)
  return dataMin === dataMax ? sensorRange : [dataMin, dataMax]
}
