import React from 'react'
import {
  Area,
  MissionRun,
  MissionType,
  SensorParameter,
  SwanBot,
} from 'swanviz'

import { SelectedHeatmapProvider } from '../../../../contexts/selectedHeatmapContext'
import { useSensorColors } from '../../../../utils/useSensorColors'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { RunPathsFetcher } from '../../../RunPathsFetcher'
import { RunsList } from '../../../RunsList'
import { SensorDataFetcher } from '../../../SensorDataFetcher'
import { DataVisMap } from '../DataVisMap'
import { DesktopLayout } from '../DesktopLayout'
import { DownloadButton } from '../DownloadButton'
import { DataForm } from '../Form'
import { InterpolatedLink } from '../InterpolatedLink'
import { MissionRunUpdater } from '../MissionRunUpdater'
import { MissionsSummary } from '../MissionsSummary'
import { MobileLayout } from '../MobileLayout'

import { getSelectedMissionRunsAndSensors, toggleIsPathShown } from './helpers'
import { useDataVisFilter, useMissionRunFilters } from './useFilterQuery'
import { useMissionRuns } from './useMissionRuns'

type Props = {
  areas: Area[]
  missions: MissionType[]
  swanBots: SwanBot[]
  sensors: SensorParameter[]
}

export const DataVisPageContent: React.FC<Props> = ({
  areas,
  missions,
  swanBots,
  sensors,
}) => {
  const { isDesktop } = useWindowSize()
  const { dataVisFilter, setDataVisFilter } = useDataVisFilter({
    areas,
    missions,
    swanBots,
  })
  const showCharts = dataVisFilter.displayAs === 'plot'
  const Layout = isDesktop ? DesktopLayout : MobileLayout

  const {
    missionRuns,
    updateMissionRun,
    isError: isMissionRunsError,
    isLoading: isMissionRunsLoading,
  } = useMissionRuns(dataVisFilter)

  const { missionRunFilters, setMissionRunFilters } = useMissionRunFilters({
    missionRuns,
  })

  const currentArea = areas.find((area) => area.id === dataVisFilter.areaId)

  const selectedMissionRunsAndSensors = React.useMemo(
    () =>
      getSelectedMissionRunsAndSensors(missionRuns || [], missionRunFilters),
    [missionRuns, missionRunFilters]
  )
  const missionRunStatuses = React.useMemo(
    () =>
      Object.fromEntries(
        missionRuns?.map((run) => [run.runId, run.status]) ?? []
      ),
    [missionRuns]
  )
  const visibleMissionRunFilters = React.useMemo(
    () => missionRunFilters.filter((filter) => filter.showPath),
    [missionRunFilters]
  )

  const { getSensorColor, setSensorColor } = useSensorColors(missionRunFilters)

  const handleShownPathChange = (
    runId: MissionRun['runId'],
    isChecked: boolean
  ) => {
    setMissionRunFilters(toggleIsPathShown(missionRunFilters, runId, isChecked))
  }

  return (
    <>
      <SensorDataFetcher
        missionRunFilters={missionRunFilters}
        missionRunStatuses={missionRunStatuses}
      >
        {({ sensorDataByRun, onRunIsPausedChange }) => (
          <SelectedHeatmapProvider>
            <Layout
              filters={
                <DataForm
                  values={dataVisFilter}
                  areas={areas}
                  swanBots={swanBots}
                  missions={missions}
                  anyMissionRunSelected={missionRunFilters.length > 0}
                  onValuesChange={setDataVisFilter}
                />
              }
              runs={
                <RunsList
                  missionRuns={missionRuns || []}
                  selection={missionRunFilters}
                  sensors={sensors}
                  isLoading={isMissionRunsLoading}
                  isError={isMissionRunsError}
                  onChange={setMissionRunFilters}
                />
              }
              map={
                currentArea ? (
                  <RunPathsFetcher
                    missionRunFilters={visibleMissionRunFilters}
                    missionRunStatuses={missionRunStatuses}
                  >
                    {(pathsByRun) => (
                      <DataVisMap
                        missionRuns={selectedMissionRunsAndSensors}
                        missionRunFilters={missionRunFilters}
                        area={currentArea}
                        sensorData={showCharts ? {} : sensorDataByRun}
                        sensors={sensors}
                        getSensorColor={getSensorColor}
                        pathsByRun={pathsByRun}
                        swanBots={swanBots}
                      />
                    )}
                  </RunPathsFetcher>
                ) : null
              }
              summary={
                <MissionsSummary
                  withGraph={showCharts}
                  missionRuns={selectedMissionRunsAndSensors}
                  sensors={sensors}
                  sensorData={sensorDataByRun}
                  missionRunFilters={missionRunFilters}
                  hideMap={Boolean(dataVisFilter.hideMap)}
                  onIsPathShownChange={handleShownPathChange}
                  onChangeColor={setSensorColor}
                  getSensorColor={getSensorColor}
                  onRunIsPausedChange={onRunIsPausedChange}
                  onFiltersChange={setMissionRunFilters}
                />
              }
              interpolatedDataLink={
                currentArea && (
                  <InterpolatedLink
                    dataVisFilter={dataVisFilter}
                    area={currentArea}
                    missions={missions}
                    swanBots={swanBots}
                    selectedMissionRuns={selectedMissionRunsAndSensors}
                    sensors={sensors}
                  />
                )
              }
              downloadButton={
                currentArea && (
                  <DownloadButton
                    area={currentArea}
                    datesRange={dataVisFilter.datesRange}
                    sensorDataByRun={sensorDataByRun}
                  />
                )
              }
              missionRunFilters={missionRunFilters}
              displayAs={dataVisFilter.displayAs}
              onDisplayAsChange={(newValue) =>
                setDataVisFilter({ ...dataVisFilter, displayAs: newValue })
              }
            />
          </SelectedHeatmapProvider>
        )}
      </SensorDataFetcher>

      {missionRuns?.map((missionRun) => (
        <MissionRunUpdater
          key={missionRun.runId}
          missionRun={missionRun}
          onChange={updateMissionRun}
        />
      ))}
    </>
  )
}
