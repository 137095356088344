import React from 'react'
import { Area, Geofence, InterpolatedData, SensorParameter } from 'swanviz'

import type { InterpolationRunInfo } from '../'
import { getGeofenceLayer } from '../../../../geoLayers/geofenceLayer'
import { getHeatmapLayer } from '../../../../geoLayers/heatmapLayer'
import { getMissionRunPathLayer } from '../../../../geoLayers/missionRunPathLayer'
import { darken, hexToRgb, RGBColor, rgbToHex } from '../../../../utils/colors'
import { isDefined } from '../../../../utils/typeGuards'
import { GetSensorColor } from '../../../../utils/useSensorColors'
import { Map } from '../../../Map'
import { PathsByRun } from '../../../RunPathsFetcher'
import { SensorDataByRun } from '../../../SensorDataFetcher/types'

type Props = {
  runInfos: InterpolationRunInfo[]
  pathsByRun: PathsByRun
  sensorDataByRun: SensorDataByRun
  interpolatedData: InterpolatedData[] | undefined
  interpolationColor: string
  sensor: SensorParameter
  area: Area
  geofence: Geofence
  getSensorColor: GetSensorColor
}

export const InterpolationMap: React.FC<Props> = ({
  runInfos,
  pathsByRun,
  sensorDataByRun,
  interpolatedData,
  interpolationColor,
  sensor,
  area,
  geofence,
  getSensorColor,
}) => {
  const geofenceLayer = getGeofenceLayer(geofence)
  const runLayers = runInfos.flatMap((runInfo) => {
    const { runId } = runInfo.run
    const rgbColor = getSensorColor(runId, sensor.id)

    const runPath = pathsByRun[runId]
    const pathLayer =
      runPath &&
      getMissionRunPathLayer({
        id: runId,
        actualPath: runPath,
        visible: runInfo.visible,
        hexColor: rgbToHex(heatmapColorToPathColor(rgbColor)),
      })

    const heatmapData =
      sensorDataByRun[runInfo.run.runId]?.dataBySensor[sensor.id]?.data
    const heatmapLayer = heatmapData?.length
      ? getHeatmapLayer({
          runId,
          data: heatmapData,
          sensor,
          color: rgbColor,
          visible: runInfo.visible,
        })
      : undefined

    return [heatmapLayer, pathLayer]
  })

  const interpolatedHeatmapLayer =
    interpolatedData &&
    getHeatmapLayer({
      runId: 'interpolated',
      data: interpolatedData,
      sensor,
      color: hexToRgb(interpolationColor),
    })

  return (
    <Map
      longitude={area.coordinates[0]}
      latitude={area.coordinates[1]}
      layers={[geofenceLayer, interpolatedHeatmapLayer, ...runLayers].filter(
        isDefined
      )}
    />
  )
}

export const heatmapColorToPathColor = (color: RGBColor): RGBColor =>
  darken(color, 0.2)
