import React from 'react'
import { Button } from 'antd'

import { ReactComponent as CrossSVG } from './cross.svg'

export const CloseButton: React.FC<React.ComponentProps<typeof Button>> = (
  props
) => {
  return <Button {...props} type="text" icon={<CrossSVG />} />
}
