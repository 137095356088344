import _ from 'lodash'
import React from 'react'
import {
  getSensorData,
  getSensorDataRange,
  MissionRunFilter,
  SensorData,
} from 'swanviz'
import useSWR, { cache, keyInterface } from 'swr'

import { SENSOR_DATA_POLL_INTERVAL } from '../../../poll-config'
import { FetchingType } from '../../../utils/fetchingType'

type Props = {
  runId: number
  sensorId: number
  fetchingType: FetchingType
  forcePause: boolean
  onSuccess: (data: SensorData[]) => void
  onError: () => void
}

export const SingleSensorDataFetcher: React.FC<Props> = ({
  runId,
  sensorId,
  fetchingType,
  forcePause,
  onSuccess,
  onError,
}) => {
  const isLive = fetchingType !== 'once'
  const swrKey: keyInterface = ['getSensorData', runId, sensorId, isLive]
  const { data } = useSWR<SensorData[]>(
    swrKey,
    async () => {
      const cachedData: SensorData[] | undefined = cache.get(swrKey)
      const filters: MissionRunFilter[] = [
        {
          runId,
          sensorParamList: [sensorId],
        },
      ]

      if (isLive) {
        const previousData = cachedData || []
        const latestItem = _.last(previousData)
        const lastUpdated = latestItem ? latestItem.time + 1 : 1

        const newDataBatch = (
          await getSensorDataRange(filters, lastUpdated, Date.now()).catch(
            (err) => {
              console.error(err)
              throw err
            }
          )
        )
          // Filtering can be removed when issue is resolved: https://github.com/subnero1/swanviz/issues/153
          .filter((sensorDatum) => sensorDatum.time >= lastUpdated)

        return previousData.concat(_.sortBy(newDataBatch, 'time'))
      } else {
        return cachedData || getSensorData(filters)
      }
    },
    {
      refreshInterval:
        fetchingType === 'liveActive' && !forcePause
          ? SENSOR_DATA_POLL_INTERVAL
          : 0,
      onError: (err) => {
        console.error(err)
        onError()
      },
    }
  )

  React.useEffect(() => {
    if (data) {
      onSuccess(data)
    }
  }, [data])

  return null
}
