import { useLocation } from 'react-router-dom'

export type QueryObject = { [key: string]: string }

const urlSearchParamsToObject = (params: URLSearchParams) => {
  const result: QueryObject = {}
  for (const [key, value] of params.entries()) {
    result[key] = value
  }
  return result
}

export const useQuery = () => {
  return urlSearchParamsToObject(new URLSearchParams(useLocation().search))
}
