import { DrawPolygonMode, ModifyMode, ViewMode } from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer, HandlePointFeature } from '@nebula.gl/layers'
import _ from 'lodash'
import React from 'react'
import { Area, Boundary, Coordinates } from 'swanviz'

import type { HoveredPolygonId } from '../'
import { commonEditableLayerGuidesProps } from '../../../../geoLayers/editableLayer'
import { BLUE_RGB, RGBColor } from '../../../../utils/colors'
import { createPolygonFeature } from '../../../../utils/geojson'
import { getNextId } from '../../../../utils/id'
import { usePermissions } from '../../../../utils/usePermissions'
import { Map } from '../../../Map'

type Props = {
  area: Area
  boundaries: Boundary[]
  hoveredPolygonId: HoveredPolygonId
  isAddingNewPolygon: boolean
  onHover: (id: HoveredPolygonId) => void
  onChange: (boundaries: Boundary[]) => void
  onAdd: (boundary: Boundary) => void
}

const LIGHT_BLUE_RGB: RGBColor = [0, 142, 169]

export const GeofenceMap: React.FC<Props> = ({
  area,
  boundaries,
  hoveredPolygonId,
  isAddingNewPolygon,
  onHover,
  onChange,
  onAdd,
}) => {
  const {
    geofences: { canManage },
  } = usePermissions()
  const features = boundaries.map((polygon) =>
    createPolygonFeature([polygon.polygon], { id: polygon.id })
  )

  const isFeatureHovered = (feature: typeof features[number]) =>
    isAddingNewPolygon ? false : feature.properties?.id === hoveredPolygonId

  const getMode = () => {
    if (!canManage) {
      return ViewMode
    }
    if (isAddingNewPolygon) {
      return DrawPolygonMode
    }
    return ModifyMode
  }

  const geofenceLayer = new EditableGeoJsonLayer({
    id: 'geofence',
    data: {
      type: 'FeatureCollection',
      features,
    },
    mode: getMode(),
    getLineColor: (feature) =>
      isFeatureHovered(feature) ? BLUE_RGB : LIGHT_BLUE_RGB,
    getLineWidth: (feature) => (isFeatureHovered(feature) ? 3 : 1),
    getFillColor: (feature) => [
      ...(isFeatureHovered(feature) ? BLUE_RGB : LIGHT_BLUE_RGB),
      0.1 * 255,
    ],
    _subLayerProps: {
      // Styling edit handles
      guides: {
        ...commonEditableLayerGuidesProps,
        getFillColor: (feature: HandlePointFeature) => {
          const isExistingPoint =
            feature.properties.editHandleType === 'existing'
          const associatedPolygonFeature =
            features[feature.properties.featureIndex]
          return [
            ...(isAddingNewPolygon || isFeatureHovered(associatedPolygonFeature)
              ? BLUE_RGB
              : [101, 173, 186]),
            (isExistingPoint ? 1 : 0.2) * 255,
          ]
        },
      },
    },
    pickable: true,
    selectedFeatureIndexes: boundaries.map((polygon, idx) => idx),
    onHover: (pickInfo) => {
      if (isAddingNewPolygon) {
        return
      }
      const hoveredFeature = pickInfo.object
      if (hoveredFeature && isHandlePointFeature(hoveredFeature)) {
        const associatedPolygon =
          boundaries[hoveredFeature.properties.featureIndex]
        onHover(associatedPolygon.id)
      } else {
        onHover(hoveredFeature?.properties?.id)
      }
    },
    onEdit: ({ updatedData, editType }) => {
      if (isAddingNewPolygon) {
        if (editType !== 'addFeature') return

        const newFeature = _.last(updatedData.features)
        const coordinates =
          newFeature?.geometry.type === 'Polygon'
            ? (newFeature.geometry.coordinates[0] as Coordinates[])
            : null

        if (!coordinates) return

        const newPolygonId = getNextId(boundaries.map((p) => p.id))

        onAdd({
          id: newPolygonId,
          name: boundaries.length ? 'New obstruction' : 'Geofence',
          polygon: coordinates,
        })
      } else {
        const newBoundaries = boundaries.map((boundary, idx): Boundary => {
          const updatedFeature = updatedData.features[idx]
          return updatedFeature && updatedFeature.geometry.type === 'Polygon'
            ? {
                ...boundary,
                polygon: updatedFeature.geometry
                  .coordinates[0] as Coordinates[],
              }
            : boundary
        })
        onChange(newBoundaries)
      }
    },
  })

  const getMessage = () => {
    if (!canManage) {
      return undefined
    }
    if (isAddingNewPolygon) {
      return `Draw ${
        boundaries.length > 0 ? 'an obstruction' : 'a geofence'
      } polygon on the map, double-click to end drawing`
    }
    return 'Modify polygon points on the map: drag to move, click to remove or add'
  }

  return (
    <Map
      longitude={area.coordinates[0]}
      latitude={area.coordinates[1]}
      layers={[geofenceLayer]}
      getCursor={geofenceLayer.getCursor.bind(geofenceLayer)}
      message={getMessage()}
    />
  )
}

const isHandlePointFeature = (
  feature: GeoJSON.Feature
): feature is HandlePointFeature =>
  feature.geometry.type === 'Point' && feature.properties?.editHandleType
