import _ from 'lodash'
import React from 'react'
import { MissionRunFilter } from 'swanviz'

import { updateAt } from './array'
import { BLUE_RGB, getRandomColor, hexToRgb, RGBColor } from './colors'
import { useLocalStorageState } from './useLocalStorageState'

type RunId = number
type SensorId = number
export type SensorColors = Array<[RunId, SensorId, RGBColor]>

export type SetSensorColor = (
  runId: RunId,
  sensorId: SensorId
) => (newColor: string) => void

export type GetSensorColor = (runId: RunId, sensorId: SensorId) => RGBColor

const COLORS_STORAGE_KEY = 'sensor_colors'
const COLORS_TO_STORE = 500

export const useSensorColors = (
  missionRunFilters: MissionRunFilter[]
): {
  getSensorColor: GetSensorColor
  setSensorColor: SetSensorColor
} => {
  const [sensorColors, setSensorColors] = useLocalStorageState<SensorColors>(
    COLORS_STORAGE_KEY,
    []
  )

  React.useEffect(() => {
    setSensorColors((state) => {
      const newSensorColors = addSensorColorsForFilters(
        state,
        missionRunFilters
      )
      return _.takeRight(newSensorColors, COLORS_TO_STORE)
    })
  }, [missionRunFilters])

  return {
    getSensorColor: React.useCallback<GetSensorColor>(
      (runId, sensorId) =>
        getSensorColor(sensorColors, runId, sensorId) || BLUE_RGB,
      [sensorColors]
    ),
    setSensorColor: React.useCallback<SetSensorColor>(
      (runId, sensorId) => (newColor) => {
        const newColorRgb = hexToRgb(newColor)
        setSensorColors((state) => {
          const index = state.findIndex(
            (item) => item[0] === runId && item[1] === sensorId
          )
          return updateAt(state, index, [runId, sensorId, newColorRgb])
        })
      },
      [setSensorColors]
    ),
  }
}

const getSensorColor = (
  state: SensorColors,
  runId: RunId,
  sensorId: SensorId
): RGBColor | undefined =>
  state.find(
    ([colorRunId, colorSensorId]) =>
      colorRunId === runId && colorSensorId === sensorId
  )?.[2]

export const addSensorColorsForFilters = (
  state: SensorColors,
  missionRunFilters: MissionRunFilter[]
): SensorColors => {
  const newSensorColors: SensorColors = missionRunFilters.flatMap(
    ({ runId, sensorParamList }) =>
      sensorParamList
        .filter((sensorId) => !getSensorColor(state, runId, sensorId))
        .map((sensorId) => [runId, sensorId, getRandomColor()])
  )

  return newSensorColors.length ? state.concat(newSensorColors) : state
}
