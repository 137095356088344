import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { MissionRun, SensorData, SensorParameter } from 'swanviz'

import { useHoveredChartPoint } from '../../../../contexts/hoveredChartPoint'
import { useDeepMemoizedValue } from '../../../../utils/useDeepMemoizedValue'
import { ApexChartsData, ChartRange, LinearChart } from '../LinearChart'

export const SensorChart: React.FC<{
  missionRun: MissionRun
  sensor: SensorParameter
  data: SensorData[]
  color: string
  xRange: ChartRange
  disableZoom: boolean
  onChangeColor: (color: string) => void
}> = ({
  missionRun: { runId, runDate, missionName, sensorIds },
  sensor,
  data: unmemoizedData,
  color,
  xRange,
  disableZoom,
  onChangeColor,
}) => {
  const data = useDeepMemoizedValue(unmemoizedData)
  const filename = getFilename({
    runId,
    runDate,
    missionName,
    sensorName: sensor.name,
  })

  const chartData: ApexChartsData = data.map(({ time, sensorParamValue }) => [
    time,
    _.isNumber(sensorParamValue) ? sensorParamValue : null,
  ])

  const { setHoveredPoint } = useHoveredChartPoint()
  const handleChartHover = React.useCallback(
    (pointIdx: number) => {
      const hoveredDatum = data[pointIdx]
      setHoveredPoint(hoveredDatum?.location)
    },
    [setHoveredPoint, data]
  )

  // Include sensorIds into group name to trigger charts update when new sensor is added
  const groupName = `${runId}x${sensorIds.length}`

  return (
    <div onMouseLeave={() => setHoveredPoint(undefined)}>
      <LinearChart
        sensor={sensor}
        filename={filename}
        color={color}
        group={groupName}
        data={chartData}
        xRange={xRange}
        disableZoom={disableZoom}
        onHover={handleChartHover}
        onChangeColor={onChangeColor}
      />
    </div>
  )
}

const getFilename = ({
  runDate,
  missionName,
  sensorName,
}: {
  runId: number
  runDate: number
  sensorName: string
  missionName: string
}) => {
  // Not a regular dash to properly show in downloaded file name
  const FILENAME_DIVIDER = '–'

  return [
    sensorName,
    _.upperFirst(_.camelCase(missionName)),
    moment(runDate).format(`MMMD`),
    moment(runDate).format(`HHmm`),
  ].join(FILENAME_DIVIDER)
}
