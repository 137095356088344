import { Dropdown, Menu } from 'antd'
import classnames from 'classnames'
import React from 'react'
import { useLocation } from 'react-router-dom'

import type { LayoutProps } from '../'
import { ReactComponent as ArrowIcon } from '../../../icons/arrow.svg'
import css from './style.module.css'

export const DesktopLayout: React.FC<LayoutProps> = ({
  logo,
  renderNavLinks,
  renderUserLinks,
  userFullName,
}) => {
  const location = useLocation()

  const userMenu = (
    <Menu>
      {renderUserLinks({ className: css.dropdownLink }).map((linkNode, idx) => (
        <Menu.Item key={idx}>{linkNode}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div className={css.main}>
      <div className={css.logo}>{logo}</div>
      <nav className={css.nav}>
        {renderNavLinks({ className: css.link, activeClassName: css.active })}
      </nav>
      {userFullName && (
        <Dropdown
          overlay={userMenu}
          placement="bottomRight"
          overlayClassName={css.dropdown}
          trigger={['click']}
        >
          <span
            className={classnames(
              css.dropdownOpener,
              css.link,
              location.pathname.includes('settings') && css.active
            )}
          >
            <span className={css.name}>{userFullName}</span>
            <ArrowIcon className={css.icon} />
          </span>
        </Dropdown>
      )}
    </div>
  )
}
