import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

const UNSAVED_WARNING_TEXT = 'You have unsaved changes. Close anyway?'

export const useCheckUnsavedChanges = <T>({
  value,
  isDisabled = false,
}: {
  value: T
  isDisabled?: boolean
}) => {
  const location = useLocation()
  const history = useHistory()
  const backupRef = React.useRef<T>()

  const setBackupValue = (initialvalue: T | undefined) =>
    (backupRef.current = initialvalue)

  const checkShouldBlock = () =>
    !isDisabled && backupRef.current && !_.isEqual(value, backupRef.current)

  React.useEffect(() => {
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      if (checkShouldBlock()) {
        event.preventDefault()
        event.returnValue = UNSAVED_WARNING_TEXT
        return UNSAVED_WARNING_TEXT
      }
    }

    // `beforeunload` blocks the page when we try refresh or close page
    window.addEventListener('beforeunload', beforeUnloadHandler)

    // `history.block` blocks the page when we try change router, e.g. clicking a link, clicking the back button.
    const historyCallback = history.block((newLocation, action) => {
      if (
        (action === 'POP' ||
          newLocation.pathname !== location.pathname ||
          newLocation.search !== location.search) &&
        checkShouldBlock()
      ) {
        return UNSAVED_WARNING_TEXT
      }
    })

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
      historyCallback()
    }
  }, [value, isDisabled])

  return {
    setBackupValue,
    hasChanges: checkShouldBlock(),
    backupValue: backupRef.current,
  }
}
