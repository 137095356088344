import { getAllSensorParameters, getDataSummary, SwanBot } from 'swanviz'
import useSWR from 'swr'
import _ from 'lodash'
import type { SensorParameter, DataSummary } from 'swanviz'

import { SWANBOT_SENSORS_DATA_POLL_INTERVAL } from '../../../poll-config'
import { prettyRound } from '../../../utils/numbers'
import { isDefined, isNotNil } from '../../../utils/typeGuards'

export type SensorInfo = {
  id: number
  chartData: [number?, number?][]
  name: string
  latestData?: string
}

type ReturnType = {
  sensors: SensorInfo[]
  isLoading: boolean
  isError: boolean
}

const getPopulatedSensorData = ({
  dataSummaries,
  sensorParameters,
}: {
  dataSummaries: DataSummary[]
  sensorParameters: SensorParameter[]
}): SensorInfo[] => {
  const sensorParametersById = _.keyBy(sensorParameters, 'id')

  return dataSummaries
    .map((item) => {
      const sensorParameter = sensorParametersById[item.sensorId]
      const latestData = _.last(item.sensorValue)
      if (!sensorParameter) {
        return undefined
      }

      return {
        id: item.sensorId,
        chartData: _.zip(item.dataTime, item.sensorValue),
        name: sensorParameter.description,
        latestData: isNotNil(latestData)
          ? `${prettyRound(latestData)} ${sensorParameter.units}`
          : undefined,
      }
    })
    .filter(isDefined)
}

export const useSensorsTabData = (swanbot: SwanBot): ReturnType => {
  const runId = swanbot.currRunId

  const {
    data: sensorsData = [],
    isValidating: isDataLoading,
    error: sensorsDataError,
  } = useSWR(
    runId ? ['getDataSummary', swanbot.id] : null,
    () => getDataSummary(swanbot.id),
    {
      refreshInterval: runId ? SWANBOT_SENSORS_DATA_POLL_INTERVAL : 0,
    }
  )

  const {
    data: sensorParameters = [],
    isValidating: areSensorsLoading,
    error: sensorsError,
  } = useSWR(runId ? 'getAllSensorParameters' : null, getAllSensorParameters)

  return {
    sensors: getPopulatedSensorData({
      dataSummaries: sensorsData,
      sensorParameters,
    }),
    isLoading: isDataLoading || areSensorsLoading,
    isError: Boolean(sensorsError || sensorsDataError),
  }
}
