import {
  decodeNumber,
  decodeString,
  encodeNumber,
  encodeString,
} from 'use-query-params'

export const NEW = 'new'

export const NewOrNumberParam = {
  encode: (
    value: typeof NEW | number | null | undefined
  ): string | null | undefined =>
    value === NEW ? encodeString(value) : encodeNumber(value),
  decode: (
    value: string | (string | null)[] | null | undefined
  ): string | number | null | undefined =>
    value === NEW ? decodeString(value) : decodeNumber(value),
}
