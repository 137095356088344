import { Tooltip, Typography } from 'antd'
import {
  ColumnFilterItem,
  ColumnsType,
  SortOrder,
} from 'antd/es/table/interface'
import _ from 'lodash'
import React from 'react'
import { NotificationFilter, SwanBot } from 'swanviz'

import { formatType, NOTIFICATION_TYPES } from '../../../../utils/notifications'
import { DateTimeDisplayObject } from '../../../../utils/time'
import { SwanBotIcon } from '../../../SwanBotIcon'
import { TableFilterIcon } from '../../../TableFilterIcon'
import { PopulatedNotification } from '../useAlertsPageData'
import css from './style.module.css'

type TableKey = keyof PopulatedNotification

export const DATA_INDEX_TIME: TableKey = 'ntfTime'
export const DATA_INDEX_TYPE: TableKey = 'type'
export const DATA_INDEX_AREA: TableKey = 'area'
export const DATA_INDEX_SWANBOT: TableKey = 'swanBot'

export const FILTER_SORT_FIELDS: {
  [key in TableKey]?: NotificationFilter['sortField']
} = {
  [DATA_INDEX_TIME]: 'ntf_time',
  [DATA_INDEX_TYPE]: 'type',
  [DATA_INDEX_AREA]: 'area_id',
  [DATA_INDEX_SWANBOT]: 'swanbot_id',
}

export const getColumns = ({
  dateTimeObjects,
  filter,
  areaFilters,
  swanBotFilters,
  isDesktop,
}: {
  dateTimeObjects: DateTimeDisplayObject[]
  filter: NotificationFilter
  areaFilters: ColumnFilterItem[]
  swanBotFilters: ColumnFilterItem[]
  isDesktop: boolean
}): ColumnsType<PopulatedNotification> => {
  const getSortOrder = (tableKey: TableKey): SortOrder => {
    if (
      filter.sortField === FILTER_SORT_FIELDS[tableKey] &&
      filter.sortDirection
    ) {
      return filter.sortDirection === 'ASC' ? 'ascend' : 'descend'
    }
    return null
  }

  const columns: ColumnsType<PopulatedNotification> = [
    {
      title: 'Date and time',
      dataIndex: DATA_INDEX_TIME,
      sorter: isDesktop,
      align: 'right',
      sortOrder: getSortOrder(DATA_INDEX_TIME),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      width: isDesktop ? 170 : 150,
      className: css.dateCell,
      render: (
        value: PopulatedNotification[typeof DATA_INDEX_TIME],
        row,
        idx
      ) => {
        const { date, time } = dateTimeObjects[idx]

        return (
          <Typography.Text type={isDangerType(row.type) ? 'danger' : undefined}>
            {date && <span>{date}</span>}
            <span className={css.time}>{time}</span>
          </Typography.Text>
        )
      },
    },
    {
      title: 'Alerts and notifications',
      dataIndex: 'message',
      width: isDesktop ? 340 : 0,
      ellipsis: !isDesktop,
      render: (_value, { message, type, taskType, swanBot, area }) => {
        const text = [message, taskType ? `(${taskType})` : null]
          .filter(Boolean)
          .join(' ')
        const textNode = (
          <Typography.Text type={isDangerType(type) ? 'danger' : undefined}>
            {text}
          </Typography.Text>
        )

        return isDesktop ? (
          textNode
        ) : (
          <Tooltip title={text} trigger={['click']}>
            {textNode}
            <SwanBotCell swanBot={swanBot} />
            {area.name}
          </Tooltip>
        )
      },
    },
    {
      title: 'Type of notification',
      dataIndex: DATA_INDEX_TYPE,
      sorter: true,
      sortOrder: getSortOrder(DATA_INDEX_TYPE),
      width: 220,
      filteredValue: filter.type || [],
      filters: NOTIFICATION_TYPES.map((value) => ({
        text: formatType(value),
        value,
      })),
      filterIcon: TableFilterIcon,
      render: formatType,
    },
    {
      title: 'Area',
      dataIndex: DATA_INDEX_AREA,
      sorter: true,
      sortOrder: getSortOrder(DATA_INDEX_AREA),
      width: 130,
      filteredValue: filter.areaId?.map(String) || [],
      filters: areaFilters,
      filterIcon: TableFilterIcon,
      render: (value: PopulatedNotification[typeof DATA_INDEX_AREA]) =>
        value.name,
    },
    {
      title: 'SwanBot',
      dataIndex: DATA_INDEX_SWANBOT,
      sorter: true,
      sortOrder: getSortOrder(DATA_INDEX_SWANBOT),
      width: 240,
      filteredValue: filter.swanbotId?.map(String) || [],
      filters: swanBotFilters,
      filterIcon: TableFilterIcon,
      render: (value: PopulatedNotification[typeof DATA_INDEX_SWANBOT]) => (
        <SwanBotCell swanBot={value} />
      ),
    },
    // Without empty column SwanBot column will take whole leftover width
    // and its filter icon will be on the far right side of the screen
    {
      title: '',
    },
  ]

  return isDesktop ? columns : _.take(columns, 2)
}

const isDangerType = (type: PopulatedNotification['type']): boolean =>
  type === 'SENSOR DATA'

const SwanBotCell: React.FC<{ swanBot: SwanBot }> = ({ swanBot }) => (
  <div className={css.swanBot}>
    <SwanBotIcon swanBot={swanBot} className={css.swanBotIcon} />
    <span className={css.swanBotName}>{swanBot.name}</span>
  </div>
)
