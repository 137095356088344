import { WebMercatorViewport } from '@deck.gl/core'
import React from 'react'
import type { ViewStateProps as ViewState } from '@deck.gl/core/lib/deck'

import css from './style.module.css'

type Props = {
  mapSize: {
    width: number
    height: number
  }
  viewState: ViewState
}

const MAX_WIDTH = 100

export const ScaleRuler: React.FC<Props> = ({ mapSize, viewState }) => {
  const viewport = new WebMercatorViewport({
    ...mapSize,
    ...viewState,
  })

  const maxLengthInMeters = MAX_WIDTH * viewport.metersPerPixel
  const lengthInMeters = getRoundedDownMeters(maxLengthInMeters)
  const lengthInPixels = lengthInMeters / viewport.metersPerPixel

  return (
    <div className={css.main} style={{ width: lengthInPixels }}>
      {formatMeters(lengthInMeters)}
    </div>
  )
}

// Rounding down to closest number starting with 5/3/2/1
// {@link https://github.com/mapbox/mapbox-gl-js/blob/c032b8d22fb9d68c5df02fd65cf21fea5ddd3055/src/ui/control/scale_control.js#L131-L142 Rounding logic from MapBox's ScaleControl}
export const getRoundedDownMeters = (value: number): number => {
  const exponent = Math.floor(Math.log10(value))
  let firstDigit = Math.floor(value / Math.pow(10, exponent))

  if (firstDigit >= 5) firstDigit = 5
  else if (firstDigit >= 3) firstDigit = 3
  else if (firstDigit >= 2) firstDigit = 2
  else firstDigit = 1

  return firstDigit * Math.pow(10, exponent)
}

export const formatMeters = (value: number): string => {
  return value >= 1000 ? `${value / 1000} km` : `${value} m`
}
