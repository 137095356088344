import {
  Area,
  Geofence,
  getActiveSwanBotsForUser,
  getAllGeofences,
  getAreaList,
  SwanBot,
} from 'swanviz'
import useSWR from 'swr'

import {
  GEOFENCES_POLL_INTERVAL,
  SWANBOTS_POLL_INTERVAL,
} from '../../../poll-config'

type MissionDictionaries = {
  geofences: Geofence[]
  areas: Area[]
  swanBots: SwanBot[]
}

type UseMissionDictionaries =
  | {
      loading: false
      error: false
      data: MissionDictionaries
    }
  | {
      loading: true
      error: false
      data?: undefined
    }
  | {
      loading: false
      error: true
      data?: undefined
    }

export const useMissionDictionaries = (): UseMissionDictionaries => {
  const { data: areas, isValidating: isAreaListLoading } = useSWR(
    'getAreaList',
    getAreaList
  )
  const { data: swanBots, isValidating: isAllSwanBotsLoading } = useSWR(
    'getActiveSwanBotsForUser',
    getActiveSwanBotsForUser,
    {
      refreshInterval: SWANBOTS_POLL_INTERVAL,
    }
  )
  const { data: geofences, isValidating: isGeofencesLoading } = useSWR(
    'getAllGeofences',
    getAllGeofences,
    {
      refreshInterval: GEOFENCES_POLL_INTERVAL,
    }
  )

  const isLoading =
    isAreaListLoading ||
    (isAllSwanBotsLoading && !swanBots) ||
    (isGeofencesLoading && !geofences)

  if (isLoading) {
    return {
      loading: true,
      error: false,
    }
  }

  if (!areas || !swanBots || !geofences) {
    return {
      loading: false,
      error: true,
    }
  }

  return {
    data: {
      areas,
      geofences,
      swanBots,
    },
    loading: false,
    error: false,
  }
}
