import { HtmlOverlay, HtmlOverlayItem } from '@nebula.gl/overlays'
import React from 'react'
import { Area } from 'swanviz'

import { useMapViewStateFittedToBounds } from '../../../../utils/useMapViewStateFittedToBounds'
import { Map } from '../../../Map'
import { ReactComponent as MarkerIcon } from './marker.svg'
import css from './style.module.css'

type Props = {
  areas: Area[]
}

export const DashboardMap: React.FC<Props> = ({ areas }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const viewState = useMapViewStateFittedToBounds({
    coordinates: areas.map((area) => area.coordinates),
    mapContainerRef: ref,
  })

  return (
    <div ref={ref} className={css.main}>
      {viewState && (
        <Map {...viewState} withStyleSwitcher={false} withZoomControls={false}>
          <HtmlOverlay zIndex={0}>
            {areas.map((area) => (
              <HtmlOverlayItem key={area.id} coordinates={area.coordinates}>
                <MarkerIcon
                  className={css.icon}
                  style={{ color: area.colorCode || undefined }}
                />
              </HtmlOverlayItem>
            ))}
          </HtmlOverlay>
        </Map>
      )}
    </div>
  )
}
