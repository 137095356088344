export const ERROR_MESSAGES = {
  userIsDisabled: 'Your account has been disabled.',
  logout: 'Failed to logout, please try again',
  login: 'Failed to login, please try again',
  invalidCredentials: 'Login failed: Invalid username or password.',
  reqForgotPassword: 'Failed to request password reset, please try again',
  resetPassword: 'Failed to reset password, please try again',
  twoFactorAuthChange: 'Failed to change 2FA status, please try again',
  activityLogDownload: "Couldn't start download, please try again",
  areaAdd: "Couldn't add new area, please try again",
  areaEdit: "Couldn't update area, please try again",
  areaDelete: "Couldn't delete area, please try again",
  geofenceUniqueName: 'Please check that geofence name is unique and try again',
  geofenceSave: "Couldn't save changes, please try again",
  geofenceDelete: "Couldn't delete geofence, please try again",
  geofenceImport:
    'Failed to import geofence, please check file content and try again',
  missionSave: "Couldn't save changes, please try again",
  missionDelete: "Couldn't delete mission, please try again",
  missionStatus: 'Failed to change mission status, please try again',
  missionTaskEstimate: (typeName: string) =>
    `Failed to get estimated distance and duration for "${typeName}" task`,
  userStatus: 'Failed to change user status, please try again',
  userUpdate: "Couldn't update user, please try again",
  userAdd: "Couldn't add user, please try again",
  userDelete: "Couldn't delete user, please try again",
  userSettingsUpdate: "Couldn't update user settings, please try again",
  swanBotAdd: 'Failed to add new SwanBot, please try again',
  swanBotUpdate: 'Failed to update SwanBot, please try again',
  swanBotDelete: 'Failed to delete SwanBot, please try again',
  alertSettingsUpdate: 'Failed to update alert settings, pleade try again',
  authenticationError:
    'Authentication error occurred. Please try to log in again.',
  authorizationError: 'Authorization error occurred.',
  localStorageUpdate:
    "Couldn't save data to localStorage. This could be because cookies and localStorage are disabled.",
  interpolationStart: "Couldn't start interpolation, please try again",
  interpolationCancel: "Couldn't cancel interpolation, please try again",
}
