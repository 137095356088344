import React from 'react'

import { PageWithMapLayout } from '../../../PageWithMapLayout'
import { LayoutProps } from '../types'
import css from './style.module.css'

export const DesktopLayout: React.FC<LayoutProps> = ({
  header,
  tasks,
  map,
  image,
  log,
}) => {
  return (
    <PageWithMapLayout
      className={css.main}
      header={<div className={css.header}>{header}</div>}
      aside={<div className={css.tasks}>{tasks}</div>}
      map={
        <>
          {map}
          {image}
          {log}
        </>
      }
    />
  )
}
