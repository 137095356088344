import { message, Spin } from 'antd'
import _ from 'lodash'
import React, { FunctionComponent, useEffect } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import {
  addMission,
  deleteMission,
  getMission,
  MissionTask,
  MissionType,
  NewMissionType,
  updateMission,
} from 'swanviz'
import useSWR from 'swr'

import { EstimatesUpdateProvider } from '../../../contexts/estimatesUpdatesContext'
import { HighlightedSurveyPointProvider } from '../../../contexts/highlightedSurveyPointContext'
import { MISSION_POLL_INTERVAL } from '../../../poll-config'
import { MISSION_DEFAULTS } from '../../../utils/defaults'
import { ERROR_MESSAGES } from '../../../utils/errorMessages'
import { getMissionLink } from '../../../utils/links'
import { isMissionRunning } from '../../../utils/missionRuns'
import { useCheckUnsavedChanges } from '../../../utils/useCheckUnsavedChanges'
import { usePermissions } from '../../../utils/usePermissions'
import { useQuery } from '../../../utils/useQuery'
import { useWindowSize } from '../../../utils/useWindowSize'
import { CenteredSpin } from '../../CenteredSpin'
import { ReloadPageResult } from '../../ReloadPageResult'

import {
  isExistingMission,
  parseMissionFromBackend,
  prepareMissionForBackend,
} from './helpers'
import { MissionPageContent } from './MissionPageContent'
import css from './style.module.css'
import { useMissionDictionaries } from './useMissionDictionaries'

const MISSION_MODES = ['view', 'edit'] as const
export type MissionPageMode = typeof MISSION_MODES[number]

export type HoveredTaskId = MissionTask['id'] | undefined
export type SelectedTaskId = MissionTask['id'] | undefined
type TaskEventHandler = (taskId: MissionTask['id'] | undefined) => void
export type OnTaskHover = TaskEventHandler
export type OnTaskSelect = TaskEventHandler

export type MissionTaskWithSpeed = MissionTask & {
  speed?: number
}

type WithTasksWithSpeed<T> = Omit<T, 'missionTasks'> & {
  missionTasks: MissionTaskWithSpeed[]
}

export type MissionTypeWithSpeed =
  | WithTasksWithSpeed<MissionType>
  | WithTasksWithSpeed<NewMissionType>

const EMPTY_MISSION: NewMissionType = {
  areaId: 0,
  missionTasks: [
    {
      id: 1,
      type: 'sensors',
      args: {
        enable: true,
      },
      estDuration: 0,
      estDistance: 0,
    },
  ],
  geofenceId: 0,
  name: '',
  nominalSpeed: MISSION_DEFAULTS.nominalSpeed.value,
  pointRadius: MISSION_DEFAULTS.pointRadius.value,
}

export const Mission: FunctionComponent = () => {
  const permissions = usePermissions()
  const { missionId } = useParams<{ missionId: string }>()
  const { mode: modeFromQuery } = useQuery()

  const shouldBeRedirected =
    !permissions.missions.canManage && modeFromQuery === 'edit'

  useEffect(() => {
    if (shouldBeRedirected) {
      message.warning(
        "You don't have permission to edit missions. You've been redirected to the mission page."
      )
    }
  }, [shouldBeRedirected])

  if (shouldBeRedirected) {
    return (
      <Redirect to={getMissionLink({ id: Number(missionId), mode: 'view' })} />
    )
  } else {
    return <MissionInner missionId={missionId} modeFromQuery={modeFromQuery} />
  }
}

type Props = {
  missionId: string
  modeFromQuery: string
}

const MissionInner: FunctionComponent<Props> = ({
  missionId,
  modeFromQuery,
}) => {
  const { isDesktop, isMobile } = useWindowSize()
  const history = useHistory()
  const mode =
    isDesktop && isMissionPageMode(modeFromQuery) ? modeFromQuery : 'view'
  const isNewMode = !missionId
  const isViewMode = Boolean(mode === 'view' && missionId)
  const [isMissionSaving, setIsMissionSaving] = React.useState(false)
  const [mission, setMission] =
    React.useState<MissionTypeWithSpeed>(EMPTY_MISSION)

  const missionDictionaries = useMissionDictionaries()
  const { areas, geofences, swanBots } = missionDictionaries.data || {}

  const { data: missionResponse, isValidating: isMissionLoading } = useSWR(
    missionId ? ['getMission', Number(missionId)] : null,
    () => getMission(Number(missionId)),
    {
      refreshInterval: isMissionRunning(mission) ? MISSION_POLL_INTERVAL : 0,
    }
  )

  const isMissionRefreshing = !!missionResponse && isMissionLoading

  const { setBackupValue, hasChanges, backupValue } = useCheckUnsavedChanges({
    isDisabled: isViewMode,
    value: mission,
  })

  const area = areas?.find((area) => area.id === mission.areaId)

  React.useEffect(() => {
    if (isMobile && isNewMode) {
      history.replace('/missions-and-geofences')
    }
  }, [isMobile, isNewMode])

  React.useEffect(() => {
    if (isViewMode) {
      if (backupValue) {
        setMission(backupValue)
      }
      setBackupValue(undefined)
    } else {
      setBackupValue(_.cloneDeep(mission))
    }
  }, [isViewMode])

  React.useEffect(() => {
    if (missionResponse) {
      const parsedMission = parseMissionFromBackend(missionResponse)
      setMission(parsedMission)
      setBackupValue(isViewMode ? undefined : _.cloneDeep(parsedMission))
    } else {
      setBackupValue(_.cloneDeep(mission))
    }
  }, [missionResponse])

  React.useEffect(() => {
    if (
      isNewMode &&
      areas?.length &&
      geofences?.length &&
      !mission.areaId &&
      !mission.geofenceId
    ) {
      setMission((state) => {
        const areaId = areas[0].id
        const geofenceId =
          geofences.find((geofence) => geofence.areaId === areaId)?.id ??
          EMPTY_MISSION.geofenceId
        const updatedMission = { ...state, areaId, geofenceId }
        setBackupValue(updatedMission)
        return updatedMission
      })
    }
  }, [areas, geofences])

  const handleSave = async () => {
    if (!hasChanges && isExistingMission(mission)) {
      return history.push(getMissionLink({ id: mission.id, mode: 'view' }))
    }

    setIsMissionSaving(true)

    try {
      const missionUpdate = prepareMissionForBackend(mission)
      const updatedMissionId = await (isNewMode
        ? addMission(missionUpdate)
        : updateMission(Number(missionId), missionUpdate))

      setBackupValue(undefined)
      if (updatedMissionId) {
        history.push(getMissionLink({ id: updatedMissionId, mode: 'view' }))
      }
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.missionSave)
    } finally {
      setIsMissionSaving(false)
    }
  }

  const handleDelete = async () => {
    if ('id' in mission) {
      try {
        await deleteMission([mission.id])
        setBackupValue(undefined)
        history.push('/missions-and-geofences')
      } catch (err) {
        console.error(err)
        message.error(ERROR_MESSAGES.missionDelete)
      }
    }
  }

  if (
    modeFromQuery === 'edit' &&
    isMissionRunning(mission) &&
    // After copying mission `missionId` may be from a new mission, while `mission` from an old one
    mission.id === Number(missionId)
  ) {
    return (
      <Redirect to={getMissionLink({ id: Number(missionId), mode: 'view' })} />
    )
  }

  if (missionDictionaries.loading) {
    return <CenteredSpin tip="Loading mission" />
  }

  if (!areas || !area || !geofences || !swanBots) {
    return isMissionLoading ? null : (
      <ReloadPageResult title="Couldn't receive mission information" />
    )
  }

  return (
    <EstimatesUpdateProvider>
      <HighlightedSurveyPointProvider>
        <Spin
          spinning={isMissionLoading && !isMissionRefreshing}
          wrapperClassName={css.spinner}
          delay={100}
        >
          <MissionPageContent
            area={area}
            areas={areas}
            swanBots={swanBots}
            geofences={geofences}
            mission={mission}
            isMissionSaving={isMissionSaving}
            isViewMode={isViewMode}
            onChange={setMission}
            onSave={handleSave}
            onDelete={handleDelete}
          />
        </Spin>
      </HighlightedSurveyPointProvider>
    </EstimatesUpdateProvider>
  )
}

const isMissionPageMode = (
  str: string | null | undefined
): str is MissionPageMode =>
  !!str && (MISSION_MODES as readonly string[]).includes(str)
