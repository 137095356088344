import { ModifyMode, ViewMode } from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  Spin,
  Typography,
} from 'antd'
import { ModalProps } from 'antd/es/modal'
import React from 'react'
import { Area, AreaAdd, Coordinates, getAllGeofences } from 'swanviz'
import useSWR from 'swr'

import { commonEditableLayerGuidesProps } from '../../../../geoLayers/editableLayer'
import { RED_RGB, WHITE_RGB } from '../../../../utils/colors'
import { INTERPOLATION_DEFAULTS } from '../../../../utils/defaults'
import { createPointFeature } from '../../../../utils/geojson'
import { getTrimmedInputValue } from '../../../../utils/input'
import { useCheckUnsavedChanges } from '../../../../utils/useCheckUnsavedChanges'
import {
  getRequiredNumberRules,
  getRequiredRule,
} from '../../../../utils/validationRules'
import { ColorPicker } from '../../../ColorPicker'
import { GeofenceSelect } from '../../../GeofenceSelect'
import { LocationInput } from '../../../LocationInput'
import { Map } from '../../../Map'

import css from './style.module.css'

export const AreaModal: React.FC<
  { title: string } & Pick<ModalProps, 'visible' | 'closable' | 'onCancel'>
> = ({ title, children, ...modalProps }) => {
  return (
    <Modal {...modalProps} footer={null} destroyOnClose>
      <Typography.Title level={3}>{title}</Typography.Title>
      {children}
    </Modal>
  )
}

export const AreaModalForm = <T extends Area | AreaAdd>({
  initialValues,
  disabled,
  onSubmit,
}: {
  initialValues: T
  disabled: boolean
  onSubmit: (area: T) => void
}): React.ReactElement => {
  const [area, setArea] = React.useState(initialValues)
  const isEditing = 'id' in area
  const { setBackupValue, hasChanges } = useCheckUnsavedChanges({
    value: area,
  })

  const { depth: depthDefaults } = INTERPOLATION_DEFAULTS

  React.useEffect(() => {
    setBackupValue(initialValues)
  }, [initialValues])

  return (
    <Form
      initialValues={initialValues}
      colon={false}
      hideRequiredMark
      labelAlign="left"
      labelCol={{ span: 6 }}
      onFinish={() => onSubmit(area)}
      onValuesChange={(changedValues) =>
        setArea((state) => ({ ...state, ...changedValues }))
      }
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[getRequiredRule('Name')]}
        getValueFromEvent={getTrimmedInputValue}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item label="Coordinates" name="coordinates">
        <LocationInput disabled={disabled} withValidation={false} />
      </Form.Item>

      <Form.Item name="coordinates">
        <CoordinatesMap disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Color"
        name="colorCode"
        rules={[getRequiredRule('Color')]}
      >
        <ColorPicker view="input" disabled={disabled} />
      </Form.Item>

      {isEditing && <GeofenceFormItem areaId={area.id} disabled={disabled} />}

      <Form.Item
        label="Depth"
        name="depth"
        rules={getRequiredNumberRules(
          'Depth',
          depthDefaults.min,
          depthDefaults.max
        )}
      >
        <InputNumber
          min={depthDefaults.min}
          max={depthDefaults.max}
          step={depthDefaults.step}
          precision={depthDefaults.precision}
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item label="&nbsp;">
        <Button
          size="large"
          type="primary"
          danger
          htmlType="submit"
          loading={disabled}
          disabled={disabled || !hasChanges}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

const CoordinatesMap: React.FC<{
  value?: Coordinates
  onChange?: (value: Coordinates) => void
  disabled: boolean
}> = ({ value, disabled, onChange = () => null }) => {
  const initialMapPosition = React.useMemo(() => value, [])

  if (!value || !initialMapPosition) {
    return null
  }

  const editLayer = new EditableGeoJsonLayer({
    id: 'areaCoordinatesPoint',
    data: {
      type: 'FeatureCollection',
      features: [createPointFeature(value)],
    },
    selectedFeatureIndexes: [0],
    mode: disabled ? ViewMode : ModifyMode,
    onEdit: ({ updatedData }) => {
      if (updatedData.features.length) {
        const { geometry } = updatedData.features[0]
        if (geometry.type === 'Point') {
          onChange?.(geometry.coordinates as Coordinates)
        }
      }
    },
    _subLayerProps: {
      geojson: {
        ...commonEditableLayerGuidesProps,
        visible: disabled,
        getFillColor: () => [...WHITE_RGB, 0.2 * 255],
      },
      guides: {
        ...commonEditableLayerGuidesProps,
        getFillColor: () => RED_RGB,
      },
    },
  })

  return (
    <div className={css.map}>
      <Map
        longitude={initialMapPosition[0]}
        latitude={initialMapPosition[1]}
        layers={[editLayer]}
        getCursor={editLayer.getCursor.bind(editLayer)}
        withStyleSwitcher={false}
        withAttribution={false}
      />
    </div>
  )
}

const GeofenceFormItem: React.FC<{
  areaId: number
  disabled: boolean
}> = ({ areaId, disabled }) => {
  const {
    data: geofences = [],
    isValidating: areGeofencesLoading,
    error: geofencesError,
    revalidate,
  } = useSWR('getAllGeofences', getAllGeofences)

  React.useEffect(() => {
    geofencesError && console.error(geofencesError)
  }, [geofencesError])

  return (
    <Form.Item label="Geofence" name="geofenceId">
      {areGeofencesLoading ? (
        <Space>
          <Typography.Text type="secondary">Loading geofences</Typography.Text>
          <Spin size="small" />
        </Space>
      ) : geofencesError ? (
        <Typography.Text type="danger">
          Couldn&apos;t load geofences.{' '}
          <Button type="link" className={css.tryAgain} onClick={revalidate}>
            Try again
          </Button>
          .
        </Typography.Text>
      ) : (
        <GeofenceSelect
          geofences={geofences}
          areaId={areaId}
          disabled={disabled}
          loading={areGeofencesLoading}
        />
      )}
    </Form.Item>
  )
}
