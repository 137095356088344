import React from 'react'
import { MissionTask } from 'swanviz'

import { getPreviousLocation } from '../../../../../utils/missionTasks'
import { MapIcon } from '../../../../MapIcon'
import { getLawnmowerEditIconsCoordinates } from '../layers/editLawnmowerLayer'

import { ReactComponent as ResizeIcon } from './resize.svg'
import { ReactComponent as RotateIcon } from './rotate.svg'
import css from './style.module.css'

export const getLawnmowerIcons = ({
  task,
  allTasks,
  isViewMode,
}: {
  task: MissionTask | undefined
  allTasks: MissionTask[]
  isViewMode: boolean
}): React.ReactNode[] => {
  if (isViewMode || task?.type !== 'lawnmower') {
    return []
  }

  const previousLocation = getPreviousLocation({
    taskId: task.id,
    allTasks,
  })

  if (!previousLocation) {
    return []
  }

  const editIconsCoords = getLawnmowerEditIconsCoordinates(
    previousLocation,
    task
  )
  const { bearing } = task.args

  return [
    <MapIcon key="bearing" coordinates={editIconsCoords.bearing}>
      <RotateIcon
        className={css.icon}
        style={{
          transform: `rotate(${bearing}deg)`,
        }}
      />
    </MapIcon>,
    <MapIcon key="length" coordinates={editIconsCoords.length}>
      <ResizeIcon
        className={css.icon}
        style={{
          transform: `rotate(${bearing}deg)`,
        }}
      />
    </MapIcon>,
    <MapIcon key="width" coordinates={editIconsCoords.width}>
      <ResizeIcon
        className={css.icon}
        style={{
          transform: `rotate(${90 + bearing}deg)`,
        }}
      />
    </MapIcon>,
  ]
}
