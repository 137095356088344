import classNames from 'classnames'
import React from 'react'
import { SwanBot } from 'swanviz'

import { statusNames } from '../../../../utils/swanbot'
import css from './style.module.css'

type SwanBotStatus = SwanBot['status']

type Props = {
  swanBots: SwanBot[]
}

const STATUSES_WITH_CARDS: SwanBotStatus[] = [
  'IN_A_MISSION',
  'SUSPENDED',
  'IDLE',
  'OFF',
]

const STATUS_CLASSES: Record<SwanBotStatus, string | undefined> = {
  IN_A_MISSION: css.onAMission,
  SUSPENDED: css.suspended,
  IDLE: css.idle,
  OFF: css.off,
  ABORT_TO_HOME: undefined,
}

export const DashboardSummary: React.FC<Props> = ({ swanBots }) => {
  return (
    <div className={css.main}>
      {STATUSES_WITH_CARDS.map((status) => {
        const count = swanBots.filter((swanBot) =>
          status === 'IN_A_MISSION'
            ? ['IN_A_MISSION', 'ABORT_TO_HOME'].includes(swanBot.status)
            : swanBot.status === status
        ).length

        return <Card key={status} status={status} count={count} />
      })}
    </div>
  )
}

const Card: React.FC<{ count: number; status: SwanBotStatus }> = ({
  count,
  status,
}) => {
  return (
    <div className={classNames(css.card, STATUS_CLASSES[status])}>
      <span className={css.count}>{count}</span>
      <div>
        {getPluralSwanbots(count)}
        <br />
        <span className={css.status}>{statusNames[status].toLowerCase()}</span>
      </div>
    </div>
  )
}

const getPluralSwanbots = (count: number): 'SwanBot' | 'SwanBots' =>
  count === 1 ? 'SwanBot' : 'SwanBots'
