import { Button, Select, Space } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import React from 'react'

import { useWindowSize } from '../../utils/useWindowSize'
import css from './style.module.css'

const SELECT_WIDTH = 280

export const TableSelectFilter: React.FC<FilterDropdownProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  filters,
  visible,
}) => {
  const { isDesktop } = useWindowSize()

  const buttons = (
    <>
      <Button
        type="primary"
        onClick={confirm}
        size="small"
        className={css.button}
      >
        Filter
      </Button>
      <Button onClick={clearFilters} size="small" className={css.button}>
        Reset
      </Button>
    </>
  )

  return (
    <div className={css.main}>
      <Select
        className={css.select}
        dropdownMatchSelectWidth={false}
        style={{ width: SELECT_WIDTH }}
        dropdownStyle={{ width: SELECT_WIDTH }}
        mode="multiple"
        showArrow
        showSearch={isDesktop}
        size={isDesktop ? undefined : 'large'}
        value={selectedKeys}
        open={visible ? undefined : false}
        onChange={(value) => {
          setSelectedKeys(value)
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Space className={css.dropdownButtons}>{buttons}</Space>
          </>
        )}
      >
        {filters?.map((filter) => (
          <Select.Option
            key={filter.value.toString()}
            value={filter.value.toString()}
          >
            {filter.text}
          </Select.Option>
        ))}
      </Select>
      <Space>{buttons}</Space>
    </div>
  )
}
