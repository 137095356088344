import { RGBAColor } from '@deck.gl/core'
import _ from 'lodash'

export type RGBColor = [number, number, number]

export const WHITE_RGB: RGBColor = [255, 255, 255]
export const HALF_WHITE_RGBA: RGBAColor = [...WHITE_RGB, 0.5 * 255]
export const ORANGE_RGB: RGBColor = [255, 122, 34]
export const RED_RGB: RGBColor = [192, 0, 0]
export const BLUE_RGB: RGBColor = [10, 111, 198]
export const TRANSPARENT_RGBA: RGBAColor = [0, 0, 0, 0]
export const getTransparentColor = () => TRANSPARENT_RGBA as number[]

const getRandomColorPart = (): number => Math.floor(Math.random() * 255)
const HEX_REG_EXP = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i

export const getRandomColor = (): RGBColor => {
  return _.shuffle([0, 255, getRandomColorPart()]) as RGBColor
}

export const rgbToHex = (color: RGBColor): string =>
  '#' + color.map((x) => x.toString(16).padStart(2, '0')).join('')

export const hexToRgb = (color: string): RGBColor =>
  color
    .replace(HEX_REG_EXP, '$1.$2.$3')
    .split('.')
    .map((x) => parseInt(x, 16)) as RGBColor

export const darken = (color: RGBColor, percent: number): RGBColor =>
  color.map((part) =>
    _.clamp(Math.round(part - percent * 255), 0, 255)
  ) as RGBColor

export const isHexColor = (color: string) => HEX_REG_EXP.test(color)

export const PREDEFINED_COLORS: string[] = [
  '#10BB61',
  '#0081EF',
  '#F81C59',
  '#B95FFF',
  '#FF851F',
  '#5DA8B3',
  '#4B7258',
  '#5E65D3',
  '#EE5552',
  '#AA007A',
  '#CD7454',
  '#000000',
]

export const getPredefinedColor = (id: number): string =>
  PREDEFINED_COLORS[id % PREDEFINED_COLORS.length]
