import _ from 'lodash'
import { DataVisFilter, getMissionRuns, MissionRun } from 'swanviz'
import useSWR from 'swr'

import { useDeepMemoizedValue } from '../../../../utils/useDeepMemoizedValue'
import { ALL_OPTION } from '../allOption'
import { FormValues } from '../types'

export const useMissionRuns = (
  dataVisFilter: FormValues
): {
  missionRuns: MissionRun[] | undefined
  updateMissionRun: (missionRun: MissionRun) => void
  isLoading: boolean
  isError: boolean
} => {
  const backendFilter = useDeepMemoizedValue(toBackendFilter(dataVisFilter))
  const {
    data: missionRuns,
    error,
    isValidating: isLoading,
    mutate: mutateMissionRuns,
  } = useSWR(['getMissionRuns', backendFilter], () =>
    getMissionRuns(backendFilter).then((res) =>
      _.orderBy(res, 'runDate', 'desc')
    )
  )

  const updateMissionRun = (missionRun: MissionRun) =>
    mutateMissionRuns(
      (state) =>
        state?.map((stateRun) =>
          stateRun.runId === missionRun.runId ? missionRun : stateRun
        ),
      false
    )

  return {
    missionRuns,
    updateMissionRun,
    isLoading,
    isError: !!error,
  }
}

const toBackendFilter = ({
  datesRange,
  areaId,
  missionId,
  swanbotId,
}: FormValues): DataVisFilter => {
  return {
    fromDate: datesRange[0].startOf('day').valueOf(),
    toDate: datesRange[1].endOf('day').valueOf(),
    areaId,
    missionId: missionId === ALL_OPTION ? undefined : missionId,
    swanbotId: swanbotId === ALL_OPTION ? undefined : swanbotId,
  }
}
