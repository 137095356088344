import { Typography } from 'antd'
import _ from 'lodash'
import React from 'react'
import { getMissionRunNtf, MissionNotification } from 'swanviz'
import useSWR from 'swr'

import { MISSION_NOTIFICATIONS_POLL_INTERVAL } from '../../../../poll-config'

type Props = {
  missionRunId: number
}

export const MissionNotifications: React.FC<Props> = ({ missionRunId }) => {
  const [notifications, setNotifications] = React.useState<
    MissionNotification[]
  >([])
  const lastUpdatedRef = React.useRef(0)

  const addNewNotifications = (
    newNotifications: MissionNotification[]
  ): void => {
    if (!newNotifications.length) {
      return
    }

    const nextNotificationsState = getNotificationsToShow([
      ...notifications,
      ...newNotifications,
    ])
    setNotifications(nextNotificationsState)

    const addedNotifications = _.difference(
      nextNotificationsState,
      notifications
    )
    scheduleNotificationsRemoval(addedNotifications)
  }

  const scheduleNotificationsRemoval = (
    newNotifications: MissionNotification[]
  ): void =>
    newNotifications.forEach((notification) => {
      const timeUntilExpire = getExpirationDate(notification) - Date.now()
      setTimeout(() => {
        setNotifications((state) =>
          state.filter((n) => n.id !== notification.id)
        )
      }, timeUntilExpire)
    })

  useSWR(
    ['getMissionRunNtf', missionRunId],
    async () => {
      const newNotificationsBatch = await getMissionRunNtf(
        missionRunId,
        undefined,
        lastUpdatedRef.current
      )

      if (newNotificationsBatch.length) {
        lastUpdatedRef.current = Math.max(
          ...newNotificationsBatch.map((notification) => notification.ntfTime)
        )
      }

      addNewNotifications(newNotificationsBatch)
    },
    {
      refreshInterval: MISSION_NOTIFICATIONS_POLL_INTERVAL,
    }
  )

  return notifications.length ? (
    <Typography.Text type="danger">
      {notifications.map((notification) => notification.message).join(', ')}
    </Typography.Text>
  ) : null
}

export const getNotificationsToShow = (
  notifications: MissionNotification[]
): MissionNotification[] => {
  const uniqNotifications = _.uniqBy(notifications, 'id')
  const filteredNotifications = uniqNotifications
    .filter((notification) => notification.severity === 1)
    .filter((notification) => getExpirationDate(notification) > Date.now())
  const orderedNotifications = _.orderBy(
    filteredNotifications,
    'ntfTime',
    'desc'
  )
  const notificationsByType = _.groupBy(orderedNotifications, 'type')

  return Object.values(notificationsByType).flatMap((notificationsOfType) =>
    _.take(notificationsOfType, 3)
  )
}

const getExpirationDate = (notification: MissionNotification): number =>
  notification.ntfTime + notification.lifetime * 1000
