import { Button, Result } from 'antd'
import classNames from 'classnames'
import React, { FormEventHandler } from 'react'
import { Area, SwanBot } from 'swanviz'

import type { OnSwanBotUpdate } from '..'
import { formatCoordinates } from '../../../utils/geojson'
import { useCheckUnsavedChanges } from '../../../utils/useCheckUnsavedChanges'
import { useWindowSize } from '../../../utils/useWindowSize'
import { AreasSelect } from '../../AreasSelect'
import { InformationItem } from '../../InformationItem'
import { LocationInput } from '../../LocationInput'
import { LocationMap } from '../LocationMap'
import css from './style.module.css'
import { usePermissions } from '../../../utils/usePermissions'

type Props = {
  areas: Area[]
  swanBot: SwanBot
  onChange: OnSwanBotUpdate
}

export const LocationTab: React.FC<Props> = ({ areas, swanBot, onChange }) => {
  const { isDesktop } = useWindowSize()
  const {
    swanbots: { canManage },
  } = usePermissions()
  const [areaId, setAreaId] = React.useState(swanBot.areaId)
  const [home, setHome] = React.useState(swanBot.home)
  const { setBackupValue, hasChanges } = useCheckUnsavedChanges({
    value: {
      areaId,
      home,
    },
  })
  const isEditingAllowed =
    isDesktop &&
    canManage &&
    (swanBot.status === 'IDLE' || swanBot.status === 'OFF')

  React.useEffect(() => {
    setBackupValue({
      areaId: swanBot.areaId,
      home: swanBot.home,
    })
  }, [swanBot])

  const area = areas.find((area) => area.id === areaId)

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    onChange({
      areaId,
      home,
    })
  }

  if (!area) {
    return <Result status="error" title="SwanBot's area doesn't exist" />
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(css.main, isEditingAllowed && css.isEditing)}
    >
      <div className={css.inputs}>
        <InformationItem label="Area" className={css.areas}>
          {isEditingAllowed ? (
            <AreasSelect
              areas={areas}
              value={areaId || undefined}
              onChange={(newAreaId) => {
                setAreaId(newAreaId)
                setHome(null)
              }}
            />
          ) : (
            areas.find((area) => area.id === areaId)?.name || '—'
          )}
        </InformationItem>
        <InformationItem label="Home location">
          {isEditingAllowed ? (
            <LocationInput value={home} onChange={setHome} />
          ) : (
            (home && formatCoordinates(home)) || '—'
          )}
        </InformationItem>
      </div>

      <div className={css.map}>
        <LocationMap
          area={area}
          swanBot={{ ...swanBot, home }}
          isEditingAllowed={isEditingAllowed}
          onChangeHomeLocation={setHome}
        />
      </div>

      {isEditingAllowed && (
        <Button
          className={css.save}
          htmlType="submit"
          type="primary"
          danger
          disabled={!hasChanges || !home}
        >
          Save
        </Button>
      )}
    </form>
  )
}
