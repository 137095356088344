import { Form } from 'antd'
import React from 'react'
import { MISSION_TASK_DEFAULTS } from '../../../utils/defaults'
import { getNumberRule } from '../../../utils/validationRules'
import { InputNumberWithPostfix } from '../InputNumberWithPostfix'
import { Label } from '../Label'

export const SpeedFormItem: React.FC<{
  missionSpeed: number
  isViewMode: boolean
}> = ({ isViewMode, missionSpeed }) => (
  <Form.Item
    label={<Label>Speed</Label>}
    name={['speed']}
    rules={[
      getNumberRule(
        'speed',
        MISSION_TASK_DEFAULTS.speed.min,
        MISSION_TASK_DEFAULTS.speed.max
      ),
    ]}
  >
    <InputNumberWithPostfix
      min={MISSION_TASK_DEFAULTS.speed.min}
      max={MISSION_TASK_DEFAULTS.speed.max}
      step={MISSION_TASK_DEFAULTS.speed.step}
      precision={MISSION_TASK_DEFAULTS.speed.precision}
      readOnly={isViewMode}
      postfix="m/s"
      placeholder={missionSpeed.toString()}
    />
  </Form.Item>
)
