import classnames from 'classnames'
import React from 'react'
import { SwanBot } from 'swanviz'

import { ReactComponent as PauseIcon } from '../../icons/pause.svg'
import { HomeIcon } from '../HomeIcon'
import css from './style.module.css'

const STATUSES: Record<SwanBot['status'], string> = {
  IDLE: css.isIdle,
  ABORT_TO_HOME: css.isAbortToHome,
  IN_A_MISSION: css.isInProgress,
  OFF: '',
  SUSPENDED: css.isSuspended,
}

export const StatusIcon: React.FC<{ status: SwanBot['status'] }> = ({
  status,
}) => {
  const className = classnames(css.status, STATUSES[status])

  if (status === 'ABORT_TO_HOME') {
    return <HomeIcon className={className} />
  }

  if (status === 'SUSPENDED') {
    return <PauseIcon className={className} />
  }

  if (status === 'OFF') {
    return null
  }

  return <span className={className} />
}
