import { Button, message, Progress, Spin } from 'antd'
import React from 'react'
import { cancelInterpolation, InterpolationInputs } from 'swanviz'
import { LoadingOutlined } from '@ant-design/icons'

import { ERROR_MESSAGES } from '../../../../utils/errorMessages'

import css from './style.module.css'

export const InterpolationProgress: React.FC<{
  progress: number
  interpolationInputs: InterpolationInputs
  updateInterpolationInfo: () => Promise<void>
}> = ({ progress, interpolationInputs, updateInterpolationInfo }) => {
  const [isCancelling, setIsCancelling] = React.useState(false)
  const handleCancelClick = async () => {
    setIsCancelling(true)
    try {
      await cancelInterpolation(interpolationInputs)
      await updateInterpolationInfo()
    } catch (err) {
      message.error(ERROR_MESSAGES.interpolationCancel)
      console.error(err)
    }
    setIsCancelling(false)
  }

  return (
    <div className={css.main}>
      Interpolation{' '}
      <span>
        <Spin
          size="small"
          indicator={<LoadingOutlined className={css.spinner} />}
        />
      </span>
      <Progress className={css.progress} percent={progress} />
      <Button
        onClick={handleCancelClick}
        loading={isCancelling}
        block
        type="ghost"
      >
        Cancel
      </Button>
    </div>
  )
}
