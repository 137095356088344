import { Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { SwanBot } from 'swanviz'

import { getMissionLink } from '../../utils/links'
import { dateAndTimeFormat } from '../../utils/momentFormat'
import { statusNames } from '../../utils/swanbot'
import { StatusIcon } from '../StatusIcon'

import css from './style.module.css'

export const SwanBotStatus: React.FC<{ swanBot: SwanBot }> = ({ swanBot }) => {
  const { status, currMissionId, delFlag, currRunId } = swanBot
  const text = <StatusText swanBot={swanBot} />

  return (
    <div className={css.main}>
      <span className={css.icon}>
        {!delFlag && <StatusIcon status={status} />}
      </span>
      {status === 'IN_A_MISSION' && currMissionId && currRunId ? (
        <Link
          to={getMissionLink({
            id: currMissionId,
            mode: 'view',
            runId: currRunId,
          })}
          target="_blank"
        >
          {text}
        </Link>
      ) : (
        text
      )}
    </div>
  )
}

const StatusText: React.FC<{ swanBot: SwanBot }> = ({
  swanBot: { status, lastLoginDate, delFlag },
}) => {
  const isOffline = status === 'OFF'
  const statusName = statusNames[status]

  if (delFlag) {
    return <Typography.Text type="secondary">Deleted</Typography.Text>
  }

  if (isOffline) {
    return (
      <Typography.Text type="secondary">
        {lastLoginDate
          ? `Last online: ${moment(lastLoginDate).format(dateAndTimeFormat)}`
          : statusName}
      </Typography.Text>
    )
  }
  return <>{statusName}</>
}
