import { getAllImages, Image } from 'swanviz'
import useSWR from 'swr'

export const useRunImages = (
  runId: number | null
): {
  images: Image[]
  isLoading: boolean
  isError: boolean
} => {
  const { data: images = [], isValidating: isLoading, error } = useSWR(
    runId ? ['getAllImages', runId] : null,
    () => (runId ? getAllImages([runId]) : undefined)
  )

  return { images, isLoading, isError: !!error }
}
