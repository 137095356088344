import classNames from 'classnames'
import React from 'react'

import { useAreasAndSwanBots } from '../../../utils/useAreasAndSwanBots'
import { usePermissions } from '../../../utils/usePermissions'
import { useWindowSize } from '../../../utils/useWindowSize'
import { CenteredSpin } from '../../CenteredSpin'
import { PageTitle } from '../../PageTitle'
import { ReloadPageResult } from '../../ReloadPageResult'
import { AreaAddButton } from './AreaAddButton'

import { DashboardMap } from './DashboardMap'
import { DashboardSummary } from './DashboardSummary'
import { DashboardTable } from './DashboardTable'
import { LatestMissions } from './LatestMissions'
import css from './style.module.css'

export const Dashboard: React.FC = () => {
  const { areas, swanBots, isLoading, isError } = useAreasAndSwanBots()
  const activeSwanBots = swanBots.filter((swanBot) => !swanBot.delFlag)

  const { isDesktop } = useWindowSize()
  const {
    dashboard: { canViewLatestMissions, canEditArea },
  } = usePermissions()

  if (isError) {
    return <ReloadPageResult title="Couldn't get dashboard data" />
  }

  if (isLoading) {
    return <CenteredSpin tip="Loading dashboard data" />
  }

  return (
    <div className={css.main}>
      <div className={css.top}>
        <div className={css.titleWrapper}>
          <PageTitle className={css.title}>Dashboard</PageTitle>
          {isDesktop && canEditArea && <AreaAddButton />}
        </div>
        <div
          className={classNames(
            css.info,
            canViewLatestMissions && css.withMissions
          )}
        >
          {canViewLatestMissions && (
            <div className={css.missions}>
              <LatestMissions areas={areas} />
            </div>
          )}
          <div className={css.map}>
            <DashboardMap areas={areas} />
          </div>
          <div className={css.summary}>
            <DashboardSummary swanBots={activeSwanBots} />
          </div>
        </div>
      </div>
      <DashboardTable areas={areas} swanBots={activeSwanBots} />
    </div>
  )
}
