import { Button, message, Spin } from 'antd'
import _ from 'lodash'
import React from 'react'
import {
  getInterpolationInfo,
  InterpolationInfo,
  InterpolationInputs,
  startInterpolation,
} from 'swanviz'
import { mutate } from 'swr'
import { useQueryParams } from 'use-query-params'

import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import { DepthControl, DepthRange } from '../DepthControl'
import { InterpolationProgress } from '../InterpolationProgress'
import { interpolationQueryConfig } from '../query'
import css from './style.module.css'

type Props = {
  interpolationInfo: InterpolationInfo | null
  interpolationInputs: InterpolationInputs
  isInterpolationInfoLoading: boolean
  depthMaximum: number
}

export const InterpolationDataControls: React.FC<Props> = ({
  interpolationInfo,
  interpolationInputs,
  isInterpolationInfoLoading,
  depthMaximum,
}) => {
  const [depthRange, setDepthRange] = React.useState<DepthRange>(
    getDepthRange(interpolationInputs)
  )
  const hasChanges = !_.isEqual(depthRange, getDepthRange(interpolationInputs))
  const isInterpolationInProgress = interpolationInfo?.status === 'STARTED'

  return (
    <Spin spinning={isInterpolationInfoLoading && !isInterpolationInProgress}>
      {interpolationInfo?.status === 'COMPLETED' && hasChanges && (
        <Button
          type="link"
          className={css.reset}
          onClick={() => setDepthRange(getDepthRange(interpolationInputs))}
        >
          Reset
        </Button>
      )}
      <DepthControl
        value={depthRange}
        onChange={setDepthRange}
        max={depthMaximum}
        disabled={isInterpolationInProgress}
      />
      <StatusActions
        interpolationInputs={{
          ...interpolationInputs,
          minDepth: depthRange[0],
          maxDepth: depthRange[1],
        }}
        interpolationInfo={interpolationInfo}
        hasChanges={hasChanges}
      />
    </Spin>
  )
}

const getDepthRange = (inputs: InterpolationInputs): DepthRange => [
  inputs.minDepth,
  inputs.maxDepth,
]

const StatusActions: React.FC<{
  interpolationInfo: InterpolationInfo | null
  interpolationInputs: InterpolationInputs
  hasChanges: boolean
}> = ({ interpolationInfo, interpolationInputs, hasChanges }) => {
  const updateInterpolationInfo = () =>
    mutate(['getInterpolationInfo', JSON.stringify(interpolationInputs)])
  const interpolateButton = (
    <InterpolateButton
      hasChanges={hasChanges}
      interpolationInputs={interpolationInputs}
      updateInterpolationInfo={updateInterpolationInfo}
    />
  )

  switch (interpolationInfo?.status) {
    case 'STARTED':
      return (
        <InterpolationProgress
          progress={interpolationInfo.progress}
          interpolationInputs={interpolationInputs}
          updateInterpolationInfo={updateInterpolationInfo}
        />
      )
    case 'COMPLETED':
      return hasChanges ? interpolateButton : null
    default:
      return interpolateButton
  }
}

const InterpolateButton: React.FC<{
  interpolationInputs: InterpolationInputs
  hasChanges: boolean
  updateInterpolationInfo: () => Promise<void>
}> = ({ interpolationInputs, hasChanges, updateInterpolationInfo }) => {
  const [, setQueryParams] = useQueryParams(interpolationQueryConfig)
  const [isStatusUpdating, setIsStatusUpdating] = React.useState(false)
  const handleInterpolateClick = async () => {
    setIsStatusUpdating(true)
    try {
      if (hasChanges) {
        // Check that interpolation with new params hasn't already been started or completed
        const newInterpolationInfo = await getInterpolationInfo(
          interpolationInputs
        )
        !newInterpolationInfo && (await startInterpolation(interpolationInputs))
        setQueryParams(interpolationInputs)
      } else {
        await startInterpolation(interpolationInputs)
        await updateInterpolationInfo()
      }
    } catch (err) {
      message.error(ERROR_MESSAGES.interpolationStart)
      console.error(err)
    }
    setIsStatusUpdating(false)
  }

  return (
    <Button
      onClick={handleInterpolateClick}
      loading={isStatusUpdating}
      type="primary"
      block
    >
      Interpolate
    </Button>
  )
}
