import { Button, message } from 'antd'
import React from 'react'

import { ERROR_MESSAGES } from '../../utils/errorMessages'
import css from './style.module.css'

type Props = {
  filename: string
  downloadAction: () => Promise<Uint8Array>
}

export const DownloadActivityLogButton: React.FC<Props> = ({
  filename,
  downloadAction,
  children = '↓ Download',
}) => {
  const [isLoading, setLoading] = React.useState(false)
  const handleClick: React.MouseEventHandler = async (e) => {
    e.stopPropagation()
    setLoading(true)
    try {
      const response = await downloadAction()
      const blob = new Blob([response], { type: 'text/plain' })
      downloadBlob(blob, filename)
    } catch (err) {
      console.error(err)
      message.error(ERROR_MESSAGES.activityLogDownload)
    }
    setLoading(false)
  }

  return (
    <Button
      type="text"
      loading={isLoading}
      className={css.main}
      onClick={handleClick}
    >
      {children}
    </Button>
  )
}

const downloadBlob = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = `${filename}.txt`
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}
