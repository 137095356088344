import { xor, xorBy } from 'lodash'
import { MissionRun, SensorParameter } from 'swanviz'
import { updateAt } from '../../utils/array'
import { MissionRunFilterWithShowPath } from '../pages/DataVis/types'

export const toggleRun = (
  missionRunFilters: MissionRunFilterWithShowPath[],
  runId: MissionRun['runId']
) => {
  return xorBy(
    missionRunFilters,
    [{ runId, sensorParamList: [], showPath: true }],
    'runId'
  )
}

export const toggleSensor = (
  missionRunFilters: MissionRunFilterWithShowPath[],
  runId: MissionRun['runId'],
  sensorId: SensorParameter['id']
) => {
  const runIndex = missionRunFilters.findIndex((item) => item.runId === runId)

  return updateAt(missionRunFilters, runIndex, {
    runId,
    sensorParamList: xor(missionRunFilters[runIndex].sensorParamList, [
      sensorId,
    ]),
    showPath: missionRunFilters[runIndex].showPath,
  })
}

/**
 * Preserve sensors selection in selected mission runs
 * */
export const selectAllMissionRuns = (
  missionRuns: MissionRun[],
  existingMissionRunFilters: MissionRunFilterWithShowPath[]
): MissionRunFilterWithShowPath[] => {
  return missionRuns.map((missionRun) => {
    const alreadySelectedMissionRun = existingMissionRunFilters.find(
      (item) => item.runId === missionRun.runId
    )
    if (alreadySelectedMissionRun) {
      return alreadySelectedMissionRun
    }
    return {
      runId: missionRun.runId,
      sensorParamList: [],
      showPath: true,
    }
  })
}
