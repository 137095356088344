import { Checkbox, Spin, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { SensorData, SensorParameter } from 'swanviz'

import type { InterpolationRunInfo } from '../'
import { dateAndTimeFormat } from '../../../../utils/momentFormat'
import { MapLegendItem } from '../../../MapLegendItem'
import { UseInterpolatedData } from '../InterpolationMapContainer/useInterpolatedData'

import { ReactComponent as GeofenceSVG } from './geofence.svg'
import { ReactComponent as LineSVG } from './line.svg'
import css from './style.module.css'

type Props = {
  legendRunInfos: LegendRunInfo[]
  sensor: SensorParameter
  interpolatedData: UseInterpolatedData
  isOriginalData: boolean
}

export type LegendRunInfo = InterpolationRunInfo & {
  heatmapColor: string
  pathColor: string
  sensorData: SensorData[] | undefined
  isDataLoading: boolean
  isPathLoading: boolean
  onColorChange: (color: string) => void
  onVisibilityChange: (visible: boolean) => void
}

export const InterpolationLegend: React.FC<Props> = ({
  legendRunInfos,
  sensor,
  interpolatedData,
  isOriginalData,
}) => {
  return (
    <>
      {legendRunInfos.map((runInfo) => (
        <PathLegendItem
          key={runInfo.run.runId}
          runInfo={runInfo}
          color={runInfo.pathColor}
          isLoading={runInfo.isPathLoading}
          onVisibilityChange={runInfo.onVisibilityChange}
        />
      ))}
      <div className={css.item}>
        <GeofenceSVG />
        <span>Geofence</span>
      </div>

      {isOriginalData ? (
        legendRunInfos.map((runInfo) => (
          <HeatmapLegendItem
            key={runInfo.run.runId}
            runInfo={runInfo}
            sensor={sensor}
          />
        ))
      ) : (
        <InterpolationLegendItem
          interpolatedData={interpolatedData}
          sensor={sensor}
        />
      )}
    </>
  )
}

const PathLegendItem: React.FC<{
  runInfo: InterpolationRunInfo
  color: string
  isLoading?: boolean
  onVisibilityChange: (value: boolean) => void
}> = ({ runInfo: { run, visible }, color, isLoading, onVisibilityChange }) => {
  return (
    <Spin spinning={isLoading}>
      <div className={css.item}>
        <LineSVG style={{ color }} />
        <span>{run.missionName}</span>
        <span className={css.date}>
          {moment(run.runDate).format(dateAndTimeFormat)}
        </span>
        <Checkbox
          className={css.checkbox}
          disabled={isLoading}
          checked={visible}
          onChange={(e) => onVisibilityChange(e.target.checked)}
        />
      </div>
    </Spin>
  )
}

const HeatmapLegendItem: React.FC<{
  runInfo: LegendRunInfo
  sensor: SensorParameter
}> = ({ runInfo, sensor }) => {
  return (
    <Spin key={runInfo.run.runId} spinning={runInfo.isDataLoading}>
      <MapLegendItem
        subtitle={[
          runInfo.run.missionName,
          moment(runInfo.run.runDate).format(dateAndTimeFormat),
        ].join(', ')}
        color={runInfo.heatmapColor}
        data={runInfo.sensorData}
        sensor={sensor}
        onChangeColor={runInfo.onColorChange}
      />
    </Spin>
  )
}

const InterpolationLegendItem: React.FC<
  Pick<Props, 'interpolatedData' | 'sensor'>
> = ({ interpolatedData, sensor }) => {
  return (
    <Spin spinning={interpolatedData.isLoading}>
      {interpolatedData.isError ? (
        <Typography.Text type="danger">
          Couldn&apos;t get interpolated data
          <br />
          <Typography.Link href="">Reload page</Typography.Link> to try again
        </Typography.Text>
      ) : (
        <MapLegendItem
          data={interpolatedData.data}
          sensor={sensor}
          color={interpolatedData.color}
          onChangeColor={interpolatedData.setColor}
        />
      )}
    </Spin>
  )
}
