import * as React from 'react'
import { Button, Dropdown, Menu } from 'antd'

import { MAP_STYLES } from './mapStyles'
import { ReactComponent as GlobeSVG } from './globe.svg'
import css from './style.module.css'

type MapStyleValue = typeof MAP_STYLES[number]['value']

export const defaultMapStyle: MapStyleValue = MAP_STYLES[0].value

export const MapStylesSwitcher: React.FC<{
  value: MapStyleValue
  onChange: (newValue: MapStyleValue) => void
}> = ({ value, onChange }) => {
  const menu = (
    <Menu
      onClick={(item) => {
        const selectedMapStyle = MAP_STYLES.find(
          (mapStyle) => mapStyle.label === item.key
        )
        if (selectedMapStyle) {
          onChange(selectedMapStyle.value)
        }
      }}
    >
      {MAP_STYLES.map((mapStyle) => (
        <Menu.Item key={mapStyle.label} disabled={mapStyle.value === value}>
          <div className={css.menuItem}>{mapStyle.label}</div>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
      <Button icon={<GlobeSVG />} size="large" className={css.button} />
    </Dropdown>
  )
}
