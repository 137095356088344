import { Form, Input, message } from 'antd'
import React from 'react'
import { reqChangePassword } from 'swanviz'

import { useCheckUnsavedChanges } from '../../../../utils/useCheckUnsavedChanges'
import { useWindowSize } from '../../../../utils/useWindowSize'
import {
  getRequiredRule,
  passwordLengthRule,
} from '../../../../utils/validationRules'
import { SaveButton } from '../../../SaveButton'

type ChangePasswordFormType = {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export const ChangePasswordForm: React.FC<{
  className: string
  userId: number
  onSuccess?: () => Promise<unknown>
}> = ({ className, userId, onSuccess }) => {
  const { isMobile } = useWindowSize()
  const [form] = Form.useForm<ChangePasswordFormType>()
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }
  const [values, setValues] = React.useState<ChangePasswordFormType>(
    initialValues
  )
  const [isSaving, setIsSaving] = React.useState(false)
  const [error, setError] = React.useState('')
  const { setBackupValue, hasChanges } = useCheckUnsavedChanges({
    value: values,
  })

  const handleSave = async (data: ChangePasswordFormType) => {
    try {
      setError('')
      setIsSaving(true)
      await reqChangePassword(userId, data.currentPassword, data.newPassword)
      await onSuccess?.()
      message.success('The password has been updated successfully')
      setBackupValue(initialValues)
      setValues(initialValues)
      form.resetFields()
    } catch (e: any) {
      console.error(e)
      setError(e.message)
    } finally {
      setIsSaving(false)
    }
  }

  React.useEffect(() => {
    setBackupValue(initialValues)
  }, [])

  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="horizontal"
      className={className}
      onValuesChange={(_changedValues, values) => {
        setValues(values)
      }}
      onFinish={handleSave}
      colon={false}
      size={isMobile ? 'large' : undefined}
    >
      <Form.Item
        label="Current password"
        name="currentPassword"
        rules={[getRequiredRule('Current password')]}
      >
        <Input type="password" />
      </Form.Item>
      <Form.Item
        label="New password"
        name="newPassword"
        rules={[getRequiredRule('New password'), passwordLengthRule]}
      >
        <Input type="password" />
      </Form.Item>
      <Form.Item
        label="Confirm new password"
        name="confirmNewPassword"
        dependencies={['newPassword', 'confirmNewPassword']}
        rules={[
          getRequiredRule('Confirm new password'),
          passwordLengthRule,
          {
            validator: (_rule, value) =>
              form.getFieldValue('newPassword') === value
                ? Promise.resolve()
                : Promise.reject("Passwords don't match"),
          },
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <SaveButton loading={isSaving} error={error} disabled={!hasChanges} />
    </Form>
  )
}
