import { Button, Typography } from 'antd'
import classnames from 'classnames'
import moment from 'moment'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { getMissionRunNtf } from 'swanviz'
import useSWR from 'swr'

import { MISSION_NOTIFICATIONS_POLL_INTERVAL } from '../../../../poll-config'
import { timeFormat } from '../../../../utils/momentFormat'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { getAlertsLink } from '../../Alerts/query'

import css from './style.module.css'

type Props = {
  runId: number
  areaId: number
  swanBotId: number
}

export const MissionLog: React.FC<Props> = ({ runId, areaId, swanBotId }) => {
  const { isDesktop } = useWindowSize()
  const notificationsCount = isDesktop ? 5 : 10
  const { data = [] } = useSWR(
    ['getMissionRunNtf', runId],
    async () => await getMissionRunNtf(runId, notificationsCount * -1),
    {
      refreshInterval: MISSION_NOTIFICATIONS_POLL_INTERVAL,
    }
  )

  const list = (
    <ul className={css.list}>
      {data.map((item, idx) => (
        <li key={idx} className={css.item}>
          <Typography.Text className={css.date}>
            {moment(item.ntfTime).format(timeFormat)}
          </Typography.Text>
          <Typography.Text>
            {item.message} {item.taskType ? `(${item.taskType})` : ''}
          </Typography.Text>
        </li>
      ))}
    </ul>
  )

  const commonLinkProps: LinkProps = {
    to: getAlertsLink({
      areaId: [areaId],
      swanbotId: [swanBotId],
    }),
    target: '_blank',
  }

  return isDesktop ? (
    <Link
      {...commonLinkProps}
      className={classnames(
        css.main,
        data.length === notificationsCount && css.hasMaxHeight
      )}
    >
      <Typography.Text className={css.title}>Activity Log</Typography.Text>
      {list}
    </Link>
  ) : (
    <div className={css.mobileMain}>
      {list}
      <Link {...commonLinkProps}>
        <Button>Show all alerts</Button>
      </Link>
    </div>
  )
}
