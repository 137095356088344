import { Empty } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { SwanBot } from 'swanviz'

import { getMissionLink } from '../../../../utils/links'
import { BatteryLevel } from '../../../BatteryLevel'
import { StatusIcon } from '../../../StatusIcon'
import { SwanBotIcon } from '../../../SwanBotIcon'

import css from './style.module.css'

export const SwanBotList: React.FC<{ swanBots: SwanBot[] }> = ({
  swanBots,
}) => {
  return (
    <div className={css.main}>
      {swanBots.length ? (
        <div className={css.list}>
          {swanBots.map(
            (swanBot) =>
              swanBot.currMissionId &&
              swanBot.currRunId &&
              swanBot.currMissionName && (
                <Link
                  key={swanBot.id}
                  className={css.item}
                  to={getMissionLink({
                    id: swanBot.currMissionId,
                    mode: 'view',
                    runId: swanBot.currRunId,
                  })}
                  target="_blank"
                >
                  <div>
                    <SwanBotIcon swanBot={swanBot} />
                    <div className={css.name} title={swanBot.name ?? undefined}>
                      {swanBot.name}
                    </div>
                  </div>
                  <div>
                    <StatusIcon status={swanBot.status} />
                    <div
                      className={css.mission}
                      title={swanBot.currMissionName}
                    >
                      {swanBot.currMissionName}
                    </div>
                  </div>
                  <BatteryLevel level={swanBot.batteryLevel} isCompact />
                </Link>
              )
          )}
        </div>
      ) : (
        <Empty
          description="No active missions in this area"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </div>
  )
}
