import { Area, getAreaList, getSwanBotsForUser } from 'swanviz'
import useSWR from 'swr'

import { SWANBOTS_PAGE_POLL_INTERVAL } from '../poll-config'
import { PopulatedSwanbot, populateSwanbots } from './populateSwanbots'

export const useAreasAndSwanBots = (): {
  swanBots: PopulatedSwanbot[]
  areas: Area[]
  isLoading: boolean
  isError: boolean
} => {
  const {
    data: areas = [],
    isValidating: areAreasLoading,
    error: areasError,
  } = useSWR('getAreaList', getAreaList)
  const {
    data,
    isValidating: areSwanBotsValidating,
    error: swanBotsError,
  } = useSWR('getSwanBotsForUser', getSwanBotsForUser, {
    refreshInterval: SWANBOTS_PAGE_POLL_INTERVAL,
  })

  const areSwanBotsLoadingFirstTime =
    data === undefined && areSwanBotsValidating

  const swanBots: PopulatedSwanbot[] = populateSwanbots(data || [], areas)

  return {
    swanBots,
    areas,
    isLoading: areAreasLoading || areSwanBotsLoadingFirstTime,
    isError: Boolean(areasError || swanBotsError),
  }
}
