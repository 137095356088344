import { Typography } from 'antd'
import { ApexOptions } from 'apexcharts'
import classnames from 'classnames'
import { dequal } from 'dequal/lite'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import ApexChart from 'react-apexcharts'
import { renderToString } from 'react-dom/server'
import { SensorParameter } from 'swanviz'

import { ReactComponent as ArrowIcon } from '../../../../icons/arrow.svg'
import { dateFormat, timeFormat } from '../../../../utils/momentFormat'
import { prettyCeil, prettyFloor, prettyRound } from '../../../../utils/numbers'
import { ColorPicker } from '../../../ColorPicker'

import css from './style.module.css'

const Y_AXIS_WIDTH = 65
const CHART_HEIGHT = 180
const CHART_FONT_FAMILY =
  "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif"

export type ApexChartsData = Array<[number, number | null]>

export type ChartRange = [number, number]

type Props = {
  className?: string
  onChangeColor: (color: string) => void
} & ChartProps

type ChartProps = {
  sensor: SensorParameter
  color: string
  data: ApexChartsData
  filename: string
  group: string
  xRange: ChartRange
  disableZoom: boolean
  onHover: (idx: number) => void
}

const titleStyles = {
  fontFamily: CHART_FONT_FAMILY,
  fontSize: '14px',
  fontWeight: 500,
}

const labelStyles = {
  fontFamily: CHART_FONT_FAMILY,
  fontSize: '11px',
  colors: 'rgba(0, 0, 0, 0.5)',
}

export const LinearChart: React.FC<Props> = (props) => {
  const { sensor } = props
  return (
    <div
      className={classnames(css.main, props.className)}
      style={{ ['--chart-height' as string]: `${CHART_HEIGHT}px` }}
    >
      <div className={css.header}>
        <Typography.Text className={css.hiddenTitle} style={titleStyles}>
          {getTitle(sensor)}
        </Typography.Text>
        <ColorPicker
          className={css.colorPicker}
          onChange={props.onChangeColor}
          value={props.color}
        />
      </div>
      <Chart {..._.omit(props, ['onChangeColor', 'className'])} />
    </div>
  )
}

const Chart = React.memo<ChartProps>((props) => {
  const { sensor } = props
  return (
    <ApexChart
      /**
       * Forcing rerender with random key to avoid issues with wrong colors and crashes:
       * https://github.com/apexcharts/react-apexcharts/issues/235
       * https://github.com/apexcharts/react-apexcharts/issues/236
       */
      key={Math.random()}
      series={[
        {
          name: sensor.description + (sensor.units ? ` (${sensor.units})` : ''),
          data: props.data,
        },
      ]}
      options={getChartOptions(props)}
      height={CHART_HEIGHT}
      type="line"
    />
  )
}, dequal)

const getChartOptions = ({
  sensor,
  color,
  filename,
  group,
  xRange,
  data,
  disableZoom,
  onHover,
}: ChartProps): ApexOptions => ({
  title: {
    text: getTitle(sensor),
    style: titleStyles,
    margin: 17,
    floating: true,
    align: 'left',
    offsetY: -6,
  },
  chart: {
    zoom: {
      enabled: !disableZoom,
      type: 'x',
      autoScaleYaxis: false,
    },
    toolbar: {
      tools: {
        download: renderToString(
          <Typography.Text className={css.download}>
            Download <ArrowIcon />
          </Typography.Text>
        ),
      },
      export: {
        csv: {
          headerCategory: 'Time',
          dateFormatter: (timestamp) =>
            timestamp
              ? moment(timestamp).format(`${dateFormat} ${timeFormat}`)
              : '-',
        },
      },
      autoSelected: 'pan',
    },
    width: '100%',
    animations: {
      enabled: false,
    },
    id: filename,
    group,
    events: {
      mouseMove: (
        event,
        chartContext,
        config: {
          config: ApexOptions
          seriesIndex: number
          dataPointIndex: number
        }
      ) => {
        onHover(config.dataPointIndex)
      },
      beforeZoom: (_e, { xaxis }: ApexOptions) => {
        // Prevent zooming out of bounds
        // @see https://stackoverflow.com/a/62833345
        const minX = data[0][0]
        const maxX = data[data.length - 1][0]
        const xDifference = maxX - minX
        const zoomDifference = (xaxis?.max || 0) - (xaxis?.min || 0)
        const shouldPreventZoomingOut = zoomDifference > xDifference

        return shouldPreventZoomingOut
          ? {
              xaxis: {
                min: minX,
                max: maxX,
              },
            }
          : {
              xaxis: {
                min: xaxis?.min,
                max: xaxis?.max,
              },
            }
      },
    },
  },
  grid: {
    borderColor: 'rgba(0, 0, 0, 0.15)',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
    width: 1,
  },
  markers: {
    size: 0,
    strokeWidth: 0,
    hover: {
      size: 3.5,
    },
  },
  xaxis: {
    type: 'datetime',
    min: xRange[0],
    max: xRange[1],
    axisBorder: {
      show: false,
    },
    crosshairs: {
      show: true,
      width: 1,
      stroke: {
        dashArray: 0,
        color: 'rgba(0, 0, 0, 0.25)',
      },
      fill: {
        type: 'solid',
      },
    },
    tooltip: {
      offsetY: 4,
      formatter: (value) => moment(value).format('H:mm:ss'),
    },
    labels: {
      datetimeUTC: false,
      offsetY: -3,
      maxHeight: 15,
      style: labelStyles,
    },
  },
  yaxis: {
    min: (min) => prettyFloor(min),
    max: (max) => prettyCeil(max),
    crosshairs: {
      show: false,
    },
    title: {
      text: sensor.units,
      style: {
        fontFamily: CHART_FONT_FAMILY,
        fontWeight: 500,
        fontSize: '12px',
      },
    },
    tickAmount: 5,
    labels: {
      offsetY: 2,
      minWidth: Y_AXIS_WIDTH,
      maxWidth: Y_AXIS_WIDTH,
      style: labelStyles,
      formatter: (value) => prettyRound(value).toLocaleString(),
    },
    tooltip: {
      enabled: false,
    },
  },
  tooltip: {
    custom: ({
      series,
      seriesIndex,
      dataPointIndex,
    }: {
      series: ApexChartsData
      seriesIndex: number
      dataPointIndex: number
    }) =>
      renderToString(
        <Typography.Text className={css.tooltip} style={{ background: color }}>
          {series[seriesIndex][dataPointIndex]?.toLocaleString()}
        </Typography.Text>
      ),
    onDatasetHover: {
      highlightDataSeries: true,
    },
  },
  colors: [color],
  noData: {
    text: 'No data yet',
  },
})

const getTitle = (sensor: SensorParameter): string => sensor.description
