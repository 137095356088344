import { GeoJsonLayer } from '@deck.gl/layers'
import { Coordinates, SwanBot } from 'swanviz'

import type { HoveredMapPoint } from '../'
import {
  HALF_WHITE_RGBA,
  hexToRgb,
  ORANGE_RGB,
  RGBColor,
  TRANSPARENT_RGBA,
} from '../../../../../utils/colors'
import {
  createLineFeature,
  createPointFeature,
} from '../../../../../utils/geojson'
import { PathsByRun } from '../../../../RunPathsFetcher'

export const getMissionRunLayers = ({
  pathsByRun,
  swanBots,
  onHover,
}: {
  pathsByRun: PathsByRun
  swanBots: SwanBot[]
  onHover: (point: HoveredMapPoint) => void
}) => {
  const runsPaths = Object.values(pathsByRun)
  const line = runsPaths
    .filter((runPath) => runPath.length > 0)
    .map((runPath) =>
      createLineFeature([...runPath.map((item) => item.location)], {
        color: getSwanBotLineColor(runPath[0].swanbotId, swanBots),
      })
    )

  const hoverPoints = runsPaths.flatMap((runPath) =>
    runPath.map((item) => createPointFeature(item.location))
  )

  return [
    new GeoJsonLayer({
      id: 'missionRunLine',
      data: line,
      getLineColor: (d) => d.properties?.color,
      lineWidthUnits: 'pixels',
      getLineWidth: 2,
      lineJointRounded: true,
    }),
    new GeoJsonLayer({
      id: 'hoverPoints',
      data: hoverPoints,
      onHover: (info) => {
        onHover((info.object?.geometry?.coordinates as Coordinates) || null)
      },
      getFillColor: TRANSPARENT_RGBA,
      getLineColor: TRANSPARENT_RGBA,
      pickable: true,
    }),
  ]
}

const getSwanBotLineColor = (
  swanBotId: number,
  swanBots: SwanBot[]
): RGBColor => {
  const swanBot = swanBots.find((sb) => sb.id === swanBotId)
  return swanBot?.iconColor ? hexToRgb(swanBot.iconColor) : ORANGE_RGB
}

export const getHoveredPointLayer = (
  coordinates: Coordinates | null | undefined
) => {
  return new GeoJsonLayer({
    id: 'hoveredPoint',
    data: coordinates ? [createPointFeature(coordinates)] : [],
    getLineColor: HALF_WHITE_RGBA,
    getFillColor: [0, 153, 255],
    getRadius: 4,
    pointRadiusUnits: 'pixels',
    getLineWidth: 1,
    lineWidthUnits: 'pixels',
    visible: !!coordinates,
  })
}
