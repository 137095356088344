import React from 'react'
import {
  Button as AntdButton,
  Form,
  Input as AntdInput,
  Typography,
} from 'antd'
import { InputProps } from 'antd/lib/input'
import { ButtonProps } from 'antd/lib/button'
import { FormItemProps } from 'antd/lib/form'

import { Logo } from '../Logo'

import css from './style.module.css'

type Props = {
  title: string
  subTitle?: React.ReactNode
  renderContent: () => React.ReactNode
  renderLink?: () => React.ReactNode
}

export const LoginWrapper: React.FC<Props> = ({
  title,
  subTitle,
  renderContent,
  renderLink,
}) => (
  <div className={css.main}>
    <div className={css.wrapper}>
      <div className={css.logoWrapper}>
        <Logo isBig className={css.logo} />
      </div>
      <div className={css.content}>
        <Typography.Text className={css.title}>{title}</Typography.Text>
        {subTitle && (
          <Typography.Text className={css.subtitle}>{subTitle}</Typography.Text>
        )}
        <div className={css.form}>{renderContent()}</div>
      </div>
      {renderLink && <div className={css.link}>{renderLink()}</div>}
    </div>
  </div>
)

export const Input: React.FC<
  { label: string } & Omit<InputProps, 'size'> &
    Pick<FormItemProps, 'rules' | 'dependencies'>
> = ({ label, name, rules = [], dependencies, ...rest }) => (
  <div className={css.input}>
    <Typography.Text className={css.label}>{label}</Typography.Text>
    <Form.Item name={name} rules={rules} dependencies={dependencies}>
      <AntdInput size="large" {...rest} />
    </Form.Item>
  </div>
)

export const Button: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <Form.Item shouldUpdate>
    {(form) => (
      <AntdButton
        className={css.button}
        {...rest}
        type="primary"
        danger
        htmlType="submit"
        block
        size="large"
        disabled={Boolean(
          !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length).length
        )}
      >
        {children}
      </AntdButton>
    )}
  </Form.Item>
)
