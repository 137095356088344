import React from 'react'
import {
  Area,
  Geofence,
  getAllSensorParameters,
  getAreaList,
  getGeofence,
  getInterpolationInfo,
  InterpolationInfo,
  InterpolationInputs,
  SensorParameter,
} from 'swanviz'
import useSWR from 'swr'

import { INTERPOLATION_INFO_POLL_INTERVAL } from '../../../poll-config'

type InterpolationPageData =
  | {
      loading: true
      error: false
      data?: never
    }
  | {
      loading: false
      error: true
      data?: never
    }
  | {
      loading: false
      error: false
      data: {
        interpolationInfo: InterpolationInfo | null
        isInterpolationInfoLoading: boolean
        sensors: SensorParameter[]
        area: Area
        geofence: Geofence
      }
    }

export const useInterpolationPageData = (
  params: InterpolationInputs
): InterpolationPageData => {
  const { data: sensors, isValidating: areSensorsLoading } = useSWR(
    'getAllSensorParameters',
    getAllSensorParameters
  )
  const { data: areas, isValidating: areAreasLoading } = useSWR(
    'getAreaList',
    getAreaList
  )
  const area = areas?.find((a) => a.id === params.areaId)
  const { data: geofence, isValidating: isGeofenceLoading } = useSWR(
    area?.geofenceId ? 'getGeofence' : null,
    () => (area?.geofenceId ? getGeofence(area.geofenceId) : undefined)
  )

  const [isInitialInfoLoading, setIsInitialInfoLoading] = React.useState(true)
  const [shouldRefreshInfo, setShouldRefreshInfo] = React.useState(false)
  const {
    data: interpolationInfo = null,
    isValidating: isInterpolationInfoLoading,
    error: interpolationInfoError,
  } = useSWR(
    ['getInterpolationInfo', JSON.stringify(params)],
    () => getInterpolationInfo(params),
    {
      refreshInterval: shouldRefreshInfo ? INTERPOLATION_INFO_POLL_INTERVAL : 0,
      onSuccess: (data) => {
        setShouldRefreshInfo(data?.status === 'STARTED')
        setIsInitialInfoLoading(false)
      },
    }
  )

  const loading =
    areSensorsLoading ||
    isInitialInfoLoading ||
    areAreasLoading ||
    isGeofenceLoading
  if (loading) {
    return {
      loading,
      error: false,
    }
  }

  const error =
    !sensors || !area || !geofence || Boolean(interpolationInfoError)
  if (error) {
    return {
      loading: false,
      error: true,
    }
  }

  return {
    loading: false,
    error: false,
    data: {
      sensors,
      interpolationInfo,
      isInterpolationInfoLoading,
      area,
      geofence,
    },
  }
}
