import { Collapse } from 'antd'
import classnames from 'classnames'
import React from 'react'

import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg'

import css from './style.module.css'

type Props = {
  className?: string
} & (
  | {
      collapsable?: false
      initialIsCollapsed?: never
      title?: React.ReactNode
    }
  | {
      collapsable: true
      initialIsCollapsed?: boolean
      title: React.ReactNode
    }
)

const COLLAPSE_PANEL_KEY = 'panel'

export const OverMapItem: React.FC<Props> = ({
  collapsable,
  initialIsCollapsed = false,
  title,
  className,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(initialIsCollapsed)

  return (
    <div
      className={classnames(
        css.main,
        className,
        collapsable && isCollapsed && css.isCollapsed
      )}
    >
      {collapsable ? (
        <Collapse
          accordion
          ghost
          expandIconPosition="right"
          activeKey={isCollapsed ? undefined : COLLAPSE_PANEL_KEY}
          expandIcon={(panelProps) => (
            <ArrowIcon
              className={classnames(
                css.arrow,
                panelProps.isActive && css.isActive
              )}
            />
          )}
          onChange={(key) => setIsCollapsed(key !== COLLAPSE_PANEL_KEY)}
        >
          <Collapse.Panel
            key={COLLAPSE_PANEL_KEY}
            header={<div className={css.title}>{title}</div>}
          >
            {children}
          </Collapse.Panel>
        </Collapse>
      ) : (
        <>
          {title && <div className={css.title}>{title}</div>}
          {children}
        </>
      )}
    </div>
  )
}
