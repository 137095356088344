import { kinks } from '@turf/turf'
import { Form, Select } from 'antd'
import React from 'react'
import { Coordinates, getAllSensorParameters, getSurveyTypeList } from 'swanviz'

import { createPolygonFeature } from '../../../utils/geojson'
import { useCachedSWR } from '../../../utils/useCachedSWR'
import { DurationFormItem } from '../DurationFormItem'
import { Label } from '../Label'
import { SpeedFormItem } from '../SpeedFormItem'
import { SurveyPolygonInput } from '../SurveyPolygonInput'

type Props = {
  isViewMode: boolean
  missionSpeed: number
}

export const SurveyTaskForm: React.FC<Props> = ({
  isViewMode,
  missionSpeed,
}) => {
  return (
    <>
      <Form.Item
        label={<Label>Polygon points</Label>}
        name={['args', 'aoi']}
        getValueProps={(value?: Coordinates[]) => {
          // Last point is just a duplicate of the first point
          return { value: value?.slice(0, -1) }
        }}
        getValueFromEvent={(newValue: Coordinates[]) =>
          newValue.length > 0 ? [...newValue, newValue[0]] : []
        }
        rules={[
          {
            required: true,
            type: 'array',
            min: 4, // 3 points for triangle + 1 closing point (duplicate of first point)
            message:
              'Please add at least 3 points or draw a polygon on the map',
            validateTrigger: 'onSubmit',
          },
          {
            validator: (_rule, value: Coordinates[]) => {
              const selfIntersections = kinks(createPolygonFeature([value]))
              return selfIntersections.features.length
                ? Promise.reject('Polygon should not have self-intersections')
                : Promise.resolve()
            },
          },
        ]}
      >
        <SurveyPolygonInput isViewMode={isViewMode} />
      </Form.Item>
      <TypeSelect isViewMode={isViewMode} />
      <SensorsSelect isViewMode={isViewMode} />
      <DurationFormItem isViewMode={isViewMode} />
      <SpeedFormItem isViewMode={isViewMode} missionSpeed={missionSpeed} />
    </>
  )
}

const TypeSelect: React.FC<{ isViewMode: boolean }> = ({ isViewMode }) => {
  const { data: surveyTypes, isValidating: isLoading } = useCachedSWR(
    isViewMode ? null : 'getSurveyTypeList',
    getSurveyTypeList
  )

  return (
    <Form.Item
      label={<Label>Type</Label>}
      name={['args', 'type']}
      rules={[
        {
          required: true,
          message: 'Please select survey type',
        },
      ]}
    >
      <Select
        placeholder="Select type"
        showArrow={true}
        options={surveyTypes?.map((type) => ({ value: type }))}
        disabled={isViewMode}
        loading={isLoading}
      />
    </Form.Item>
  )
}

const SensorsSelect: React.FC<{ isViewMode: boolean }> = ({ isViewMode }) => {
  const { data: sensorParameters, isValidating: isLoading } = useCachedSWR(
    isViewMode ? null : 'getAllSensorParameters',
    getAllSensorParameters
  )

  return (
    <Form.Item
      label={<Label>Sensors</Label>}
      name={['args', 'quantity']}
      rules={[
        {
          required: true,
          type: 'array',
          min: 1,
          message: 'Please select at least 1 sensor',
        },
      ]}
    >
      <Select
        mode="multiple"
        placeholder="Select parameter"
        showArrow={true}
        options={sensorParameters?.map((sensor) => ({
          value: sensor.name,
          label: sensor.name,
        }))}
        disabled={isViewMode}
        loading={isLoading}
      />
    </Form.Item>
  )
}
