import { RGBAColor } from '@deck.gl/core/utils/color'
import { GeoJSON } from 'geojson'
import { MissionTask } from 'swanviz'

import { getTransparentColor, RGBColor } from '../../../../../utils/colors'
import { isDefined } from '../../../../../utils/typeGuards'

import type { HoveredTaskId, SelectedTaskId } from '../../'

type PointStyleType = 'default' | 'active' | 'hidden'

const POINTS_MAP_STYLE: Record<
  PointStyleType,
  {
    radius: number
    width: number
    fill: RGBColor | RGBAColor
    line: RGBColor | RGBAColor
  }
> = {
  default: {
    radius: 5,
    width: 1,
    fill: [218, 218, 218],
    line: [189, 39, 8],
  },
  active: {
    radius: 6,
    width: 3,
    fill: [230, 120, 47],
    line: [255, 255, 255],
  },
  hidden: {
    radius: 0,
    width: 0,
    fill: getTransparentColor() as RGBAColor,
    line: getTransparentColor() as RGBAColor,
  },
}

export const getFeatureStyleGetter = ({
  isViewMode,
  tasks,
  hoveredTaskId,
  selectedTaskId,
}: {
  isViewMode: boolean
  tasks: MissionTask[]
  hoveredTaskId: HoveredTaskId
  selectedTaskId: SelectedTaskId
}) => {
  const getFeatureTask = (feature: GeoJSON.Feature): MissionTask | undefined =>
    tasks.find((task) => task.id === feature.properties?.taskId)

  const isFeatureActive = (feature: GeoJSON.Feature): boolean => {
    const featureTask = getFeatureTask(feature)
    if (isDefined(featureTask)) {
      const isGoHomeTask =
        featureTask.type === 'go' && featureTask.args.location === 'home'
      return isViewMode || isGoHomeTask
        ? [hoveredTaskId, selectedTaskId].includes(featureTask.id)
        : featureTask.id === hoveredTaskId
    }
    return false
  }

  const arePointsHidden = (feature: GeoJSON.Feature): boolean => {
    const featureTask = getFeatureTask(feature)
    return (
      !isViewMode &&
      featureTask?.id === selectedTaskId &&
      featureTask?.type === 'lawnmower'
    )
  }

  return <T extends keyof typeof POINTS_MAP_STYLE[PointStyleType]>(style: T) =>
    (feature: GeoJSON.Feature): typeof POINTS_MAP_STYLE[PointStyleType][T] => {
      const featureType: PointStyleType = (() => {
        if (isFeatureActive(feature)) return 'active'
        if (arePointsHidden(feature)) return 'hidden'
        return 'default'
      })()
      return POINTS_MAP_STYLE[featureType][style]
    }
}
