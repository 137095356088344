import { Spin, Typography } from 'antd'
import React from 'react'

import { useLatestImage } from '../../../utils/useLatestImage'

import css from './style.module.css'

type Props = {
  runId: number
}

export const LatestImage: React.FC<Props> = ({ runId }) => {
  const { data, isLoading, isError } = useLatestImage(runId)
  const url = data?.imageURL

  return (
    <Spin spinning={!data && isLoading}>
      <div className={css.main}>
        {isError ? (
          <Typography.Text type="danger" className={css.text}>
            Couldn&apos;t get latest image
          </Typography.Text>
        ) : url ? (
          <img className={css.image} src={url} alt="Latest from SwanBot" />
        ) : (
          <Typography.Text type="secondary" className={css.text}>
            No image from SwanBot yet
          </Typography.Text>
        )}
      </div>
    </Spin>
  )
}
