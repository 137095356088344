import React from 'react'
import { Typography } from 'antd'
import classnames from 'classnames'

import css from './style.module.css'

export const PageTitle: typeof Typography.Title = ({
  children,
  className,
  ...restProps
}) => (
  <Typography.Title
    level={1}
    className={classnames(css.title, className)}
    {...restProps}
  >
    {children}
  </Typography.Title>
)
