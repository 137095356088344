import { Button, ConfigProvider, Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import { Area, NotificationFilter, SwanBot } from 'swanviz'

import { formatType, NOTIFICATION_TYPES } from '../../../../utils/notifications'
import { getItemsForSelect } from '../../../../utils/select'
import { AreasSelect } from '../../../AreasSelect'
import { InformationItem } from '../../../InformationItem'
import { SingleCollapse } from '../../../SingleCollapse'

import css from './style.module.css'

type Props = {
  areas: Area[]
  swanBots: SwanBot[]
  value: NotificationFilter
  onChange: (filter: Partial<NotificationFilter>) => void
  onReset: () => void
}

export const MobileAlertsFilters: React.FC<Props> = ({
  areas,
  swanBots,
  value,
  onChange,
  onReset,
}) => {
  const content = (
    <SingleCollapse header="Filter by type, area or SwanBot">
      <div className={css.main}>
        <InformationItem label="Type of notification" className={css.type}>
          <Select
            {...COMMON_SELECT_PROPS}
            mode="multiple"
            showSearch={false}
            placeholder="Select types"
            value={value.type}
            options={NOTIFICATION_TYPES.map((value) => ({
              label: formatType(value),
              value,
            }))}
            optionFilterProp="label"
            onChange={(types) => onChange({ type: types })}
          />
        </InformationItem>

        <InformationItem label="Area">
          <AreasSelect
            {...COMMON_SELECT_PROPS}
            areas={areas}
            mode="multiple"
            showSearch={false}
            placeholder="Select areas"
            value={value.areaId}
            onChange={(areaIds) => onChange({ areaId: areaIds })}
          />
        </InformationItem>

        <InformationItem label="SwanBot">
          <Select
            {...COMMON_SELECT_PROPS}
            mode="multiple"
            showSearch={false}
            placeholder="Select SwanBots"
            value={value.swanbotId}
            options={getItemsForSelect(swanBots).map((swanBot) => ({
              label: swanBot.name,
              value: swanBot.id,
            }))}
            optionFilterProp="label"
            dropdownAlign={{ points: ['tr', 'br'] }}
            onChange={(swanBotIds) => onChange({ swanbotId: swanBotIds })}
          />
        </InformationItem>

        <InformationItem label="Sort by">
          <Select
            {...COMMON_SELECT_PROPS}
            placeholder="Select sorting field"
            value={value.sortField}
            options={SORT_FIELD_OPTIONS}
            onChange={(sortField) => onChange({ sortField })}
          />
        </InformationItem>

        <InformationItem label="Sort direction">
          <Select
            {...COMMON_SELECT_PROPS}
            placeholder="Select sorting direction"
            value={value.sortDirection}
            options={SORT_DIRECTION_OPTIONS}
            onChange={(sortDirection) => onChange({ sortDirection })}
          />
        </InformationItem>

        <Button className={css.reset} type="text" onClick={onReset}>
          Reset filters
        </Button>
      </div>
    </SingleCollapse>
  )

  return (
    <ConfigProvider
      getPopupContainer={(trigger) => trigger?.parentElement || document.body}
      componentSize="large"
    >
      {content}
    </ConfigProvider>
  )
}

const COMMON_SELECT_PROPS: SelectProps<any> = {
  style: { width: '100%' },
  dropdownMatchSelectWidth: 320,
}

const SORT_FIELD_OPTIONS: Array<{
  value: NotificationFilter['sortField']
  label: string
}> = [
  {
    value: 'ntf_time',
    label: 'Date and time',
  },
  {
    value: 'type',
    label: 'Type of notification',
  },
  {
    value: 'area_id',
    label: 'Area',
  },
  {
    value: 'swanbot_id',
    label: 'SwanBot',
  },
]

const SORT_DIRECTION_OPTIONS: Array<{
  value: NotificationFilter['sortDirection']
  label: string
}> = [
  {
    value: 'ASC',
    label: 'Ascending',
  },
  {
    value: 'DESC',
    label: 'Descending',
  },
]
