import { Checkbox, Empty, Spin, Typography } from 'antd'
import * as React from 'react'
import { MissionRun, SensorParameter } from 'swanviz'

import { formatDateTimeList } from '../../utils/time'
import { MissionRunFilterWithShowPath } from '../pages/DataVis/types'
import { ReloadPageResult } from '../ReloadPageResult'

import { selectAllMissionRuns, toggleRun, toggleSensor } from './helpers'
import { ItemContainer } from './ItemContainer'
import { Run } from './Run'
import css from './style.module.css'

export type OnMissionRunsSelectionChange = (
  missionRunFilter: MissionRunFilterWithShowPath[]
) => void

type Props = {
  missionRuns: MissionRun[]
  selection: MissionRunFilterWithShowPath[]
  sensors: SensorParameter[]
  isLoading: boolean
  isError: boolean
  onChange: OnMissionRunsSelectionChange
}

export const RunsList: React.FC<Props> = ({
  missionRuns,
  selection,
  sensors,
  isLoading,
  isError,
  onChange,
}) => {
  const showHeaderCheckbox =
    !isLoading && !isError && Boolean(missionRuns.length)
  const areAllSelected = () => selection.length === missionRuns.length
  const areSomeSelected = () =>
    selection.length > 0 && selection.length < missionRuns.length

  const onCheckAllToggle = () => {
    if (areAllSelected()) {
      onChange([])
    } else {
      onChange(selectAllMissionRuns(missionRuns, selection))
    }
  }

  const onMissionRunToggle = (runId: MissionRun['runId']) => {
    onChange(toggleRun(selection, runId))
  }

  const onSensorToggle = (
    runId: MissionRun['runId'],
    sensorId: SensorParameter['id']
  ) => {
    onChange(toggleSensor(selection, runId, sensorId))
  }

  const renderMissionRuns = () => {
    if (!isLoading && !missionRuns.length) {
      return (
        <Empty
          description="No mission runs were found"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )
    }

    const dateTimeObjects = formatDateTimeList({
      dates: missionRuns.map((missionRun) => missionRun.runDate),
    })

    return missionRuns.map((missionRun, i) => {
      const dateTime = dateTimeObjects[i]
      const date = dateTime.date
      const time =
        missionRun.status === 'MISSION STARTED' ? 'Now' : dateTime.time

      const sensorsSelection = selection.find(
        (item) => item.runId === missionRun.runId
      )

      return (
        <Run
          key={missionRun.runId}
          isSelected={selection.some((item) => item.runId === missionRun.runId)}
          missionRun={missionRun}
          date={date}
          time={time}
          selectedSensorIds={
            sensorsSelection ? sensorsSelection.sensorParamList : []
          }
          allSensorParameters={sensors}
          onMissionRunToggle={onMissionRunToggle}
          onSensorToggle={onSensorToggle}
        />
      )
    })
  }

  return (
    <Spin spinning={isLoading}>
      <div className={css.header}>
        <ItemContainer
          checkbox={
            showHeaderCheckbox ? (
              <Checkbox
                indeterminate={areSomeSelected()}
                onChange={onCheckAllToggle}
                checked={areAllSelected()}
                className={css.checkbox}
              />
            ) : null
          }
          date={<Typography.Text type="secondary">Date</Typography.Text>}
          missionRun={
            <Typography.Text type="secondary">Mission run</Typography.Text>
          }
        />
      </div>

      {isError ? (
        <ReloadPageResult title="Couldn't get mission runs list" />
      ) : (
        renderMissionRuns()
      )}
    </Spin>
  )
}
