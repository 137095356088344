import type { ComponentType } from 'react'
import type { RouteProps } from 'react-router'

import { Page404 } from './components/pages/404/404'
import { Alerts } from './components/pages/Alerts'
import { AreaPage } from './components/pages/Area'
import { Dashboard } from './components/pages/Dashboard'
import { DataVis } from './components/pages/DataVis'
import { ForgotPassword } from './components/pages/ForgotPassword'
import { GeofencePage } from './components/pages/Geofence'
import { Interpolation } from './components/pages/Interpolation'
import { Login } from './components/pages/Login'
import { Mission } from './components/pages/Mission'
import { MissionsAndGeofences } from './components/pages/MissionsAndGeofences'
import { ResetPassword } from './components/pages/ResetPassword'
import { SwanBots } from './components/pages/SwanBots'
import { Users } from './components/pages/Users'
import { UserSettings } from './components/pages/UserSettings'
import type { CheckHasAccess } from './components/ProtectedRoute'
import {
  ALERTS_URL,
  AREA_URL,
  DASHBOARD_URL,
  DATA_URL,
  GEOFENCE_URL,
  LOGIN_URL,
  SETTINGS_URL,
} from './utils/links'
import { UserPermissions } from './utils/usePermissions'

export type RouteInfo = {
  path: string
  component: ComponentType
  exact?: RouteProps['exact']
  key?: string
  requiresAuthentication?: boolean
  checkHasAccess?: CheckHasAccess
  noLayout?: boolean
  title?: string | null
}

export const routes: RouteInfo[] = [
  {
    path: LOGIN_URL,
    component: Login,
    noLayout: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    noLayout: true,
  },
  {
    path: '/reset',
    component: ResetPassword,
    noLayout: true,
  },
  {
    path: DASHBOARD_URL,
    component: Dashboard,
    title: 'Dashboard',
    requiresAuthentication: true,
  },
  {
    path: `${AREA_URL}/:areaId`,
    component: AreaPage,
    title: null,
    requiresAuthentication: true,
    checkHasAccess: (permissions) => permissions.missions.canView,
  },
  {
    path: DATA_URL,
    component: DataVis,
    title: 'Data',
    requiresAuthentication: true,
    checkHasAccess: (p: UserPermissions) => p.dataVis.canView,
  },
  {
    path: '/interpolation',
    component: Interpolation,
    title: 'Interpolated data',
    requiresAuthentication: true,
    checkHasAccess: (p: UserPermissions) => p.dataVis.canView,
  },
  {
    path: '/missions-and-geofences',
    component: MissionsAndGeofences,
    title: 'Missions And Geofences',
    requiresAuthentication: true,
    checkHasAccess: (p: UserPermissions) => p.missions.canView,
  },
  {
    // Using same key for both mission paths to avoid component remounting
    key: 'mission',
    path: '/mission/new',
    component: Mission,
    title: 'New Mission',
    requiresAuthentication: true,
    checkHasAccess: (p: UserPermissions) => p.missions.canManage,
  },
  {
    key: 'mission',
    path: '/mission/:missionId',
    component: Mission,
    title: 'Mission',
    requiresAuthentication: true,
    checkHasAccess: (p: UserPermissions) => p.missions.canView,
  },
  {
    path: `${GEOFENCE_URL}/new`,
    component: GeofencePage,
    title: 'New Geofence',
    requiresAuthentication: true,
    checkHasAccess: (p: UserPermissions) => p.geofences.canManage,
  },
  {
    path: `${GEOFENCE_URL}/:geofenceId`,
    component: GeofencePage,
    title: 'Geofence',
    requiresAuthentication: true,
    checkHasAccess: (p: UserPermissions) => p.geofences.canView,
  },
  {
    path: '/swan-bots',
    component: SwanBots,
    title: 'SwanBots',
    requiresAuthentication: true,
  },
  {
    path: ALERTS_URL,
    component: Alerts,
    title: 'Alerts',
    requiresAuthentication: true,
    checkHasAccess: (p: UserPermissions) => p.alerts.canView,
  },
  {
    path: SETTINGS_URL,
    component: UserSettings,
    title: 'Settings',
    requiresAuthentication: true,
  },
  {
    path: '/users',
    component: Users,
    title: 'Users',
    requiresAuthentication: true,
    checkHasAccess: (p: UserPermissions) => p.users.canManage,
  },
  {
    path: '*',
    component: Page404,
    noLayout: true,
    exact: false,
  },
]
