import { MissionRun, MissionType, NewMissionType } from 'swanviz'

export const isRunStarted = (
  status: MissionRun['status'] | undefined
): boolean =>
  !!status &&
  ['MISSION RESUMED', 'MISSION SUSPENDED', 'MISSION STARTED'].includes(status)

export const isRunExecuting = (
  status: MissionRun['status'] | undefined
): boolean => !!status && isRunStarted(status) && status !== 'MISSION SUSPENDED'

export const isMissionRunning = (
  mission: MissionType | NewMissionType
): mission is MissionType => 'status' in mission && mission.status === 'RUNNING'
