export const MISSION_NOTIFICATIONS_POLL_INTERVAL = 10000
export const MISSION_ACTUAL_PATH_POLL_INTERVAL = 5000
export const MISSION_TASKS_POLL_INTERVAL = 3000
export const MISSION_POLL_INTERVAL = 3000
export const SWANBOTS_POLL_INTERVAL = 3000
export const GEOFENCES_POLL_INTERVAL = 5000
export const LATEST_IMAGE_POLL_INTERVAL = 5000
export const SWANBOTS_PAGE_POLL_INTERVAL = 5000
export const SENSOR_DATA_POLL_INTERVAL = 3000
export const MISSION_RUN_POLL_INTERVAL = 3000
export const SWANBOT_SENSORS_DATA_POLL_INTERVAL = 10000
export const INTERPOLATION_INFO_POLL_INTERVAL = 5000
