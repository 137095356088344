import { Table, Typography } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { ColumnFilterItem } from 'antd/es/table/interface'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Area, Geofence, MissionType } from 'swanviz'

import { getMissionLink } from '../../../../utils/links'
import { isMissionRunning } from '../../../../utils/missionRuns'
import { shortTimeFormat } from '../../../../utils/momentFormat'
import {
  getColumnFilterProps,
  getColumnSearchProps,
  getFilters,
  sorter,
  stickyProps,
} from '../../../../utils/table'
import { formatTotalTime } from '../../../../utils/time'
import { usePermissions } from '../../../../utils/usePermissions'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { AddButton } from '../../../AddButton'
import { LastRunLink } from '../../../LastRunLink'
import { TableFilterIcon } from '../../../TableFilterIcon'
import { TableHeader } from '../../../TableHeader'
import { TableSelectFilter } from '../../../TableSelectFilter'
import { DeleteMissionsButton } from '../DeleteMissionsButton'

export type PopulatedMission = MissionType & {
  areaName: string
  geofenceName: string
}

type Props = {
  missions: PopulatedMission[]
  areas: Area[]
  geofences: Geofence[]
  pagination: TablePaginationConfig
  isLoading: boolean
}

export const MissionsTable: React.FC<Props> = ({
  missions,
  areas,
  geofences,
  pagination,
  isLoading,
}) => {
  const { isDesktop } = useWindowSize()
  const permissions = usePermissions()
  const canManageMissions = isDesktop && permissions.missions.canManage

  const [isDeleting, setDeleting] = React.useState(false)
  const [selectedMissionIds, setSelectedMissionIds] = React.useState<
    Array<PopulatedMission['id']>
  >([])

  React.useEffect(() => {
    setSelectedMissionIds((selectedMissionIdsState) =>
      // Remove outdated ids from selected list
      selectedMissionIdsState.filter((missionId) =>
        missions.some(
          (mission) => mission.id === missionId && !isMissionRunning(mission)
        )
      )
    )
  }, [missions])

  const areaFilters = getFilters({
    items: areas,
    getValue: (item) => item.name,
  })

  const geofenceFilters = getFilters({
    items: geofences,
    getValue: (item) => item.name,
  })

  return (
    <>
      <TableHeader
        title="Missions"
        buttons={
          canManageMissions && (
            <>
              <Link to={getMissionLink({ id: 'new' })} target="_blank">
                <AddButton>New mission</AddButton>
              </Link>
              <DeleteMissionsButton
                missionIds={selectedMissionIds}
                onDeletingChange={setDeleting}
              />
            </>
          )
        }
      />
      <Table
        rowKey={(row) => row.id}
        loading={isLoading || isDeleting}
        rowSelection={
          canManageMissions
            ? {
                selectedRowKeys: selectedMissionIds,
                hideSelectAll: true,
                getCheckboxProps: (mission) => ({
                  disabled: isMissionRunning(mission),
                }),
                onChange: (newSelectedKeys) =>
                  setSelectedMissionIds(
                    newSelectedKeys as Array<PopulatedMission['id']>
                  ),
              }
            : undefined
        }
        dataSource={missions}
        columns={getMissionColumns(areaFilters, geofenceFilters).concat(
          isDesktop ? {} : []
        )}
        pagination={pagination}
        scroll={{ x: 1310 }}
        {...stickyProps}
      />
    </>
  )
}

const getMissionColumns = (
  areaFilters: ColumnFilterItem[],
  geofenceFilters: ColumnFilterItem[]
): ColumnsType<PopulatedMission> => [
  {
    title: 'Mission',
    dataIndex: 'name',
    render: (text, mission) => (
      <Link to={getMissionLink({ id: mission.id })}>
        <Typography.Link>{text}</Typography.Link>
      </Link>
    ),
    width: 205,
    sorter: sorter('name'),
    ...getColumnSearchProps('name'),
  },
  {
    title: 'Area',
    dataIndex: 'areaName',
    width: 127,
    sorter: sorter('areaName'),
    filters: areaFilters,
    ...getColumnFilterProps('areaName'),
  },
  {
    title: 'Geofence',
    dataIndex: 'geofenceName',
    width: 271,
    sorter: sorter('geofenceName'),
    filters: geofenceFilters,
    filterDropdown: TableSelectFilter,
    filterIcon: TableFilterIcon,
    onFilter: (value, record) => record.geofenceName === value,
  },
  {
    title: 'Est. time',
    dataIndex: 'totalTime',
    sorter: sorter('totalTime'),
    width: 138,
    render: (totalTime: number) => formatTotalTime(totalTime),
  },
  {
    title: 'Distance',
    dataIndex: 'totalDistance',
    sorter: sorter('totalDistance'),
    width: 120,
    render: (totalDistance: number) =>
      totalDistance ? Math.round(totalDistance).toLocaleString() + ' m' : '—',
  },
  {
    title: 'Author',
    dataIndex: 'createdUser',
    width: 150,
    sorter: sorter('createdUser'),
    ...getColumnSearchProps('createdUser'),
  },
  {
    title: 'Last run',
    dataIndex: 'lastMissionRun',
    width: 120,
    render: (lastMissionRun: number | null, mission: MissionType) => (
      <LastRunLink
        missionId={mission.id}
        areaId={mission.areaId}
        lastMissionRun={lastMissionRun}
      />
    ),
    sorter: sorter('lastMissionRun'),
    defaultSortOrder: 'descend',
  },
  {
    title: 'Current runs',
    width: 220,
    filterIcon: TableFilterIcon,
    filters: [{ text: 'With active runs', value: true }],
    onFilter: (value, record) => Boolean(value && record.currentRunId?.length),
    render: (_value, mission: MissionType) => {
      return mission.currentRunId?.map((runId, runIdx) => {
        const runDate = mission.currentRunDate?.[runIdx]
        return (
          <div key={runId}>
            <Link to={getMissionLink({ id: mission.id, mode: 'view', runId })}>
              #{runId}
            </Link>{' '}
            <Typography.Text type="secondary">
              (started at {moment(runDate).format(shortTimeFormat)})
            </Typography.Text>
          </div>
        )
      })
    },
  },
]
