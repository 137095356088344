import _ from 'lodash'

export const getSignificantDigitPlace = (n: number): number =>
  n === 0 ? 0 : Math.floor(Math.log10(Math.abs(n)))

const SIGNIFICANT_DIGITS_COUNT = 2

export const getRoundingPrecision = (n: number): number => {
  const significantDigitPlace = getSignificantDigitPlace(n)
  return significantDigitPlace >= 1
    ? 0
    : Math.abs(significantDigitPlace + 1 - SIGNIFICANT_DIGITS_COUNT)
}

export const prettyRound = (n: number): number =>
  _.round(n, getRoundingPrecision(n))
export const prettyFloor = (n: number): number =>
  _.floor(n, getRoundingPrecision(n))
export const prettyCeil = (n: number): number =>
  _.ceil(n, getRoundingPrecision(n))
