import type { MissionTaskWithSpeed } from './'

type Validator = (tasks: MissionTaskWithSpeed[]) => string | undefined

export const validateMissionTasks: Validator = (tasks) => {
  if (!tasks.length) {
    return 'Add one or more tasks to save mission'
  }

  return validateLawnmowerTasks(tasks) ?? validateSensorsTasks(tasks)
}

const validateLawnmowerTasks: Validator = (tasks) => {
  let areCoordinatesDetermined = false
  for (const [idx, task] of tasks.entries()) {
    switch (task.type) {
      case 'lawnmower': {
        if (areCoordinatesDetermined) {
          areCoordinatesDetermined = false
        } else {
          return `There should be a "Go" task with coordinates before "Lawnmower" (task #${
            idx + 1
          })`
        }
        break
      }
      case 'go': {
        areCoordinatesDetermined = task.args.location !== 'home'
        break
      }
      case 'survey': {
        areCoordinatesDetermined = false
        break
      }
    }
  }
}

const validateSensorsTasks: Validator = (tasks) => {
  if (tasks.every((task) => task.type === 'sensors')) {
    return "Mission can't consist only of sensors tasks"
  }

  let previousSensorsState = null
  for (const [idx, task] of tasks.entries()) {
    if (task.type === 'sensors') {
      const { enable } = task.args
      if (enable === previousSensorsState) {
        return `Sensors can't be ${
          enable ? 'enabled' : 'disabled'
        } twice (task #${idx + 1})`
      }

      previousSensorsState = enable
    }
  }
}
