import { MissionType, NewMissionType, SwanBot } from 'swanviz'
import { isExistingMission } from '../components/pages/Mission/helpers'

export const statusNames: Record<SwanBot['status'], string> = {
  IN_A_MISSION: 'On a mission',
  SUSPENDED: 'Suspended',
  IDLE: 'Idle',
  ABORT_TO_HOME: 'Returning home',
  OFF: 'Offline',
}

const getStatusIdx = (swanBot: SwanBot): number =>
  swanBot.delFlag ? Infinity : Object.keys(statusNames).indexOf(swanBot.status)

export const swanBotStatusSorter = (a: SwanBot, b: SwanBot): number =>
  getStatusIdx(a) - getStatusIdx(b)

const BATTERY_LEVELS_ORDER: Array<SwanBot['batteryLevel']> = [
  'Low',
  'Medium',
  'Full',
]

const getBatteryLevelWeight = (batteryLevel: SwanBot['batteryLevel']): number =>
  BATTERY_LEVELS_ORDER.indexOf(batteryLevel)

export const batteryLevelSorter = (a: SwanBot, b: SwanBot): number =>
  getBatteryLevelWeight(a.batteryLevel) - getBatteryLevelWeight(b.batteryLevel)

export const getActivityLogFilename = (swanBot: SwanBot): string =>
  `${swanBot.name}'s activity log`

export const isSwanBotActive = (swanBot: SwanBot): boolean =>
  swanBot.status !== 'OFF'

export const isSwanBotOnMission = (swanBot: SwanBot): boolean =>
  ['IN_A_MISSION', 'SUSPENDED'].includes(swanBot.status)

export const isSwanBotRunningAnotherMission = (
  swanBot: SwanBot,
  mission: MissionType | NewMissionType
): boolean => {
  return (
    isExistingMission(mission) &&
    isSwanBotOnMission(swanBot) &&
    swanBot.currMissionId !== mission.id
  )
}

export const isSwanBotSelectable = (
  swanBot: SwanBot,
  mission: MissionType | NewMissionType
): boolean =>
  isSwanBotActive(swanBot) && !isSwanBotRunningAnotherMission(swanBot, mission)
