import _ from 'lodash'
import React from 'react'
import { Area, Boundary, GeofenceUpdate } from 'swanviz'

import { PageWithMapLayout } from '../../../PageWithMapLayout'
import { GeofenceHeader } from '../GeofenceHeader'
import { GeofenceMap } from '../GeofenceMap'
import { ObstructionsList } from '../ObstructionsList'

import css from './style.module.css'

export type HoveredPolygonId = Boundary['id'] | undefined

type Props = {
  geofence: GeofenceUpdate
  area: Area
  isAddingNewGeofence: boolean
  isSaving: boolean
  isDeleting: boolean
  onChange: (geofence: GeofenceUpdate) => void
  onSave: () => void
  onDelete: () => void
}

export const GeofencePageContent: React.FC<Props> = ({
  geofence,
  area,
  isAddingNewGeofence,
  isSaving,
  isDeleting,
  onChange,
  onSave,
  onDelete,
}) => {
  const [hoveredPolygonId, setHoveredPolygonId] =
    React.useState<HoveredPolygonId>()
  const [isAddingNewPolygon, setIsAddingNewPolygon] = React.useState(
    geofence.boundaries.length === 0
  )

  const obstructions = _.tail(geofence.boundaries)
  const handleObstructionsChange = (newObstructions: Boundary[]) =>
    onChange({
      ...geofence,
      boundaries: [geofence.boundaries[0], ...newObstructions],
    })

  return (
    <PageWithMapLayout
      className={css.main}
      header={
        <div className={css.header}>
          <GeofenceHeader
            geofence={geofence}
            area={area}
            isAddingNewGeofence={isAddingNewGeofence}
            isSaving={isSaving}
            isDeleting={isDeleting}
            onChange={onChange}
            onSave={onSave}
            onDelete={onDelete}
          />
        </div>
      }
      aside={
        <div className={css.list}>
          <ObstructionsList
            obstructions={obstructions}
            hoveredPolygonId={hoveredPolygonId}
            isAddingNewPolygon={isAddingNewPolygon}
            onHover={setHoveredPolygonId}
            onChange={handleObstructionsChange}
            onAdd={() => setIsAddingNewPolygon(true)}
          />
        </div>
      }
      map={
        <GeofenceMap
          area={area}
          boundaries={geofence.boundaries}
          hoveredPolygonId={hoveredPolygonId}
          isAddingNewPolygon={isAddingNewPolygon}
          onHover={setHoveredPolygonId}
          onChange={(boundaries) => onChange({ ...geofence, boundaries })}
          onAdd={(polygon) => {
            onChange({
              ...geofence,
              boundaries: [...geofence.boundaries, polygon],
            })
            setIsAddingNewPolygon(false)
          }}
        />
      }
    />
  )
}
