import React from 'react'
import { Typography } from 'antd'

import css from './style.module.css'

export const TextRows: React.FC<{
  firstRow: React.ReactNode
  secondRow?: React.ReactNode
}> = ({ firstRow, secondRow }) => (
  <div className={css.main}>
    <Typography.Text className={css.firstRow}>{firstRow}</Typography.Text>
    <Typography.Text type="secondary" className={css.secondRow}>
      {secondRow || '—'}
    </Typography.Text>
  </div>
)
