import React from 'react'
import { Button } from 'antd'

import { ReactComponent as AddIcon } from './addIcon.svg'
import css from './style.module.css'

export const AddButton: React.FC<React.ComponentProps<typeof Button>> = (
  props
) => {
  return (
    <Button type="primary" {...props} icon={<AddIcon className={css.icon} />} />
  )
}
