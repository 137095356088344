import { Typography } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { InterpolatedData, SensorData, SensorParameter } from 'swanviz'

import { getSensorHeatmapDataRange } from '../../geoLayers/heatmapLayer'
import { prettyCeil, prettyFloor } from '../../utils/numbers'
import { getSensorParamDesc } from '../../utils/sensorParameters'
import { ColorPicker } from '../ColorPicker'

import css from './style.module.css'

export const MapLegendItem: React.FC<{
  subtitle?: string
  color: string
  data: SensorData[] | InterpolatedData[] | undefined
  sensor: SensorParameter
  isLoading?: boolean
  isActive?: boolean
  onClick?: () => void
  onChangeColor?: (color: string) => void
}> = ({ subtitle, color, data, sensor, isActive, onClick, onChangeColor }) => {
  const [min, max] = getSensorHeatmapDataRange(data, sensor)

  return (
    <button
      type="button"
      className={css.button}
      onClick={onClick}
      disabled={!onClick}
    >
      <div
        className={classNames(
          css.legend,
          isActive && css.isActive,
          onClick && css.isClickable
        )}
      >
        <div className={css.title}>{getSensorParamDesc(sensor)}</div>
        <div
          className={css.color}
          style={{
            background: `linear-gradient(90deg, #fff, ${color})`,
          }}
        />
        {onChangeColor && (
          <ColorPicker
            className={css.colorPicker}
            onChange={onChangeColor}
            value={color}
          />
        )}
        <div className={css.bottom}>
          <Typography.Text>{prettyFloor(min)}</Typography.Text>
          <Typography.Text className={css.subtitle}>{subtitle}</Typography.Text>
          <Typography.Text>{prettyCeil(max)}</Typography.Text>
        </div>
      </div>
    </button>
  )
}
