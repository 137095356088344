import { message } from 'antd'
import _ from 'lodash'
import React from 'react'
import { Area, Coordinates, getTaskEstimate, MissionTask } from 'swanviz'

import type { MissionTaskWithSpeed } from '../'
import { useEstimatesUpdates } from '../../../../contexts/estimatesUpdatesContext'
import { ERROR_MESSAGES } from '../../../../utils/errorMessages'
import { getTaskTypeName } from '../../../../utils/missionTask'
import {
  getPreviousLocation,
  updateTaskById,
} from '../../../../utils/missionTasks'
import { useDebounce } from '../../../../utils/useDebounce'
import { useDeepMemoizedValue } from '../../../../utils/useDeepMemoizedValue'

type Props = {
  tasks: MissionTaskWithSpeed[]
  area: Area
  missionSpeed: number
  onChange: (
    updater: (previousTasks: MissionTaskWithSpeed[]) => MissionTaskWithSpeed[]
  ) => void
}

export const EstimatesUpdaters: React.FC<Props> = React.memo(
  ({ tasks, area, missionSpeed, onChange }) => {
    const onEstimateChange = React.useCallback(
      (id: number, patch: Partial<MissionTask>) => {
        onChange((previousTasks) => {
          const previousValue = previousTasks.find((task) => task.id === id)
          return previousValue
            ? updateTaskById(previousTasks, {
                ...previousValue,
                ...patch,
              } as MissionTaskWithSpeed)
            : previousTasks
        })
      },
      [onChange]
    )

    return (
      <>
        {tasks.map((task) => {
          const previousLocation = getPreviousLocation({
            taskId: task.id,
            allTasks: tasks,
          })

          return (
            <EstimatesUpdater
              key={`${task.type}-${task.id}`}
              task={task}
              area={area}
              missionSpeed={missionSpeed}
              previousLocation={previousLocation}
              onChange={onEstimateChange}
            />
          )
        })}
      </>
    )
  }
)

const EstimatesUpdater: React.FC<{
  task: MissionTaskWithSpeed
  area: Area
  missionSpeed: number
  previousLocation: Coordinates | null
  onChange: (id: number, patch: Partial<MissionTask>) => void
}> = (props) => {
  const isDebouncing = useIsDebouncing({
    ...props,
    // Ignore change in values which are not used for estimate calculation
    task: _.omit(props.task, ['waypoints', 'estDistance', 'estDuration']),
  })

  const { task, area, missionSpeed, previousLocation, onChange } = props

  const { setEstimateUpdating } = useEstimatesUpdates()
  const lastRequestRef = React.useRef<Promise<void>>()

  const speed = task.speed || missionSpeed

  React.useLayoutEffect(() => {
    if (task.type === 'sensors') {
      return
    }

    if (isDebouncing) {
      setEstimateUpdating(task.id, true)
      lastRequestRef.current = undefined
      if (task.type === 'lawnmower') {
        onChange(task.id, { waypoints: [] })
      }
      return
    }

    if (!previousLocation) {
      onChange(task.id, {
        estDistance: 0,
        estDuration: 0,
      })
      setEstimateUpdating(task.id, false)
      return
    }

    if (task.type === 'stationKeep' || task.type === 'survey') {
      onChange(task.id, {
        estDuration: task.args.duration,
      })
      setEstimateUpdating(task.id, false)
      return
    }

    const request = getTaskEstimate({
      missionTask: task,
      nominalSpeed: speed,
      currentLocation: previousLocation,
      origin: area.coordinates,
    })
      .then((result) => {
        if (request === lastRequestRef.current) {
          onChange(task.id, result)
        }
      })
      .catch((err) => {
        if (request === lastRequestRef.current) {
          console.error(err)
          message.error(
            ERROR_MESSAGES.missionTaskEstimate(getTaskTypeName(task.type))
          )
        }
      })
      .finally(() => {
        if (request === lastRequestRef.current) {
          setEstimateUpdating(task.id, false)
        }
      })
    lastRequestRef.current = request
  }, [isDebouncing])

  return null
}

const useIsDebouncing = (props: unknown): boolean => {
  const memoizedProps = useDeepMemoizedValue(props)
  const debouncedProps = useDebounce(memoizedProps, 300)
  return debouncedProps !== memoizedProps
}
