import {
  Area,
  getAllMissions,
  getAllSensorParameters,
  getAreaList,
  getSwanBotsForUser,
  MissionType,
  SensorParameter,
  SwanBot,
} from 'swanviz'
import useSWR from 'swr'

type DataVisDictionaries = {
  areas: Area[]
  swanBots: SwanBot[]
  missions: MissionType[]
  sensors: SensorParameter[]
}

type UseDataVisDictionaries =
  | {
      loading: false
      error: false
      data: DataVisDictionaries
    }
  | {
      loading: true
      error: false
      data?: undefined
    }
  | {
      loading: false
      error: true
      data?: undefined
    }

export const useDataVisDictionaries = (): UseDataVisDictionaries => {
  const { data: areas, isValidating: isAreaListLoading } = useSWR(
    'getAreaList',
    getAreaList
  )
  const { data: swanBots, isValidating: isAllSwanBotsLoading } = useSWR(
    'getSwanBotsForUser',
    getSwanBotsForUser
  )
  const { data: missions, isValidating: isMissionsLoading } = useSWR(
    'getAllMissions',
    getAllMissions
  )
  const { data: sensors, isValidating: isSensorsLoading } = useSWR(
    'getAllSensorParameters',
    getAllSensorParameters
  )

  const isLoading =
    isAreaListLoading ||
    isAllSwanBotsLoading ||
    isMissionsLoading ||
    isSensorsLoading

  if (isLoading) {
    return {
      loading: true,
      error: false,
    }
  }

  if (!areas || !swanBots || !missions || !sensors) {
    return {
      loading: false,
      error: true,
    }
  }

  return {
    data: {
      areas,
      missions,
      swanBots,
      sensors,
    },
    loading: false,
    error: false,
  }
}
