import { Button, Popover } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'
import _ from 'lodash'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Area,
  MissionRun,
  MissionType,
  SensorParameter,
  SwanBot,
} from 'swanviz'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { getInterpolationLink } from '../../Interpolation/query'
import { FormValues } from '../types'

import css from './style.module.css'

type Props = {
  dataVisFilter: FormValues
  area: Area
  missions: MissionType[]
  swanBots: SwanBot[]
  selectedMissionRuns: MissionRun[]
  sensors: SensorParameter[]
}

export const InterpolatedLink: React.FC<Props> = ({
  dataVisFilter,
  area,
  missions,
  swanBots,
  selectedMissionRuns,
  sensors,
}) => {
  const location = useLocation()
  const { isDesktop } = useWindowSize()
  const [isPopupVisible, setIsPopupVisible] = React.useState(false)

  const getInterpolateLinkForSensor = (sensor: SensorParameter) =>
    getInterpolationLink({
      areaId: dataVisFilter.areaId,
      missionName: missions.find(
        (mission) => mission.id === dataVisFilter.missionId
      )?.name,
      sensorParam: sensor.name,
      startDate: dataVisFilter.datesRange[0].valueOf(),
      endDate: dataVisFilter.datesRange[1].valueOf(),
      swanbotName:
        swanBots.find((swanBot) => swanBot.id === dataVisFilter.swanbotId)
          ?.name ?? undefined,
      backUrl: location.pathname + location.search,
      missionRunIds: selectedMissionRuns.map((run) => run.runId),
      missionRunDates: selectedMissionRuns.map((run) => run.runDate),
      missionRunNames: selectedMissionRuns.map((run) => run.missionName),
      maxDepth: area.depth,
    })

  return (
    <Popover
      visible={isPopupVisible}
      onVisibleChange={setIsPopupVisible}
      trigger="click"
      overlayClassName={css.popoverOverlay}
      align={{ points: isDesktop ? ['tl', 'bl'] : ['tc', 'bc'] }}
      content={
        <>
          {_.sortBy(sensors, 'id').map((sensor) => (
            <Link
              key={sensor.id}
              to={getInterpolateLinkForSensor(sensor)}
              target="_blank"
              className={css.item}
              onClick={() => setIsPopupVisible(false)}
            >
              {sensor.description}
            </Link>
          ))}
        </>
      }
    >
      <InterpolatedLinkButton
        isPopupVisible={isPopupVisible}
        disabled={selectedMissionRuns.length === 0}
      />
    </Popover>
  )
}

const InterpolatedLinkButton: React.FC<
  ButtonProps & {
    isPopupVisible: boolean
  }
> = ({ isPopupVisible, ...buttonProps }) => {
  const { isDesktop } = useWindowSize()
  return (
    <Button
      type={isDesktop ? (isPopupVisible ? 'text' : 'link') : 'ghost'}
      size={isDesktop ? 'middle' : 'large'}
      block={!isDesktop}
      {...buttonProps}
    >
      {isDesktop ? 'Interpolate' : 'Interpolated'}
    </Button>
  )
}
