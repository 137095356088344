import React, { HTMLAttributes } from 'react'
import classNames from 'classnames'

import css from './style.module.css'

type Props = {
  task: React.ReactNode
  estimation: React.ReactNode
} & HTMLAttributes<HTMLDivElement>

export const ItemContainer: React.FC<Props> = ({
  task,
  estimation,
  className,
  ...rest
}) => {
  return (
    <div className={classNames(css.main, className)} {...rest}>
      <div className={css.task}>{task}</div>
      <div className={css.estimation}>{estimation}</div>
    </div>
  )
}
