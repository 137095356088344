import _ from 'lodash'
import { Geofence, MissionType, SwanBot } from 'swanviz'

export const getItemsForSelect = <T extends SwanBot | MissionType | Geofence>(
  items: T[],
  areaId?: number
): T[] => {
  const filtered = areaId
    ? items.filter((item) => item.areaId === areaId)
    : items
  return _.sortBy(filtered, (item) => item.name?.toLowerCase())
}
