import { MissionTask } from 'swanviz'

const MISSION_TASK_NAMES: Record<MissionTask['type'], string> = {
  go: 'Go to',
  survey: 'Auto survey',
  lawnmower: 'Lawnmower',
  sensorDepth: 'Sensor depth',
  waterSampler: 'Take water sample',
  stationKeep: 'Station keep',
  speed: 'Speed',
  sensors: 'Sensors',
}

export const getTaskTypeName = (type: MissionTask['type']): string =>
  MISSION_TASK_NAMES[type]
