import { Button, Empty, Table } from 'antd'
import { SortOrder } from 'antd/es/table/interface'
import React from 'react'
import { Area, NotificationFilter, SwanBot } from 'swanviz'

import { timeFormat } from '../../../../utils/momentFormat'
import { NOTIFICATION_TYPES } from '../../../../utils/notifications'
import { getFilters, stickyProps } from '../../../../utils/table'
import { formatDateTimeList } from '../../../../utils/time'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { PopulatedNotification } from '../useAlertsPageData'
import {
  DATA_INDEX_AREA,
  DATA_INDEX_SWANBOT,
  DATA_INDEX_TYPE,
  FILTER_SORT_FIELDS,
  getColumns,
} from './columns'

type Props = {
  isLoading: boolean
  notifications: PopulatedNotification[]
  areas: Area[]
  swanBots: SwanBot[]
  filter: NotificationFilter
  onChangeFilter: (filter: Partial<NotificationFilter>) => void
  onResetFilters: () => void
}

export const DEFAULT_SORT_FIELD: NotificationFilter['sortField'] = 'ntf_time'
export const DEFAULT_SORT_DIRECTION: NotificationFilter['sortDirection'] =
  'DESC'

export const AlertsTable: React.FC<Props> = ({
  isLoading,
  notifications,
  areas,
  swanBots,
  filter,
  onChangeFilter,
  onResetFilters,
}) => {
  const { isDesktop } = useWindowSize()

  const dateTimeObjects = formatDateTimeList({
    dates: notifications.map((notification) => notification.ntfTime),
    timeFormat,
  })
  const areaFilters = getFilters({ items: areas })
  const swanBotFilters = getFilters({ items: swanBots })

  return (
    <Table
      dataSource={notifications}
      columns={getColumns({
        dateTimeObjects,
        filter,
        areaFilters,
        swanBotFilters,
        isDesktop,
      })}
      rowKey={(row) => row.id}
      {...stickyProps}
      pagination={false}
      loading={isLoading}
      locale={{
        emptyText: () => {
          const areFiltersActive =
            filter.type?.length ||
            filter.areaId?.length ||
            filter.swanbotId?.length

          return (
            <Empty
              description={
                areFiltersActive
                  ? 'No notifications meeting filter criteria'
                  : 'No data'
              }
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            >
              {areFiltersActive && (
                <Button onClick={onResetFilters}>Reset filters</Button>
              )}
            </Empty>
          )
        },
      }}
      onChange={(_newPagination, newFilters, newSorter) => {
        const newTypes = newFilters[
          DATA_INDEX_TYPE
        ]?.filter((item): item is PopulatedNotification['type'] =>
          NOTIFICATION_TYPES.includes(item as any)
        )

        const newAreas = newFilters[DATA_INDEX_AREA]?.map(
          Number
        ).filter((item) => areas.some((area) => area.id === item))

        const newSwanBots = newFilters[DATA_INDEX_SWANBOT]?.map(
          Number
        ).filter((item) => swanBots.some((swanBot) => swanBot.id === item))

        const { field, order } = Array.isArray(newSorter)
          ? newSorter[0]
          : newSorter

        onChangeFilter({
          ...getSortingParameters({
            field: field as keyof PopulatedNotification,
            order,
          }),
          type: newTypes,
          areaId: newAreas,
          swanbotId: newSwanBots,
          pageNo: 1,
        })
      }}
    />
  )
}

export const getSortingParameters = ({
  field,
  order,
}: {
  field?: keyof PopulatedNotification
  order?: SortOrder
}): Pick<NotificationFilter, 'sortField' | 'sortDirection'> => {
  const newSortField = order && field ? FILTER_SORT_FIELDS[field] : undefined
  const newSortDirection =
    (order && newSortField && (order === 'ascend' ? 'ASC' : 'DESC')) ||
    undefined

  return {
    sortField: newSortField || DEFAULT_SORT_FIELD,
    sortDirection: newSortDirection || DEFAULT_SORT_DIRECTION,
  }
}
