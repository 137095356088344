import { GeoJSON } from 'geojson'
import { Coordinates } from 'swanviz'
import _ from 'lodash'

const MIN_LNG = -180
const MAX_LNG = 180
const MIN_LAT = -90
const MAX_LAT = 90

export const createPointFeature = (
  coordinates: Coordinates,
  properties = {}
): GeoJSON.Feature<GeoJSON.Point> => ({
  type: 'Feature',
  properties,
  geometry: {
    type: 'Point',
    coordinates,
  },
})

export const createLineFeature = (
  coordinates: Coordinates[],
  properties = {}
): GeoJSON.Feature<GeoJSON.LineString> => ({
  type: 'Feature',
  properties,
  geometry: {
    type: 'LineString',
    coordinates,
  },
})

export const createPolygonFeature = (
  coordinates: Coordinates[][],
  properties = {}
): GeoJSON.Feature<GeoJSON.Polygon> => {
  return {
    type: 'Feature',
    properties,
    geometry: {
      type: 'Polygon',
      coordinates: coordinates.map(getClosedCoordinates),
    },
  }
}

export const getClosedCoordinates = (
  coordinates: Coordinates[]
): Coordinates[] => {
  const firstPoint = _.first(coordinates)
  const lastPoint = _.last(coordinates)
  return firstPoint && !_.isEqual(firstPoint, lastPoint)
    ? [...coordinates, firstPoint]
    : coordinates
}

export const formatCoordinates = (
  coordinates: Coordinates | null,
  precision = 6
): string =>
  coordinates?.map((coord) => coord.toFixed(precision)).join(', ') || ''

export const areValidCoordinates = (
  coordinates: number[] | null
): coordinates is Coordinates => {
  if (coordinates?.length !== 2) {
    return false
  }

  const [lng, lat] = coordinates
  return lng >= MIN_LNG && lng <= MAX_LNG && lat >= MIN_LAT && lat <= MAX_LAT
}

export const getBounds = (
  coordinates: Coordinates[]
): [Coordinates, Coordinates] => {
  if (coordinates.length) {
    const longitudes = coordinates.map((coord) => coord[0])
    const latitudes = coordinates.map((coord) => coord[1])
    return [
      [Math.min(...longitudes), Math.min(...latitudes)],
      [Math.max(...longitudes), Math.max(...latitudes)],
    ]
  }

  return [
    [MIN_LNG, MIN_LAT],
    [MAX_LNG, MAX_LAT],
  ]
}
