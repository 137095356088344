import _ from 'lodash'
import moment from 'moment'
import { Timestamp } from 'swanviz'
import { shortDateFormat, shortTimeFormat } from './momentFormat'

export type DateTimeDisplayObject = {
  date?: string
  time: string
}

export const formatTotalTime = (time: number, precision = 0): string => {
  const hours = Math.floor(moment.duration(time, 'seconds').asHours())
  const minutes = _.round(
    moment.duration(time, 'seconds').asMinutes() % 60,
    precision
  )

  return (
    [hours && `${hours} hr`, minutes && `${minutes} min`]
      .filter(Boolean)
      .join(' ') || '—'
  )
}

/*
 * Converts an array of timestamps into an array of datetime objects,
 * in which the date is specified only if it differs from the date of the previous array element.
 *
 * Example:
 * ts(4 Aug, 9:37)  | 4 Aug 9:37
 * ts(3 Aug, 12:09) | 3 Aug 12:09
 * ts(3 Aug, 11:43) |       11:43
 * ts(3 Aug, 10:02) |       10:02
 * ts(2 Aug, 19:31) | 2 Aug 19:31
 * */
export const formatDateTimeList = ({
  dates,
  dateFormat = shortDateFormat,
  timeFormat = shortTimeFormat,
}: {
  dates: Timestamp[]
  dateFormat?: string
  timeFormat?: string
}): DateTimeDisplayObject[] => {
  let lastDate: string
  return dates.reduce<DateTimeDisplayObject[]>((acc, timestamp) => {
    const momentObject = moment(timestamp)
    const date = momentObject.format(dateFormat)
    const time = momentObject.format(timeFormat)

    if (!lastDate || date !== lastDate) {
      lastDate = date
      acc.push({
        date,
        time,
      })
    } else {
      acc.push({
        time,
      })
    }

    return acc
  }, [])
}
