import React from 'react'
import { isDefined } from './typeGuards'

export const useDefinedValue = <T>(
  value: T,
  defaultValue: Exclude<T, undefined>
): Exclude<T, undefined> => {
  const [definedValue, setDefinedValue] = React.useState(
    isDefined(value) ? value : defaultValue
  )

  React.useLayoutEffect(() => {
    if (isDefined(value)) {
      setDefinedValue(value)
    }
  }, [value])

  return definedValue
}
