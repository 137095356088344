import { Button, Checkbox, Typography } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { MissionRun, SensorParameter, Timestamp } from 'swanviz'

import { useSelectedHeatmap } from '../../../../contexts/selectedHeatmapContext'
import { rgbToHex } from '../../../../utils/colors'
import { isLiveUpdating } from '../../../../utils/fetchingType'
import { dateAndTimeFormat } from '../../../../utils/momentFormat'
import { isDefined } from '../../../../utils/typeGuards'
import {
  GetSensorColor,
  SetSensorColor,
} from '../../../../utils/useSensorColors'
import { useWindowSize } from '../../../../utils/useWindowSize'
import { RunInfo } from '../../../SensorDataFetcher/types'
import { ImagesModal } from '../ImagesModal'
import { ChartRange } from '../LinearChart'
import { MissionRunSensor } from '../MissionRunSensor'

import css from './style.module.css'

type MoveDirection = 'up' | 'down'

type Props = {
  missionRun: MissionRun
  runInfo: RunInfo
  sensors: SensorParameter[]
  withGraph: boolean
  isPathShown: boolean
  onIsPathShownChange: (runId: MissionRun['runId'], isChecked: boolean) => void
  onChangeColor: SetSensorColor
  getSensorColor: GetSensorColor
  allowedMoveDirections: MoveDirection[]
  onIsPausedChange: (value: boolean) => void
  onMove: (direction: MoveDirection) => void
}

export const MissionRunSummary: React.FC<Props> = ({
  missionRun,
  runInfo,
  sensors,
  withGraph,
  isPathShown,
  onIsPathShownChange,
  onChangeColor,
  getSensorColor,
  allowedMoveDirections,
  onIsPausedChange,
  onMove,
}) => {
  const { isDesktop, isMobile } = useWindowSize()
  const { isHeatmapSelected, setSelectedHeatmap } = useSelectedHeatmap()
  const { runId, missionName, runDate, swanbotName, sensorIds } = missionRun
  const runDataTimes: Timestamp[] = Object.values(runInfo.dataBySensor)
    .flatMap((sensorInfo) => sensorInfo?.data.map((datum) => datum.time))
    .filter(isDefined)
  const minTime = Math.min(...runDataTimes)
  const maxTime = Math.max(...runDataTimes)
  // For chart synchronization to work charts must have the same x range
  const xRange: ChartRange = [minTime, maxTime]
  const runDateFormatted = moment(runDate).format(dateAndTimeFormat)

  const isRunLiveUpdating = isLiveUpdating(missionRun.status)
  const isLiveUpdatePaused = runInfo.isPaused
  const showPauseUpdatesControl =
    isDesktop && withGraph && isRunLiveUpdating && sensorIds.length > 0

  // Turn live update back on when switching to map
  React.useEffect(() => {
    if (!withGraph && isLiveUpdatePaused) {
      onIsPausedChange(false)
    }
  }, [withGraph])

  React.useEffect(() => {
    if (
      sensorIds.length &&
      !sensorIds.some((sensorId) => isHeatmapSelected(runId, sensorId))
    ) {
      setSelectedHeatmap(runId, sensorIds[0])
    }
  }, [sensorIds, runId, isHeatmapSelected, setSelectedHeatmap])

  React.useEffect(() => {
    return () => setSelectedHeatmap(runId, undefined)
  }, [runId])

  return (
    <>
      <div className={classNames(css.header, withGraph && css.withGraph)}>
        <div className={css.infoContainer}>
          <div className={css.titleWrapper}>
            <Typography.Text className={css.title}>
              {missionName}
            </Typography.Text>
            {!withGraph && (
              <div className={css.orderButtons}>
                {(['up', 'down'] as const).map((direction) => (
                  <Button
                    key={direction}
                    className={css.orderButton}
                    size="small"
                    type="text"
                    icon={direction === 'up' ? '↑' : '↓'}
                    disabled={!allowedMoveDirections.includes(direction)}
                    onClick={() => onMove(direction)}
                  />
                ))}
              </div>
            )}
          </div>
          <div className={css.info}>
            <Typography.Text
              type={withGraph && isMobile ? 'secondary' : undefined}
              className={css.infoTextContainer}
            >
              <span className={css.text}>{runDateFormatted}</span>
              <span
                className={classNames(css.text, css.withEllipsis)}
                title={swanbotName}
              >
                {swanbotName}
              </span>
            </Typography.Text>
            {withGraph && (
              <ImagesModal
                runId={runId}
                title={`${missionName} (${runDateFormatted})`}
              />
            )}
          </div>
        </div>
        <div className={css.actions}>
          {(isDesktop || !withGraph) && (
            <Checkbox
              className={css.checkbox}
              checked={isPathShown}
              onChange={(e) =>
                onIsPathShownChange(missionRun.runId, e.target.checked)
              }
            >
              SwanBot path
            </Checkbox>
          )}
          {showPauseUpdatesControl && (
            <Button
              className={css.pause}
              danger={isLiveUpdatePaused}
              onClick={() => onIsPausedChange(!isLiveUpdatePaused)}
            >
              {isLiveUpdatePaused ? 'Resume live update' : 'Pause live update'}
            </Button>
          )}
        </div>
      </div>
      {sensorIds.length > 0 ? (
        sensorIds.map((sensorId) => {
          const color = rgbToHex(getSensorColor(runId, sensorId))
          const sensor = sensors.find((s) => s.id === sensorId)

          if (!sensor) return null

          const { data: sensorData, isError } =
            runInfo.dataBySensor[sensorId] || {}

          return (
            <div
              key={sensorId}
              className={withGraph ? css.sensorChart : css.sensorLegend}
            >
              <MissionRunSensor
                missionRun={missionRun}
                sensor={sensor}
                sensorData={sensorData}
                color={color}
                xRange={xRange}
                withGraph={withGraph}
                isError={isError}
                disableZoom={
                  isMobile || (isRunLiveUpdating && !isLiveUpdatePaused)
                }
                onChangeColor={onChangeColor(runId, sensorId)}
              />
            </div>
          )
        })
      ) : (
        <div className={css.notification}>
          <Typography.Text>
            ← Please select parameters on the left panel
          </Typography.Text>
        </div>
      )}
    </>
  )
}
