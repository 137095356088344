import { Alert } from 'antd'
import React from 'react'

import { useLocalStorageState } from '../../utils/useLocalStorageState'
import { useWindowSize } from '../../utils/useWindowSize'

const STORAGE_KEY = 'landscape_unsupported_alert_was_closed'

export const LandscapeUnsupportedAlert: React.FC = () => {
  const { isMobile, isSmallHeightLandscape } = useWindowSize()
  const [alertWasClosed, setAlertWasClosed] = useLocalStorageState(
    STORAGE_KEY,
    false
  )

  if (!alertWasClosed && isMobile && isSmallHeightLandscape) {
    return (
      <Alert
        message="Landscape mode isn't supported. The app may have some visual glitches."
        banner
        closable
        onClose={() => setAlertWasClosed(true)}
      />
    )
  }

  return null
}
