import { Notification } from 'swanviz'

export const NOTIFICATION_TYPES: Notification['type'][] = [
  'MISSION',
  'SENSOR DATA',
  'SWANBOT',
]

const notificationTypeNames: Record<Notification['type'], string> = {
  MISSION: 'Mission',
  'SENSOR DATA': 'Sensor data',
  SWANBOT: 'SwanBot',
}

export const formatType = (type: Notification['type']): string =>
  notificationTypeNames[type]
