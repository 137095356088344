import _ from 'lodash'
import React from 'react'
import { SwanBot } from 'swanviz'

import { useDeepMemoizedValue } from '../../../../utils/useDeepMemoizedValue'
import { ActualPathFetcher } from '../../../ActualPathFetcher'
import { RunPath } from '../../DataVis/types'

export type PathsBySwanBot = Record<SwanBot['id'], RunPath | undefined>

export const SwanBotPathsFetcher: React.FC<{
  swanBots: SwanBot[]
  children: (pathsBySwanBot: PathsBySwanBot) => React.ReactNode
}> = ({ swanBots, children }) => {
  const [pathsBySwanBot, setPathsBySwanBot] = React.useState<PathsBySwanBot>({})

  // Keep paths only for currently visible SwanBots
  const swanBotIds = useDeepMemoizedValue(swanBots.map((swanBot) => swanBot.id))
  React.useEffect(() => {
    setPathsBySwanBot((state) => _.pick(state, swanBotIds))
  }, [swanBotIds])

  return (
    <>
      {swanBots.map((swanBot) => {
        return (
          swanBot.currRunId && (
            <ActualPathFetcher
              key={swanBot.id}
              runId={swanBot.currRunId}
              fetchingType={
                swanBot.status === 'IN_A_MISSION' ? 'liveActive' : 'livePaused'
              }
              onChange={(pathData) =>
                setPathsBySwanBot((state) => ({
                  ...state,
                  [swanBot.id]: pathData,
                }))
              }
            />
          )
        )
      })}
      {children(pathsBySwanBot)}
    </>
  )
}
